import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'
import 'firebase/messaging'
// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyCOKJyQDwiOrrGuthc808qB3MRQKK6aJEs",
  authDomain: "bitcardapio.firebaseapp.com",
  databaseURL: "https://bitcardapio.firebaseio.com",
  projectId: "bitcardapio",
  storageBucket: "bitcardapio.appspot.com",
  messagingSenderId: "787445357188",
  appId: "1:787445357188:web:277d6b1e832cda2bb1c0d1"
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)

export default firebase
