import React from 'react'
import {AppBar, Card, CardContent, Divider, FormLabel, IconButton, Toolbar, Typography} from '@material-ui/core'
import {ArrowBack as ArrowBackIcon} from '@material-ui/icons'
import './Area.css'
import {showData, pageView, IDENTIFIER_STORE} from '../../util'

let identifier

class Area extends React.Component {

    state = {
        area: [],
        radius: [],
        colorStore: {
            color_title: '',
            color_description: '',
            color_price: '',
            color_toolbar: '',
            color_body: '',
            color_cardProduct: '',
            color_fontToolbar: ''
        }
    }

    onClickBack = () => this.props.history.goBack()

    serviceArea = () => {
        let radius = localStorage.getItem(`bitmenu-${identifier}:serviceRadius`)
        radius = showData(radius)
        radius = radius !== null ? JSON.parse(radius) : []
        let area = localStorage.getItem(`bitmenu-${identifier}:area_atendimento`)
        area = showData(area)
        area = area !== null ? JSON.parse(area) : ''
        this.setState({area: area, radius: radius})
    }

    personalize = () => {
        try {
            let colors = JSON.parse(localStorage.getItem(`bitmenu-${identifier}:colorStore`))
            const {title, description, price, toolbar, body, cardProduct, nextButton, fontButton, fontToolbar} = colors
            this.setState({
                colorStore: {
                    color_title: `#${title}`,
                    color_description: `#${description}`,
                    color_price: `#${price}`,
                    color_toolbar: `#${toolbar}`,
                    color_body: `#${body}`,
                    color_cardProduct: `#${cardProduct}`,
                    color_nextButton: `#${nextButton}`,
                    color_fontButton: `#${fontButton}`,
                    color_fontToolbar: `#${fontToolbar}`
                }
            })
        } catch (e) {

        }
    }

    componentDidMount() {
        identifier = IDENTIFIER_STORE
        this.serviceArea()
        this.personalize()
        window.scrollTo(0, 0)
        pageView()
    }

    render() {
        const {
            area,
            radius,
            colorStore: {
                color_title,
                color_price,
                color_toolbar,
                color_body,
                color_cardProduct,
                color_fontToolbar
            }
        } = this.state
        return (
            <div id="area" style={color_body ? {background: color_body} : {}}>
                <AppBar
                    id="appBar"
                    position="sticky"
                    style={color_toolbar ? {background: color_toolbar} : {}}
                    color="default">
                    <Toolbar variant="dense">
                        <IconButton style={color_fontToolbar ? {color: color_fontToolbar} : {}}
                                    edge="start" color="inherit"
                                    aria-label="menu" onClick={this.onClickBack}>
                            <ArrowBackIcon/>
                        </IconButton>
                        <Typography
                            id={'label-title'}
                            style={color_fontToolbar ? {color: color_fontToolbar} : {}}
                            variant="h6"
                            color="inherit">
                            Área de atendimento
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div id={'container-area'}>
                    {
                        radius.length !== 0 &&
                        <div>
                            <Card id="card-city">
                                <CardContent id="card-content-city"
                                             style={color_cardProduct ? {background: color_cardProduct} : {}}>
                                    <FormLabel id="label-titulo-city" style={color_title ? {color: color_title} : {}}>
                                        Raio de distância
                                    </FormLabel>
                                </CardContent>
                            </Card>
                            <Card id="card-city">
                                <CardContent id="card-content-city"
                                             style={color_cardProduct ? {background: color_cardProduct} : {}}>
                                    {
                                        radius.map((i, index) => (
                                            <div id="div-districts" key={index}>
                                                <FormLabel
                                                    style={color_title ? {color: color_title} : {}}
                                                    id="label-titulo-districts">{`${i.distance} - Metros`}</FormLabel>
                                                <FormLabel
                                                    style={color_price ? {color: color_price} : {}}
                                                    id="label-price">{i.price.toLocaleString('pt-BR', {
                                                    style: 'currency',
                                                    currency: 'BRL'
                                                })}</FormLabel>
                                                <Divider/>
                                            </div>
                                        ))
                                    }
                                </CardContent>
                            </Card>
                        </div>
                    }
                    <div>
                        {
                            area.map((i, index) => (
                                <div id="div-card-city" key={index}>
                                    <Card id="card-city"
                                          style={color_cardProduct ? {background: color_cardProduct} : {}}>
                                        <CardContent id="card-content-city">
                                            <FormLabel id="label-titulo-city"
                                                       style={color_title ? {color: color_title} : {}}>{i.name}</FormLabel>
                                        </CardContent>
                                    </Card>
                                    <Card key={index} id="card-city"
                                          style={color_cardProduct ? {background: color_cardProduct} : {}}>
                                        <CardContent id="card-content-city">
                                            {
                                                i.districts.map((a, index) => (
                                                    <div id="div-districts" key={index}>
                                                        <div>
                                                            <FormLabel id="label-titulo-districts"
                                                                       style={color_title ? {color: color_title} : {}}>{a.name}</FormLabel>
                                                            <FormLabel
                                                                id="label-titulo-permission">{a.permission === 'partial' ? '(Atendimendo Parcial)' : ''}</FormLabel>
                                                        </div>
                                                        <FormLabel
                                                            style={color_price ? {color: color_price} : {}}
                                                            id="label-price">{a.deliveryFee.toLocaleString('pt-BR', {
                                                            style: 'currency',
                                                            currency: 'BRL'
                                                        })}</FormLabel>
                                                        <Divider/>
                                                    </div>
                                                ))
                                            }
                                        </CardContent>
                                    </Card>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default Area
