import React from 'react'
import {ArrowBack as ArrowBackIcon} from '@material-ui/icons'
import {
    AppBar,
    Toolbar,
    Typography,
    FormControlLabel,
    RadioGroup,
    TextField,
    Box,
    Divider,
    Button,
    Snackbar,
    Radio,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Dialog,
    Checkbox, CardMedia, FormLabel
} from '@material-ui/core'
import {withStyles, createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles'
import './Cadastro.css'
import {withRouter} from 'react-router-dom'
import user from '../../usuario.json'
import bitmenu from '../../images/bitmenu.png'
import {IDENTIFIER_STORE, pageView, rest, URL_BASE, validaCPF, validarCNPJ} from '../../util'
import {maskDate} from "react-lf-tools";

let identifier

const RedRadio = withStyles({
    root: {
        color: '#000000',
        '&$checked': {
            color: '#000000',
        },
    },
    checked: {},
})(props => <Radio color="default" {...props} />)

const RedCheck = withStyles({
    root: {
        color: '#000000',
        '&$checked': {
            color: '#000000',
        },
    },
    checked: {},
})(props => <Checkbox color="default" {...props} />)

class Cadastro extends React.Component {

    state = {
        name: '',
        email: '',
        password: '',
        repeatPassword: '',
        phone: '',
        ddd: '',
        cpf: '',
        cep: '',
        number: '',
        street: '',
        neighborhood: '',
        city: '',
        complement: '',
        registre: true,
        house: '',
        errorCpf: false,
        openSnackBar: false,
        openTerm: false,
        google: false,
        snackBar: '',
        birthDate: ''
    }

    handleInputs = (e) => {
        let value = e.target.value
        if (e.target.name === 'cep') {
            value = e.target.value.replace(/[^\d]+/g, '')
            if (value.length >= 8) {
                this.validaCep(value)
            }
        } else if (e.target.name === 'phone') {
            if (e.target.name !== undefined) {
                let phone = e.target.value
                phone = phone.trim()
                phone = phone.replace(/[^\d]+/g, '')
                if (phone === '') {
                    this.setState({[e.target.name]: ''})
                    return
                }
                phone = phone.substring(phone.length - 11)
                this.setState({[e.target.name]: this.maskPhone(phone)})
            }
        } else if (e.target.name === 'cpf') {
            this.setState({cpf: value.substring(0, 18)})
        } else if (e.target.name === 'birthDate') {
            value = value.substring(0, 10)
            this.setState({birthDate: maskDate(value)})
        } else {
            this.setState({[e.target.name]: value})
        }
    }

    onRadio = e =>
        this.setState({house: e.target.value})

    closeSnackBar = () =>
        this.setState({openSnackBar: false})

    onClickTerm = () => {
        const {readTerms} = this.state
        if (readTerms) {
            localStorage.setItem(`bitmenu-${identifier}:term`, 'ok')
            this.setState({openTerm: false})
        } else {
            this.setState({openSnackBar: true, snackBar: 'Leia os termos até o final'})
        }
    }

    onClickBack = () =>
        this.props.history.goBack()

    clearText = text => {
        try {
            text = text.replace(/[^\d]+/g, '')
            return text.trim()
        } catch (e) {
            console.log(e)
        }
        return '';
    }

    onClickCadastrar = () => {
        try {
            const {
                email,
                password,
                repeatPassword,
                name,
                phone,
                cpf,
                cep,
                number,
                street,
                neighborhood,
                city,
                complement,
                house,
            } = this.state
            try {
                let clientCPF = this.clearText(cpf)
                if (clientCPF.length > 11) {
                    if (!validarCNPJ(clientCPF)) return this.setState({
                        snackBar: 'Coloque um CNPJ válido',
                        openSnackBar: true
                    })
                } else {
                    if (!validaCPF(clientCPF)) return this.setState({
                        snackBar: 'Coloque um CPF válido',
                        openSnackBar: true
                    })
                }
            } catch (e) {

            }
            if (email === '' || email.length < 5 || !email.includes('@')) {
                this.setState({openSnackBar: true, snackBar: 'Coloque um email'})
                return
            } else if (password !== repeatPassword) {
                this.setState({openSnackBar: true, snackBar: 'Senhas diferentes'})
                return
            } else if (password.length < 5) {
                this.setState({openSnackBar: true, snackBar: 'Senha deve ter 6 caracteres'})
                return
            } else if (name === '') {
                this.setState({openSnackBar: true, snackBar: 'Coloque um nome'})
                return
            } else if (phone === '' || phone.length < 14 || (phone.substring(phone.length - 9).substring(0, 1) !== '9')) {
                this.setState({
                    openSnackBar: true,
                    snackBar: 'Telefone inválido, o campo telefone precisar sem um número de celular'
                })
                return
            } else if (cep.length < 8) {
                this.setState({openSnackBar: true, snackBar: 'Cep inválido'})
                return
            } else if (number === '') {
                this.setState({openSnackBar: true, snackBar: 'Número inválido'})
                return
            } else if (street === '') {
                this.setState({openSnackBar: true, snackBar: 'Coloque a rua'})
                return
            } else if (neighborhood === '') {
                this.setState({openSnackBar: true, snackBar: 'Coloque o bairro'})
                return
            } else if (city === '') {
                this.setState({openSnackBar: true, snackBar: 'Coloque a cidade'})
                return
            } else if (house === '') {
                this.setState({openSnackBar: true, snackBar: 'Selecione tipo Casa ou Apto'})
                return
            } else if (house === 'apto' && complement === '') {
                this.setState({openSnackBar: true, snackBar: 'Coloque o complemento'})
                return
            }
            this.registre()
        } catch (e) {
            alert(e)
        }
    }

    registre = () => {
        try {
            let {
                email,
                password,
                name,
                phone,
                cpf,
                cep,
                number,
                street,
                neighborhood,
                city,
                uf,
                complement,
                house,
                birthDate
            } = this.state
            let key = this.key()
            let dddPhone = phone.split(')')
            let json = {
                email: email.toLowerCase().trim(),
                nome: name,
                key: key,
                senha: password,
                telefone: dddPhone[1].trim(),
                ddd: dddPhone[0].substring(1),
                cpf: cpf,
                nascimento: birthDate,
                endereco: [{
                    cep: cep,
                    rua: street,
                    numero: number,
                    bairro: neighborhood,
                    cidade: city,
                    uf: uf,
                    casa: house,
                    complemento: complement
                }]
            }
            this.checkEmail(email, json, key)
        } catch (e) {
            alert(e)
        }
    }

    checkEmail = async (email, json, key) => {
        let conexao = {
            method: 'POST',
            body: JSON.stringify({email: email})
        }
        const {returnCode, data} = await rest(`${URL_BASE}/login`, conexao)
        if (returnCode && data) {
            this.setState({openSnackBar: true, snackBar: 'E-mail já está cadastrado.'})
        } else {
            this.recordRegistration(json, key)
        }
    }

    recordRegistration = async (json, key) => {
        if (key === '' || key === undefined) return
        localStorage.setItem(`bitmenu-${identifier}:cliente`, JSON.stringify(json))
        let conexao = {method: 'POST', body: JSON.stringify({json: json, key: key})}
        const {returnCode} = await rest(`${URL_BASE}/cadastro`, conexao)
        if (returnCode) this.props.history.replace('/carrinho')
    }

    key = () => {
        var hora = new Date()
        return hora.getTime()
    }

    validaCep = cep => {
        cep = cep.replace(/[^\d]+/g, '')
        return fetch(`https://bitmenu.com.br/util/ceps.php?tipo=json&chave=578099157F74DB95A37FEBB53CFEAEC1&cep=${cep}`)
            .then((response) => response.json())
            .then((json) => {
                try {
                    const {bairro, cidade, endereco, uf} = json.CEP.dadoscep
                    this.setState({
                        cep: cep,
                        street: endereco,
                        neighborhood: bairro,
                        city: cidade,
                        uf: uf
                    })
                } catch (e) {

                }
            })
            .catch((error) => {
                console.error(error)
            })
    }

    validaCPF = cpf => {
        cpf = cpf.replace(/[^\d]+/g, '')
        if (cpf === '') return false
        if (cpf.length !== 11 ||
            cpf === "00000000000" ||
            cpf === "11111111111" ||
            cpf === "22222222222" ||
            cpf === "33333333333" ||
            cpf === "44444444444" ||
            cpf === "55555555555" ||
            cpf === "66666666666" ||
            cpf === "77777777777" ||
            cpf === "88888888888" ||
            cpf === "99999999999")
            return false
        let add = 0
        let rev
        for (let i = 0; i < 9; i++)
            add += parseInt(cpf.charAt(i)) * (10 - i)
        rev = 11 - (add % 11)
        if (rev === 10 || rev === 11)
            rev = 0
        if (rev !== parseInt(cpf.charAt(9)))
            return false
        add = 0
        for (let i = 0; i < 10; i++)
            add += parseInt(cpf.charAt(i)) * (11 - i)
        rev = 11 - (add % 11)
        if (rev === 10 || rev === 11)
            rev = 0
        if (rev !== parseInt(cpf.charAt(10)))
            return false
        return true
    }

    maskPhone = number => {
        if (number !== '')
            return number.replace(/\D/g, '').replace(/(\d{2})(\d)/, '($1) $2')
    }

    checkTerm = () => {
        if (localStorage.getItem(`bitmenu-${identifier}:term`) === null)
            this.setState({openTerm: true})
    }

    checkClient = () => {
        try {
            let client = sessionStorage.getItem(`bitmenu-email:`)
            client = client !== null ? JSON.parse(client) : ''
            if (client !== '') {
                const {name, email, password, google} = client
                this.setState({
                    google: (google !== undefined) ? google : false,
                    name: name,
                    email: email,
                    password: password,
                    repeatPassword: password
                })
            }
        } catch (e) {
            console.error(e)
        }
    }

    componentDidMount() {
        identifier = IDENTIFIER_STORE
        this.checkClient()
        this.checkTerm()
        window.scrollTo(0, 0)
        pageView()
    }

    render() {
        const {
            name,
            email,
            password,
            repeatPassword,
            cpf,
            errorCpf,
            phone,
            street,
            neighborhood,
            city,
            readTerms,
            google,
            birthDate
        } = this.state
        const theme = createMuiTheme({
            palette: {
                primary: {
                    main: '#000000'
                }
            },
        })
        return (
            <div id="registre">
                <AppBar
                    id="appBar"
                    position="sticky"
                    color="default">
                    <Toolbar variant="dense">
                        <ArrowBackIcon id="voltar-sacola" onClick={this.onClickBack}/>
                        <Typography id={'label-title'} variant="h6" color="inherit">Cadastro</Typography>
                    </Toolbar>
                </AppBar>
                <MuiThemeProvider theme={theme}>
                    <div id="container-registre">
                        <div id="div-container-cadastro">

                            <div id="div-info-cadastro">
                                <CardMedia image={bitmenu} id="img-logo"/>
                                <FormLabel id="label-login">
                                    Seu cadastro é único em todas as lojas do Bitmenu delivery
                                </FormLabel>
                            </div>

                            <div style={{marginBottom: 8}}>
                                <TextField autoFocus margin="dense" value={email.toLowerCase()}
                                           name="email"
                                           label="E-mail"
                                           disabled={google}
                                           type="text" autoComplete="false"
                                           placeholder="E-mail" fullWidth variant="outlined"
                                           onChange={this.handleInputs}/>

                                <div id="form-cadastro" style={{marginBottom: 8}}>
                                    <TextField margin="dense" value={password} name="password"
                                               type="password"
                                               disabled={google}
                                               label="Senha" placeholder="Senha" fullWidth autoComplete="false"
                                               variant="outlined" onChange={this.handleInputs}/>
                                    <Box p={1}/>
                                    <TextField margin="dense" value={repeatPassword}
                                               name="repeatPassword"
                                               disabled={google}
                                               label="Repetir senha" type="password" fullWidth
                                               placeholder="Repita a senha" variant="outlined"
                                               onChange={this.handleInputs}/>
                                </div>
                                <Divider/>
                            </div>
                            <div id="form-cadastro">
                                <TextField margin="dense" name="name" value={name} label="Nome" type="text"
                                           placeholder="Nome"
                                           disabled={google}
                                           fullWidth variant="outlined" autoComplete="false"
                                           onChange={this.handleInputs}/>
                                <Box p={1}/>
                                <TextField margin="dense" name="birthDate" value={birthDate}
                                           label="Nascimento" placeholder="00/00/0000"
                                           variant="outlined" onChange={this.handleInputs}/>
                            </div>
                            <div id="form-cadastro">
                                <TextField margin="dense" name="cpf" error={errorCpf} value={cpf} type="number"
                                           fullWidth
                                           label="CPF/CNPJ" placeholder="CPF/CNPJ" autoComplete="false"
                                           variant="outlined"
                                           onChange={this.handleInputs}/>
                                <Box p={1}/>
                                <TextField margin="dense" name="phone" value={phone}
                                           label="Telefone" placeholder="(00) 00000000" fullWidth
                                           variant="outlined" onChange={this.handleInputs}/>
                            </div>
                            <TextField margin="dense" label="Cep" name="cep" type="text" placeholder="CEP" fullWidth
                                       variant="outlined"
                                       onChange={this.handleInputs}/>
                            <div id="form-cadastro">
                                <TextField margin="dense" name="street" value={street} type="text"
                                           label="Rua"
                                           placeholder="Rua" fullWidth variant="outlined" onChange={this.handleInputs}/>
                                <Box p={1}/>
                                <TextField label="Número" margin="dense" name="number" type="number" placeholder="Nº"
                                           variant="outlined"
                                           onChange={this.handleInputs}/>
                            </div>
                            <div id="form-cadastro">
                                <TextField margin="dense" name="neighborhood" value={neighborhood}
                                           type="text"
                                           label="Bairro" placeholder="Bairro" fullWidth variant="outlined"
                                           onChange={this.handleInputs}/>
                                <Box p={1}/>
                                <TextField margin="dense" label={'Cidade'} name="city" value={city} type="text"
                                           placeholder="Cidade" fullWidth variant="outlined"
                                           onChange={this.handleInputs}/>
                            </div>
                            <div>
                                <RadioGroup id="form-residence">
                                    <FormControlLabel id="form-radio" value="casa" control={<RedRadio/>} label="Casa"
                                                      onChange={this.onRadio}/>
                                    <FormControlLabel id="form-radio" value="apto" control={<RedRadio/>} label="Apto"
                                                      onChange={this.onRadio}/>
                                </RadioGroup>
                            </div>
                            <TextField margin="dense" name="complement" type="text"
                                       label="Complemento" placeholder="Complemento" fullWidth variant="outlined"
                                       onChange={this.handleInputs}/>
                            <div id="container-button-registre">
                                <Button id="button-registre" variant="contained" color="primary"
                                        onClick={() => this.onClickCadastrar()}>Cadastrar</Button>
                            </div>
                        </div>
                    </div>

                    <Dialog open={this.state.openTerm}>
                        <DialogTitle>Termos e Condições Gerais</DialogTitle>
                        <DialogContent>
                            <DialogContentText id={'label-description'} style={{color: '#1e1e1e'}}>
                                Coleta de informações
                            </DialogContentText>
                            <DialogContentText id={'label-description'}>
                                A {user.nome_estabecimento} poderá coletar e armazenar as informações pessoais quando o
                                Usuário:
                                cadastrar-se para obter uma conta na {user.nome_estabecimento}, etapa necessária para
                                poder desfrutar dos produtos colocados à venda no website da empresa; ou
                                realizar compras, visitar páginas, enviar e-mails, e fazer comentários sobre o website,
                                seu funcionamento ou qualquer outro assunto;
                                Os dados cadastrados poderão ser confirmados através de consultas a entidades públicas,
                                companhias especializadas ou bancos de dados,
                                o que, desde já, fica expressamente autorizado.
                            </DialogContentText>
                            <DialogContentText id={'label-description'}>
                                A {user.nome_estabecimento} também recebe e armazena em seus servidores as informações
                                das atividades dos Usuários,
                                obtidas através de cookies (informações enviadas pelo servidor da
                                {user.nome_estabecimento} ao computador do usuário, para identificá-lo).
                                Os cookies servem para controle interno de audiência e de navegação e jamais para
                                controlar, identificar ou rastrear preferências do internauta,
                                exceto quando este desrespeitar alguma regra de segurança ou exercer alguma atividade
                                prejudicial ao bom funcionamento do site, como por exemplo,
                                tentativas de hackear o serviço. A aceitação dos cookies pode ser livremente alterada na
                                configuração de seu navegador.
                            </DialogContentText>
                            <DialogContentText id={'label-description'} style={{color: '#1e1e1e'}}>
                                Cashback e promoções
                            </DialogContentText>
                            <DialogContentText id={'label-description'}>
                                As promoções e cashback não são cumulativas
                            </DialogContentText>
                            <DialogContentText id={'label-description'} style={{color: '#1e1e1e'}}>
                                Pagamento online
                            </DialogContentText>
                            <DialogContentText id={'label-description'}>
                                Por questões de segurança, o cliente deverá apresentar sempre que for solicitado, o
                                documento identidade do titular do cartão, e os 4 últimos digitos do cartão de
                                crédito quando for pagamento online.
                            </DialogContentText>
                            <DialogContentText id={'label-description'} style={{color: '#1e1e1e'}}>
                                Uso das informações
                            </DialogContentText>
                            <DialogContentText id={'label-description'}>
                                Para cumprir devidamente suas obrigações e para que os Usuários possam
                                realizar operações de forma ágil e segura, a {user.nome_estabecimento} solicita alguns
                                dados de
                                caráter pessoal. A coleta dessas informações, além de permitir a oferta de serviços e
                                funcionalidades que se adaptam melhor as necessidades de cada Usuário, tem as seguintes
                                finalidades:
                            </DialogContentText>
                            <DialogContentText id={'label-description'}>
                                desenvolver estudos internos sobre os interesses e comportamentos dos Usuários;
                            </DialogContentText>
                            <DialogContentText id={'label-description'}>
                                melhorar as iniciativas comerciais e promocionais, para aperfeiçoar a oferta de
                                produtos;
                            </DialogContentText>
                            <DialogContentText id={'label-description'}>
                                enviar as informações ou mensagens por e-mail sobre notícias, promoções, publicidade
                                e
                                ofertas da {user.nome_estabecimento};
                            </DialogContentText>
                            <DialogContentText id={'label-description'}>
                                compartilhar a informação pessoal com os provedores de serviços
                                da {user.nome_estabecimento}, como
                                (sem limitar-se a) serviços de transporte, seguros ou intermediários na gestão de
                                pagamentos, call centers ou programas de fidelidade, entre outros.
                            </DialogContentText>
                            <DialogContentText id={'label-description'}>
                                A {user.nome_estabecimento} zelará para que se cumpram certos padrões, mediante a
                                assinatura de
                                contratos, cujo objeto seja a privacidade dos dados pessoais de seus Usuários.
                                Contudo,
                                a {user.nome_estabecimento} não poderá ser responsabilizado pelo uso indevido da
                                informação pessoal
                                do Usuário por parte destas empresas. Se a {user.nome_estabecimento} precisar
                                revelar ou
                                compartilhar
                                informação pessoal com terceiros que não sejam provedores de serviços, órgãos
                                governamentais habilitados ou empresas afiliadas ou relacionadas
                                a {user.nome_estabecimento}, o
                                consentimento do Usuário será previamente requerido.
                            </DialogContentText>
                            <DialogContentText id={'label-description'} style={{color: '#1e1e1e'}}>
                                Segurança e armazenagem da informação
                            </DialogContentText>
                            <DialogContentText id={'label-description'}>
                                A {user.nome_estabecimento} observa todas as normas aplicáveis às medidas de
                                segurança
                                da informação
                                pessoal e emprega os padrões mais avançados da indústria em matéria de proteção da
                                confidencialidade destas informações. Ainda assim, é necessário considerar que a
                                segurança perfeita não existe na Internet. Portanto, a {user.nome_estabecimento} não
                                será responsável
                                por interceptações ilegais ou violação de seus sistemas ou bases de dados por parte
                                de
                                pessoas não autorizadas e tampouco se responsabiliza pela indevida utilização da
                                informação obtida por esses meios.
                            </DialogContentText>
                            <FormControlLabel id="form-check" control={<RedCheck/>} label="Li e aceito os termos"
                                              onChange={() => this.setState({readTerms: !readTerms})}/>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                id={'label-description'}
                                onClick={this.onClickBack} color="primary">
                                Cancelar
                            </Button>
                            {
                                readTerms &&
                                <Button
                                    id={'label-description'}
                                    variant={'contained'}
                                    onClick={this.onClickTerm} color="primary">
                                    Aceitar
                                </Button>
                            }
                        </DialogActions>
                    </Dialog>
                </MuiThemeProvider>
                <Snackbar
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    open={this.state.openSnackBar}
                    autoHideDuration={2000}
                    onClose={this.closeSnackBar}
                    message={this.state.snackBar}
                />
            </div>
        )
    }
}

export default withRouter(Cadastro)
