/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React from 'react'
import {Carousel} from 'react-bootstrap'
import {
    AppBar,
    Button,
    Toolbar,
    Typography,
    FormLabel,
    CardMedia,
    Dialog,
    DialogContentText,
    Fab,
    IconButton,
    CircularProgress,
    Hidden,
    Drawer,
    Divider,
    DialogContent,
    DialogTitle,
    DialogActions,
    Input,
    Snackbar, Box
} from '@material-ui/core'
import {
    createMuiTheme,
    MuiThemeProvider,
    makeStyles
} from '@material-ui/core/styles'
import {
    ShoppingCart as ShoppingCartIcon,
    Menu as MenuIcon,
    Phone,
    QueryBuilder as QueryBuilderIcon,
    Close as CloseIcon,
    Search as SearchIcon,
    AccountCircle,
    WhatsApp,
    Facebook,
    Map,
    AccessTime,
    Description,
    MonetizationOnRounded,
    Restaurant,
    Motorcycle,
    InsertEmoticon,
    LocationOn,
    Cached,
    Warning,
    Refresh,
    DesktopWindows,
    ExpandLess,
    ExpandMore,
    ClearAll
} from '@material-ui/icons'
import {
    isSafari,
    isIOS
} from 'react-device-detect'
import QRCode from 'qrcode.react'
import Destaques from '../../components/destaques/Destaques'
import Produtos from '../../components/produtos/Produtos'
import Categorias from '../../components/categorias/Categorias'
import firebase from '../../firebase'
import Logo from '../../images/logo.png'
import loading from '../../images/loading.gif'
import bitbar from '../../images/bitbar.png'
import user from '../../usuario.json'
import {chave, eventGA4, hideData, pageView, rest, URL_BASE} from '../../util'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import './Menu.css'

let version = process.env.REACT_APP_VERSION_APP
let arrayGroups
let category
let authkey
let id_loja
let index_loja
let identifier
const drawerWidth = 240
let contVersion = 0

const classes = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    }
}))

class Menu extends React.Component {

    state = {
        menu: [],
        images: [],
        openSearch: false,
        openMessage: false,
        openContact: false,
        openMaps: false,
        openPop: false,
        openErro: false,
        openProcessOrder: false,
        openNotification: false,
        isBackButtonClicked: false,
        installAppIos: true,
        totem: false,
        closeForce: false,
        statusStore: false,
        receiveMessage: {
            open: false,
            title: '',
            description: ''
        },
        colorStore: {
            color_title: '',
            color_description: '',
            color_price: '',
            color_toolbar: '',
            color_body: '',
            color_cardProduct: '',
            color_nextButton: '',
            color_fontButton: '',
            color_fontToolbar: ''
        },
        queryUrl: '',
        gularisUrl: '',
        retire: false,
        options: [],
        favorites: [],
        snackbar: '',
        closeStore: '',
        openStrore: '',
        openSnackbar: false,
        orderId: '',
        status: '',
        messageError: '',
        firstAccess: false,
        drawer: false,
        openCloseStatusPix: false,
        openErroCopy: false,
        lastetUpdate: '',
        title_page: '',
        telphone: '',
        whatsapp: '',
        itens: [],
        totalOrder: 0,
        purchaseMin: 0,
        totalItens: 0,
        modules: 0,
        address: '',
        motifClosed: '',
        client: '',
        messagePop: '',
        couponPop: '',
        messageStatus: '',
        openingHours: false,
        scheduled: false,
        clearStore: false,
        openShare: false,
        cookies: false,
        disableTryAgain: true,
        pix: false,
        closedHoliday: true,
        takeAwayToday: true,
        deliveryToday: true,
        deliveryDay: true,
        takeAwayDay: true,
        open: true,
        openLocation: false,
        messageLocation: '',
        closedNextDay: false,
        scheduledDay: false,
        openDataStore: false
    }

    installAppIos = (isClick = false) => {
        if (isClick) {
            alert('1) Abra o menu Compartilhar\n2) Toque no botão "Adicionar à tela inicial"')
            localStorage.setItem(`bitmenu-${identifier}:installAppIos`, 'ok')
        }
        let install = localStorage.getItem(`bitmenu-${identifier}:installAppIos`)
        this.setState({installAppIos: (install === null)})
    }

    installApp = async () => {
        if (!this.installPrompt) return false
        this.setState({installButton: false})
        this.installPrompt.prompt()
        let outcome = await this.installPrompt.userChoice
        if (outcome.outcome === 'accepted') {
            console.log("App Installed")
        } else {
            console.log("App not installed")
        }
        this.installPrompt = null
    }

    installPrompt = null

    checksInstalledApp = () => {
        window.addEventListener('beforeinstallprompt', e => {
            e.preventDefault();
            console.log("Install Prompt fired")
            this.installPrompt = e
            if ((window.matchMedia && window.matchMedia('(display-mode: standalone)').matches) || window.navigator.standalone === true) {
                return false
            }
            this.setState({installButton: true})
        })
    }

    componentDidMount() {
        sessionStorage.removeItem(`bitmenu-${identifier}:freeDelivery`)
        this.auth()
        this.checkLatestUpdate()
        this.items()
        this.getMenu()
        this.requestMessage()
        this.scrollMenu()
        this.idOrder()
        this.receiveMessage()
        this.clientsData()
        this.timeline()
        this.version()
        this.updateStore(`https://${window.location.hostname}${window.location.pathname}version`)
        this.dateImage()
        this.favorites()
        this.checksInstalledApp()
        this.installAppIos()
        setTimeout(function () {
            this.notification()
        }.bind(this), 15000)
        this.scroll()
        this.totem()
        this.cupom()
        this.checkStatus()
        this.queryUrl()
        pageView()
    }

    componentDidUpdate() {
        this.assembleGroups()
    }

    auth = () => {
        authkey = user.authkey
        id_loja = user.id_loja
        index_loja = user.index
        identifier = `${authkey}_${id_loja}_${index_loja}`
        localStorage.setItem(`bitmenu:authkey`, authkey)
        localStorage.setItem(`bitmenu:id_loja`, id_loja)
        localStorage.setItem(`bitmenu-${index_loja}:identifier`, identifier)
        localStorage.removeItem(`bitmenu-${authkey}:cliente`)
        this.setState({title_page: user.nome_estabecimento})
    }

    getMenu = () => {
        let data = localStorage.getItem(`bitmenu-${identifier}:data`)

        if (data !== null) {
            data = JSON.parse(data)
            this.loadMenu(data)
        } else {
            data = []
            this.setState({firstAccess: true})
        }

        fetch(`https://bitbar.online/util/api/v3/getmenu.php?authkey=${authkey}&id=${id_loja}&index=${index_loja}`)
            .then((response) => response.json())
            .then((json) => {
                if (json.blocked) return this.setState({
                    blocked: true,
                    openMessage: true,
                    message: 'Loja em manutenção'
                })
                this.recordMenu(data, json)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    checkLatestUpdate = () => {
        fetch(`https://bitbar.online/util/api/v3/timelastmenu.php?authkey=${authkey}&id=${id_loja}&index=${index_loja}`)
            .then((response) => response.text())
            .then((data) => {
                let date = data.split(' ')
                let s = date[0].split('/')
                date = `${s[2]}-${s[1]}-${s[0]} ${date[1]}`
                let dateOldUpdate = localStorage.getItem(`bitmenu-${identifier}:dateUpdate`)
                dateOldUpdate = dateOldUpdate !== null ? dateOldUpdate : ''
                dateOldUpdate = new Date(dateOldUpdate)
                let dateUpdate = new Date(date)
                if (dateOldUpdate.getTime() !== dateUpdate.getTime())
                    this.setState({progress: true})
                this.setState({lastetUpdate: data})
            })
            .catch((error) => {
                console.error(error)
            })
    }

    recordMenu = (data, json) => {
        if (data.length === 0)
            this.setState({firstAccess: false})
        let date = this.state.lastetUpdate.split(" ")
        let s = date[0].split('/')
        date = `${s[2]}-${s[1]}-${s[0]} ${date[1]}`
        let dateOldUpdate = localStorage.getItem(`bitmenu-${identifier}:dateUpdate`)
        dateOldUpdate = dateOldUpdate !== null ? dateOldUpdate : ''
        dateOldUpdate = new Date(dateOldUpdate)
        let dateUpdate = new Date(date)
        if (dateOldUpdate.getTime() !== dateUpdate.getTime()) {
            try {
                data = JSON.stringify(json)
                localStorage.setItem(`bitmenu-${identifier}:data`, data)
            } catch (e) {
                console.log(e)
            }
            localStorage.setItem(`bitmenu-${identifier}:dateUpdate`, date)
            this.loadMenu(json)
        }
        this.setState({progress: false})
    }

    items = () => {
        let itens = localStorage.getItem(`bitmenu-${identifier}:itens`)
        itens = itens !== null ? JSON.parse(itens) : []
        let vI = 0
        if (itens.length !== 0) {
            itens.forEach(i => {
                if (!i.itemCoupon) vI += (i.total * i.quantidade)
            })
        }
        this.setState({itens: itens, totalItens: vI})
    }

    notification = () => {
        if (!isSafari && !isIOS) {
            let token = localStorage.getItem(`bitmenu-${identifier}:token`)
            if (token === null || token === 'repeat') {
                this.setState({openNotification: true})
            } else {
                token = parseInt(localStorage.getItem(`bitmenu-${identifier}:token`))
                if (Number.isInteger(token)) {
                    if (--token <= 0) {
                        localStorage.setItem(`bitmenu-${identifier}:token`, 'repeat')
                    } else {
                        localStorage.setItem(`bitmenu-${identifier}:token`, token)
                    }
                }
            }
        }
    }

    permissionNotification = async () => {
        try {
            const messaging = firebase.messaging()
            await messaging.requestPermission()
            const token = await messaging.getToken()
            this.recordToken(token)
            return token
        } catch (error) {
            console.error(error)
        }
    }

    scroll = () => {
        try {
            let to = sessionStorage.getItem(`bitmenu-${identifier}:positionPage`)
            to = to !== null ? JSON.parse(to) : 0

            let elem = document.scrollingElement || document.documentElement
            let style = 'scrollTop'
            let unit = ''
            let from = window.scrollY
            let prop = true
            let time = 10

            if (!elem) return

            var start = new Date().getTime(),
                timer = setInterval(function () {
                    var step = Math.min(1, (new Date().getTime() - start) / time)
                    if (prop) {
                        elem[style] = (from + step * (to - from)) + unit
                    } else {
                        elem.style[style] = (from + step * (to - from)) + unit
                    }
                    if (step === 1) {
                        clearInterval(timer)
                    }
                }, 25);
            if (prop) {
                elem[style] = from + unit
            } else {
                elem.style[style] = from + unit
            }
        } catch (e) {

        }
    }

    checkStatus = () => {
        try {
            let statusOrder = localStorage.getItem(`bitmenu-${identifier}:statusOrder`)
            statusOrder = statusOrder !== null ? JSON.parse(statusOrder) : false
            if (!statusOrder) return
            if (statusOrder.pix) {
                const pixExpire = statusOrder.pixExpire
                let intervalID = setInterval(async () => {
                    const tempoRestante = pixExpire - new Date().getTime()
                    if (tempoRestante <= 0) {
                        const url = `${URL_BASE}/logs/?authkey=${authkey}&id=${statusOrder.id}`
                        let {returnCode, data: {pedido: {retorno: {status}}}} = await rest(url, {method: 'GET'})
                        if ((returnCode === 1 && (status === 'PIX'))) this.deleteOrder(statusOrder.id)
                        this.setState({timePixExpire: ''})
                        clearInterval(intervalID)
                    } else {
                        const minutos = Math.floor(tempoRestante / (60 * 1000));
                        const segundos = Math.floor((tempoRestante % (60 * 1000)) / 1000);
                        const s = `${minutos}:${segundos.toString().padStart(2, '0')}`
                        this.setState({timePixExpire: s})
                        console.log(s)
                    }
                    localStorage.setItem(`bitmenu-${identifier}:intervalID`, intervalID)
                }, 1000)
            }
        } catch (e) {
            console.log(e.message)
        }
    }

    deleteOrder = id => {
        try {
            let json = {
                pedido: {
                    authkey: authkey,
                    id_pedido: id,
                    retorno: {
                        status: 'CANCELADO',
                        mensagem: 'PIX não pago'
                    }
                }
            }
            rest(`${URL_BASE}/logs`, {method: 'PUT', body: JSON.stringify(json)})
        } catch (e) {
            console.log(e.message)
        }
    }

    totem = () => {
        try {
            let query = this.props.history.location.search
            if (query === '?totem=true') localStorage.setItem(`bitmenu-${identifier}:totem`, JSON.stringify(true))
            let totem = localStorage.getItem(`bitmenu-${identifier}:totem`)
            totem = totem !== null ? totem : false
            this.setState({totem: totem})
            if (totem) this.notification()
        } catch (e) {

        }
    }

    cupom = () => {
        let query = this.props.history.location.search
        if (query.includes('?cupom=')) {
            const cupom = query.substring(query.lastIndexOf('=') + 1)
            sessionStorage.setItem(`bitmenu-${identifier}:automatic_coupon`, cupom)
        }
    }

    recordToken = (token = '') => localStorage.setItem(`bitmenu-${identifier}:token`, token)

    requestMessage = () => {
        try {
            let response = this.props.location.response
            if (response !== undefined) {
                this.setState({openMessage: response.open, message: response.message})
                setTimeout(function () {
                    this.closeDialogMessage()
                }.bind(this), 1500)

                setTimeout(function () {
                    this.openMaps(response.retire)
                }.bind(this), 10000)
            } else {
                response = false
            }
        } catch (error) {
            console.error(error)
        }
    }

    openMaps = retire => {
        this.closeDialogMessage()
        if (retire) {
            this.setState({openMaps: true})
        }
    }

    scrollMenu = () => {
        try {
            window.onscroll = () => {
                try {
                    for (let i = 0; i < category.length; i++) {
                        let id = `#${this.clearText(category[i])}`
                        let elem = document.querySelector(id)
                        let position = 0
                        let scroll = document.getElementById('scroll')
                        if (elem !== null) {
                            let bounding = elem.getBoundingClientRect()
                            if (bounding.top > -100 && bounding.top < 100) {
                                if (i === 0) {
                                    this.positionGuide(`${id}-guia`)
                                    scroll.scrollTo(0, 0)
                                    break
                                } else if (i === 1) {
                                    this.positionGuide(`${id}-guia`)
                                    position = arrayGroups[0].position
                                    scroll.scrollTo((position - 40), 0)
                                    break
                                } else {
                                    let cont = i
                                    for (let x = 0; x < ++cont; x++) {
                                        this.positionGuide(`${id}-guia`)
                                        if (`${id}-menu` !== arrayGroups[x].tag) {
                                            position += arrayGroups[x].position
                                        } else {
                                            scroll.scrollTo((position - 40), 0)
                                            break
                                        }
                                    }
                                }
                            }
                        }
                    }
                } catch (e) {

                }
            }
        } catch (error) {
            console.error(error)
        }
    }

    idOrder = () => {
        try {
            let id = localStorage.getItem(`bitmenu:idOrder`)
            if (id === null) {
                let d = new Date()
                localStorage.setItem(`bitmenu:idOrder`, d.getTime().toString())
            }
        } catch (error) {
            console.error(error)
        }
    }

    receiveMessage = () => {
        try {
            if (!isSafari && !isIOS) {
                let context = this
                firebase
                    .messaging()
                    .onMessage((payload) => {
                        const {notification} = payload
                        context.setState({
                            receiveMessage: {
                                open: true,
                                title: notification.title,
                                description: notification.body
                            }
                        })
                    })
            }
        } catch (error) {
            console.error(error)
        }
    }

    clientsData = () => {
        let client = localStorage.getItem(`bitmenu-${identifier}:cliente`)
        client = client !== null ? JSON.parse(client) : ''
        this.setState({client: client})
    }

    timeline = () => {
        try {
            let object = JSON.parse(localStorage.getItem(`bitmenu-${identifier}:statusOrder`))
            if (object !== null) {
                let index = object.id
                let date = new Date()
                if (date.getTime() >= object.hours) {
                    this.closeStatus()
                    return
                }
                const {status} = this.state
                if (status === '') this.setState({pix: object.pix, status: 'ATUALIZANDO', orderId: index})
                index = `${authkey}/${index}/pedido`
                let ctx = this
                const db = firebase.database().ref('logs').child(index)
                db.on('value', snap => {
                    try {
                        let value = snap.val()
                        if (value.retorno.status !== 'ERRO') {
                            let scheduled = false
                            try {
                                scheduled = value.scheduled !== undefined ? value.scheduled : false
                            } catch (e) {

                            }

                            let dataHora = value.dataHora
                            let data = localStorage.getItem(`bitmenu-${identifier}:dataConfirmacaoPedido`)
                            if (data === null && value.retorno.status === 'CONFIRMADO' && !scheduled) {
                                let s = this.dataHora()
                                localStorage.setItem(`bitmenu-${identifier}:dataConfirmacaoPedido`, s)
                                dataHora = s
                            } else if (data !== null) {
                                dataHora = data
                            }

                            ctx.setState({
                                id_lojaOrder: value.id_loja,
                                orderId: value.id_pedido,
                                status: value.retorno.status,
                                messageStatus: value.retorno.mensagem !== undefined ? value.retorno.mensagem : '',
                                retire: value.buscar,
                                scheduled: scheduled,
                                requestedTime: dataHora,
                                totalOrder: value.total,
                                openErro: false,
                                orderWithOnlinePayment: (value.retorno.primeiroPagOnline !== undefined) ? value.retorno.primeiroPagOnline : false
                            })

                            try {
                                if (value.retorno.qrcode === '' && value.retorno.mensagem !== undefined && this.state.totem === 'true' && value.retorno.status === 'CONFIRMADO') {
                                    localStorage.removeItem(`bitmenu-${identifier}:statusOrder`)
                                    this.setState({
                                        receiveMessage: {
                                            open: true,
                                            title: 'Aviso!',
                                            description: value.retorno.mensagem
                                        }
                                    })
                                }
                            } catch (e) {

                            }

                            this.updateOrder(value.id_pedido, value.retorno)
                            if (value.retorno.status === 'CONFIRMADO' || value.retorno.status === 'CANCELADO')
                                localStorage.removeItem(`bitmenu-${identifier}:itensOld`)

                        } else if (value.retorno.status === 'ERRO') {
                            this.updateOrder(value.id_pedido, value.retorno)
                            this.setState({
                                openErro: true,
                                messageError: value.retorno.mensagem,
                                status: '',
                                orderId: value.id_pedido,
                                disableTryAgain: (!value.retorno.mensagem.toLowerCase().includes('revise seu pedido.'))
                            })
                            localStorage.removeItem(`bitmenu-${identifier}:statusOrder`)
                            localStorage.removeItem(`bitmenu-${identifier}:orderRoute`)
                            localStorage.removeItem(`bitmenu-${identifier}:dataConfirmacaoPedido`)
                        }
                    } catch (e) {

                    }
                })
            }
        } catch (e) {

        }
    }

    dataHora = () => {
        let data = new Date()
        let year = data.getFullYear()
        let mouth = (data.getMonth() + 1).toString()
        let day = data.getDate().toString()
        let hours = data.getHours().toString()
        let minutes = data.getMinutes().toString()
        let seconds = data.getSeconds().toString()
        mouth = mouth.length === 1 ? '0' + mouth : mouth
        day = day.length === 1 ? '0' + day : day
        hours = hours.length === 1 ? '0' + hours : hours
        minutes = minutes.length === 1 ? '0' + minutes : minutes
        seconds = seconds.length === 1 ? '0' + seconds : seconds
        return `${year}-${mouth}-${day} ${hours}:${minutes}:${seconds}`
    }

    updateOrder = (id_pedido, status) => {
        try {
            let orderHistory = localStorage.getItem(`bitmenu-${identifier}:orderHistory`)
            orderHistory = orderHistory !== null ? JSON.parse(orderHistory) : []
            orderHistory.forEach(i => {
                if (i.id_pedido === id_pedido) {
                    i.retorno = status
                }
            })
            localStorage.setItem(`bitmenu-${identifier}:orderHistory`, JSON.stringify(orderHistory))
        } catch (e) {

        }
    }

    version = () => {
        localStorage.setItem(`bitmenu:version`, version)
    }

    updateStore = url => {
        if (window.location.hostname !== 'localhost') {
            if (!sessionStorage.getItem(`bitmenu:reload`)) {
                fetch(url)
                    .then((response) => response.text())
                    .then((data) => {
                        // eslint-disable-next-line no-restricted-globals
                        if (localStorage.getItem(`bitmenu:version`) !== data.replace('\n', '')) window.location.reload(true)
                    })
                sessionStorage.setItem(`bitmenu:reload`, 'ok')
            }
        }
    }

    dateImage = () => {
        if (!sessionStorage.getItem('bitmenu:dataImage')) sessionStorage.setItem('bitmenu:dataImage', new Date())
    }

    favorites = () => {
        let favorites = localStorage.getItem(`bitmenu-${identifier}:favorites`)
        favorites = favorites !== null ? JSON.parse(favorites) : []
        this.setState({favorites: favorites})
    }

    positionPage = () => {
        try {
            let index = sessionStorage.getItem(`bitmenu-${identifier}:positionPage`)
            index = index !== null ? JSON.parse(index) : 0
            window.scrollTo(0, index)
            sessionStorage.removeItem(`bitmenu-${identifier}:positionPage`)
        } catch (e) {

        }
    }

    cookies = () => {
        if (localStorage.getItem(`bitmenu-${identifier}:cookies`)) {
            this.setState({cookies: false})
        } else {
            this.setState({cookies: true})
        }
    }

    showDescriptionImage = json => {
        try {
            const {showDescription, showPhoto} = json
            localStorage.setItem(`bitmenu-${identifier}:showDescription`, showDescription !== undefined ? showDescription : false)
            localStorage.setItem(`bitmenu-${identifier}:showPhoto`, showPhoto !== undefined ? showPhoto : false)
        } catch (e) {
            console.error(e)
        }
    }

    freeDelivery = json => {
        try {
            if (json.freeDelivery !== undefined) {
                if (json.freeDelivery > 0) {
                    sessionStorage.setItem(`bitmenu-${identifier}:freeDelivery`, JSON.stringify(json.freeDelivery))
                    localStorage.setItem(`bitmenu-${identifier}:teste`, `entrou aqui ${json.freeDelivery}`)
                } else {
                    sessionStorage.removeItem(`bitmenu-${identifier}:freeDelivery`)
                }
            } else {
                sessionStorage.removeItem(`bitmenu-${identifier}:freeDelivery`)
            }
        } catch (e) {

        }
    }

    positionGuide = div => {
        arrayGroups.forEach(e => {
            let elem = e.tag.replace('menu', '') + 'guia'
            if (elem === div) {
                let s = document.querySelector(elem)
                s.style.display = "block"
            } else {
                let s = document.querySelector(elem)
                s.style.display = "none"
            }
        })
    }

    assembleGroups = () => {
        try {
            arrayGroups = []
            category.forEach(i => {
                let tag = this.clearText(i)
                tag = `#${tag}-menu`
                let elem = document.querySelector(tag)
                if (elem !== null) {
                    let coordenadas = elem.getBoundingClientRect()
                    arrayGroups.push({tag: tag, position: coordenadas.width})
                }
            })
        } catch (error) {

        }
    }

    loadMenu = json => {
        try {
            this.modules(json)
            this.menu(json.menu)
            this.images(json.frontImage)
            this.serviceArea(json)
            this.storeStatus(json)
            this.info(json.info)
            this.paymentTypes(json)
            this.paymentOnline(json)
            this.guralis(json)
            this.purchaseMin(json)
            this.serviceRadius(json)
            this.scheduledDelivery(json)
            this.questions(json)
            this.popMessage(json)
            this.personalize(json)
            this.storesLocations(json)
            this.cookies()
            this.showDescriptionImage(json)
            this.freeDelivery(json)
        } catch (error) {
            console.log(error)
        }
    }

    modules = json => {
        try {
            localStorage.setItem(`bitmenu-${identifier}:mode`, json.mode)
            localStorage.setItem(`bitmenu-${identifier}:modules`, json.modules)
            this.setState({modules: json.modules})
            if (json.allowFractioned !== undefined)
                sessionStorage.setItem(`bitmenu-${identifier}:allowFractioned`, JSON.stringify(json.allowFractioned))
            try {
                if (json.takeawayOptions !== undefined)
                    localStorage.setItem(`bitmenu-${identifier}:modeRetire`, JSON.stringify(json.takeawayOptions))
                else
                    localStorage.setItem(`bitmenu-${identifier}:modeRetire`, JSON.stringify(['Retirar no local']))
            } catch (e) {

            }
        } catch (e) {

        }
    }

    menu = json => {
        try {
            let favorites = localStorage.getItem(`bitmenu-${identifier}:favorites`)
            favorites = favorites !== null ? JSON.parse(favorites) : []
            favorites.forEach(v => v.favorites = false)
            let menu = []
            category = []
            let options = []
            json.forEach(a => {
                let visibility = false
                menu.push(a)
                category.push(a.category)
                a.products.forEach(b => {
                    if (b.visibility.includes('DELIVERY')) {
                        b.variations.forEach((v, v_index) => {
                            v.key = chave(v_index)
                            v.ordem = v_index
                        })
                        b.cat = a.category
                        menu.push(b)

                        if (a.category !== 'Destaques')
                            options.push(`#_${this.clearText(b.product)}`)

                        visibility = true
                        favorites.forEach(v => {
                            if (v.sku === b.sku) {
                                v.favorites = true
                            }
                        })
                    }
                })

                if (!visibility) {
                    let index = (menu.length) - 1
                    menu.splice(index, 1)
                    index = (category.length) - 1
                    category.splice(index, 1)
                }
            })

            localStorage.setItem(`bitmenu-${identifier}:favorites`, JSON.stringify(favorites))
            sessionStorage.setItem(`bitmenu-${identifier}:menu`, JSON.stringify(menu))
            this.setState({menu: menu, options: options, favorites: favorites})
        } catch (error) {
            console.error(error)
        }
    }

    images = json => {
        try {
            let images = []
            json.forEach(i => {
                i = i.replace('http', 'https')
                images.push(i)
            })
            this.setState({images: images})
        } catch (error) {
            console.error(error)
        }
    }

    serviceArea = json => {
        try {
            let {area, CEPNeeded} = json
            localStorage.setItem(`bitmenu-${identifier}:area_atendimento`, hideData(JSON.stringify(area)))
            try {
                CEPNeeded = (CEPNeeded !== undefined) ? CEPNeeded : true
                localStorage.setItem(`bitmenu-${identifier}:CEPNeeded`, JSON.stringify(CEPNeeded))
            } catch (e) {

            }
            this.ceps(area)
        } catch (e) {
            console.log(e.message)
        }
    }

    ceps = area => {
        let cepsBloqueados = []
        try {
            area.forEach(a => {
                if (a.districts) {
                    a.districts.forEach(d => {
                        if (d.restrictions) {
                            d.restrictions.forEach(r => {
                                if (r.status === 'blocked') cepsBloqueados.push(r.postalCode)
                            })
                        }
                    })
                }
            })
            localStorage.setItem(`bitmenu-${identifier}:bloqueados`, hideData(JSON.stringify(cepsBloqueados)))
        } catch (e) {
            console.log(e.message)
        }
    }

    storeStatus = json => {
        try {
            localStorage.setItem(`bitmenu-${identifier}:open`, JSON.stringify(json.open))
            try {
                this.setState({closeForce: (json.closeForce !== undefined) ? json.closeForce : false})
            } catch (e) {

            }
            this.setState({
                open: json.open,
                motifClosed: (json.motifClosed !== undefined) ? json.motifClosed : ''
            })
            if (!json.open) {
                this.setState({openMessage: true, openingHours: true, message: 'Estamos fechados no momento'})
            }
            this.waitingTime(json)
            this.timeControl()
        } catch (error) {
            console.error(error)
        }
    }

    info = json => {
        try {
            this.setState({
                telphone: json.telphone.replace(/[^\d]+/g, ''),
                whatsapp: json.whatsapp.replace(/[^\d]+/g, ''),
                address: json.address,
                title_page: json.name,
            })
            if (json.latitude !== undefined) {
                localStorage.setItem(`bitmenu-${identifier}:restaurantCoordinates`, `${json.latitude},${json.longitude}`)
            }
            localStorage.setItem(`bitmenu-${identifier}:restaurantAddress`, json.address)
        } catch (error) {
            console.error(error)
        }
    }

    paymentTypes = json => {
        try {
            localStorage.setItem(`bitmenu-${identifier}:paymentTypes`, JSON.stringify(json.paymentTypes))
            json.paymentTypes.forEach(i => {
                if (i.description === 'Pix')
                    sessionStorage.setItem(`bitmenu-${identifier}:pix`, 'true')
            })
        } catch (e) {
            console.error(e)
        }
    }

    paymentOnline = json => {
        const {onlinePayments, physicalPayments, cashPayments, pagsegurochavepub} = json
        try {
            localStorage.setItem(`bitmenu-${identifier}:paymentOnline`, onlinePayments !== undefined ? onlinePayments : 'ALL')
            localStorage.setItem(`bitmenu-${identifier}:paymentPhysical`, physicalPayments !== undefined ? physicalPayments : 'ALL')
            localStorage.setItem(`bitmenu-${identifier}:cashPayments`, cashPayments !== undefined ? cashPayments : 'ALL')
            if (pagsegurochavepub !== undefined && pagsegurochavepub !== '') {
                sessionStorage.setItem(`bitmenu:publicKeyPagSeguro`, pagsegurochavepub)
            } else {
                sessionStorage.removeItem(`bitmenu:publicKeyPagSeguro`)
            }
        } catch (e) {

        }
    }

    guralis = json => {
        const {gularisToken, gularisMinimum, gularisUrl} = json
        try {
            localStorage.setItem(`bitmenu-${identifier}:gularisToken`, gularisToken !== undefined ? gularisToken : '')
            localStorage.setItem(`bitmenu-${identifier}:gularisMinimo`, gularisMinimum !== undefined ? gularisMinimum : 0)
            this.setState({gularisUrl: gularisUrl !== undefined ? gularisUrl : ''})
        } catch (e) {

        }
    }

    purchaseMin = (json) => {
        try {
            localStorage.setItem(`bitmenu-${identifier}:purchaseMin`, json.purchaseMin)
            this.setState({purchaseMin: json.purchaseMin})
        } catch (error) {
            console.error(error)
        }
    }

    serviceRadius = json => {
        if (json.radius !== undefined) {
            localStorage.setItem(`bitmenu-${identifier}:serviceRadius`, hideData(JSON.stringify(json.radius)))
        }
        if (json.radiusmax !== undefined) {
            localStorage.setItem(`bitmenu-${identifier}:radiusmax`, json.radiusmax)
        }
    }

    scheduledDelivery = ({open, scheduled, scheduledday}) => {
        if (open) {
            let allowScheduledDelivery = scheduled !== undefined ? scheduled : false
            this.setState({allowScheduledDelivery: allowScheduledDelivery})
            localStorage.setItem(`bitmenu-${identifier}:allowScheduledDelivery`, allowScheduledDelivery)
        } else {
            this.setState({allowScheduledDelivery: false})
            localStorage.setItem(`bitmenu-${identifier}:allowScheduledDelivery`, false)
        }
        scheduledday = (scheduledday !== undefined) ? scheduledday : false
        localStorage.setItem(`bitmenu-${identifier}:scheduledDay`, JSON.stringify(scheduledday))
        this.setState({scheduledDay: scheduledday})
    }

    questions = json => {
        try {
            sessionStorage.removeItem(`bitmenu-${identifier}:confirmedAnswers`)
            if (json.questions === undefined) {
                localStorage.removeItem(`bitmenu-${identifier}:questions`)
            } else {
                localStorage.setItem(`bitmenu-${identifier}:questions`, JSON.stringify(json.questions))
            }
        } catch (e) {

        }
    }

    popMessage = json => {
        try {
            const {time, msg, coupon} = json.popup
            if (coupon) {
                if (localStorage.getItem(`bitmenu-${identifier}:popup`) === null && time !== 0) {
                    const {itens} = this.state
                    let ctx = this
                    setInterval(function () {
                        if (itens.length === 0 && localStorage.getItem(`bitmenu-${identifier}:popup`) === null) {
                            ctx.setState({openPop: true, messagePop: msg, couponPop: coupon})
                        }
                    }, (time * 1000))
                }
            }
        } catch (e) {

        }
    }

    personalize = json => {
        try {
            const {
                title,
                description,
                price,
                toolbar,
                body,
                cardProduct,
                nextButton,
                fontButton,
                fontToolbar,
                footer
            } = json.colorStore
            localStorage.setItem(`bitmenu-${identifier}:colorStore`, JSON.stringify(json.colorStore))
            this.setState({
                colorStore: {
                    color_title: `#${title}`,
                    color_description: `#${description}`,
                    color_price: `#${price}`,
                    color_toolbar: `#${toolbar}`,
                    color_body: `#${body}`,
                    color_cardProduct: `#${cardProduct}`,
                    color_nextButton: `#${nextButton}`,
                    color_fontButton: `#${fontButton}`,
                    color_fontToolbar: `#${fontToolbar}`,
                    color_footer: `#${footer}`
                }
            })
        } catch (e) {

        }
    }

    storesLocations = json => {
        if (json.storesLocations !== undefined) {
            localStorage.setItem(`bitmenu-${identifier}:storesLocations`, JSON.stringify(json.storesLocations))
        } else {
            localStorage.removeItem(`bitmenu-${identifier}:storesLocations`)
        }
    }

    queryUrl = () => {
        let query = this.props.history.location.search
        let queryUrl = sessionStorage.getItem(`bitmenu-${identifier}:queryUrl`)
        if (query && queryUrl === null) {
            query = query.split('=')
            sessionStorage.setItem(`bitmenu-${identifier}:queryUrl`, 'ok')
            if (query[0] === '?produto') {
                this.searchProduct(query[1])
            } else {

            }
        }
    }

    searchProduct = sku => {
        let menu = sessionStorage.getItem(`bitmenu-${identifier}:menu`)
        menu = (menu !== null) ? JSON.parse(menu) : []
        for (let i = 0; i < menu.length; i++)
            if (sku === menu[i].sku) {
                this.props.history.replace({pathname: '/sacola', search: '', state: {dados: menu[i]}})
                break
            }
    }

    waitingTime = json => {
        localStorage.setItem(`bitmenu-${identifier}:weekDeliveryStore`, JSON.stringify(json.weekDeliveryStore))
        localStorage.setItem(`bitmenu-${identifier}:weekDeliveryRoute`, JSON.stringify(json.weekDeliveryRoute))
        localStorage.setItem(`bitmenu-${identifier}:weekShopStore`, JSON.stringify(json.weekShopStore))
        localStorage.setItem(`bitmenu-${identifier}:timeDeliverMin`, this.convertHours(json.timeDeliverMin) + 'min')
        localStorage.setItem(`bitmenu-${identifier}:timeTakeAwayMin`, this.convertHours(json.timeTakeAwayMin) + 'min')
        localStorage.setItem(`bitmenu-${identifier}:timeDeliverMax`, this.convertHours(json.timeDeliverMax) + 'min')
        localStorage.setItem(`bitmenu-${identifier}:timeTakeAwayMax`, this.convertHours(json.timeTakeAwayMax) + 'min')
        this.setState({
            timeTakeAway: `${this.convertHours(json.timeTakeAwayMin)}min`,
            timeTakeDelivery: `${this.convertHours(json.timeDeliverMin)}min - ${this.convertHours(json.timeDeliverMax)}min`
        })
    }

    convertHours = hour => {
        try {
            let timeParts = hour.split(':')
            return Number(timeParts[0]) * 60 + Number(timeParts[1])
        } catch (e) {

        }
    }

    deliveryTime = time => {
        let min = ''
        time = time.split('')
        if (time[0] !== '0')
            min += time[0]
        if (time[1] !== '0') {
            min += time[1]
            min += min === '1' ? ' hora ' : ' horas '
        }
        if (!(time[3] === '0' && time[4] === '0'))
            min += time[3] + time[4] + ' min'
        return min
    }

    timeControl = () => {
        let json = localStorage.getItem(`bitmenu-${identifier}:data`)
        json = (json !== null && json !== '') ? JSON.parse(json) : false
        if (!json) return
        let modules = localStorage.getItem(`bitmenu-${identifier}:modules`)
        modules = modules !== null ? parseInt(modules) : 0
        let deliveryToday = false
        let deliveryDay = false
        let deliveryNow = false
        let closedNextDay = false
        let closeStore = ''
        let openStrore = ''
        let s = ''

        if (modules === 0 || modules === 1) {
            try {
                let delivery = json.weekDeliveryStore
                for (let i = 0; i < delivery.length; i++) {
                    try {
                        sessionStorage.setItem(`bitmenu-${identifier}:openTime`, delivery[0].openTime)
                        if (!delivery[i].day.includes(((this.day() === 7) ? 0 : this.day()) + 1))
                            closedNextDay = true
                        else
                            closedNextDay = false
                    } catch (e) {

                    }
                    try {
                        if (s === '') {
                            s = delivery[i].openTime
                            if (s.length === 12) s = s.substring(0, s.length - 4)
                            s = s.substring(0, s.length - 3)
                        }
                    } catch (e) {

                    }

                    if (delivery[i].day.includes(this.day())) {
                        deliveryDay = true
                        let openingTime = this.dateTime(delivery[i].openTime, false)
                        let closingTime = this.dateTime(delivery[i].closeTime, false)
                        let cn = false
                        try {
                            cn = delivery[i].closeTime.split(':')[0] === '00'
                        } catch (e) {

                        }
                        let closeNow = this.dateTime(delivery[i].closeTime, cn)
                        let closingTime1 = this.dateTime(delivery[i].closeTime, true)
                        let currentTime = this.dateTime(this.currentTime(), false)
                        if (delivery[i].closeTime.substring(0, 1) !== '0') {
                            closingTime = 0
                        }
                        try {
                            if (s === '') {
                                s = delivery[i].openTime
                                if (s.length === 12) s = s.substring(0, s.length - 4)
                                s = s.substring(0, s.length - 3)
                            }
                            if (new Date().getTime() < this.dateTime(delivery[i].openTime, false) && openStrore === '') {
                                openStrore = delivery[i].openTime
                                if (openStrore.length === 12) openStrore = openStrore.substring(0, openStrore.length - 4)
                                openStrore = openStrore.substring(0, openStrore.length - 3)
                                openStrore = `Abre às ${openStrore}`
                            }
                        } catch (e) {

                        }
                        try {
                            closeStore = delivery[i].closeTime
                            if (closeStore.length === 12) closeStore = closeStore.substring(0, closeStore.length - 4)
                            closeStore = closeStore.substring(0, closeStore.length - 3)
                            closeStore = `Fecha às ${closeStore}`
                        } catch (e) {

                        }
                        deliveryNow = (currentTime < closeNow)
                        if ((currentTime >= openingTime || currentTime <= closingTime) && (currentTime <= closingTime1)) {
                            deliveryToday = true
                            break
                        }
                    }
                    if (!deliveryNow) s = ''
                }
            } catch (e) {
                deliveryToday = true
            }
        }
        let takeAwayToday = false
        let takeAwayDay = false
        try {
            if (modules === 0 || modules === 2 || modules === 3) {
                let takeAway = json.weekShopStore
                for (let i = 0; i < takeAway.length; i++) {
                    if (takeAway[i].day.includes(this.day())) {
                        takeAwayDay = true
                        let openingTime = this.dateTime(takeAway[i].openTime, false)
                        let closingTime = this.dateTime(takeAway[i].closeTime, false)
                        let closingTime1 = this.dateTime(takeAway[i].closeTime, true)
                        let currentTime = this.dateTime(this.currentTime(), false)
                        if (takeAway[i].closeTime.substring(0, 1) !== '0') {
                            closingTime = 0
                        }
                        try {
                            if (s === '') {
                                s = takeAway[i].openTime
                                if (s.length === 12) s = s.substring(0, s.length - 4)
                                s = s.substring(0, s.length - 3)
                                if (new Date().getTime() < this.dateTime(takeAway[i].openTime, false) && openStrore === '') {
                                    openStrore = takeAway[i].openTime
                                    if (openStrore.length === 12) openStrore = openStrore.substring(0, openStrore.length - 4)
                                    openStrore = openStrore.substring(0, openStrore.length - 3)
                                    openStrore = `Abre às ${openStrore}`
                                }
                                closeStore = takeAway[i].closeTime
                                if (closeStore.length === 12) closeStore = closeStore.substring(0, closeStore.length - 4)
                                closeStore = closeStore.substring(0, closeStore.length - 3)
                                closeStore = `Fecha às ${closeStore}`
                            }
                        } catch (e) {

                        }
                        if ((currentTime >= openingTime || currentTime <= closingTime) && (currentTime <= closingTime1)) {
                            takeAwayToday = true
                            break
                        }
                    }
                }
            }
        } catch (e) {

        }
        if (openStrore === '') openStrore = `Abre amanhã às ${s}`
        let closedHoliday = true
        try {
            for (let i = 0; i < json.holidays.length; i++) {
                let data = new Date()
                let s = `${data.getFullYear()}-${(data.getMonth() + 1).toString()}-${data.getDate()}`
                if (s === json.holidays[i].date) {
                    closedHoliday = json.holidays[i].open
                    break
                }
            }
        } catch (e) {
            console.log(e)
        }
        let statusStore = (json.open && closedHoliday && (deliveryToday || takeAwayToday))
        if (!statusStore) {
            let shopClosedNotice = sessionStorage.getItem(`bitmenu-${identifier}:shopClosedNotice`)
            shopClosedNotice = (shopClosedNotice !== null && shopClosedNotice !== '') ? parseInt(shopClosedNotice) : 1
            if (shopClosedNotice++ === 1) {
                if (!json.open) {
                    this.setState({openMessage: true, openingHours: true, message: 'Estamos fechados no momento'})
                } else if (!json.open) {
                    this.setState({openMessage: true, openingHours: true, message: 'Estamos fechados no momento'})
                }
            } else if (shopClosedNotice >= 5) {
                shopClosedNotice = 1
            }
            sessionStorage.setItem(`bitmenu-${identifier}:shopClosedNotice`, shopClosedNotice)
        } else {
            this.setState({openMessage: false})
        }
        localStorage.setItem(`bitmenu-${identifier}:closedHoliday`, closedHoliday)
        localStorage.setItem(`bitmenu-${identifier}:takeAwayToday`, takeAwayToday)
        localStorage.setItem(`bitmenu-${identifier}:deliveryToday`, deliveryToday)
        if (modules === 3) {
            statusStore = json.open
            if (json.open)
                closeStore = 'Aceita pedidos programados'
            else
                openStrore = 'Indisponível para pedidos'
        }

        closedNextDay = (!closedHoliday)

        this.setState({
            closedHoliday: closedHoliday,
            takeAwayDay: takeAwayDay,
            deliveryDay: deliveryDay,
            deliveryNow: deliveryNow,
            statusStore: statusStore,
            closedNextDay: (closedNextDay && !statusStore),
            closeStore: closeStore,
            openStrore: openStrore
        })
        return statusStore
    }

    dateTime = (hora, close) => {
        hora = hora.split(':')
        let d = new Date()
        let s = hora[0].substring(0, 1)
        let dia = d.getDate()
        if (close && s === '0') {
            dia = dia + 1
        }
        let data = new Date(d.getFullYear(), d.getMonth(), dia, hora[0], hora[1])
        return data.getTime()
    }

    currentTime = () => {
        let data = new Date()
        let hours = data.getHours().toString()
        let minutes = data.getMinutes().toString()
        let seconds = data.getSeconds().toString()
        hours = hours.length === 1 ? '0' + hours : hours
        minutes = minutes.length === 1 ? '0' + minutes : minutes
        seconds = seconds.length === 1 ? '0' + seconds : seconds
        return `${hours}:${minutes}:${seconds}`
    }

    day = () => {
        let d = new Date();
        return d.getDay() + 1
    }

    date = () => {
        let data = new Date()
        let year = data.getUTCFullYear()
        let mouth = (data.getUTCMonth() + 1).toString()
        let day = data.getUTCDate().toString()
        mouth = mouth.length === 1 ? '0' + mouth : mouth
        day = day.length === 1 ? '0' + day : day
        return `${year}-${mouth}-${day}`
    }

    idDiv = () => {
        return new Date().getTime()
    }

    filter = text => {
        const {options} = this.state
        let id = `${this.clearText(text)}`
        for (let i = 0; i < options.length; i++) {
            try {
                if (id === '') {
                    options.forEach(i => {
                        let elem = document.querySelector(i)
                        elem.style.opacity = 1;
                    })
                    let scroll = document.getElementById('scroll')
                    scroll.scrollTo(0, 0)
                    window.scrollTo(0, 0)
                    this.setState({search: text, next_search: i})
                    break
                } else if (options[i].includes(id)) {
                    let elem = document.querySelector(options[i])
                    let position = (elem.offsetTop) - 120
                    window.scrollTo(0, position)
                    this.setState({search: text, next_search: i})
                    break
                }
                this.setState({search: text, next_search: 0})
            } catch (e) {
                console.log(e)
            }
        }
    }

    addHours = () => {
        const {requestedTime} = this.state
        let array = JSON.parse(localStorage.getItem(`bitmenu-${identifier}:deliveryForecasts`))

        let expectedTime = ''
        try {
            array.forEach((hrB, index) => {
                let hrA = requestedTime
                hrA = hrA.split(' ')
                hrA = hrA[1]
                hrA = hrA.substring(0, hrA.length - 3)

                if (hrA.length !== 5 || hrB.length !== 5) return '00:00'

                let temp
                let nova_h = 0
                let novo_m

                let hora1 = hrA.substr(0, 2) * 1
                let hora2 = hrB.substr(0, 2) * 1
                let minu1 = hrA.substr(3, 2) * 1
                let minu2 = hrB.substr(3, 2) * 1

                temp = minu1 + minu2
                while (temp > 59) {
                    nova_h++;
                    temp = temp - 60
                }
                novo_m = temp.toString().length === 2 ? temp : ("0" + temp)

                temp = hora1 + hora2 + nova_h
                while (temp > 23) {
                    temp = (temp - 24)
                }
                nova_h = temp.toString().length === 2 ? temp : ("0" + temp)
                expectedTime += `${(index === 0) ? `entre ` : ``}${this.roundMinutes(nova_h, novo_m)}${index === 0 ? ` e ` : ``}`
            })
        } catch (e) {

        }
        return expectedTime
    }

    roundMinutes = (nova_h, novo_m) => {
        let minutes = novo_m.toString().split('')
        let decimal = parseInt(minutes[0])
        let unitario = parseInt(minutes[1])
        if (unitario === 5 || unitario === 0) {
            return `${nova_h}:${decimal}${unitario}`
        } else if (unitario > 5) {
            if (decimal === 5) {
                decimal = 0
                unitario = 0
                nova_h += 1
                return `${nova_h}:${decimal}${unitario}`
            } else {
                decimal += 1
                unitario = 0
            }
            return `${nova_h}:${decimal}${unitario}`
        } else if (unitario < 5) {
            unitario = 0
            return `${nova_h}:${decimal}${unitario}`
        }
    }

    scheduledDeliveryTime = () => {
        let {requestedTime} = this.state
        requestedTime = requestedTime.split(' ')
        requestedTime = requestedTime[1]
        requestedTime = requestedTime.substring(0, requestedTime.length - 3)
        return `Por volta das ${requestedTime}`
    }

    orderDetails = id_pedido => this.props.history.push({pathname: '/historico', state: {idOrder: id_pedido}})

    handleInput = e => this.filter(e.target.value)

    handleProduct = object => {
        if (object.type === 'share')
            this.setState({openShare: true, queryUrl: object.object})
        else
            this.setState({favorites: object.object})
    }

    handleMessage = message => this.setState({openMessage: true, message: message})

    closeStatus = (entregue = true) => {
        if (entregue) {
            localStorage.removeItem(`bitmenu-${identifier}:statusOrder`)
            localStorage.removeItem(`bitmenu-${identifier}:orderRoute`)
            localStorage.removeItem(`bitmenu-${identifier}:dataConfirmacaoPedido`)
            localStorage.removeItem(`bitmenu-${identifier}:deliveryForecasts`)
            this.stopInterval()
        }
        this.setState({status: ''})
    }

    closeStatusPix = () => this.setState({openCloseStatusPix: true})

    closeMessage = () => this.setState({receiveMessage: {open: false, title: '', description: ''}})

    closeDialogContact = () => this.setState({openContact: false})

    closeMaps = () => this.setState({openMaps: false})

    closeNotification = () => {
        this.setState({openNotification: false})
        localStorage.setItem(`bitmenu-${identifier}:token`, 4)
    }

    closeDialogMessage = () => this.setState({openMessage: false})

    onClickVersion = () => {
        try {
            if (++contVersion >= 7) {
                this.setState({openDataStore: true})
                contVersion = 0
            }
        } catch (e) {

        }
    }

    onClickCokkies = () => {
        localStorage.setItem(`bitmenu-${identifier}:cookies`, 'ok')
        this.setState({cookies: false})
    }

    onClickShare = whatsApp => {
        const {queryUrl} = this.state
        if (whatsApp) {
            window.location.href = `https://api.whatsapp.com/send?text=https://${window.location.hostname}${window.location.pathname}?produto=${queryUrl}`
        } else {
            window.location.href = `https://www.facebook.com/sharer/sharer.php?u=https://${window.location.hostname}${window.location.pathname}?produto=${queryUrl}`
        }
    }

    onClickCopydCoupon = () => {
        this.setState({openPop: false, openSnackbar: true, snackbar: 'Cupom copiado com sucesso!'})
        const {couponPop} = this.state
        navigator.clipboard.writeText(couponPop)
        localStorage.setItem(`bitmenu-${identifier}:popup`, 'ok')
    }

    onClickCanceledCoupon = () => {
        this.setState({openPop: false})
        localStorage.setItem(`bitmenu-${identifier}:popup`, 'ok')
    }

    onClickCanceledOrder = () => {
        this.setState({openErro: false})
        localStorage.removeItem(`bitmenu-${identifier}:itensOld`)
        localStorage.removeItem(`bitmenu-${identifier}:statusOrder`)
        localStorage.removeItem(`bitmenu-${identifier}:orderRoute`)
        localStorage.removeItem(`bitmenu-${identifier}:dataConfirmacaoPedido`)
    }

    onClickResendOrder = async () => {
        try {
            let lastWish = localStorage.getItem(`bitmenu-${identifier}:lastWish`)
            if (lastWish !== null) {
                lastWish = JSON.parse(lastWish)
                this.setState({openProcessOrder: true, openErro: false})
                let object = {
                    authkey_id: identifier,
                    pedido: lastWish,
                    version: localStorage.getItem(`bitmenu:version`),
                    key: lastWish.id_pedido
                }
                await rest(`${URL_BASE}/logs`, {method: 'POST', body: JSON.stringify(object)})
                const {returnCode, message} = await rest(`${URL_BASE}/pedido`, {
                    method: 'POST', body: JSON.stringify(lastWish)
                })
                if (!returnCode) {
                    this.setState({
                        openErro: true,
                        messageError: 'Falha ao enviar pedido\n' + message
                    })
                } else {
                    this.setState({status: 'PENDENTE'})
                }
                this.setState({openProcessOrder: false})
            } else {
                this.setState({openErro: false})
            }
        } catch (e) {
            console.log(e.message)
        }
    }

    onClickMap = () => {
        try {
            let stores = localStorage.getItem(`bitmenu-${identifier}:storesLocations`)
            if (stores !== null) {
                const {id_lojaOrder} = this.state
                stores = JSON.parse(stores)
                let address = ''
                stores.forEach(i => {
                    if (i.id_loja === id_lojaOrder) {
                        address = i.address
                    }
                })
                window.location.href = `http://www.google.com/maps/place/${address}`
            } else {
                let address = localStorage.getItem(`bitmenu-${identifier}:restaurantAddress`)
                window.location.href = `http://www.google.com/maps/place/${address}`
            }
        } catch (e) {

        }
    }

    onClickCloseSearch = () => {
        const {options} = this.state
        options.forEach(i => {
            let elem = document.querySelector(i)
            elem.style.opacity = 1;
        })
        this.setState({openSearch: false})
        document.getElementById('scroll').scrollTo(0, 0)
        window.scrollTo(0, 0)
        this.visibilityTitle(false)
    }

    onClickExpand = up => {
        try {
            const {search, next_search, options} = this.state
            let id = `${this.clearText(search)}`
            if (up) {
                let did_not_find = true
                let position_search = []
                options.forEach((i, index) => {
                    try {
                        if (i.includes(id)) position_search.push(index)
                        let elem = document.querySelector(i)
                        elem.style.opacity = 0.5;
                    } catch (e) {

                    }
                })
                for (let i = 0; i < options.length; i++) {
                    try {
                        if (options[i].includes(id)) {
                            if (i === next_search) {
                                let index_o = position_search[position_search.indexOf(next_search) - 1]
                                let elem = document.querySelector(options[index_o])
                                elem.style.opacity = 1;
                                let position = (elem.offsetTop) - 120
                                window.scrollTo(0, position)
                                this.setState({next_search: index_o})
                                did_not_find = false
                                break
                            }
                        }
                    } catch (e) {

                    }
                }
                if (did_not_find) {
                    options.forEach((i, index) => {
                        try {
                            if (i.includes(id)) position_search.push(index)
                            let elem = document.querySelector(i)
                            elem.style.opacity = 1;
                        } catch (e) {

                        }
                    })
                }
            } else {
                let did_not_find = true
                options.forEach(i => {
                    try {
                        let elem = document.querySelector(i)
                        elem.style.opacity = 0.5;
                    } catch (e) {

                    }
                })
                for (let i = 0; i < options.length; i++) {
                    try {
                        if (options[i].includes(id)) {
                            if (i > next_search) {
                                let elem = document.querySelector(options[i])
                                elem.style.opacity = 1;
                                let position = (elem.offsetTop) - 120
                                window.scrollTo(0, position)
                                this.setState({next_search: i})
                                did_not_find = false
                                break
                            }
                        }
                    } catch (e) {

                    }
                }
                if (did_not_find) {
                    options.forEach(i => {
                        try {
                            let elem = document.querySelector(i)
                            elem.style.opacity = 1;
                        } catch (e) {

                        }
                    })
                }
            }
        } catch (e) {

        }
    }

    onClickSerach = () => {
        this.setState({openSearch: !this.state.openSearch, next_search: 0})
        this.visibilityTitle(!this.state.openSearch)
    }

    onClickCached = () => {
        let orderHistory = localStorage.getItem(`bitmenu-${identifier}:orderHistory`)
        localStorage.clear()
        sessionStorage.clear()
        if (orderHistory !== 'null' && orderHistory !== null) localStorage.setItem(`bitmenu-${identifier}:orderHistory`, orderHistory)
        localStorage.setItem(`bitmenu-${identifier}:orderHistory`, orderHistory)
        window.location.reload(true)
    }

    onClickNotification = () => {
        localStorage.setItem(`bitmenu-${identifier}:token`, 'ok')
        this.setState({openNotification: false})
        this.permissionNotification()
    }

    onClickContact = (i, number) =>
        window.open((i === 0) ? `tel:${number}` : `https://api.whatsapp.com/send?phone=55${number}&text=Oi`, '_blank')

    onclickCarrinho = () => {
        try {
            //let url = `https://${window.location.hostname}${window.location.pathname}version`
            const {allowScheduledDelivery, closedHoliday, deliveryDay, takeAwayDay} = this.state
            let open = true
            try {
                open = JSON.parse(localStorage.getItem(`bitmenu-${identifier}:open`))
            } catch (e) {

            }
            if (!this.timeControl()) {
                if (open && allowScheduledDelivery && closedHoliday && (deliveryDay || takeAwayDay)) {
                    this.props.history.push('/carrinho')
                } else {
                    this.setState({openMessage: true, openingHours: true, message: 'Estamos fechados no momento'})
                }
            } else {
                this.props.history.push('/carrinho')
            }
            this.setState({disableTryAgain: true})
            this.stopInterval()
            sessionStorage.removeItem(`bitmenu:reload`)
            //this.updateStore(url)
        } catch (e) {
            console.log(e)
        }
    }

    stopInterval = () => {
        try {
            let intervalID = localStorage.getItem(`bitmenu-${identifier}:intervalID`)
            clearInterval(intervalID)
        } catch (e) {
            console.log(e.message)
        }
    }

    onClickShortcut = () => {
        this.setState({installButton: false, installAppIos: false})
        localStorage.setItem(`bitmenu-${identifier}:installAppIos`, 'ok')
    }

    onClickDrawer = () => {
        this.setState({drawer: !this.state.drawer})
    }

    onClickItemDrawer = i => {
        switch (i) {
            case 0:
                this.props.history.push('/historico')
                break
            case 1:
                this.props.history.push('/perfil')
                break
            case 2:
                this.setState({openContact: true})
                break
            case 3:
                this.props.history.push('/area')
                break
            case 4:
                this.props.history.push('/horarios')
                break
            case 5:
                this.setState({clearStore: true})
                break
            case 7:
                this.setState({drawer: false})
                this.props.history.replace('/')
                window.location.reload(true)
                break
            case 8:
                let itens = localStorage.getItem(`bitmenu-${identifier}:orderOld`)
                itens = (itens !== null) ? JSON.parse(itens) : []
                itens.forEach(i => i.hora = this.orderTime())
                localStorage.setItem(`bitmenu-${identifier}:itens`, JSON.stringify(itens))
                this.setState({
                    drawer: false,
                    openSnackbar: true,
                    snackbar: 'Pedido adicionado ao carrinho'
                })
                this.items()
                break
            default:
                this.setState({drawer: false, totem: false})
                localStorage.removeItem(`bitmenu-${identifier}:totem`)
                this.props.history.replace('/')
                window.location.reload(true)
                break
        }
    }

    orderTime = () => {
        let d = new Date()
        let hours = d.getHours()
        let day = d.getDate()
        if (hours === 0) {
            hours = 0
            day += 1
        } else if (hours === 21) {
            hours = 1
            day += 1
        } else if (hours === 22) {
            hours = 2
            day += 1
        } else if (hours === 23) {
            hours = 3
            day += 1
        } else {
            hours += 4
        }
        return new Date(d.getFullYear(), d.getMonth(), day, hours, d.getMinutes())
    }

    onClickGularisConsultation = url => {
        window.open(url);
    }

    onClickGroups = e => {
        let div = this.clearText(e.target.innerText)
        window.location.hash = `#${div}`
        this.positionGuide(`#${div}-guia`)
        try {
            eventGA4('select_content', {content_type: e.target.innerText})
        } catch (e) {

        }
    }

    key = () => {
        let hora = new Date()
        return hora.getTime()
    }

    clearText = text => {
        text = text.normalize("NFD").replace(/[^A-Z0-9]/ig, "")
        text = text.trim()
        return text.toLowerCase()
    }

    visibilityTitle = visibility => {
        let left = document.getElementById('toolbar-menu-left')
        let right = document.getElementById('toolbar-menu-right')
        let title = document.getElementById('not-selector')
        if (visibility) {
            left.style.width = '20%'
            right.style.width = '80%'
            title.style.display = 'none'
        } else {
            left.style.width = '90%'
            right.style.width = '10%'
            title.style.display = 'block'
        }
    }

    maskPhone = number => {
        if (number !== '')
            return number.replace(/\D/g, '').replace(/(\d{2})(\d)/, '($1) $2')
    }

    orderId = orderId => {
        try {
            return `Nº #${orderId.substring(orderId.length - 4)}`
        } catch (e) {

        }
        return `Nº #${orderId}`
    }

    render() {
        const {
            closeForce,
            client,
            title_page,
            openSearch,
            drawer,
            images,
            installButton,
            menu,
            totalOrder,
            totem,
            progress,
            itens,
            openCloseStatusPix,
            motifClosed,
            openContact,
            firstAccess,
            telphone,
            installAppIos,
            whatsapp,
            address,
            blocked,
            openShare,
            status,
            retire,
            orderId,
            openingHours,
            allowScheduledDelivery,
            orderWithOnlinePayment,
            scheduled,
            openPop,
            openProcessOrder,
            openErro,
            couponPop,
            messagePop,
            messageError,
            favorites,
            gularisUrl,
            messageStatus,
            clearStore,
            statusStore,
            closeStore,
            openStrore,
            cookies,
            snackbar,
            openSnackbar,
            disableTryAgain,
            pix,
            purchaseMin,
            modules,
            open,
            timeTakeDelivery,
            timeTakeAway,
            deliveryNow,
            totalItens,
            closedNextDay,
            scheduledDay,
            timePixExpire,
            openDataStore,
            colorStore: {
                color_title,
                color_toolbar,
                color_body,
                color_cardProduct,
                color_description,
                color_nextButton,
                color_fontButton,
                color_fontToolbar
            }
        } = this.state

        const dialogs = createMuiTheme({
            palette: {
                primary: {
                    main: '#000000'
                }
            },
        })

        const buttons = createMuiTheme({
            palette: {
                primary: {
                    main: color_title ? color_title : '#ffffff'
                }
            },
        })

        const theme = createMuiTheme({
            palette: {
                primary: {
                    main: color_fontToolbar ? color_fontToolbar : '#000000'
                }
            },
        })

        return (
            <div id="menu" style={color_body ? {background: color_body} : {}}>

                {
                    !blocked &&
                    <div id={'div-container-menu'}>
                        <AppBar
                            id="appBar"
                            position="sticky"
                            color="default"
                            style={color_toolbar ? {background: color_toolbar} : {}}>
                            <Toolbar variant="dense">
                                <div id="toolbar-menu">
                                    <div id="toolbar-menu-left">
                                        <IconButton edge="start" color="inherit" aria-label="menu"
                                                    onClick={this.onClickDrawer}>
                                            <MenuIcon style={color_fontToolbar ? {color: color_fontToolbar} : {}}/>
                                        </IconButton>
                                        <Typography id="not-selector" variant="h6" color="inherit"
                                                    style={color_fontToolbar ? {color: color_fontToolbar} : {}}>
                                            {title_page}
                                        </Typography>
                                    </div>
                                    <div id="toolbar-menu-right">
                                        {
                                            openSearch &&
                                            <div id="div-search-menu">
                                                <CloseIcon id="icon-search-menu"

                                                           onClick={this.onClickCloseSearch}/>
                                                <MuiThemeProvider theme={theme}>
                                                    <Input
                                                        name="search"
                                                        id="search"
                                                        autoFocus={true}
                                                        color="primary"
                                                        fullWidth={true}
                                                        onChange={this.handleInput}
                                                        placeholder="Buscar"/>
                                                </MuiThemeProvider>
                                                <div id="div-arrow-search">
                                                    <ExpandLess
                                                        id="expand-search-sacola"
                                                        onClick={() => this.onClickExpand(true)}/>
                                                    <ExpandMore
                                                        id="expand-search-sacola"
                                                        onClick={() => this.onClickExpand(false)}/>
                                                </div>
                                            </div>
                                        }
                                        <SearchIcon id="search-sacola"
                                                    style={color_fontToolbar ? {color: color_fontToolbar} : {}}
                                                    onClick={this.onClickSerach}/>
                                    </div>
                                </div>
                            </Toolbar>
                        </AppBar>

                        <div id={'div-menu'}>
                            <div id="container-menu">
                                <nav className={classes.drawer} aria-label="mailbox folders">
                                    <Hidden smUp implementation="css">
                                        <Drawer variant="temporary" anchor='left' open={drawer}
                                                onClose={this.onClickDrawer}
                                                classes={{
                                                    paper: classes.drawerPaper,
                                                }}
                                                ModalProps={{
                                                    keepMounted: true,
                                                }}>
                                            <div>
                                                <div className={classes.toolbar}/>
                                                <div id="div-logo-drawer">
                                                    <CardMedia id="logo-drawer" image={Logo} alt=""/>
                                                </div>
                                                <div id="div-logo-drawer">
                                                    <FormLabel
                                                        id="name-drawer">{user.nome_estabecimento}</FormLabel>
                                                </div>
                                                <Divider/>
                                                {
                                                    (gularisUrl) &&
                                                    <div id="card-drawer"
                                                         onClick={() => this.onClickGularisConsultation(gularisUrl)}>
                                                        <MonetizationOnRounded id="icon-drawer"/>
                                                        <FormLabel id="text-drawer">Cashback</FormLabel>
                                                    </div>
                                                }
                                                {
                                                    (gularisUrl) &&
                                                    <Divider id={'divider-drawer'}/>
                                                }
                                                {
                                                    (client) &&
                                                    <div id="card-drawer" onClick={() => this.onClickItemDrawer(8)}>
                                                        <Cached id="icon-drawer"/>
                                                        <FormLabel id="text-drawer">
                                                            Repetir último pedido
                                                        </FormLabel>
                                                    </div>
                                                }
                                                {
                                                    (client) &&
                                                    <Divider id={'divider-drawer'}/>
                                                }
                                                {
                                                    (client) &&
                                                    <div id="card-drawer" onClick={() => this.onClickItemDrawer(0)}>
                                                        <Description id="icon-drawer"/>
                                                        <FormLabel id="text-drawer">Pedidos</FormLabel>
                                                    </div>
                                                }
                                                {
                                                    (client) &&
                                                    <Divider id={'divider-drawer'}/>
                                                }
                                                <div id="card-drawer" onClick={() => this.onClickItemDrawer(1)}>
                                                    <AccountCircle id="icon-drawer"/>
                                                    <FormLabel id="text-drawer">Perfil</FormLabel>
                                                </div>
                                                <Divider id={'divider-drawer'}/>
                                                {
                                                    ((telphone !== '' || whatsapp !== '')) &&
                                                    <div id="card-drawer"
                                                         onClick={() => this.onClickItemDrawer(2)}>
                                                        <Phone id="icon-drawer"/>
                                                        <FormLabel id="text-drawer">Fale conosco</FormLabel>
                                                    </div>
                                                }
                                                {
                                                    (telphone !== '' || whatsapp !== '') &&
                                                    <Divider id={'divider-drawer'}/>
                                                }
                                                <div id="card-drawer" onClick={() => this.onClickItemDrawer(3)}>
                                                    <Map id="icon-drawer"/>
                                                    <FormLabel id="text-drawer">Área de atendimento</FormLabel>
                                                </div>
                                                <Divider id={'divider-drawer'}/>
                                                <div id="card-drawer" onClick={() => this.onClickItemDrawer(4)}>
                                                    <QueryBuilderIcon id="icon-drawer"/>
                                                    <FormLabel id="text-drawer">Horários de atendimento</FormLabel>
                                                </div>
                                                <Divider id={'divider-drawer'}/>
                                                {
                                                    totem &&
                                                    <div id="card-drawer" onClick={() => this.onClickItemDrawer(6)}>
                                                        <DesktopWindows id="icon-drawer"/>
                                                        <FormLabel id="text-drawer">Sair modo totem</FormLabel>
                                                    </div>
                                                }
                                                {
                                                    totem &&
                                                    <Divider id={'divider-drawer'}/>
                                                }
                                                <div id="card-drawer" onClick={() => this.onClickItemDrawer(5)}>
                                                    <ClearAll id="icon-drawer"/>
                                                    <FormLabel id="text-drawer">Limpar dados do App</FormLabel>
                                                </div>
                                                <Divider id={'divider-drawer'}/>
                                                <div id="div-developed">
                                                    <CardMedia image={bitbar} id="logo-by-developed"/>
                                                    <FormLabel
                                                        id="text-by-developed"
                                                        onClick={() => window.open('https://www.bitbar.com.br', '_blank')}>
                                                        Desenvolvido Por BitBar
                                                    </FormLabel>
                                                </div>
                                                <div id="div-version">
                                                    <FormLabel id="text-version"
                                                               onClick={() => this.onClickVersion()}>{`Versão: ${version}`}</FormLabel>
                                                </div>
                                            </div>
                                        </Drawer>
                                    </Hidden>
                                </nav>
                                <Carousel id="carousel">
                                    {
                                        images.map(((i, index) => {
                                            return (
                                                <Carousel.Item key={index}>
                                                    {/*<LazyLoad>*/}
                                                    <CardMedia image={i} id="img-carousel"/>
                                                    {/*</LazyLoad>*/}
                                                </Carousel.Item>
                                            )
                                        }))
                                    }
                                </Carousel>
                                {
                                    (!scheduledDay) &&
                                    <div id={'div-info'} style={{backgroundColor: color_body}}>
                                        <div id={'div-item-info'}>
                                            {(() => {
                                                if (closeForce) {
                                                    return (
                                                        <div id={'div-status-info'} style={{
                                                            backgroundColor: '#ef9a9a',
                                                            border: '2px solid #a11515'
                                                        }}>
                                                            {
                                                                (!!motifClosed)
                                                                    ? <>
                                                                        <FormLabel
                                                                            id={'label-status-store'}
                                                                            style={{color: '#a11515'}}>
                                                                            {motifClosed}
                                                                        </FormLabel>
                                                                    </>
                                                                    : <>
                                                                        <FormLabel
                                                                            id={'label-status-store'}
                                                                            style={{color: '#a11515'}}>
                                                                            Fechado
                                                                        </FormLabel>
                                                                    </>
                                                            }
                                                        </div>
                                                    )
                                                } else if (closedNextDay) {
                                                    return (
                                                        <div id={'div-status-info'} style={{
                                                            backgroundColor: '#ef9a9a',
                                                            border: '2px solid #a11515'
                                                        }}>
                                                            {
                                                                (!!motifClosed)
                                                                    ? <>
                                                                        <FormLabel
                                                                            id={'label-status-store'}
                                                                            style={{color: '#a11515'}}>
                                                                            {motifClosed}
                                                                        </FormLabel>
                                                                    </>
                                                                    : <>
                                                                        <FormLabel
                                                                            id={'label-status-store'}
                                                                            style={{color: '#a11515'}}>
                                                                            Fechado
                                                                        </FormLabel>
                                                                    </>
                                                            }
                                                        </div>
                                                    )
                                                } else if (statusStore && allowScheduledDelivery) {
                                                    return (
                                                        <div id={'div-status-info'} style={{
                                                            backgroundColor: '#b6e1b8',
                                                            border: '2px solid #0dc143'
                                                        }}>
                                                            <FormLabel
                                                                id={'label-status-store'}
                                                                style={{color: '#0dc143'}}>
                                                                {(!deliveryNow) ? 'Aberto somente para retiradas' : 'Aberto'}
                                                            </FormLabel>
                                                            <div>•</div>
                                                            <FormLabel
                                                                id={'label-status-store'}
                                                                style={{color: '#1e1e1e'}}>
                                                                {closeStore}
                                                            </FormLabel>
                                                        </div>
                                                    )
                                                } else if (statusStore && !allowScheduledDelivery) {
                                                    return (
                                                        <div id={'div-status-info'} style={{
                                                            backgroundColor: '#b6e1b8',
                                                            border: '2px solid #0dc143'
                                                        }}>
                                                            <FormLabel
                                                                id={'label-status-store'}
                                                                style={{color: '#0dc143'}}>
                                                                Aberto
                                                            </FormLabel>
                                                            <div>•</div>
                                                            <FormLabel
                                                                id={'label-status-store'}
                                                                style={{color: '#1e1e1e'}}>
                                                                {closeStore}
                                                            </FormLabel>
                                                        </div>
                                                    )
                                                } else if (!statusStore && !allowScheduledDelivery) {
                                                    return (
                                                        <div id={'div-status-info'} style={{
                                                            backgroundColor: '#ef9a9a',
                                                            border: '2px solid #a11515'
                                                        }}>
                                                            {
                                                                (!!motifClosed)
                                                                    ? <>
                                                                        <FormLabel
                                                                            id={'label-status-store'}
                                                                            style={{color: '#a11515'}}>
                                                                            {motifClosed}
                                                                        </FormLabel>
                                                                    </>
                                                                    : <>
                                                                        <FormLabel
                                                                            id={'label-status-store'}
                                                                            style={{color: '#a11515'}}>
                                                                            Fechado
                                                                        </FormLabel>
                                                                        <div>•</div>
                                                                        <FormLabel
                                                                            id={'label-status-store'}
                                                                            style={{color: '#1e1e1e'}}>
                                                                            {openStrore}
                                                                        </FormLabel>
                                                                    </>
                                                            }
                                                        </div>
                                                    )
                                                } else if (!statusStore && allowScheduledDelivery) {
                                                    return (
                                                        <div id={'div-status-info'} style={{
                                                            backgroundColor: '#ffecb3',
                                                            border: '2px solid #ffb74d'
                                                        }}>
                                                            <FormLabel
                                                                id={'label-status-store'}
                                                                style={{color: '#ff8c00'}}>
                                                                Apenas programadas
                                                            </FormLabel>
                                                            <div>•</div>
                                                            <FormLabel
                                                                id={'label-status-store'}
                                                                style={{color: '#1e1e1e'}}>
                                                                {openStrore}
                                                            </FormLabel>
                                                        </div>
                                                    )
                                                }
                                            })()}
                                        </div>
                                        <div id={'div-item-info'} style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-around",
                                            marginTop: 10
                                        }}>
                                            {
                                                ((purchaseMin !== 0)) &&
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    alignItems: "center"
                                                }}>
                                                    <FormLabel
                                                        id={'label-status-store'}
                                                        style={{color: color_title}}>
                                                        {`Pedido mínimo`}
                                                    </FormLabel>
                                                    <FormLabel
                                                        id={'label-status-store'}
                                                        style={{color: color_title}}>
                                                        {`${purchaseMin.toLocaleString('pt-BR', {
                                                            style: 'currency',
                                                            currency: 'BRL'
                                                        })}`}
                                                    </FormLabel>
                                                </div>
                                            }
                                            {
                                                ((modules === 0 || modules === 1)) &&
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    alignItems: "center"
                                                }}>
                                                    <FormLabel
                                                        id={'label-status-store'}
                                                        style={{color: color_title}}>
                                                        Entrega
                                                    </FormLabel>
                                                    <FormLabel
                                                        id={'label-status-store'}
                                                        style={{color: color_title}}>
                                                        {timeTakeDelivery}
                                                    </FormLabel>
                                                </div>
                                            }
                                            {
                                                ((modules === 0 || modules === 2)) &&
                                                <div style={{
                                                    display: "flex",
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    alignItems: "center"
                                                }}>
                                                    <FormLabel
                                                        id={'label-status-store'}
                                                        style={{color: color_title}}>
                                                        Retirada
                                                    </FormLabel>
                                                    <FormLabel
                                                        id={'label-status-store'}
                                                        style={{color: color_title}}>
                                                        {timeTakeAway}
                                                    </FormLabel>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                }
                                <Divider/>
                                <MuiThemeProvider theme={buttons}>
                                    <div id="scroll" style={color_body ? {background: color_body} : {}}>
                                        {
                                            menu.map((i, index) => {
                                                if (i.category !== undefined) {
                                                    return (
                                                        <div key={index} id={this.clearText(i.category) + '-menu'}
                                                             className="guia-menu">
                                                            <Button id="button-guia" variant={'outlined'}
                                                                    color={'primary'}
                                                                    style={color_title ? {color: color_title} : {}}
                                                                    onClick={this.onClickGroups}>
                                                                {i.category}
                                                            </Button>
                                                            <div id={this.clearText(i.category) + '-guia'}
                                                                 className='guia'
                                                                 style={color_title ? {background: color_title} : {}}/>
                                                        </div>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                </MuiThemeProvider>
                                {
                                    progress &&
                                    <div id="progress">
                                        <CircularProgress
                                            style={color_title ? {color: color_title} : {color: '#1e1e1e'}}
                                            size={30}/>
                                    </div>
                                }
                                <div className="scroll-highlights">
                                    <div className="search" id="destaques"/>
                                    {
                                        menu.map(((i, index) => {
                                            if (i.cat === "Destaques") {
                                                return (
                                                    <Destaques key={index} data={i} colors={this.state.colorStore}/>
                                                )
                                            }
                                        }))
                                    }
                                </div>
                                {
                                    (favorites.length !== 0) &&
                                    <div>
                                        <Categorias category={"Meus Favoritos"} colors={this.state.colorStore}/>
                                        <div className="scroll-highlights">
                                            {
                                                favorites.map(((i, index) => (
                                                        <Destaques key={index} data={i}
                                                                   handleChange={this.handleMessage.bind(this)}
                                                                   colors={this.state.colorStore}/>
                                                    )
                                                ))
                                            }
                                        </div>
                                    </div>
                                }
                                <div id="list-products">
                                    {
                                        menu.map(((i, index) => {
                                            if (i.category !== undefined && i.category !== 'Destaques') {
                                                return (
                                                    <div key={index} style={{width: '100%'}}>
                                                        <div id={this.clearText(i.category)} className="search"/>
                                                        <Categorias category={i.category}
                                                                    colors={this.state.colorStore}/>
                                                    </div>
                                                )
                                            } else if (i.cat !== undefined && i.cat !== 'Destaques') {
                                                return (
                                                    <Produtos key={index} data={i}
                                                              handleChange={this.handleProduct.bind(this)}
                                                              colors={this.state.colorStore}/>
                                                )
                                            }
                                        }))
                                    }
                                </div>
                                <div id="rodape-info" style={{background: color_cardProduct}}>
                                    {
                                        (telphone !== '') &&
                                        <FormLabel
                                            id="label-info-rodape"
                                            style={{color: color_title}}>
                                            {`Telefone: ${this.maskPhone(telphone)}`}
                                        </FormLabel>
                                    }
                                    {
                                        (whatsapp !== '') &&
                                        <FormLabel
                                            id="label-info-rodape"
                                            style={{color: color_title}}>
                                            {`WhatsApp: ${this.maskPhone(whatsapp)}`}
                                        </FormLabel>
                                    }
                                    {
                                        (address !== '') &&
                                        <FormLabel
                                            id="label-info-rodape"
                                            style={{color: color_title}}>
                                            {`Endereço: ${address}`}
                                        </FormLabel>
                                    }
                                </div>
                                {/*<div style={{padding: 10, background: '#d9d9d9', borderRadius: 10}}>*/}
                                {/*    <FormLabel>Nova versão</FormLabel>*/}
                                {/*</div>*/}
                                {
                                    (whatsapp) &&
                                    <div id="div-floatingButton">
                                        <Fab
                                            style={status !== '' && (itens.length === 0) ? {
                                                bottom: 280,
                                                background: "#0dc143"
                                            } : {
                                                bottom: 80,
                                                background: "#0dc143"
                                            }}
                                            id="floating-button"
                                            aria-label="WhatsApp" color="primary"
                                            onClick={() => this.onClickContact(1, whatsapp)}>
                                            <WhatsApp id="logo-floating-button"/>
                                        </Fab>
                                    </div>
                                }
                            </div>
                        </div>
                        {
                            (itens.length !== 0) &&
                            <div id="footer-menu"
                                 style={color_nextButton ? {backgroundColor: color_nextButton} : {}}
                                 onClick={this.onclickCarrinho}>
                                <div id="footer-right">
                                    <div>
                                        <ShoppingCartIcon
                                            id="footer-img-car"
                                            style={color_fontButton ? {color: color_fontButton} : {}}/>
                                    </div>
                                    <div id="footer-qtd"
                                         style={color_fontButton ? {backgroundColor: color_fontButton} : {}}>
                                        <FormLabel id="footer-label-qtd"
                                                   style={color_nextButton ? {color: color_nextButton} : {}}>
                                            {itens.length}
                                        </FormLabel>
                                    </div>
                                </div>
                                <div id="footer-center">
                                    <FormLabel id="footer-text"
                                               style={color_fontButton ? {
                                                   fontWeight: "bold",
                                                   color: color_fontButton
                                               } : {}}>
                                        Carrinho
                                    </FormLabel>
                                </div>
                                <div id="footer-left">
                                    <FormLabel id="footer-text"
                                               style={color_fontButton ? {
                                                   color: color_fontButton,
                                                   fontWeight: "bold"
                                               } : {}}>
                                        {totalItens.toLocaleString('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL'
                                        })}
                                    </FormLabel>
                                </div>
                            </div>
                        }
                        {
                            (installAppIos && isIOS && (itens.length === 0)) &&
                            <div id="shortcut" style={{cursor: "pointer"}}>
                                <div id="shortcut-right"
                                     onClick={this.installAppIos}
                                     style={{cursor: "pointer"}}>
                                    <div style={{cursor: "pointer"}}>
                                        <img id="logo-shortcut" src={Logo} alt=""/>
                                    </div>
                                    <div style={{cursor: "pointer"}}>
                                        <FormLabel id="text-shortcut" style={{cursor: "pointer"}}>
                                            Criar atalho na tela inicial
                                        </FormLabel>
                                    </div>
                                </div>
                                <div id="shortcut-left" onClick={this.onClickShortcut}>
                                    <CloseIcon/>
                                </div>
                            </div>
                        }
                        {
                            (installButton && (itens.length === 0)) &&
                            <div id="shortcut" style={{cursor: "pointer"}}>
                                <div id="shortcut-right" onClick={this.installApp} style={{cursor: "pointer"}}>
                                    <div style={{cursor: "pointer"}}>
                                        <img id="logo-shortcut" src={Logo} alt=""/>
                                    </div>
                                    <div style={{cursor: "pointer"}}>
                                        <FormLabel id="text-shortcut" style={{cursor: "pointer"}}>
                                            Instalar aplicativo
                                        </FormLabel>
                                    </div>
                                </div>
                                <div id="shortcut-left" onClick={this.onClickShortcut}>
                                    <CloseIcon/>
                                </div>
                            </div>
                        }
                        {
                            (cookies) &&
                            <div id="cookies">
                                <div id="cookies-left">
                                    <FormLabel id="label-cookies">
                                        Utilizamos cookies essenciais e tecnologias semelhantes de acordo com a
                                        nossa
                                        Política de Privacidade e, ao continuar navegando, você concorda com estas
                                        condições.
                                    </FormLabel>
                                </div>
                                <div id="cookies-right">
                                    <div id="div-buttons-cookies" onClick={this.onClickCokkies}
                                         style={color_nextButton ? {backgroundColor: color_nextButton} : {}}>
                                        <FormLabel id="label-aceitar-cookies"
                                                   style={color_fontButton ? {color: color_fontButton} : {}}>Aceitar</FormLabel>
                                    </div>
                                </div>
                            </div>
                        }
                        {/*eu sempre mexo aqui, descobrir da próxima vez*/}
                        {
                            (((status !== '') && (status !== null) && (itens.length === 0))) &&
                            <div id="timeline"
                                 style={color_cardProduct ? {backgroundColor: color_cardProduct} : {}}>
                                <div id="div-title-timeline">
                                    {(() => {
                                        if (status === 'PIX') {
                                            return (
                                                <FormLabel id="text-timeline"
                                                           style={color_title ? {color: color_title} : {}}>
                                                    Aguardando pagamento PIX
                                                </FormLabel>
                                            )
                                        } else if (!totem) {
                                            return (
                                                <FormLabel id="text-timeline"
                                                           style={color_title ? {color: color_title} : {}}>
                                                    Andamento do pedido
                                                </FormLabel>
                                            )
                                        }
                                    })()}
                                </div>
                                <div id="timeline-top">
                                    <div id="timeline-top-left">
                                        {(() => {
                                            if (status === 'PIX') {
                                                return (<div/>)
                                            } else if (!totem && status !== 'CANCELADO') {
                                                if (status !== 'ATUALIZANDO') {
                                                    return (
                                                        <div id="timeline-description-time-status">
                                                            <FormLabel
                                                                id="text-status-timeline"
                                                                style={color_title ? {color: color_title} : {}}>{retire ? `Previsão de retirada, ${scheduled ? this.scheduledDeliveryTime() : this.addHours()}` : `Previsão de entrega, ${scheduled ? (localStorage.getItem(`bitmenu-${identifier}:orderRoute`) !== null) ? localStorage.getItem(`bitmenu-${identifier}:orderRoute`) : this.scheduledDeliveryTime() : this.addHours()}`}</FormLabel>
                                                            <FormLabel
                                                                id="text-status-timeline"
                                                                style={color_title ? {color: color_title} : {}}>{this.orderId(orderId)}</FormLabel>
                                                            <FormLabel
                                                                id="text-detalhes-pedido-timeline"
                                                                style={color_title ? {color: color_title} : {}}
                                                                onClick={() => this.orderDetails(orderId)}>
                                                                Ver detalhes do pedido
                                                            </FormLabel>
                                                            {
                                                                orderWithOnlinePayment &&
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    alignItems: "center",
                                                                    justifyContent: "center"
                                                                }}>
                                                                    <FormLabel
                                                                        id="text-status-timeline-payment">
                                                                        ATENÇÃO: Primeira compra com pagamento online.
                                                                    </FormLabel>
                                                                    <FormLabel
                                                                        id="text-status-timeline-payment">
                                                                        O cliente deve apresentar documento de
                                                                        identificação e
                                                                        cartão de crédito.
                                                                    </FormLabel>
                                                                </div>
                                                            }
                                                            {
                                                                retire &&
                                                                <div id="div-route">
                                                                    <LocationOn
                                                                        style={color_title ? {color: color_title} : {}}/>
                                                                    <FormLabel
                                                                        id="text-status-timeline"
                                                                        style={color_title ? {
                                                                            color: color_title,
                                                                            marginLeft: 0,
                                                                            cursor: "pointer"
                                                                        } : {
                                                                            marginLeft: 0,
                                                                            cursor: "pointer"
                                                                        }}
                                                                        onClick={this.onClickMap}>
                                                                        Ver rota até local de retirada
                                                                    </FormLabel>
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                }
                                            } else if (!totem) {
                                                return (
                                                    <div id="div-status-cancelamento">
                                                        <div id="div-icon-warning">
                                                            <Warning id="icon-warning"/>
                                                        </div>
                                                        <FormLabel id="text-status-timeline"
                                                                   style={{color: '#f44336'}}>
                                                            Pedido Cancelado
                                                        </FormLabel>
                                                        <FormLabel id="text-status-timeline"
                                                                   style={color_title ? {color: color_title} : {}}>
                                                            {messageStatus}
                                                        </FormLabel>
                                                    </div>
                                                )
                                            }
                                        })()}
                                    </div>
                                    {
                                        (!totem && (status === 'ENTREGANDO' || status === 'ENTREGUE' || status === 'CANCELADO' || status === 'PIX')) &&
                                        <div id="timeline-top-right"
                                             onClick={() => {
                                                 if (status === 'PIX') {
                                                     this.closeStatusPix()
                                                 } else {
                                                     this.closeStatus((status === 'ENTREGUE' || status === 'CANCELADO'))
                                                 }
                                             }}>
                                            <CloseIcon
                                                style={color_title ? {
                                                    color: color_title,
                                                    margin: 8,
                                                    cursor: "pointer"
                                                } : {cursor: "pointer", margin: 8}}/>
                                        </div>
                                    }
                                </div>
                                {(() => {
                                    if (status === 'PIX') {
                                        return (
                                            <div id="timeline-footer">
                                                <div id="div-status-delivery">
                                                    <FormLabel id="text-status-timeline-qrcode"
                                                               style={color_title ? {color: color_title} : {}}>
                                                        O pedido só será enviado depois do pagamento
                                                    </FormLabel>
                                                </div>
                                                <div id="div-timeline-qrcode">
                                                    <QRCode id="qrcode" value={messageStatus}/>
                                                </div>
                                                <div id="div-status-delivery-qrcode">
                                                    <CopyToClipboard
                                                        text={messageStatus}
                                                        onCopy={() => this.setState({
                                                            openSnackbar: true,
                                                            snackbar: 'Copiado !'
                                                        })}>
                                                        <span style={{
                                                            color: '#ffffff',
                                                            margin: 8
                                                        }}>Copiar PIX</span>
                                                    </CopyToClipboard>
                                                </div>
                                                {/*<div*/}
                                                {/*    onClick={() => this.closeStatus()}>*/}
                                                {/*    <FormLabel id="text-status-timeline-qrcode"*/}
                                                {/*               style={{color: color_title}}>*/}
                                                {/*        Cancelar*/}
                                                {/*    </FormLabel>*/}
                                                {/*</div>*/}
                                                <div id="div-status-delivery-qrcode-text">
                                                    <FormLabel id="text-status-timeline-qrcode">
                                                        1º Copie o código PIX
                                                    </FormLabel>
                                                    <FormLabel id="text-status-timeline-qrcode">
                                                        2º Abra o aplicativo do seu banco
                                                    </FormLabel>
                                                    <FormLabel id="text-status-timeline-qrcode">
                                                        3º Vá até a opção PIX
                                                    </FormLabel>
                                                    <FormLabel id="text-status-timeline-qrcode">
                                                        4º Vá em pagar PIX com copia e cola
                                                    </FormLabel>
                                                </div>
                                                <div id="div-status-delivery">
                                                    <FormLabel id="text-status-timeline-qrcode"
                                                               style={color_title ? {color: color_title} : {}}>
                                                        {`Nº #${orderId}`}
                                                    </FormLabel>
                                                    {
                                                        totalOrder !== 0 &&
                                                        <FormLabel id="text-status-timeline-qrcode"
                                                                   style={color_title ? {
                                                                       color: color_title
                                                                   } : {}}>
                                                            {`Total: ${totalOrder.toLocaleString('pt-BR', {
                                                                style: 'currency',
                                                                currency: 'BRL'
                                                            })}`}
                                                        </FormLabel>
                                                    }
                                                    {
                                                        (pix && timePixExpire) &&
                                                        <div id="div-status-delivery">
                                                            <AccessTime style={{color: color_title}}/>
                                                            <FormLabel
                                                                id="text-status-timeline-qrcode"
                                                                style={{color: color_title}}>
                                                                {timePixExpire}
                                                            </FormLabel>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    } else if (!totem && status === 'ATUALIZANDO') {
                                        return (
                                            <div id="timeline-footer">
                                                <div id="div-timeline">
                                                    <div id="div-step-timeline">
                                                        <div id="div-icones-steps-timeline">
                                                            <Refresh id="icon-warning"
                                                                     style={{color: '#ff6d00'}}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="div-status-delivery">
                                                    <Warning id="icon-warning"
                                                             style={{color: '#ff6d00'}}/>
                                                    <FormLabel id="text-status-timeline"
                                                               style={{color: '#ff6d00'}}>
                                                        {pix ? 'Aguarde a geração do PIX para pagar.' : 'Buscando status do pedido'}
                                                    </FormLabel>
                                                </div>
                                            </div>
                                        )
                                    } else if (!totem && status !== 'CANCELADO') {
                                        return (
                                            <div id="timeline-footer">
                                                <div id="div-timeline">
                                                    <div id="div-step-timeline">
                                                        <div id="div-icones-steps-timeline">
                                                            <div id="div-delivery-step"
                                                                 style={status === 'PENDENTE' ? {borderColor: '#ff6d00'} : {borderColor: '#81c784'}}>
                                                                <FormLabel id="label-delivery-step"
                                                                           style={status === 'PENDENTE' ? {color: '#ff6d00'} : {color: '#81c784'}}>1</FormLabel>
                                                            </div>
                                                        </div>
                                                        <div id="div-order-create"
                                                             style={status === 'PENDENTE' ? {backgroundColor: '#ff6d00'} : {backgroundColor: '#81c784'}}/>
                                                    </div>
                                                    <div id="div-step-timeline">
                                                        <div id="div-icones-steps-timeline">
                                                            <div id="div-delivery-step"
                                                                 style={status === 'CONFIRMADO' || status === 'ENTREGANDO' || status === 'ENTREGUE' ? {borderColor: '#81c784'} : {borderColor: color_description}}>
                                                                <FormLabel id="label-delivery-step"
                                                                           style={status === 'CONFIRMADO' || status === 'ENTREGANDO' || status === 'ENTREGUE' ? {color: '#81c784'} : {color: color_description}}>2</FormLabel>
                                                            </div>
                                                        </div>
                                                        <div id="div-order-received"
                                                             style={status === 'CONFIRMADO' || status === 'ENTREGANDO' || status === 'ENTREGUE' ? {background: '#81c784'} : {background: color_description}}/>
                                                    </div>
                                                    <div id="div-step-timeline">
                                                        <div id="div-icones-steps-timeline">
                                                            <div id="div-delivery-step"
                                                                 style={status === 'ENTREGANDO' || status === 'ENTREGUE' ? {borderColor: '#81c784'} : {borderColor: color_description}}>
                                                                <FormLabel id="label-delivery-step"
                                                                           style={status === 'ENTREGANDO' || status === 'ENTREGUE' ? {color: '#81c784'} : {color: color_description}}>3</FormLabel>
                                                            </div>
                                                        </div>
                                                        <div id="div-order-delivering"
                                                             style={status === 'ENTREGANDO' || status === 'ENTREGUE' ? {background: '#81c784'} : {background: color_description}}/>
                                                    </div>
                                                    <div id="div-step-timeline">
                                                        <div id="div-icones-steps-timeline">
                                                            <div id="div-delivery-step"
                                                                 style={status === 'ENTREGUE' ? {borderColor: '#81c784'} : {borderColor: color_description}}>
                                                                <FormLabel id="label-delivery-step"
                                                                           style={status === 'ENTREGUE' ? {color: '#81c784'} : {color: color_description}}>4</FormLabel>
                                                            </div>
                                                        </div>
                                                        <div id="div-order-delivered"
                                                             style={status === 'ENTREGUE' ? {background: '#81c784'} : {background: color_description}}/>
                                                    </div>
                                                </div>
                                                {(() => {
                                                    if (!totem && status === 'ATUALIZANDO') {
                                                        return (
                                                            <div id="div-status-delivery">
                                                                <Warning id="icon-warning"
                                                                         style={pix ? {color: '#1e1e1e'} : {color: '#ff6d00'}}/>
                                                                <FormLabel id="text-status-timeline"
                                                                           style={pix ? {color: '#1e1e1e'} : {color: '#ff6d00'}}>
                                                                    {pix ? 'Gerando PIX' : 'Buscando status do pedido'}
                                                                </FormLabel>
                                                            </div>
                                                        )
                                                    } else if (!totem && status === 'PENDENTE') {
                                                        return (
                                                            <div id="div-status-delivery">
                                                                <Warning id="icon-warning"/>
                                                                <FormLabel id="text-status-timeline"
                                                                           style={{color: '#ff6d00'}}>
                                                                    Aguardando confirmação do restaurante
                                                                </FormLabel>
                                                            </div>
                                                        )
                                                    } else if (!totem && status === 'CONFIRMADO') {
                                                        return (
                                                            <div id="div-status-delivery">
                                                                <Restaurant
                                                                    id="icon-order"
                                                                    style={color_title ? {color: color_title} : {color: '#ffffff'}}/>
                                                                <FormLabel id="text-status-timeline"
                                                                           style={color_title ? {color: color_title} : {}}>
                                                                    Pedido recebido e sendo preparado ...
                                                                </FormLabel>
                                                            </div>
                                                        )
                                                    } else if (!totem && status === 'ENTREGANDO') {
                                                        return (
                                                            <div id="div-status-delivery">
                                                                {
                                                                    retire ?
                                                                        <InsertEmoticon
                                                                            id="icon-order"
                                                                            style={color_title ? {color: color_title} : {color: '#ffffff'}}/>
                                                                        :
                                                                        <Motorcycle
                                                                            id="icon-order"
                                                                            style={color_title ? {color: color_title} : {color: '#ffffff'}}/>
                                                                }
                                                                <FormLabel id="text-status-timeline"
                                                                           style={color_title ? {color: color_title} : {}}>
                                                                    {retire ? `Pedido está pronto, aguardando retirada` : `Pedido saiu para entrega`}
                                                                </FormLabel>
                                                            </div>
                                                        )
                                                    } else if (!totem && status === 'ENTREGUE') {
                                                        return (
                                                            <div id="div-status-delivery">
                                                                <InsertEmoticon
                                                                    id="icon-order"
                                                                    style={color_title ? {color: color_title} : {color: '#ffffff'}}/>
                                                                <FormLabel id="text-status-timeline"
                                                                           style={color_title ? {color: color_title} : {}}>
                                                                    Pedido entregue
                                                                </FormLabel>
                                                            </div>
                                                        )
                                                    }
                                                })()}
                                            </div>
                                        )
                                    }
                                })()}
                            </div>
                        }
                        <MuiThemeProvider theme={dialogs}>
                            <Snackbar
                                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                                open={openSnackbar}
                                autoHideDuration={700}
                                onClose={() => this.setState({openSnackbar: false})}
                                message={snackbar}
                            />
                            <Dialog open={openContact} onClose={this.closeDialogContact}>
                                <DialogTitle style={{textAlign: 'center'}}>Contatos</DialogTitle>
                                <DialogContent>
                                    <div id="contatc">
                                        {
                                            (telphone !== '') &&
                                            <div
                                                id="div-contact"
                                                onClick={() => this.onClickContact(0, telphone)}>
                                                <DialogContentText
                                                    id="texto-message">
                                                    {`Telefone: ${this.maskPhone(telphone)}`}
                                                </DialogContentText>
                                            </div>
                                        }
                                        {
                                            (whatsapp !== '') &&
                                            <div id="div-contact"
                                                 onClick={() => this.onClickContact(1, whatsapp)}>
                                                <DialogContentText
                                                    id="texto-message">
                                                    {`WhatsApp: ${this.maskPhone(whatsapp)}`}
                                                </DialogContentText>
                                            </div>
                                        }
                                        {
                                            (address !== '') &&
                                            <div>
                                                <Divider/>
                                                <div id="div-contact" style={{margin: 12}}>
                                                    <DialogContentText
                                                        id="texto-message">{address}
                                                    </DialogContentText>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </DialogContent>
                            </Dialog>
                            <Dialog open={firstAccess} fullScreen={true}>
                                <div id="firstAccess">
                                    <div>
                                        <CardMedia image={loading} id="gif-firstAcess"/>
                                    </div>
                                    <div>
                                        <FormLabel id="text-firstAcess">
                                            Aguarde enquanto montamos o cardápio pra você
                                        </FormLabel>
                                    </div>
                                </div>
                            </Dialog>
                            <Dialog open={this.state.openMaps} onClose={this.closeMaps}>
                                <DialogTitle>Mapa</DialogTitle>
                                <DialogContentText
                                    id="texto-message">
                                    Deseja ver a localização do estabelecimento no mapa ?
                                </DialogContentText>
                                <DialogActions>
                                    <Button
                                        id={'button-dialog'}
                                        onClick={this.closeMaps}
                                        color="primary">
                                        Não
                                    </Button>
                                    <Button
                                        variant={'contained'}
                                        id={'button-dialog'}
                                        onClick={this.onClickMap}
                                        color="primary">
                                        Sim
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <Dialog open={openCloseStatusPix}
                                    onClose={() => this.setState({openCloseStatusPix: false})}>
                                <DialogTitle>Confirmação</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="texto-message">
                                        Deseja cancelar pagamento PIX?
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        id={'button-dialog'}
                                        onClick={() => this.setState({openCloseStatusPix: false})}
                                        color={'primary'}>
                                        Não
                                    </Button>
                                    <Button
                                        variant={'contained'}
                                        id={'button-dialog'}
                                        onClick={() => {
                                            this.closeStatus()
                                            this.setState({openCloseStatusPix: false})
                                        }}
                                        color={'primary'}>
                                        Sim
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <Dialog open={clearStore} onClose={() => this.setState({clearStore: false})}>
                                <DialogTitle>Limpar dados da loja</DialogTitle>
                                <DialogContentText
                                    id="texto-message">
                                    Deseja limpar todos os seus dados dessa loja ?
                                </DialogContentText>
                                <DialogActions>
                                    <Button
                                        id={'button-dialog'}
                                        onClick={() => this.setState({clearStore: false})}
                                        color="primary">Não</Button>
                                    <Button
                                        variant={'contained'}
                                        id={'button-dialog'}
                                        onClick={this.onClickCached}
                                        color="primary">
                                        Sim
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <Dialog open={openShare} onClose={() => this.setState({openShare: false})}>
                                <DialogTitle>Compartilhar</DialogTitle>
                                <DialogContent id="dialog-content-share">
                                    <WhatsApp id="share-whats" onClick={() => this.onClickShare(true)}/>
                                    <Facebook id="share-face" onClick={() => this.onClickShare(false)}/>
                                </DialogContent>
                            </Dialog>
                            <Dialog open={this.state.openNotification} onClose={this.closeNotification}>
                                <DialogTitle>Notificações</DialogTitle>
                                <DialogContentText
                                    id="texto-message">
                                    Permita as notificações para informá-lo sobre o andamento de seus pedidos
                                </DialogContentText>
                                <DialogActions>
                                    <Button
                                        id={'button-dialog'}
                                        onClick={this.closeNotification}
                                        color="primary">
                                        Não
                                    </Button>
                                    <Button
                                        variant={'contained'}
                                        id={'button-dialog'}
                                        onClick={this.onClickNotification}
                                        color="primary">
                                        Sim
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <Dialog open={this.state.receiveMessage.open} onClose={this.closeMessage}>
                                <div style={{width: 250}}>
                                    <div id="container-sucess">
                                        <img id="logo-sucess" src={Logo} alt=""/>
                                    </div>
                                    <DialogTitle id="title-message">
                                        {this.state.receiveMessage.title}
                                    </DialogTitle>
                                    <DialogContentText
                                        id="texto-message">{this.state.receiveMessage.description}
                                    </DialogContentText>
                                </div>
                                <DialogActions>
                                    <Button
                                        variant={'contained'}
                                        fullWidth={true}
                                        onClick={() => this.setState({receiveMessage: {open: false}})}
                                        color="primary">Ok</Button>
                                </DialogActions>
                            </Dialog>
                            <Dialog open={openDataStore} onClose={() => this.setState({openDataStore: false})}>
                                <DialogContent>
                                    <DialogContentText onClick={() => navigator.clipboard.writeText(authkey)}>
                                        {`Authkey: ${authkey}`}
                                    </DialogContentText>
                                    <DialogContentText onClick={() => navigator.clipboard.writeText(id_loja)}>
                                        {`Id Loja: ${id_loja}`}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        fullWidth={true}
                                        onClick={() => {
                                            window.location.href = `https://bitbar.online/util/api/v3/getmenu.php?authkey=${authkey}&id=${id_loja}&index=1`
                                        }}
                                        color="primary">
                                        LINK
                                    </Button>
                                    <Button
                                        variant={'contained'}
                                        fullWidth={true}
                                        onClick={() => this.setState({openDataStore: false})}
                                        color="primary">
                                        Fechar
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <Dialog open={openPop}>
                                <DialogTitle style={{fontFamily: 'Ubuntu'}}>
                                    Você ganhou um cupom de desconto
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="texto-message">{messagePop}</DialogContentText>
                                    <DialogContentText id="texto-message">{couponPop}</DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        id={'button-dialog'}
                                        onClick={this.onClickCanceledCoupon}
                                        color="primary">
                                        Cancelar
                                    </Button>
                                    <Button
                                        id={'button-dialog'}
                                        variant={'contained'}
                                        onClick={this.onClickCopydCoupon}
                                        color="primary">
                                        Copiar Cupom
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <Dialog open={openErro} onClose={() => this.setState({openErro: false})}>
                                <div id="div-warning">
                                    <Warning id="icon-warning"/>
                                </div>
                                <DialogTitle id={'title-dialog'}>
                                    Erro ao enviar o pedido
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="texto-message">{messageError}</DialogContentText>
                                    <DialogContentText id="texto-message">{`Nº #${orderId}`}</DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    {
                                        disableTryAgain &&
                                        <Button
                                            id={'button-dialog'}
                                            onClick={this.onClickResendOrder} color="primary">
                                            Tentar novamente
                                        </Button>
                                    }
                                    <Button
                                        variant={'contained'}
                                        id={'button-dialog'}
                                        onClick={this.onclickCarrinho} color="primary">
                                        Ir pro carrinho
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <Dialog open={openProcessOrder}>
                                <DialogContent>
                                    <div id="processOrder">
                                        <div id="processOrder-left">
                                            <CircularProgress/>
                                        </div>
                                        <div id="processOrder-right">
                                            <DialogContentText id="texto-message">
                                                Enviando pedido
                                            </DialogContentText>
                                        </div>
                                    </div>
                                </DialogContent>
                            </Dialog>
                        </MuiThemeProvider>
                    </div>
                }
                <Box p={2}>.</Box>
                <MuiThemeProvider theme={dialogs}>
                    <Dialog open={this.state.openMessage} onClose={this.closeDialogMessage}
                            id={'dialog'}>
                        <div style={{width: 250}}>
                            <div id="container-sucess">
                                <img id="logo-sucess" src={Logo} alt=""/>
                            </div>
                            <DialogContentText id="texto-sucess">{this.state.message}</DialogContentText>
                            {
                                openingHours &&
                                <div>
                                    <DialogContentText id="label-text-opening-hours">
                                        {(allowScheduledDelivery && open) ? `Você poderá navegar normalmente, mas só poderá fazer pedidos programados` : `Você poderá navegar normalmente, mas não poderá finalizar o pedido`}
                                    </DialogContentText>
                                    <DialogContentText id="label-opening-hours" onClick={() => {
                                        this.onClickItemDrawer(4)
                                        this.setState({openMessage: false})
                                    }}>Ver horários de atendimento</DialogContentText>
                                </div>
                            }
                        </div>
                        <DialogActions>
                            <Button variant="contained"
                                    fullWidth={true}
                                    onClick={() => this.setState({openMessage: false})}
                                    color="primary"
                                    style={{fontFamily: 'Ubuntu'}}>
                                Ok
                            </Button>
                        </DialogActions>
                    </Dialog>
                </MuiThemeProvider>
                <div id={this.idDiv()}/>
            </div>
        )
    }

}

export default Menu
