import React from 'react'
import {RadioGroup, Radio, Card, FormControlLabel, FormLabel, Divider, CardMedia} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import './RadioButton.css'
import {IDENTIFIER_STORE} from '../../util'
import LazyLoad from "react-lazy-load";

const RedRadio = withStyles({
    checked: {},
})(props => <Radio color="default" {...props} />)

let identifier

class RadioButton extends React.Component {

    state = {
        showPicture: false,
        showDescription: '',
        showPhoto: false
    }

    onRadio = (e) => {
        let obj = JSON.parse(e.target.value)
        const {step, variation, key, ordem} = this.props.variations
        let priceVariation = this.props.variations.price !== undefined ? this.props.variations.price : 0
        let options = {
            step: step,
            variation: variation,
            option: obj.option,
            sku: obj.sku,
            price: obj.price,
            amount: 1,
            key: key,
            ordem: ordem,
            priceVariation: priceVariation,
            discountWithCoupon: (step === 1)
        }
        this.setState({[this.props.variations.variation]: false})
        this.props.handleChange(options)
    }

    clearText = text => {
        text = text.normalize("NFD").replace(/[^a-zA-Zs]/g, "")
        return text.toLowerCase()
    }

    clickImage = image => {
        this.props.handleChange({image: image})
    }

    showPicture = options => {
        let showPicture = false
        try {
            for (let i = 0; i < options.length; i++) {
                if (options[i].image !== '') {
                    showPicture = true
                    break
                }
            }
        } catch (e) {

        }
        let showDescription = false
        let showPhoto = false
        try {
            showDescription = JSON.parse(localStorage.getItem(`bitmenu-${identifier}:showDescription`))
            showPhoto = JSON.parse(localStorage.getItem(`bitmenu-${identifier}:showPhoto`))
        } catch (e) {

        }
        this.setState({showPicture: showPicture, showDescription: showDescription, showPhoto: showPhoto})
    }

    checkVariations = () => {
        const {variations: {variation}, options} = this.props
        this.setState({[variation]: true})
        this.showPicture(options)
    }

    componentDidMount() {
        identifier = IDENTIFIER_STORE
        this.checkVariations()
    }

    render() {
        const {variations: {variation, key, price}, options} = this.props
        const {
            color_title,
            color_toolbar,
            color_cardProduct,
            color_description,
            color_price,
            color_fontToolbar
        } = this.props.colors
        const {showPicture, showDescription, showPhoto} = this.state
        return (
            <Card id="card-radio" style={color_cardProduct ? {background: color_cardProduct} : {}}>
                <div id={key}/>
                <div id="card-titulo-radio" style={color_toolbar ? {background: color_toolbar} : {}}>
                    <div id="card-texto-radio">
                        <FormLabel id="variacao-radio"
                                   style={color_fontToolbar ? {color: color_fontToolbar} : {}}>{`${variation}`}</FormLabel>
                    </div>
                    {
                        this.state[variation] &&
                        < div id="card-obrigatorio-radio">
                            <FormLabel id="variacao-obrigatorio-radio">OBRIGATÓRIO</FormLabel>
                        </div>
                    }

                </div>
                {
                    price &&
                    <div id="div-title-price">
                        <FormLabel id="price-variation"
                                   style={color_price ? {color: color_price} : {}}>{price.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                        })}</FormLabel>
                    </div>
                }
                <Divider/>
                <RadioGroup>
                    {
                        options.map((i, index) => {
                            return (
                                <div id={this.clearText(i.option)} className="radio" key={index}>
                                    {
                                        (showPicture && (i.sku.includes('S') || showPhoto)) &&
                                        <div id="div-image-flavor" onClick={() => this.clickImage(i.image)}>
                                            <LazyLoad>
                                                <CardMedia id="image-flavor"
                                                           image={i.image !== undefined ? i.image : ''}/>
                                            </LazyLoad>
                                        </div>
                                    }
                                    <div id="div-radio">
                                        <FormControlLabel id="form-radio" value={JSON.stringify(i)}
                                                          control={<RedRadio
                                                              style={color_title ? {color: color_title} : {color: '#000000'}}/>}
                                                          label={i.option}
                                                          style={color_title ? {color: color_title} : {}}
                                                          onChange={this.onRadio}/>
                                        {(showDescription || i.sku.includes('S')) && i.description !== '' &&
                                            <FormLabel id="description-radio"
                                                       style={color_description ? {color: color_description} : {}}>{i.description}</FormLabel>}
                                        <div id="div-prices">
                                            {i.price !== 0 &&
                                                <FormLabel id="preco-radio"
                                                           style={color_price ? {color: color_price} : {}}>{i.price.toLocaleString('pt-BR', {
                                                    style: 'currency',
                                                    currency: 'BRL'
                                                })}</FormLabel>}
                                            {i.oldPrice !== undefined && i.price !== 0 &&
                                                <FormLabel id="preco-radio-old">{i.oldPrice.toLocaleString('pt-BR', {
                                                    style: 'currency',
                                                    currency: 'BRL'
                                                })}</FormLabel>}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </RadioGroup>
            </Card>
        )
    }
}

export default RadioButton
