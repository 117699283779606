import React from 'react'
import {Card, FormLabel} from '@material-ui/core'
import './Categorias.css'

class Categorias extends React.Component {

    render() {
        const {color_title, color_cardProduct} = this.props.colors
        return (
            <Card className="card-content-category" style={color_cardProduct ? {background: color_cardProduct} : {}}>
                <FormLabel id="card-titulo"
                           style={color_title ? {color: color_title} : {}}>{this.props.category}</FormLabel>
            </Card>
        )
    }
}

export default Categorias
