import ReactGA from 'react-ga4'
import ReactPixel from 'react-facebook-pixel'
import user from './usuario.json'

const showData = (data) => {
    if (data) {
        data = data.split('').reverse().join('')
        data = atob(data)
        return data
    }
}

const hideData = (data) => {
    if (data) {
        data = btoa(data)
        data = data.split('').reverse().join('')
        return data
    }
}

const rest = async (url, conexao) => {
    return await fetch(url, conexao).then((data) => data.json()).catch((error) => (error))
}

const smoothScrolling = (id, time, top = 50) => {
    try {
        let target = document.getElementById(id)
        let elem = document.scrollingElement || document.documentElement
        let style = 'scrollTop'
        let unit = ''
        let from = window.scrollY
        let prop = true
        let to = ((target.offsetTop) - top)

        if (!elem) return

        var start = new Date().getTime(),
            timer = setInterval(function () {
                var step = Math.min(1, (new Date().getTime() - start) / time)
                if (prop) {
                    elem[style] = (from + step * (to - from)) + unit
                } else {
                    elem.style[style] = (from + step * (to - from)) + unit
                }
                if (step === 1) {
                    clearInterval(timer)
                }
            }, 25);
        if (prop) {
            elem[style] = from + unit
        } else {
            elem.style[style] = from + unit
        }
    } catch (e) {

    }
}

const searchEmJSON = search => {
    let pairs = search.substring(1).split('&'), objeto = {}, pair, i;
    for (i in pairs) {
        if (pairs[i] === '') continue
        pair = pairs[i].split('=')
        objeto[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1])
    }
    return objeto
}

const chave = (index = '') => {
    try {
        let a = randomLetters()
        let date = new Date()
        let b = date.getTime()
        return `${a}${b}`
    } catch (e) {
        return `${index}`
    }
}

const randomLetters = () => {
    const letters = 'qwertyuioplkjhgfdsazxcvbnmQWERTYUIOPLKJHGFDSAZXCVBNM'
    let result = ''
    for (let i = 0; i < 10; i++) {
        result += letters.charAt(Math.floor(Math.random() * letters.length))
    }
    return result
}

//const URL_BASE = 'http://localhost:8080/bitmenu'
const URL_BASE = 'https://bitmenu.com.br/bitmenu'
const GOOGLE_API_KEY = 'AIzaSyBOz_AQwKVkMtM4hI0luyG6avRhnRi1IZ4'
const IDENTIFIER_STORE = `${user.authkey}_${user.id_loja}_${user.index}`

const pageView = () => {
    if (!isDebug()) {
        try {
            ReactPixel.pageView()
        } catch (e) {
            console.log('Falha ao marcar página Pixel')
        }
        try {
            ReactGA.pageview(window.location.pathname + window.location.search)
        } catch (e) {
            console.log('Falha ao marcar página Analytics')
        }
    }
}

const eventFacebook = (event, data = null) => {
    if (!isDebug()) {
        try {
            ReactPixel.track(event, data)
        } catch (e) {
            console.log('Falha ao marcar evento Pixel')
        }
    }
}

const eventGA4 = (event, params) => {
    if (!isDebug()) {
        try {
            ReactGA.event(event, params)
        } catch (e) {
            console.log('Falha ao marcar evento Analytics')
        }
    }
}

const eventGoogle = (event, data = null) => {
    if (!isDebug()) {
        try {
            let args
            if (data !== null) {
                args = {
                    category: event,
                    action: data.action,
                    label: data.content_name,
                    value: data.value,
                }
            } else {
                args = {category: event}
            }
            ReactGA.event(args)
        } catch (e) {
            console.log('Falha ao marcar evento Analytics')
        }
    }
}

const maskPhone = phone => {
    if (phone !== '')
        return phone.replace(/\D/g, '').replace(/(\d{2})(\d)/, '($1) $2')
}

const validaCPF = (cpf) => {
    cpf = cpf.replace(/[^\d]+/g, '')
    if (cpf === '') return false
    if (cpf.length !== 11 ||
        cpf === "00000000000" ||
        cpf === "11111111111" ||
        cpf === "22222222222" ||
        cpf === "33333333333" ||
        cpf === "44444444444" ||
        cpf === "55555555555" ||
        cpf === "66666666666" ||
        cpf === "77777777777" ||
        cpf === "88888888888" ||
        cpf === "99999999999")
        return false
    let add = 0
    let rev
    for (let i = 0; i < 9; i++)
        add += parseInt(cpf.charAt(i)) * (10 - i)
    rev = 11 - (add % 11)
    if (rev === 10 || rev === 11)
        rev = 0
    if (rev !== parseInt(cpf.charAt(9)))
        return false
    add = 0
    for (let i = 0; i < 10; i++)
        add += parseInt(cpf.charAt(i)) * (11 - i)
    rev = 11 - (add % 11)
    if (rev === 10 || rev === 11)
        rev = 0
    if (rev !== parseInt(cpf.charAt(10)))
        return false
    return true
}

const validarCNPJ = cnpj => {
    /* eslint-disable */
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj === '') return false;

    if (cnpj.length !== 14)
        return false;

    // Elimina CNPJs invalidos conhecidos
    if (cnpj === "00000000000000" ||
        cnpj === "11111111111111" ||
        cnpj === "22222222222222" ||
        cnpj === "33333333333333" ||
        cnpj === "44444444444444" ||
        cnpj === "55555555555555" ||
        cnpj === "66666666666666" ||
        cnpj === "77777777777777" ||
        cnpj === "88888888888888" ||
        cnpj === "99999999999999")
        return false;

    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0))
        return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
        return false;

    return true;
    /* eslint-enable */
}

const isDebug = () => {
    try {
        return (window.location.host === 'localhost:3000')
    } catch (e) {

    }
}

export {
    hideData,
    showData,
    rest,
    smoothScrolling,
    chave,
    searchEmJSON,
    pageView,
    eventGA4,
    eventGoogle,
    eventFacebook,
    URL_BASE,
    GOOGLE_API_KEY,
    IDENTIFIER_STORE,
    maskPhone,
    validaCPF,
    validarCNPJ,
    isDebug
}
