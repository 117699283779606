import React from 'react'
import {withRouter} from 'react-router-dom'
import {AppBar, Toolbar, FormLabel, Typography, Snackbar, Button, TextField} from '@material-ui/core'
import {ArrowBack} from '@material-ui/icons'
import './Perfil.css'
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles'
import {IDENTIFIER_STORE, pageView} from '../../util'
import {maskDate} from "react-lf-tools";

let identifier

class Perfil extends React.Component {

    state = {
        name: '',
        email: '',
        cpf: '',
        phone: '',
        birthDate: '',
        snackBar: {
            open: false,
            text: ''
        },
        colorStore: {
            color_title: '',
            color_description: '',
            color_price: '',
            color_toolbar: '',
            color_body: '',
            color_cardProduct: '',
            color_fontToolbar: '',
            color_nextButton: '',
            color_fontButton: '',
            color_footer: ''
        },
        noRecord: false
    }

    handleInput = e => {
        if (e.target.name === 'phone') {
            this.setState({[e.target.name]: this.maskPhone(e.target.value)})
        } else if (e.target.name === 'birthDate') {
            let value = e.target.value
            value = value.substring(0, 10)
            this.setState({[e.target.name]: maskDate(value)})
        } else {
            this.setState({[e.target.name]: e.target.value})
        }
    }

    loginLogout = () => {
        const {name} = this.state
        if (name !== undefined) {
            let orderHistory = localStorage.getItem(`bitmenu-${identifier}:orderHistory`)
            localStorage.clear()
            sessionStorage.clear()
            if (orderHistory !== 'null' && orderHistory !== null) localStorage.setItem(`bitmenu-${identifier}:orderHistory`, orderHistory)
            this.props.history.replace('/')
        } else {
            this.props.history.replace({pathname: '/login', state: {goBack: '/'}})
        }
    }

    closeSnackBar = () => this.setState({snackBar: {open: false}})

    onclickVoltar = () => this.props.history.goBack()

    onClickSalve = () => {
        const {name, email, cpf, birthDate, phone, adress, key, noRecord} = this.state
        if (!noRecord && (email === '' || email.length < 5 || !email.includes('@'))) {
            this.setState({snackBar: {open: true, text: 'Coloque um email válido'}})
            return
        } else if (name === '' || name.length < 5) {
            this.setState({snackBar: {open: true, text: 'Coloque um nome válido'}})
            return
        } else if (phone === '' || phone.length < 14 || (phone.substring(phone.length - 8).substring(0, 1) !== '7' && phone.substring(phone.length - 8).substring(0, 1) !== '8' && phone.substring(phone.length - 8).substring(0, 1) !== '9')) {
            this.setState({
                snackBar: {
                    open: true,
                    text: 'Telefone inválido, o campo telefone precisar sem um número de celular'
                }
            })
            return
        } else if (!noRecord && !this.validaCPF(cpf)) {
            this.setState({snackBar: {open: true, text: 'Coloque um cpf válido'}})
            return
        }
        let dddPhone = phone.split(')')
        let json = {
            nome: name,
            email: email,
            cpf: cpf,
            nascimento: birthDate,
            telefone: dddPhone[1].trim(),
            ddd: dddPhone[0].substring(1),
            endereco: adress,
            key: (key === '' || key === undefined) ? this.geraID() : key
        }
        localStorage.setItem(`bitmenu-${identifier}:cliente`, JSON.stringify(json))
        this.setState({snackBar: {open: true, text: 'Salvo com sucesso'}})
        this.onclickVoltar()
    }

    geraID = () => {
        let date = new Date()
        return date.getTime()
    }

    validaCPF = cpf => {
        cpf = cpf.replace(/[^\d]+/g, '')
        if (cpf === '') return false
        if (cpf.length !== 11 ||
            cpf === "00000000000" ||
            cpf === "11111111111" ||
            cpf === "22222222222" ||
            cpf === "33333333333" ||
            cpf === "44444444444" ||
            cpf === "55555555555" ||
            cpf === "66666666666" ||
            cpf === "77777777777" ||
            cpf === "88888888888" ||
            cpf === "99999999999")
            return false
        let add = 0
        let rev
        for (let i = 0; i < 9; i++)
            add += parseInt(cpf.charAt(i)) * (10 - i)
        rev = 11 - (add % 11)
        if (rev === 10 || rev === 11)
            rev = 0
        if (rev !== parseInt(cpf.charAt(9)))
            return false
        add = 0
        for (let i = 0; i < 10; i++)
            add += parseInt(cpf.charAt(i)) * (11 - i)
        rev = 11 - (add % 11)
        if (rev === 10 || rev === 11)
            rev = 0
        if (rev !== parseInt(cpf.charAt(10)))
            return false
        return true
    }

    maskPhone = number => {
        if (number !== '') {
            return number.replace(/\D/g, '').replace(/(\d{2})(\d)/, '($1) $2')
        }
    }

    clientsData = () => {
        let client = localStorage.getItem(`bitmenu-${identifier}:cliente`)
        client = client !== null ? JSON.parse(client) : ''
        let {cpf, email, nome, telefone, ddd, endereco, nascimento, key} = client

        if (ddd !== undefined) {
            telefone = `(${ddd}) ${telefone}`
        }

        this.setState({
            cpf: (cpf !== undefined) ? cpf : '',
            email: (email !== undefined) ? email : '',
            name: nome,
            phone: telefone,
            adress: endereco,
            birthDate: (nascimento !== undefined && nascimento !== null) ? nascimento : '',
            key: (key !== undefined) ? key : ''
        })
    }

    personalize = () => {
        try {
            let colors = JSON.parse(localStorage.getItem(`bitmenu-${identifier}:colorStore`))
            const {
                title,
                description,
                price,
                toolbar,
                body,
                cardProduct,
                nextButton,
                fontButton,
                fontToolbar,
                footer
            } = colors
            this.setState({
                colorStore: {
                    color_title: `#${title}`,
                    color_description: `#${description}`,
                    color_price: `#${price}`,
                    color_toolbar: `#${toolbar}`,
                    color_body: `#${body}`,
                    color_cardProduct: `#${cardProduct}`,
                    color_nextButton: `#${nextButton}`,
                    color_fontButton: `#${fontButton}`,
                    color_fontToolbar: `#${fontToolbar}`,
                    color_footer: `#${footer}`
                }
            })
        } catch (e) {

        }
    }

    noRecord = () => {
        let noRecord = localStorage.getItem(`bitmenu-${identifier}:noRecord`)
        noRecord = (noRecord !== null) ? JSON.parse(noRecord) : false
        this.setState({noRecord: noRecord})
    }

    componentDidMount() {
        identifier = IDENTIFIER_STORE
        this.noRecord()
        this.clientsData()
        this.personalize()
        window.scrollTo(0, 0)
        pageView()
    }

    render() {
        const theme = createMuiTheme({
            palette: {
                primary: {
                    main: '#000000'
                }
            },
        })
        const {
            snackBar: {
                open, text
            },
            cpf,
            email,
            birthDate,
            name,
            phone,
            colorStore: {
                color_toolbar,
                color_fontToolbar,
                color_nextButton,
                color_fontButton
            }
        } = this.state
        return (
            <div id="perfil">
                <MuiThemeProvider theme={theme}>
                    <AppBar
                        id="appBar"
                        position="sticky"
                        color="default"
                        style={color_toolbar ? {background: color_toolbar} : {}}>
                        <Toolbar variant="dense">
                            <div id="toolbar-profile">
                                <div id="toolbar-left">
                                    <ArrowBack
                                        style={color_fontToolbar ? {color: color_fontToolbar} : {}}
                                        id="voltar-sacola"
                                        onClick={this.onclickVoltar}/>
                                    <Typography
                                        id={'label-title'}
                                        style={color_fontToolbar ? {color: color_fontToolbar} : {}}
                                        variant="h6"
                                        color="inherit">Perfil</Typography>
                                </div>
                                <div id="toolbar-right">
                                    <Button
                                        id={'label-title'}
                                        style={color_fontToolbar ? {color: color_fontToolbar} : {}}
                                        onClick={this.loginLogout}>{name === undefined ? `Login` : `Logout`}</Button>
                                </div>
                            </div>
                        </Toolbar>
                    </AppBar>
                    <div id="container-perfil">
                        {
                            name !== undefined &&
                            <div id="main-profile">
                                <FormLabel id="title-profile">Meus dados</FormLabel>
                                <div id="div-input-profile">
                                    <TextField name="name" value={name} label="Nome" autoFocus={true}
                                               variant="outlined" fullWidth={true} onChange={this.handleInput}/>
                                </div>
                                <div id="div-input-profile">
                                    <TextField name="email" value={email} label="E-mail" variant="outlined"
                                               fullWidth={true} onChange={this.handleInput}/>
                                </div>
                                <div id="div-input-profile">
                                    <TextField name="cpf" value={cpf} label="CPF" variant="outlined" fullWidth={true}
                                               onChange={this.handleInput}/>
                                </div>
                                <div id="div-input-profile">
                                    <TextField name="phone" value={phone} label="Telefone" variant="outlined"
                                               placeholder="(00) 00000000" fullWidth={true}
                                               onChange={this.handleInput}/>
                                </div>
                                <div id="div-input-profile">
                                    <TextField name="birthDate" value={birthDate} label="Nascimento" variant="outlined"
                                               placeholder="00/00/0000" fullWidth={true}
                                               onChange={this.handleInput}/>
                                </div>
                            </div>
                        }
                    </div>
                    {
                        name !== undefined &&
                        <div id="footer-profile" style={{backgroundColor: color_nextButton, cursor: "pointer"}}
                             onClick={() => this.onClickSalve()}>
                            <FormLabel style={color_fontButton ? {
                                color: color_fontButton,
                                cursor: "pointer"
                            } : {cursor: "pointer"}} id="texto-finaliza">Salvar</FormLabel>
                        </div>
                    }
                    <Snackbar
                        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                        open={open}
                        autoHideDuration={2000}
                        onClose={this.closeSnackBar}
                        message={text}
                    />
                </MuiThemeProvider>
            </div>
        )
    }
}

export default withRouter(Perfil)
