import React from 'react'
import {MoreVert as MoreVertIcon} from '@material-ui/icons'
import {FormLabel} from '@material-ui/core'
import user from '../../usuario.json'
import './ListaItens.css'

class ListaItens extends React.Component {

    onClickOptions = (index, obs, visibleObs = true) => {
        if (index !== undefined) {
            this.props.handleChange(index, true, obs, visibleObs)
        }
    }

    priceVariation = variations => {
        let steps = []
        let total = 0
        try {
            variations.forEach(i => {
                if (i.priceVariation !== 0 && i.priceVariation !== undefined) {
                    let s = `${i.key}`
                    if (steps.indexOf(s) === -1) {
                        steps.push(s)
                        total += i.priceVariation
                    }
                }
            })
        } catch (e) {

        }
        return total
    }

    render() {
        const {color_title} = this.props.colors
        let {titulo, quantidade, precoBase, subItens, fracionado, obs, visibleObs} = this.props.data
        let position = this.props.position
        let priceVariation = this.priceVariation(subItens)
        let valorSubitens = 0
        let maxValue = 0
        let combo = false
        if (subItens.lenght !== 0) {
            subItens.forEach(i => {
                if (i.soma === 'MAXVALUE') {
                    maxValue = i.precoProduto > maxValue ? i.precoProduto : maxValue
                } else {
                    valorSubitens += (i.precoProduto * i.quantidade)
                }
                combo = i.sku.includes("C")
            })
        }
        precoBase = combo ? 0 : precoBase
        let valorProduto = ((precoBase + valorSubitens + priceVariation + maxValue) * quantidade)
        return (
            <div id="lista-itens">
                <div id="lista-itens-esquerda">
                    <FormLabel
                        id="quantidade-itens"
                        style={color_title ? {color: color_title} : {}}>{quantidade !== 1 ? `${quantidade}${fracionado ? ' Kg -' : 'x'} ${titulo}` : `${titulo}`}</FormLabel>
                    {
                        subItens.map((i, index) => {
                            return (
                                <div key={index}>
                                    <div id="container-subitens">
                                        {
                                            !i.sku.includes('O') && (i.quantidade !== 1) &&
                                            <div>
                                                <FormLabel id="quantidade-subitens"
                                                           style={color_title ? {color: color_title} : {}}>{`${i.quantidade}x`}</FormLabel>
                                            </div>
                                        }
                                        <FormLabel id="titulo-subitens"
                                                   style={color_title ? {color: color_title} : {}}>{i.titulo}</FormLabel>
                                    </div>

                                </div>
                            )
                        })
                    }
                    <FormLabel
                        id="titulo-subitens"
                        style={color_title ? {color: color_title} : {}}>  {obs}
                    </FormLabel>
                </div>
                <div id="lista-itens-direita">
                    <FormLabel id="valor-itens"
                               style={color_title ? {color: color_title} : {}}>{valorProduto.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                    })}</FormLabel>
                    <MoreVertIcon style={color_title ? {color: color_title, cursor: "pointer"} : {
                        color: user.main_color,
                        cursor: "pointer"
                    }} className="menu-itens"
                                  onClick={() => this.onClickOptions(position, obs, visibleObs)}/>
                </div>
            </div>
        )
    }
}

export default ListaItens
