import React from 'react'
import {withRouter} from 'react-router-dom'
import {AppBar, Toolbar, Typography, IconButton, FormLabel, Divider} from '@material-ui/core'
import {ArrowBack as ArrowBackIcon, AccessTimeRounded} from '@material-ui/icons'
import './Horario.css'
import {IDENTIFIER_STORE, pageView} from '../../util'
import moment from "moment"

let identifier

class Horarios extends React.Component {

    state = {
        open: true,
        delivery: [],
        takeWay: [],
        colorStore: {
            color_title: '',
            color_description: '',
            color_price: '',
            color_toolbar: '',
            color_body: '',
            color_cardProduct: '',
            color_fontToolbar: ''
        }
    }

    onClickBack = () => this.props.history.goBack()

    personalize = () => {
        try {
            let colors = JSON.parse(localStorage.getItem(`bitmenu-${identifier}:colorStore`))
            const {title, description, price, toolbar, body, cardProduct, nextButton, fontButton, fontToolbar} = colors
            this.setState({
                colorStore: {
                    color_title: `#${title}`,
                    color_description: `#${description}`,
                    color_price: `#${price}`,
                    color_toolbar: `#${toolbar}`,
                    color_body: `#${body}`,
                    color_cardProduct: `#${cardProduct}`,
                    color_nextButton: `#${nextButton}`,
                    color_fontButton: `#${fontButton}`,
                    color_fontToolbar: `#${fontToolbar}`
                }
            })
        } catch (e) {

        }
    }

    checkDelivery = () => {
        try {
            let open = localStorage.getItem(`bitmenu-${identifier}:open`)
            open = (open !== null) ? JSON.parse(open) : true
            let delivery = localStorage.getItem(`bitmenu-${identifier}:weekDeliveryStore`)
            delivery = delivery !== null ? JSON.parse(delivery) : []
            let diasSemana = ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo', 'Feriado']
            let domingo = []
            let segunda = []
            let terca = []
            let quarta = []
            let quinta = []
            let sexta = []
            let sabado = []
            let feriado = []
            delivery.forEach(delivery => {
                delivery.day.forEach(day => {
                    if (day === 1) {
                        domingo.push({abertura: delivery.openTime, fechamento: delivery.closeTime})
                    } else if (day === 2) {
                        segunda.push({abertura: delivery.openTime, fechamento: delivery.closeTime})
                    } else if (day === 3) {
                        terca.push({abertura: delivery.openTime, fechamento: delivery.closeTime})
                    } else if (day === 4) {
                        quarta.push({abertura: delivery.openTime, fechamento: delivery.closeTime})
                    } else if (day === 5) {
                        quinta.push({abertura: delivery.openTime, fechamento: delivery.closeTime})
                    } else if (day === 6) {
                        sexta.push({abertura: delivery.openTime, fechamento: delivery.closeTime})
                    } else if (day === 7) {
                        sabado.push({abertura: delivery.openTime, fechamento: delivery.closeTime})
                    } else if (day === 8) {
                        feriado.push({abertura: delivery.openTime, fechamento: delivery.closeTime})
                    }
                })
            })
            let arrayDiaSemana = [segunda, terca, quarta, quinta, sexta, sabado, domingo, feriado]
            let weekDeliveryStore = []
            arrayDiaSemana.forEach((v, index) => {
                let horarios = ''
                v.sort((a, b) => {
                    const nameA = moment(a.abertura, 'HH:mm:ss').toDate().getTime()
                    const nameB = moment(b.abertura, 'HH:mm:ss').toDate().getTime()
                    if (nameA < nameB) return -1
                    if (nameA > nameB) return 1
                    return 0
                })
                v.forEach(s => {
                    let abertura = s.abertura
                    abertura = abertura.substring(0, 5)
                    let fechamento = s.fechamento
                    fechamento = fechamento.substring(0, 5)
                    horarios += `${abertura} às ${fechamento} - `
                })
                weekDeliveryStore.push({
                    dia: `${diasSemana[index]}`,
                    horario: `${(horarios.length !== 0) ? horarios.substring(0, horarios.length - 2) : 'fechado'}`
                })
            })
            let takeWay = localStorage.getItem(`bitmenu-${identifier}:weekShopStore`)
            takeWay = takeWay !== null ? JSON.parse(takeWay) : []
            domingo = []
            segunda = []
            terca = []
            quarta = []
            quinta = []
            sexta = []
            sabado = []
            feriado = []
            takeWay.forEach(t => {
                t.day.forEach(day => {
                    if (day === 1) {
                        domingo.push({abertura: t.openTime, fechamento: t.closeTime})
                    } else if (day === 2) {
                        segunda.push({abertura: t.openTime, fechamento: t.closeTime})
                    } else if (day === 3) {
                        terca.push({abertura: t.openTime, fechamento: t.closeTime})
                    } else if (day === 4) {
                        quarta.push({abertura: t.openTime, fechamento: t.closeTime})
                    } else if (day === 5) {
                        quinta.push({abertura: t.openTime, fechamento: t.closeTime})
                    } else if (day === 6) {
                        sexta.push({abertura: t.openTime, fechamento: t.closeTime})
                    } else if (day === 7) {
                        sabado.push({abertura: t.openTime, fechamento: t.closeTime})
                    } else if (day === 8) {
                        feriado.push({abertura: t.openTime, fechamento: t.closeTime})
                    }
                })
            })
            arrayDiaSemana = [segunda, terca, quarta, quinta, sexta, sabado, domingo, feriado]
            let weekTakeWayStore = []
            arrayDiaSemana.forEach((v, index) => {
                let horarios = ''
                v.sort((a, b) => {
                    const nameA = moment(a.abertura, 'HH:mm:ss').toDate().getTime()
                    const nameB = moment(b.abertura, 'HH:mm:ss').toDate().getTime()
                    if (nameA < nameB) return -1
                    if (nameA > nameB) return 1
                    return 0
                })
                v.forEach(s => {
                    let abertura = s.abertura
                    abertura = abertura.substring(0, 5)
                    let fechamento = s.fechamento
                    fechamento = fechamento.substring(0, 5)
                    horarios += `${abertura} às ${fechamento} - `
                })
                weekTakeWayStore.push({
                    dia: `${diasSemana[index]}`,
                    horario: `${(horarios.length !== 0) ? horarios.substring(0, horarios.length - 2) : 'fechado'}`
                })
            })
            this.setState({delivery: weekDeliveryStore, takeWay: weekTakeWayStore, open: open})
        } catch (e) {
            console.error(e)
        }
    }

    checkDay = (index) => {
        let day = new Date().getDay()
        if (index === 0 && day === 1) {
            return true
        } else if (index === 1 && day === 2) {
            return true
        } else if (index === 2 && day === 3) {
            return true
        } else if (index === 3 && day === 4) {
            return true
        } else if (index === 4 && day === 5) {
            return true
        } else if (index === 5 && day === 6) {
            return true
        } else if (index === 6 && day === 0) {
            return true
        } else {
            return false
        }
    }

    componentDidMount() {
        identifier = IDENTIFIER_STORE
        this.checkDelivery()
        this.personalize()
        window.scrollTo(0, 0)
        pageView()
    }

    render() {
        const {
            open,
            delivery,
            takeWay,
            colorStore: {color_title, color_toolbar, color_body, color_fontToolbar}
        } = this.state
        return (
            <div id="times" style={color_body ? {background: color_body} : {}}>
                <AppBar
                    id="appBar"
                    position="sticky"
                    style={color_toolbar ? {background: color_toolbar} : {}}
                    color="default">
                    <Toolbar variant="dense">
                        <IconButton style={color_fontToolbar ? {color: color_fontToolbar} : {}} edge="start"
                                    color="inherit"
                                    aria-label="menu" onClick={this.onClickBack}>
                            <ArrowBackIcon/>
                        </IconButton>
                        <Typography id={'label-title'}
                                    style={color_fontToolbar ? {color: color_fontToolbar} : {}}
                                    variant="h6"
                                    color="inherit">
                            Horários
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div id="container-horarios">
                    <div id="card-delivery" style={{background: color_body}}>
                        <FormLabel id="titile-times" style={{color: color_title}}>
                            Tele entrega
                        </FormLabel>
                        <Divider/>
                        <div>
                            {
                                delivery.map((i, index) => {
                                    return (
                                        <div id={'div-dia-horario'} key={index}>
                                            <div id={'div-icone-label-day'}>
                                                {
                                                    this.checkDay(index) &&
                                                    <AccessTimeRounded
                                                        style={{
                                                            color: (open && i.horario !== 'fechado') ? '#0dc143' : '#a11515',
                                                            backgroundColor: (open && i.horario !== 'fechado') ? '#b6e1b8' : '#ef9a9a',
                                                            border: `2px solid ${(open && i.horario !== 'fechado') ? '#0dc143' : '#a11515'}`,
                                                            borderRadius: 15
                                                        }}
                                                    />
                                                }
                                                <FormLabel
                                                    id="time-day"
                                                    style={{
                                                        color: color_title,
                                                        fontWeight: this.checkDay(index) ? 'bold' : 'normal'
                                                    }}>
                                                    {i.dia}
                                                </FormLabel>
                                            </div>
                                            <FormLabel
                                                id="time-day"
                                                style={{
                                                    color: color_title,
                                                    fontWeight: this.checkDay(index) ? 'bold' : 'normal',
                                                }}>
                                                {(this.checkDay(index) && !open) ? 'fechado' : i.horario}
                                            </FormLabel>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div id="card-delivery" style={{background: color_body}}>
                        <FormLabel id="titile-times" style={{color: color_title}}>
                            Retirada na loja
                        </FormLabel>
                        <Divider/>
                        <div>
                            {
                                takeWay.map((i, index) => {
                                    return (
                                        <div id={'div-dia-horario'} key={index}>
                                            <div id={'div-icone-label-day'}>
                                                {
                                                    this.checkDay(index) &&
                                                    <AccessTimeRounded
                                                        style={{
                                                            color: (open && i.horario !== 'fechado') ? '#0dc143' : '#a11515',
                                                            backgroundColor: (open && i.horario !== 'fechado') ? '#b6e1b8' : '#ef9a9a',
                                                            border: `2px solid ${(open && i.horario !== 'fechado') ? '#0dc143' : '#a11515'}`,
                                                            borderRadius: 15
                                                        }}
                                                    />
                                                }
                                                <FormLabel
                                                    id="time-day"
                                                    style={{
                                                        color: color_title,
                                                        fontWeight: this.checkDay(index) ? 'bold' : 'normal'
                                                    }}>
                                                    {i.dia}
                                                </FormLabel>
                                            </div>
                                            <FormLabel
                                                id="time-day"
                                                style={{
                                                    color: color_title,
                                                    fontWeight: this.checkDay(index) ? 'bold' : 'normal'
                                                }}>
                                                {(this.checkDay(index) && !open) ? 'fechado' : i.horario}
                                            </FormLabel>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Horarios)
