import React from 'react'
import {withRouter} from 'react-router-dom'
import {
    AppBar,
    Toolbar,
    Typography,
    Card,
    TextField,
    FormLabel,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    RadioGroup,
    Box,
    DialogActions,
    Button,
    FormControl,
    Radio,
    Snackbar,
    Select,
    InputLabel,
    MenuItem,
    Divider,
    DialogContentText,
    Input,
    CardContent,
    CircularProgress
} from '@material-ui/core'
import {withStyles, createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles'
import {ArrowBack, MoreVert} from '@material-ui/icons'
import './Enderecos.css'
import {rest, showData, URL_BASE, GOOGLE_API_KEY, pageView, IDENTIFIER_STORE} from '../../util'
import user from '../../usuario.json'
import {getDistance} from 'geolib'
import {isMobile} from "react-device-detect";

let identifier
let email = ''

const RedRadio = withStyles({
    checked: {},
})(props => <Radio color="default" {...props} />)

const RedRadioDialog = withStyles({
    root: {
        color: '#000000',
        '&$checked': {
            color: '#000000',
        },
    },
    checked: {},
})(props => <Radio color="default" {...props} />)

class Enderecos extends React.Component {

    state = {
        complementMyLocation: '',
        myCurrentLocation: '',
        storesLocation: '',
        inputZipCode: '',
        warningText: '',
        complement: '',
        snackBar: '',
        district: '',
        zipCode: '',
        adress: '',
        street: '',
        number: '',
        email: '',
        house: '',
        city: '',
        uf: '',
        openComplementMyLocation: false,
        allowScheduledDelivery: false,
        openStoresLocations: false,
        openSearchAddress: false,
        openComplement: false,
        takeAwayToday: true,
        openLocation: false,
        openOptionsTakeWay: false,
        openSnackBar: false,
        openWarning: false,
        openOptions: false,
        openAdress: false,
        openDelete: false,
        openCEPNeeded: false,
        uniqueZip: false,
        update: false,
        retire: false,
        CEPNeeded: true,
        noRecord: false,
        isFocusNumber: false,
        errorNumber: false,
        errorCEP: false,
        position: 0,
        modules: 0,
        citys: [],
        districts: [],
        storesLocations: [],
        modeRetire: [],
        colorStore: {
            color_title: '',
            color_description: '',
            color_price: '',
            color_toolbar: '',
            color_body: '',
            color_cardProduct: '',
            color_nextButton: '',
            color_fontButton: '',
            color_fontToolbar: ''
        },
        mostrarLocalizacao: true,
        disabledCep: false
    }

    onRadio = e => {
        this.setState({house: e.target.value})
    }

    onRadioStore = obj => {
        const {client} = this.state
        let json = {
            cep: (client.endereco[0] !== undefined) ? client.endereco[0].cep : '',
            bairro: (client.endereco[0] !== undefined) ? client.endereco[0].bairro : '',
            cidade: (client.endereco[0] !== undefined) ? client.endereco[0].cidade : '',
            complemento: (client.endereco[0] !== undefined) ? client.endereco[0].complemento : '',
            numero: (client.endereco[0] !== undefined) ? client.endereco[0].numero : '',
            rua: (client.endereco[0] !== undefined) ? client.endereco[0].rua : '',
            uf: (client.endereco[0] !== undefined) ? client.endereco[0].uf : '',
            retire: true,
            deliveryFee: 0,
            storesLocation: obj.address,
            id_loja: obj.id_loja
        }
        localStorage.setItem(`bitmenu-${identifier}:adress`, JSON.stringify(json))
        sessionStorage.removeItem(`bitmenu-${identifier}:dateDeliveryRoute`)
        sessionStorage.removeItem(`bitmenu-${identifier}:timeRoute`)
        this.onClickBack()
    }

    onRadioTakeAway = (optionsTakeWay) => {
        const {client, storesLocations} = this.state
        if (storesLocations.length) {
            this.setState({openStoresLocations: true})
        } else {
            let obj = {
                cep: (client.endereco[0] !== undefined) ? client.endereco[0].cep : '',
                bairro: (client.endereco[0] !== undefined) ? client.endereco[0].bairro : '',
                cidade: (client.endereco[0] !== undefined) ? client.endereco[0].cidade : '',
                complemento: (client.endereco[0] !== undefined) ? client.endereco[0].complemento : '',
                numero: (client.endereco[0] !== undefined) ? client.endereco[0].numero : '',
                rua: (client.endereco[0] !== undefined) ? client.endereco[0].rua : '',
                uf: (client.endereco[0] !== undefined) ? client.endereco[0].uf : '',
                retire: true,
                deliveryFee: 0,
                storesLocation: 'Retirar no local',
                id_loja: user.id_loja
            }
            localStorage.setItem(`bitmenu-${identifier}:optionsTakeWay`, optionsTakeWay)
            localStorage.setItem(`bitmenu-${identifier}:adress`, JSON.stringify(obj))
            // sessionStorage.removeItem(`bitmenu-${identifier}:dateDeliveryRoute`)
            // sessionStorage.removeItem(`bitmenu-${identifier}:timeRoute`)
            this.onClickBack()
        }
    }

    handleInputs = e => {
        if (e.target.name === 'select-city') {
            const {citys} = this.state
            let i = citys[e.target.value]
            this.selectDistrict(i.city)
            this.setState({city: i.city, uf: i.uf})
        } else if (e.target.name === 'select-district') {
            let {districts} = this.state
            this.setState({district: districts[e.target.value]})
        } else {
            this.setState({[e.target.name]: e.target.value, errorNumber: false})
        }
    }

    handleSearchZip = e => {
        this.setState({[e.target.name]: e.target.value, errorCEP: false})
        let zipCode = e.target.value.replace(/[^\d]+/g, '')
        if (zipCode.length >= 8) {
            this.clear()
            this.setState({openSearchAddress: true})
            this.validateZipCode(e.target.value)
        }
    }

    closeWarning = () => this.setState({openWarning: false})

    closeDelete = () => this.setState({openDelete: false})

    closeOptions = () => this.setState({openOptions: false})

    closeSnackBar = () => this.setState({openSnackBar: false})

    closeAdress = () => {
        this.setState({openAdress: false})
        this.clear()
    }

    closeCEPNeeded = () => {
        this.setState({openCEPNeeded: false, disabledCep: false})
        this.clear()
    }

    closeComplement = () => {
        this.setState({openComplement: false})
        this.onClickBack()
    }

    onClickConfirmComplement = () => {
        let adress = JSON.parse(localStorage.getItem(`bitmenu-${identifier}:adress`))
        adress.complemento = this.state.complementMyLocation
        localStorage.setItem(`bitmenu-${identifier}:adress`, JSON.stringify(adress))
        this.closeComplement()
    }

    onClickCEPNeeded = () => this.setState({openCEPNeeded: true, disabledCep: true})

    onClickDelete = () => {
        const {key, client, position, noRecord} = this.state
        let enderecoAtual
        try {
            enderecoAtual = client.endereco[position]
        } catch (e) {

        }
        client.endereco.splice(position, 1)
        if (!noRecord) this.recordRegistration(key, client)
        this.setState({client: client, openDelete: false})
        try {
            let enderecoSelecionado = localStorage.getItem(`bitmenu-${identifier}:adress`)
            enderecoSelecionado = (enderecoSelecionado !== null) ? JSON.parse(enderecoSelecionado) : ''
            if (enderecoAtual.cep === enderecoSelecionado.cep)
                localStorage.removeItem(`bitmenu-${identifier}:adress`)
        } catch (e) {

        }
        localStorage.setItem(`bitmenu-${identifier}:cliente`, JSON.stringify(client))
    }

    onClickEditAndress = () => {
        const {client, position} = this.state
        let adress = client.endereco[position]
        const {cep, rua, numero, bairro, cidade, uf, casa, complemento} = adress
        try {
            if ((cep.substring(cep.length - 3) === '000')) {
                this.selectDistrict(cidade)
                this.setState({uniqueZip: true})
            } else {
                this.setState({uniqueZip: false})
            }
        } catch (e) {

        }
        this.setState({
            update: true,
            openOptions: false,
            openAdress: true,
            zipCode: cep,
            street: rua,
            number: numero,
            district: bairro,
            city: cidade,
            uf: uf,
            house: casa,
            complement: complemento
        })
    }

    onClickDeleteAdress = () => this.setState({openDelete: true, openOptions: false})

    onClickOption = index => this.setState({openOptions: true, position: index})

    onClickAdress = () => {
        try {
            let {
                key,
                client,
                zipCode,
                street,
                number,
                district,
                city,
                uf,
                complement,
                house,
                update,
                position,
                CEPNeeded,
                noRecord
            } = this.state

            if (CEPNeeded) {
                let s = zipCode.replace(/[^\d]+/g, '')
                if (zipCode === '' || s.length < 8) {
                    return this.setState({openSnackBar: true, snackBar: 'Informe o CEP', errorCEP: true})
                }
            }

            if (city === '') {
                this.setState({openSnackBar: true, snackBar: 'Informe o Cidade'})
                return
            } else if (street === '') {
                this.setState({openSnackBar: true, snackBar: 'Informe o Rua'})
                return
            } else if (number === '') {
                return this.setState({openSnackBar: true, snackBar: 'Informe o Número', errorNumber: true})
            } else if (district === '') {
                return this.setState({openSnackBar: true, snackBar: 'Informe o Bairro'})
            } else if (house === '') {
                return this.setState({openSnackBar: true, snackBar: 'Escolha Casa ou Apto'})
            } else if (house === 'apto' && complement === '') {
                this.setState({openSnackBar: true, snackBar: 'Informe o Complemento'})
                return
            }

            if (!noRecord) {
                if (client === undefined || client.cpf === undefined) {
                    this.props.history.push({pathname: '/login', state: {goBack: '/enderecos'}})
                    return
                }
            }

            const {cpf, email, nome, senha, telefone, endereco, ddd} = client

            client = {
                cpf: (cpf !== undefined) ? cpf : '',
                email: email,
                key: (key !== undefined) ? key : this.key(),
                nome: nome,
                senha: (senha !== undefined) ? senha : '',
                telefone: telefone,
                ddd: ddd !== undefined ? ddd : '',
                endereco: endereco !== undefined ? endereco : []
            }

            let s = {
                cep: zipCode,
                numero: number,
                rua: street,
                bairro: district,
                cidade: city,
                uf: uf,
                casa: house,
                complemento: complement
            }

            if (update) {
                client.endereco[position] = s
            } else {
                client.endereco.push(s)
            }

            if (!noRecord) this.recordRegistration(key, client)
            this.setState({
                openAdress: false,
                openCEPNeeded: false,
                disabledCep: false,
                client: client,
                update: false,
                inputZipCode: ''
            })
            localStorage.setItem(`bitmenu-${identifier}:cliente`, JSON.stringify(client))
            this.clear()
        } catch (e) {
            alert(e)
        }
    }

    onClickLogout = () => {
        try {
            let orderHistory = localStorage.getItem(`bitmenu-${identifier}:orderHistory`)
            localStorage.clear()
            sessionStorage.clear()
            localStorage.setItem(`bitmenu-${identifier}:orderHistory`, orderHistory)
            this.props.history.replace('/')
        } catch (e) {

        }
    }

    onClickBack = () => this.props.history.goBack()

    onClickAndress = async (obj = null) => {
        localStorage.removeItem(`bitmenu-${identifier}:optionsTakeWay`)
        obj = obj !== null ? obj : this.state.myLocation
        const {bairro, cidade, uf, cep} = obj
        const {attendance, deliveryFee, message, id_loja} = this.checkArea(bairro, cidade, uf, cep)

        if (!attendance) {
            this.setState({
                openWarning: true,
                warningText: message !== '' ? message : 'Infelizmente não atendemos a sua região'
            })
            return
        }

        let distance = 0, latLog = ''

        let raio = localStorage.getItem(`bitmenu-${identifier}:serviceRadius`)
        raio = (raio !== undefined && raio !== null) ? raio : ''

        if (raio !== '=01W' && raio.length > 4) {
            let res = await this.distance(obj)
            distance = res.distance
            latLog = res.latLog
            let radiusMax = localStorage.getItem(`bitmenu-${identifier}:radiusmax`)
            radiusMax = (radiusMax !== null) ? parseInt(radiusMax) : 0
            if (distance) {
                if (radiusMax !== 0 && (distance > radiusMax)) {
                    this.setState({openWarning: true, warningText: 'Infelizmente não atendemos a sua região'})
                    return
                }
            }
        }

        let {answer, price} = this.calculateRadius(distance ? distance : 999999)
        this.setAddress((obj === null), obj, answer ? price : deliveryFee, answer, latLog, id_loja)
    }

    clearText = text => {
        text = text.normalize("NFD").replace(/[^A-Z0-9]/ig, "")
        text = text.trim()
        return text.toLowerCase()
    }

    checkArea = (neighborhood, city, uf, cep) => {
        let area = localStorage.getItem(`bitmenu-${identifier}:area_atendimento`)
        area = showData(area)
        area = JSON.parse(area)
        let attendance
        let deliveryFee
        let id_loja = ''
        let message = ''

        try {
            if (cep !== '' && cep !== undefined) {
                let cepsBloqueados = showData(localStorage.getItem(`bitmenu-${identifier}:bloqueados`))
                if (cepsBloqueados.includes(cep)) return {attendance: false, message: ''}
            }
        } catch (e) {
            console.log(e.message)
        }

        for (let e = 0; e < area.length; e++) {
            attendance = true
            deliveryFee = 0
            if ((uf.toLowerCase() === area[e].UF.toLowerCase()) && (city.toLowerCase() === area[e].name.toLowerCase())) {
                let districts = area[e].districts
                districts = districts !== undefined ? districts : []
                for (let x = 0; x < districts.length; x++) {
                    if (this.clearText(neighborhood) === this.clearText(districts[x].name)) {
                        let restrictions = districts[x].restrictions
                        restrictions = restrictions !== undefined ? restrictions : []
                        deliveryFee = districts[x].deliveryFee
                        id_loja = districts[x].id_loja
                        if (restrictions.length === 0) {
                            attendance = true
                            break
                        }
                        let block = false
                        for (let i = 0; i < restrictions.length; i++) {
                            if (cep === restrictions[i].postalCode) {
                                try {
                                    message = restrictions[i].obs !== undefined ? restrictions[i].obs : ''
                                    try {
                                        if (restrictions[i].status === 'block') {
                                            block = true
                                        } else {
                                            id_loja = restrictions[i].id_loja
                                            block = false
                                        }
                                    } catch (e) {
                                        console.log(e)
                                    }
                                } catch (e) {
                                    block = true
                                }
                            }
                        }
                        if (block) {
                            attendance = false
                            deliveryFee = 0
                            break
                        } else {
                            attendance = true
                            break
                        }
                    } else {
                        attendance = false
                    }
                }
                break
            } else {
                attendance = false
            }
        }
        return {attendance: attendance, deliveryFee: deliveryFee, message: message, id_loja: id_loja}
    }

    distance = async obj => {
        try {
            const {numero, rua, bairro, cidade, uf, cep} = obj
            let address = `${rua}, ${numero} - ${bairro}, ${cidade} - ${uf}, ${cep}`
            let url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${GOOGLE_API_KEY}`
            let {status, results} = await fetch(url).then((response) => response.json())
            if (status === 'OK') {
                const {geometry: {location: {lat, lng}}} = results[0]
                let coordinates = localStorage.getItem(`bitmenu-${identifier}:restaurantCoordinates`)
                coordinates = coordinates.split(',')
                let a = {latitude: parseFloat(coordinates[0]), longitude: parseFloat(coordinates[1])}
                let b = {latitude: lat, longitude: lng}
                return {distance: getDistance(a, b), latLog: b}
            } else {
                return {distance: false, latLog: ''}
            }
        } catch (e) {

        }
    }

    key = () => {
        var hora = new Date()
        return hora.getTime()
    }

    calculateRadius = distance => {
        let radius = localStorage.getItem(`bitmenu-${identifier}:serviceRadius`)
        radius = showData(radius)
        radius = JSON.parse(radius)
        let maxPrice = 0
        try {
            let maxDistance = radius.reduce((max, obj) => obj.distance > max.distance ? obj : max, radius[0])
            maxPrice = maxDistance.price
        } catch (e) {

        }
        for (let i = 0; i < radius.length; i++) {
            if (distance <= radius[i].distance) {
                return {answer: true, price: radius[i].price}
            }
        }
        return {answer: false, price: maxPrice}
    }

    setAddress = (myLocation, obj, deliveryFee, radius = false, latLog, id_loja) => {
        const {numero, rua, bairro, cidade, uf, cep, complemento} = obj
        let location = {
            numero: numero,
            rua: rua,
            bairro: bairro,
            cidade: cidade,
            uf: uf,
            cep: cep,
            complemento: complemento,
            retire: false,
            deliveryFee: deliveryFee,
            radius: radius,
            id_loja: id_loja,
            latitude: latLog.latitude !== undefined ? latLog.latitude : '',
            longitude: latLog.longitude !== undefined ? latLog.longitude : ''
        }

        localStorage.setItem(`bitmenu-${identifier}:adress`, JSON.stringify(location))

        if (myLocation) {
            this.setState({openComplement: true})
        } else {
            this.setState({zipCode: cep})
            this.onClickBack()
        }
    }

    checkEmail = async () => {
        try {
            let cliente = localStorage.getItem(`bitmenu-${identifier}:cliente`)
            cliente = (cliente !== null && cliente !== '') ? JSON.parse(cliente) : ''
            let client
            if (cliente !== '' && cliente.endereco !== undefined) {
                client = cliente
            } else {
                const {returnCode, data} = await rest(`${URL_BASE}/cadastro/?email=${email}`, {method: 'GET'})
                if (returnCode && data !== null) client = Object.values(data)[0]
            }
            this.setState({key: client.key, client: client})
        } catch (e) {
            console.log(e.message)
        }
    }

    recordRegistration = async (key, json) => {
        if (key === '' || key === undefined) return
        let s = localStorage.getItem(`bitmenu-${identifier}:s`)
        s = s !== null && s !== undefined ? showData(s) : ''
        json.senha = s
        let conexao = {method: 'POST', body: JSON.stringify({json: json, key: key})}
        await rest(`${URL_BASE}/cadastro`, conexao)
        this.setState({key: json.key, client: json})
    }

    validateZipCode = zipCode => {
        zipCode = zipCode.replace(/[^\d]+/g, '')
        fetch(`https://bitmenu.com.br/util/ceps.php?tipo=json&chave=578099157F74DB95A37FEBB53CFEAEC1&cep=${zipCode}`)
            .then((response) => response.json())
            .then((json) => {
                this.setState({openSearchAddress: false})
                if (json.CEP.sucesso) {
                    const {bairro, cidade, endereco, uf} = json.CEP.dadoscep
                    if (bairro !== '' && bairro !== null) {
                        let {attendance, message} = this.checkArea(bairro, cidade, uf, zipCode)
                        if (attendance) {
                            this.setState({
                                uniqueZip: bairro === '',
                                openAdress: true,
                                zipCode: zipCode,
                                street: endereco,
                                district: bairro,
                                city: cidade,
                                uf: uf,
                                isFocusNumber: true
                            })
                        } else {
                            this.setState({
                                openAdress: true,
                                openWarning: true,
                                warningText: message !== '' ? message : 'Infelizmente não atendemos a sua região, mas pode ser cadastrado outro endereço'
                            })
                        }
                    } else {
                        this.selectDistrict(cidade)
                        this.setState({
                            uniqueZip: (bairro === '' || bairro === null),
                            openAdress: true,
                            zipCode: zipCode,
                            city: cidade,
                            uf: uf
                        })
                    }
                } else {
                    this.setState({
                        openWarning: true,
                        warningText: json.CEP.mensagem !== undefined ? json.CEP.mensagem : 'Cep não encontrado'
                    })
                }
            })
            .catch((error) => {
                this.setState({openSearchAddress: false})
                console.error(error)
            })
    }

    selectDistrict = city => {
        let serviceArea = localStorage.getItem(`bitmenu-${identifier}:area_atendimento`)
        serviceArea = showData(serviceArea)
        serviceArea = serviceArea !== null ? JSON.parse(serviceArea) : ''
        let index = 0
        for (let i = 0; i < serviceArea.length; i++) {
            if (city.toLowerCase() === serviceArea[i].name.toLowerCase()) {
                index = i
                break
            }
        }
        let districts = []
        serviceArea[index].districts.forEach(a => {
            districts.push(a.name)
        })
        this.setState({districts: districts})
    }

    checkGeolocation = () => {
        return ("geolocation" in navigator)
    }

    checkDeliveryTakeAway = () => {
        let deliveryToday = JSON.parse(localStorage.getItem(`bitmenu-${identifier}:deliveryToday`))
        let takeAwayToday = JSON.parse(localStorage.getItem(`bitmenu-${identifier}:takeAwayToday`))
        this.setState({takeAwayToday: takeAwayToday, deliveryToday: deliveryToday})
    }

    checkAdress = () => {
        let adress = localStorage.getItem(`bitmenu-${identifier}:adress`)
        adress = adress !== null ? JSON.parse(adress) : ''
        if (adress.retire) {
            this.setState({
                retire: true,
                storesLocation: adress.storesLocation !== undefined ? adress.storesLocation : ''
            })
        } else {
            this.setState({zipCode: adress.cep, adress: `${adress.cep}${adress.numero}`})
        }
    }

    checkCEPNeeded = () => {
        try {
            let CEPNeeded = JSON.parse(localStorage.getItem(`bitmenu-${identifier}:CEPNeeded`))
            this.setState({CEPNeeded: CEPNeeded})
        } catch (e) {
            console.log(e)
        }
    }

    clear = () => {
        this.setState({zipCode: '', street: '', number: '', district: '', city: '', uf: ''})
        localStorage.removeItem(`bitmenu-${identifier}:adress`)
    }

    checkClient = () => {
        let cliente = localStorage.getItem(`bitmenu-${identifier}:cliente`)
        cliente = (cliente !== null) ? JSON.parse(cliente) : ''
        if (cliente !== '') {
            email = cliente.email
        }
    }

    checkModules = () => {
        let modules = localStorage.getItem(`bitmenu-${identifier}:modules`)
        modules = modules !== null ? parseInt(modules) : 0
        let modeRetire = localStorage.getItem(`bitmenu-${identifier}:modeRetire`)
        modeRetire = (modeRetire !== null) ? JSON.parse(modeRetire) : ['Retirar local']
        this.setState({modules: modules, modeRetire: modeRetire})
    }

    checkScheduled = () => {
        let allowScheduledDelivery = localStorage.getItem(`bitmenu-${identifier}:allowScheduledDelivery`)
        allowScheduledDelivery = allowScheduledDelivery !== null ? JSON.parse(allowScheduledDelivery) : false
        let modules = localStorage.getItem(`bitmenu-${identifier}:modules`)
        modules = modules !== null ? parseInt(modules) : 0
        this.setState({allowScheduledDelivery: (modules !== 3) ? allowScheduledDelivery : false})
    }

    checkStoresLocations = () => {
        let storesLocations = localStorage.getItem(`bitmenu-${identifier}:storesLocations`)
        this.setState({storesLocations: storesLocations !== null ? JSON.parse(storesLocations) : []})
    }

    noRecord = () => {
        try {
            let noRecord = localStorage.getItem(`bitmenu-${identifier}:noRecord`)
            noRecord = (noRecord !== null) ? JSON.parse(noRecord) : false
            this.setState({noRecord: noRecord})
        } catch (e) {

        }
    }

    personalize = () => {
        try {
            let colors = JSON.parse(localStorage.getItem(`bitmenu-${identifier}:colorStore`))
            const {title, description, price, toolbar, body, cardProduct, nextButton, fontButton, fontToolbar} = colors
            this.setState({
                colorStore: {
                    color_title: `#${title}`,
                    color_description: `#${description}`,
                    color_price: `#${price}`,
                    color_toolbar: `#${toolbar}`,
                    color_body: `#${body}`,
                    color_cardProduct: `#${cardProduct}`,
                    color_nextButton: `#${nextButton}`,
                    color_fontButton: `#${fontButton}`,
                    color_fontToolbar: `#${fontToolbar}`
                }
            })
        } catch (e) {

        }
    }

    citys = () => {
        try {
            let serviceArea = localStorage.getItem(`bitmenu-${identifier}:area_atendimento`)
            serviceArea = showData(serviceArea)
            serviceArea = serviceArea !== null ? JSON.parse(serviceArea) : ''
            let citys = []
            serviceArea.forEach(i => {
                citys.push({city: i.name, uf: i.UF})
            })
            this.setState({citys: citys})
        } catch (e) {

        }
    }

    componentDidMount() {
        identifier = IDENTIFIER_STORE
        this.noRecord()
        this.checkScheduled()
        this.checkModules()
        this.checkClient()
        this.checkEmail()
        this.checkDeliveryTakeAway()
        this.checkAdress()
        this.checkCEPNeeded()
        this.personalize()
        this.checkStoresLocations()
        this.citys()
        window.scrollTo(0, 0)
        pageView()
    }

    render() {
        const {
            modules,
            deliveryToday,
            adress,
            takeAwayToday,
            uniqueZip,
            openComplement,
            openAdress,
            openSnackBar,
            openWarning,
            openComplementMyLocation,
            openStoresLocations,
            inputZipCode,
            storesLocations,
            openOptions,
            openDelete,
            CEPNeeded,
            openCEPNeeded,
            snackBar,
            warningText,
            street,
            house,
            number,
            zipCode,
            district,
            city,
            citys,
            districts,
            modeRetire,
            complement,
            client,
            openSearchAddress,
            allowScheduledDelivery,
            colorStore: {
                color_title,
                color_toolbar,
                color_body,
                color_cardProduct,
                color_description,
                color_fontToolbar
            },
            disabledCep,
            isFocusNumber,
            errorNumber,
            errorCEP
        } = this.state

        const theme = createMuiTheme({
            palette: {
                primary: {
                    main: '#000000'
                }
            },
        })
        const theme2 = createMuiTheme({
            palette: {
                primary: {
                    main: color_title ? color_title : '#000000'
                }
            },
        })

        return (
            <div id="adress" style={color_body ? {background: color_body} : {}}>
                <AppBar
                    id="appBar"
                    position="sticky"
                    color="default"
                    style={color_toolbar ? {background: color_toolbar} : {}}>
                    <Toolbar id="toolbar-adress" variant="dense">
                        <div id="div-back-adress">
                            <ArrowBack style={color_fontToolbar ? {color: color_fontToolbar} : {}} id="voltar-carrinho"
                                       onClick={this.onClickBack}/>
                            <Typography id={'label-title'} style={color_fontToolbar ? {color: color_fontToolbar} : {}}
                                        variant="h6"
                                        color="inherit">Endereços</Typography>
                        </div>
                        <div onClick={this.onClickLogout}>
                            <FormLabel id="label-logout">LOGOUT</FormLabel>
                        </div>
                    </Toolbar>
                </AppBar>
                <div id="container-endereco">
                    {
                        modules !== 2 &&
                        <Card id="card-inform-location"
                              style={color_cardProduct ? {background: color_cardProduct} : {}}>
                            <div id="div-input-location">
                                <MuiThemeProvider theme={theme2}>
                                    <Input
                                        id="input-location"
                                        label={'cep'}
                                        value={inputZipCode}
                                        disabled={disabledCep}
                                        style={color_title ? {
                                            background: color_cardProduct,
                                            color: color_title
                                        } : {}}
                                        name="inputZipCode"
                                        fullWidth={true} placeholder="Digite o CEP que deseja cadastrar"
                                        onChange={this.handleSearchZip}/>
                                </MuiThemeProvider>
                                {
                                    !CEPNeeded &&
                                    <div id="div-CEPNeeded" onClick={this.onClickCEPNeeded}>
                                        <FormLabel style={{color: color_title}} id="label-CEPNeeded">
                                            Não sei meu CEP
                                        </FormLabel>
                                    </div>
                                }
                            </div>
                        </Card>
                    }

                    {
                        ((takeAwayToday || allowScheduledDelivery) && (modules !== 1)) &&
                        <div>
                            {
                                (storesLocations.length !== 0)
                                    ?
                                    <Card id="card-inform-location"
                                          style={color_cardProduct ? {
                                              background: color_cardProduct,
                                              marginBottom: 0
                                          } : {marginBottom: 0}}>
                                        <div id="titulo-carrinho"
                                             onClick={() => this.setState({openStoresLocations: true})}>
                                            <FormLabel
                                                id="card-titulo-carrinho"
                                                style={color_title ? {color: color_title} : {}}>Retirar no
                                                local</FormLabel>
                                            <FormLabel
                                                id="text-chose-payment-method"
                                                style={color_title ? {
                                                    color: color_title,
                                                    textDecoration: 'underline'
                                                } : {textDecoration: 'underline'}}>Selecionar</FormLabel>
                                        </div>
                                    </Card>
                                    :
                                    <Card id="card-inform-location"
                                          style={color_cardProduct ? {
                                              background: color_cardProduct,
                                              marginBottom: 0
                                          } : {marginBottom: 0}}>
                                        <div id="div-adress">
                                            {
                                                modeRetire.map(m => (
                                                    <FormControlLabel
                                                        id="form-radio"
                                                        checked={m === localStorage.getItem(`bitmenu-${identifier}:optionsTakeWay`)}
                                                        control={<RedRadio
                                                            style={{color: color_title}}/>}
                                                        style={{color: color_title}}
                                                        onChange={() => this.onRadioTakeAway(m)}
                                                        label={m}/>
                                                ))
                                            }
                                        </div>
                                    </Card>
                            }
                        </div>
                    }

                    <div id="list-adress">
                        {(() => {
                            try {
                                if (client !== undefined && modules !== 2) {
                                    let endereco = client.endereco
                                    if ((endereco !== undefined) && (deliveryToday || allowScheduledDelivery || modules === 3)) {
                                        return (
                                            endereco.map((i, index) => {
                                                return (
                                                    <Card id="card-radio-adress" key={index}
                                                          style={color_cardProduct ? {background: color_cardProduct} : {}}>
                                                        <div id="div-adress">
                                                            <FormControlLabel

                                                                id="form-radio"
                                                                style={color_title ? {
                                                                    fontFamily: 'Ubuntu',
                                                                    color: color_title
                                                                } : {}}
                                                                checked={adress === `${i.cep}${i.numero}`}
                                                                control={<RedRadio
                                                                    style={{color: color_title}}/>}
                                                                onChange={() => this.onClickAndress(i)}
                                                                label={`${i.rua}, ${i.numero}`}/>
                                                            {
                                                                i.complement !== '' &&
                                                                <FormLabel
                                                                    style={color_description ? {color: color_description} : {}}
                                                                    id="text-complement">{i.complemento}</FormLabel>
                                                            }
                                                        </div>
                                                        <div id="options-adress">
                                                            <MoreVert style={color_title ? {
                                                                color: color_title,
                                                                cursor: "pointer"
                                                            } : {cursor: "pointer"}}
                                                                      onClick={() => this.onClickOption(index)}/>
                                                        </div>
                                                    </Card>
                                                )
                                            })
                                        )
                                    }
                                }
                            } catch (e) {
                                console.log(e)
                            }
                        })()}
                    </div>
                </div>


                <MuiThemeProvider theme={theme}>
                    <Dialog open={openAdress} onClose={() => this.closeAdress()} fullScreen={isMobile}>
                        <DialogTitle>Adicione outro endeço de entrega</DialogTitle>
                        <DialogContent>
                            <TextField margin="dense" name="zipCode" label="CEP" value={zipCode} error={errorCEP}
                                       type="text"
                                       placeholder="CEP" fullWidth variant="outlined"
                                       onChange={this.handleSearchZip}/>
                            <div id="form-cadastro">
                                <TextField margin="dense" disabled={!uniqueZip} name="street" label="Rua"
                                           value={street}
                                           type="text"
                                           placeholder="Rua" fullWidth variant="outlined"
                                           onChange={this.handleInputs}/>
                            </div>
                            <div id="form-cadastro-endereco">
                                {
                                    !uniqueZip ?
                                        <TextField margin="dense" name="district" label="Bairro"
                                                   disabled={!uniqueZip}
                                                   value={district}
                                                   type="text"
                                                   placeholder="Bairro" fullWidth variant="outlined"
                                                   onChange={this.handleInputs}/>
                                        :
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel id="demo-simple-select-label">Bairro</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                label="Age"
                                                name="select-district"
                                                onChange={this.handleInputs}>
                                                {
                                                    districts.map((i, index) => (
                                                        <MenuItem value={index}>{i}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                }
                                <Box p={1}/>
                                <TextField margin="dense" name="number" label="Número" fullWidth
                                           value={number}
                                           focused={isFocusNumber}
                                           error={errorNumber}
                                           type="number"
                                           placeholder="Nº" variant="outlined"
                                           onChange={this.handleInputs}/>
                            </div>
                            <div id="form-cadastro">
                                <TextField margin="dense" disabled name="city" label="Cidade" value={city}
                                           type="text"
                                           placeholder="Cidade" fullWidth variant="outlined"
                                           onChange={this.handleInputs}/>
                            </div>
                            <div>
                                <RadioGroup id="form-residence">
                                    <FormControlLabel id="form-radio" checked={house === 'casa'}
                                                      value="casa"
                                                      control={<RedRadioDialog/>} label="Casa"
                                                      onChange={this.onRadio}/>
                                    <FormControlLabel id="form-radio" checked={house === 'apto'}
                                                      value="apto"
                                                      control={<RedRadioDialog/>} label="Apto"
                                                      onChange={this.onRadio}/>
                                </RadioGroup>
                            </div>
                            <TextField margin="dense" name="complement" label="Complemento"
                                       value={complement}
                                       type="text" placeholder="Complemento" fullWidth variant="outlined"
                                       onChange={this.handleInputs}/>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={this.closeAdress}
                                color={'primary'}>
                                Cancelar
                            </Button>
                            <Button
                                onClick={this.onClickAdress}
                                color={'primary'}
                                variant={'contained'}>
                                Confirmar
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={openCEPNeeded} onClose={this.closeCEPNeeded}>
                        <DialogTitle id="form-dialog-title">Adicione outro endeço de entrega</DialogTitle>
                        <DialogContent>
                            <div id="form-cadastro-endereco">
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="demo-simple-select-label">Cidade</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        name="select-city"
                                        onChange={this.handleInputs}>
                                        {
                                            citys.map((i, index) => (
                                                <MenuItem key={index} value={index}>{i.city}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                            <div id="form-cadastro-endereco">
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="demo-simple-select-label">Bairro</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        label="Age"
                                        name="select-district"
                                        onChange={this.handleInputs}>
                                        {
                                            districts.map((i, index) => (
                                                <MenuItem value={index}>{i}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                <Box p={1}/>
                                <TextField margin="dense" name="number" label="Número" fullWidth
                                           value={number}
                                           type="number"
                                           placeholder="Nº" variant="outlined"
                                           onChange={this.handleInputs}/>
                            </div>
                            <div id="form-cadastro">
                                <TextField margin="dense" name="street" label="Rua" value={street} type="text"
                                           placeholder="Rua" fullWidth variant="outlined" autoComplete={false}
                                           onChange={this.handleInputs}/>
                            </div>
                            <div>
                                <RadioGroup id="form-residence">
                                    <FormControlLabel id="form-radio" checked={house === 'casa'}
                                                      value="casa"
                                                      control={<RedRadioDialog/>} label="Casa"
                                                      onChange={this.onRadio}/>
                                    <FormControlLabel id="form-radio" checked={house === 'apto'}
                                                      value="apto"
                                                      control={<RedRadioDialog/>} label="Apto"
                                                      onChange={this.onRadio}/>
                                </RadioGroup>
                            </div>
                            <TextField margin="dense" name="complement" label="Complemento"
                                       value={complement}
                                       type="text" placeholder="Complemento" fullWidth variant="outlined"
                                       onChange={this.handleInputs}/>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.closeCEPNeeded} color="primary">Cancelar</Button>
                            <Button onClick={this.onClickAdress} color="primary">Confirmar</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={openStoresLocations}
                            onClose={() => this.setState({openStoresLocations: false})}
                            aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Retirar no local</DialogTitle>
                        <FormLabel id="form-dialog-subtitle">
                            Escolha aonde deseja retirar o pedido
                        </FormLabel>
                        <DialogContent id="dialog-content-locations">
                            {
                                storesLocations.map((i, index) => (
                                    <Card id="card-locations" key={index}>
                                        <CardContent id="card-content-locations">
                                            <FormControlLabel id="form-radio"
                                                              control={<RedRadioDialog/>}
                                                              label={i.address}
                                                              onChange={() => this.onRadioStore(i)}/>
                                        </CardContent>
                                    </Card>
                                ))
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({openStoresLocations: false})}
                                    color="primary">Fechar</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={openOptions} onClose={this.closeOptions}>
                        <DialogTitle id="form-dialog-title">Detalhes do endereço</DialogTitle>
                        <DialogContent>
                            <div id="detalhes">
                                <div className="btn-options" onClick={this.onClickEditAndress}>
                                    <FormLabel
                                        id={'text-btn-options'}>
                                        Editar endereço
                                    </FormLabel>
                                </div>
                                <Divider/>
                                <div className="btn-options" onClick={this.onClickDeleteAdress}>
                                    <FormLabel
                                        id={'text-btn-options'}>
                                        Deletar endereço
                                    </FormLabel>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
                    <Dialog open={openDelete} onClose={this.closeDelete}
                            aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Deletar endereço</DialogTitle>
                        <DialogContent>
                            <DialogContentText>Deseja deletar esse endereço ?</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.closeDelete} color="primary">Não</Button>
                            <Button onClick={this.onClickDelete} color="primary" variant={'contained'}>Sim</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={openComplement} onClose={() => this.setState({openComplement: false})}>
                        <DialogTitle>Complemento</DialogTitle>
                        <DialogContent>
                            <DialogContentText id={'label-content-dialog'}>
                                Deseja adicionar um complemento ?
                            </DialogContentText>
                            {
                                openComplementMyLocation &&
                                <TextField
                                    margin="dense"
                                    name="complementMyLocation"
                                    label="Complemento"
                                    fullWidth
                                    variant="outlined"
                                    onChange={this.handleInputs}/>
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button
                                id={'button-dialog'}
                                onClick={this.closeComplement}
                                color="primary">
                                Não
                            </Button>
                            {
                                openComplementMyLocation &&
                                <Button
                                    id={'button-dialog'}
                                    variant={'contained'}
                                    onClick={this.onClickConfirmComplement}
                                    color="primary">
                                    Confirmar
                                </Button>
                            }
                            {
                                !openComplementMyLocation &&
                                <Button
                                    id={'button-dialog'}
                                    variant={'contained'}
                                    onClick={() => this.setState({openComplementMyLocation: true})}
                                    color="primary">
                                    Sim
                                </Button>
                            }
                        </DialogActions>
                    </Dialog>
                    <Dialog open={openWarning} onClose={this.closeWarning}>
                        <DialogTitle>Aviso</DialogTitle>
                        <DialogContent>
                            <DialogContentText id={'label-content-dialog'}>{warningText}</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                id={'button-dialog'}
                                variant={'contained'}
                                onClick={() => this.setState({openWarning: false})}
                                color="primary">
                                Ok
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={openSearchAddress}>
                        <DialogContent id="dialog-content-login">
                            <CircularProgress id="progress-bar-login" style={{color: '#1e1e1e'}} size={30}/>
                            <DialogContentText id="dialog-text-login">Buscando cep</DialogContentText>
                        </DialogContent>
                    </Dialog>
                </MuiThemeProvider>
                <Snackbar
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    open={openSnackBar}
                    autoHideDuration={2000}
                    onClose={this.closeSnackBar}
                    message={snackBar}
                />
            </div>
        )
    }
}

export default withRouter(Enderecos)
