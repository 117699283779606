import React from 'react'
import {withRouter} from 'react-router-dom'
import {
    AppBar,
    Toolbar,
    Typography,
    FormLabel,
    Divider,
    Card,
    CardMedia,
    Snackbar,
    Input,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    Box
} from '@material-ui/core'
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles'
import {
    ArrowBack as ArrowBackIcon,
    Search as SearchIcon,
    Close as CloseIcon,
    Favorite,
    FavoriteBorder,
    ExpandLess,
    ExpandMore,
    Delete,
    Share,
    WhatsApp,
    Facebook,
    Add,
    Remove
} from '@material-ui/icons'
import './Sacola.css'
import Mais from '../../images/mais.png'
import Menos from '../../images/menos.png'
import MenosDisable from '../../images/menos_disable.png'
import CheckBox from '../../components/checkbox/CheckBox.jsx'
import RadioButton from '../../components/radiobutton/RadioButton.jsx'
import ButtonAdd from '../../components/buttonadd/ButtonAdd.jsx'
import {pageView, smoothScrolling, IDENTIFIER_STORE, eventFacebook, eventGoogle, eventGA4} from '../../util'
import {isMobile} from 'react-device-detect'

let cont = 0
let identifier
let obrigatorios
let subItensObrigatorios = []
let options
let qt = 0

const themeDialog = createMuiTheme({
    palette: {
        primary: {
            main: '#000000',
        }
    },
})

class Sacola extends React.Component {

    state = {
        openAmount: false,
        nome: '',
        observationOrder: '',
        sku: '',
        quantidade: 1,
        total: 0,
        valor: 0,
        firstStepValue: 0,
        valorBase: 0,
        qtdeMax: 100,
        variations: [],
        flavors: [],
        showSearchFlavor: false,
        favoritesSku: [],
        listOfSteps: [],
        allowFractioned: false,
        peso: 1,
        openFlavorAssistant: false,
        visibleObs: true,
        obrigatorio: false,
        openSearch: false,
        openImage: false,
        imageFlavor: false,
        openShare: false,
        fracionado: false,
        required: 0,
        digitAmount: 1,
        sku_delete: '',
        snackbar: {
            open: false,
            message: ''
        },
        colorStore: {
            color_title: '',
            color_description: '',
            color_price: '',
            color_toolbar: '',
            color_body: '',
            color_cardProduct: '',
            color_nextButton: '',
            color_fontButton: '',
            color_fontToolbar: '',
            color_footer: ''
        },
        typeproduct: '',
        valorCombo: 0
    }

    handleClickRadio = obj => {
        try {
            if (obj.image !== undefined) {
                if (obj.image !== '') {
                    this.setState({openImage: true, imageFlavor: obj.image})
                }
                return
            }
        } catch (e) {
            console.log(e.message)
        }
        let array = this.state.variations
        let achou = false
        array.forEach((i, index) => {
            if (`${obj.key}` === `${i.key}`) {
                array[index] = obj
                achou = true
            }
        })
        if (!achou) {
            array.push(obj)
            if (++cont === this.state.required) {
                this.setState({obrigatorio: false})
            }
        }

        this.checkFlavor(array)
        this.obrigatorios(array)
        this.setState({variations: array})
        this.calculate()
        this.clearInput()
        this.positionStep(obj.key)
        this.cleaOpacity()
    }

    handleClick = (obj, remove = false) => {
        try {
            if (obj.image !== undefined) {
                if (obj.image !== '') {
                    this.setState({openImage: true, imageFlavor: obj.image})
                }
                return
            }
        } catch (e) {
            console.log(e.message)
        }
        try {
            if (obj.open) {
                this.setState({snackbar: {open: true, message: obj.message}})
                return
            }
        } catch (e) {
            console.log(e.message)
        }
        let array = this.state.variations
        let achou = false
        array.forEach((i, index) => {
            if (`${obj.key}${obj.sku}` === `${i.key}${i.sku}`) {
                if (obj.amount === 0) {
                    array.splice(index, 1)
                } else {
                    array[index] = obj
                }
                achou = true
            }
        })
        if (!achou) {
            array.push(obj)
        }
        if (obj.amountType === 'AVERAGE') {
            let cont = 0
            array.forEach(i => {
                if (`${obj.key}` === `${i.key}`) {
                    cont += i.amount
                }
            })
            array.forEach(i => {
                if (`${obj.key}` === `${i.key}`) {
                    i.price = parseFloat((i.fixedPrice / cont).toFixed(2))
                }
            })
        }

        this.checkFlavor(array)
        this.obrigatorios(array)
        this.setState({variations: array})
        this.calculate()
        this.clearInput()
        try {
            if (!remove) {
                if (obj.currentQuantity >= obj.limit) this.positionStep(obj.key)
            }
        } catch (e) {

        }
        this.cleaOpacity()
    }

    deleteFlavor = ({sku}) => {
        const {variations} = this.state
        let i = variations.filter(v => v.sku === sku)
        this.setState({sku_delete: sku})
        i[0].amount = 0
        const s = i[0]
        setTimeout(() => {
            this.handleClick(s, true)
            this.setState({sku_delete: ''})
        }, 10)
    }

    positionStep = key => {
        try {
            setTimeout(() => {
                const {listOfSteps} = this.state
                let index = listOfSteps.indexOf(`${key}`)
                index = listOfSteps[index + 1]
                let flavorAssistant = document.getElementById('flavor-assistant')
                let top = flavorAssistant !== null ? flavorAssistant.offsetHeight : 0
                smoothScrolling(index, 200, (top + 55))
            }, 200)
        } catch (e) {

        }
    }

    checkFlavor = array => {
        try {
            if (array.length !== 0) {
                let confirm = false
                let flavors = []
                for (let i = 0; i < array.length; i++) {
                    if (array[i].sku.includes('S')) {
                        let achou = false
                        flavors.forEach(a => {
                            if (`${a.key}` === `${array[i].key}`) {
                                let item = {
                                    item: array[i].option,
                                    price: array[i].price,
                                    sku: array[i].sku,
                                    amount: array[i].amount
                                }
                                a.flavors.push(item)
                                achou = true
                            }
                        })
                        if (!achou) {
                            let flavor = {
                                title: array[i].variation,
                                key: array[i].key,
                                flavors: []
                            }
                            let item = {
                                item: array[i].option,
                                price: array[i].price,
                                sku: array[i].sku,
                                amount: array[i].amount
                            }
                            if (flavor.flavors.indexOf(item) === -1) {
                                flavor.flavors.push(item)
                            }
                            flavors.push(flavor)
                        }
                        confirm = true
                    }
                }
                const {visibility} = this.state
                if (visibility === undefined || !visibility) {
                    this.setState({visibility: true})
                }
                this.setState({openFlavorAssistant: confirm, flavors: flavors})
            } else {
                this.setState({openFlavorAssistant: false, visibility: false})
            }
        } catch (e) {

        }
    }

    obrigatorios = itens => {
        let ob = []
        let it = []
        obrigatorios.forEach(i => {
            ob.push(i.obg)
            let quantidade = 0
            itens.forEach(a => {
                if (i.obg === `${a.key}`) {
                    quantidade += a.amount
                    if (quantidade >= i.min) {
                        if (!it.includes(`${a.key}`)) {
                            it.push(`${a.key}`)
                        }
                    }
                }
            })
        })
        const {qtdeMax} = this.state
        this.setState({obrigatorio: ((ob.length !== it.length) || (qtdeMax === 0))})
    }

    calculate = () => {
        let {variations, valorBase, typeproduct} = this.state
        let total = this.priceVariation(variations)
        let maxValue = 0
        variations.forEach(i => {
            if (i.amountType === 'MAXVALUE') {
                maxValue = i.price > maxValue ? i.price : maxValue
            } else {
                total += (i.price * i.amount)
            }
        })
        if (typeproduct === 'C') {
            this.setState({valorCombo: (total + maxValue)})
        } else {
            this.setState({valor: (valorBase + total + maxValue)})
        }
    }

    priceVariation = variations => {
        let steps = []
        let total = 0
        try {
            variations.forEach(i => {
                if (i.priceVariation !== 0 && i.priceVariation !== undefined) {
                    let s = `${i.key}`
                    if (steps.indexOf(s) === -1) {
                        steps.push(s)
                        total += i.priceVariation
                    }
                }
            })
        } catch (e) {

        }
        return total
    }

    visibility = index => this.setState({visibility: (index === 0)})

    onclickVoltar = () => {
        window.scrollTo(0, 0)
        this.props.history.replace({pathname: '/'})
        sessionStorage.removeItem(`bitmenu-${identifier}:queryUrl`)
    }

    onClickExpand = up => {
        try {
            const {search, next_search} = this.state
            let id = `${this.clearText(search)}`
            if (up) {
                let did_not_find = true
                let position_search = []
                options.forEach((i, index) => {
                    try {
                        if (i.includes(id)) position_search.push(index)
                        let elem = document.querySelector(i)
                        elem.style.opacity = 0.5
                    } catch (e) {

                    }
                })
                for (let i = 0; i < options.length; i++) {
                    try {
                        if (options[i].includes(id)) {
                            if (i === next_search) {
                                let index_o = position_search[position_search.indexOf(next_search) - 1]
                                let elem = document.querySelector(options[index_o])
                                elem.style.opacity = 1
                                let div_height = 0
                                let elem2 = document.querySelector('#flavor-assistant')
                                if (elem2 !== null)
                                    div_height = elem2.offsetHeight
                                let position = (elem.offsetTop) - (50 + div_height)
                                window.scrollTo(0, position)
                                this.setState({next_search: index_o})
                                did_not_find = false
                                break
                            }
                        }
                    } catch (e) {

                    }
                }
                if (did_not_find) {
                    options.forEach((i, index) => {
                        try {
                            if (i.includes(id)) position_search.push(index)
                            let elem = document.querySelector(i)
                            elem.style.opacity = 1
                        } catch (e) {

                        }
                    })
                }
            } else {
                let did_not_find = true
                options.forEach(i => {
                    try {
                        let elem = document.querySelector(i)
                        elem.style.opacity = 0.5
                    } catch (e) {

                    }
                })
                for (let i = 0; i < options.length; i++) {
                    try {
                        if (options[i].includes(id)) {
                            if (i > next_search) {
                                let elem = document.querySelector(options[i])
                                elem.style.opacity = 1
                                let div_height = 0
                                let elem2 = document.querySelector('#flavor-assistant')
                                if (elem2 !== null)
                                    div_height = elem2.offsetHeight
                                let position = (elem.offsetTop) - (50 + div_height)
                                window.scrollTo(0, position)
                                this.setState({next_search: i})
                                did_not_find = false
                                break
                            }
                        }
                    } catch (e) {

                    }
                }
                if (did_not_find) {
                    options.forEach(i => {
                        try {
                            let elem = document.querySelector(i)
                            elem.style.opacity = 1
                        } catch (e) {

                        }
                    })
                }
            }
        } catch (e) {

        }
    }

    onClickSerach = () => {
        if (this.state.openSearch) {
            window.scrollTo(0, 0)
        }
        this.setState({openSearch: !this.state.openSearch})
    }

    onClickCloseSearch = () => {
        this.cleaOpacity()
        this.setState({openSearch: false})
        this.filter('search', '')
        window.scrollTo(0, 0)
    }

    cleaOpacity = () => {
        try {
            options.forEach(i => {
                let elem = document.querySelector(i)
                elem.style.opacity = 1
            })
        } catch (e) {

        }
    }

    handleInputKg = e => {
        if (e.target.value >= 0) {
            this.setState({[e.target.name]: e.target.value})
        }
    }

    handleInput = e => {
        this.setState({[e.target.name]: e.target.value})
        if (e.target.name !== 'observationOrder')
            this.filter(e.target.name, e.target.value)
    }

    handleInputAmount = e => {
        const {qtdeMax} = this.state
        if ((e.target.value !== 'e')) {
            if (e.target.value > qtdeMax) {
                this.setState({
                    quantidade: qtdeMax,
                    snackbar: {
                        open: true,
                        message: 'Você atingiu o quantidade máxima!'
                    }
                })
            } else {
                if (!(e.target.value < 0)) {
                    this.setState({[e.target.name]: isNaN(parseFloat(e.target.value)) ? '' : parseFloat(e.target.value)})
                }
            }
        }
    }

    onClickAddAmount = (amount, iniciaZero = true) => {
        /* eslint-disable */
        try {
            let {quantidade, qtdeMax} = this.state
            quantidade = ((quantidade == 1) && (iniciaZero)) ? 0 : parseInt(quantidade)
            quantidade += amount
            if (quantidade > qtdeMax) {
                this.setState({
                    quantidade: qtdeMax,
                    snackbar: {
                        open: true,
                        message: 'Você atingiu o quantidade máxima!'
                    }
                })
            } else {
                this.setState({quantidade: ((quantidade === 0) ? 1 : quantidade)})
            }
        } catch (e) {
            this.setState({snackbar: {open: true, message: e.message}})
        }
        /* eslint-enable */
    }

    onCloseAmount = () => {
        /* eslint-disable */
        const {quantidade} = this.state
        this.setState({quantidade: ((quantidade === '' || quantidade == '0') ? 1 : quantidade), openAmount: false})
        /* eslint-enable */
    }

    buttonMais = () => {
        this.setState({teste: new Date().getTime()})
        let {quantidade, total, valor, qtdeMax} = this.state

        if (qtdeMax === 0) {
            this.setState({
                snackbar: {
                    open: true,
                    message: `Você já atingui o quantidade máxima do produto`
                }
            })
            return
        }

        if (quantidade < qtdeMax) {
            let s = (total + valor)
            this.setState({
                quantidade: ++quantidade,
                total: s
            })
        } else {
            this.setState({snackbar: {open: true, message: `Você atingui a quantidade máxima desse produto`}})
        }
    }

    buttonMenos = () => {
        let quantidade = this.state.quantidade
        let valor = this.state.valor
        let total = this.state.total
        if (--quantidade !== 0) {
            let s = (total - valor)
            this.setState({
                quantidade: quantidade,
                total: s
            })
        }
    }

    buttonMaisKg = () => {
        let {peso} = this.state
        peso = (peso !== '') ? parseFloat(peso) : 0
        this.setState({peso: parseFloat((peso + 0.1).toFixed(1))})
    }

    buttonMenosKg = () => {
        let {peso} = this.state
        peso = (peso !== '') ? parseFloat(peso) : 0
        if (peso > 0.1) {
            this.setState({peso: parseFloat((peso - 0.1).toFixed(1))})
        }
    }

    buttonAdicionar = () => {
        if (!this.state.obrigatorio) {
            let {
                sku,
                nome,
                quantidade,
                valor,
                valorBase,
                firstStepValue,
                valorCombo,
                observationOrder,
                variations,
                peso,
                fracionado,
                allowFractioned,
                itemCoupon,
                typeproduct,
                qtdeMin,
                visibleObs
            } = this.state
            if (quantidade < qtdeMin) return this.setState({
                snackbar: {
                    open: true,
                    message: `Esse produto tem um mínimo de ${qtdeMin} itens`
                }
            })
            let qtdeMax = JSON.parse(sessionStorage.getItem(`bitmenu-${identifier}:qtdeMax-${sku}`))
            if (qtdeMax === 0) return this.setState({
                snackbar: {
                    open: true,
                    message: `Você já atingui o quantidade máxima do produto`
                }
            })
            if (fracionado && peso === '') return this.setState({
                snackbar: {
                    open: true,
                    message: 'Adicione um peso ao produto.'
                }
            })
            let itens = JSON.parse(localStorage.getItem(`bitmenu-${identifier}:itens`))
            itens = itens !== null ? itens : []
            let preco = valorBase + this.priceVariation(variations)
            const subitens = []
            if (subItensObrigatorios.length !== 0) {
                subItensObrigatorios.forEach(i => {
                    //        subitens.push(i)
                })
            }
            let variacao = ''
            variations.forEach(i => {
                let s = {
                    key: i.key,
                    priceVariation: i.priceVariation,
                    sku: i.sku,
                    titulo: i.option,
                    quantidade: i.amount,
                    precoProduto: i.price,
                    preco: (i.amount * i.price),
                    etapa: this.step(i.step, i.sku),
                    variacao: variacao !== i.variation ? i.variation : '',
                    ordem: i.ordem,
                    soma: i.amountType,
                    descontaComCupom: (i.discountWithCoupon !== undefined) ? i.discountWithCoupon : false
                }
                variacao = i.variation
                subitens.push(s)
            })
            subitens.sort((a, b) => {
                if (a.ordem < b.ordem) return -1
                if (a.ordem > b.ordem) return 1
                return 0
            })
            const item = {
                sku: sku,
                titulo: nome,
                quantidade: (allowFractioned && fracionado) ? parseFloat(peso) : quantidade,
                precoBase: valorBase,
                precoProduto: preco,
                preco: (quantidade * preco),
                obs: observationOrder,
                subItens: subitens,
                itemCoupon: itemCoupon,
                fracionado: (fracionado && allowFractioned),
                total: (((typeproduct === 'C') ? valorCombo : (valor + firstStepValue))),
                hora: this.orderTime(),
                visibleObs: visibleObs
            }
            try {
                let contQtdM = 0
                itens.forEach(i => {
                    if (sku === i.sku) {
                        contQtdM++
                    }
                })
                if ((contQtdM + 1) > qt) {
                    return this.setState({
                        snackbar: {
                            open: true,
                            message: 'Você já atingui o quantidade máxima do produto'
                        }
                    })
                }
            } catch (e) {

            }
            itens.push(item)
            sessionStorage.setItem(`bitmenu-${identifier}:qtdeMax-${sku}`, (qtdeMax - quantidade))
            localStorage.setItem(`bitmenu-${identifier}:itens`, JSON.stringify(itens))
            localStorage.removeItem(`bitmenu-${identifier}:statusOrder`)
            eventFacebook('AddToCart', {content_name: item.titulo, currency: 'BRL', value: item.total})
            eventGoogle('AddToCart', {
                action: 'adiciona_carrinho',
                content_name: item.titulo,
                currency: 'BRL',
                value: (item.total)
            })

            let items = [{
                item_id: item.sku,
                item_name: item.titulo,
                price: item.total,
                quantity: item.quantidade
            }]
            eventGA4('add_to_cart', {currency: 'BRL', value: item.total, items: items})
            this.onclickVoltar()
        } else {
            const {qtdeMax} = this.state
            this.setState({
                snackbar: {
                    open: true,
                    message: (qtdeMax === 0) ? 'Você já atingui o quantidade máxima do produto' : 'Selecione os itens obrigatórios'
                }
            })
        }
    }

    step = (step, sku) => {
        try {
            if ((sku.substring(0, 1) === 'S') || (step.toString() === '')) {
                return 0
            } else {
                return step
            }
        } catch (e) {
            return step
        }
    }

    orderTime = () => {
        let d = new Date()
        let hours = d.getHours()
        let day = d.getDate()
        if (hours === 0) {
            hours = 0
            day += 1
        } else if (hours === 21) {
            hours = 1
            day += 1
        } else if (hours === 22) {
            hours = 2
            day += 1
        } else if (hours === 23) {
            hours = 3
            day += 1
        } else {
            hours += 4
        }
        return new Date(d.getFullYear(), d.getMonth(), day, hours, d.getMinutes())
    }

    handleClose = () => {
        this.setState({snackbar: {open: false, message: ''}})
    }

    filter = (name, text) => {
        try {
            if (name === 'search') {
                let id = `${this.clearText(text)}`
                for (let i = 0; i < options.length; i++) {
                    try {
                        let elem = document.querySelector(options[i])
                        if (id === '') {
                            window.scrollTo(0, 0)
                            this.setState({next_search: i})
                            break
                        } else if (options[i].includes(id)) {
                            let div_height = 0
                            let elem2 = document.querySelector('#flavor-assistant')
                            if (elem2 !== null)
                                div_height = elem2.offsetHeight
                            let position = (elem.offsetTop) - (50 + div_height)
                            // elem.style.backgroundColor = '#9e9e9e'
                            // elem.style.borderRadius = '10px'
                            window.scrollTo(0, position)
                            this.setState({next_search: i})
                            break
                        }
                        this.setState({next_search: 0})
                    } catch (e) {

                    }
                }
            }
            if (text === '')
                this.cleaOpacity()
        } catch (error) {

        }
    }

    checkMandatory = (obj, qtdeMax) => {
        try {
            if (obj.variations !== undefined) {
                obj.variations.forEach(i => {
                    const {minSelection, maxSelection, options, visibility} = i
                    if ((options[0].sku.includes('S')) || (options[0].sku.includes('O')) || (visibility !== undefined && visibility.includes('DELIVERY'))) {
                        if (minSelection === 1 && maxSelection === 1 && options.length === 1 && obj.variations.length === 1) {
                            this.setState({obrigatorio: false})
                        } else if (minSelection === 1 && maxSelection === 1 && this.checkOptions(obj.variations)) {
                            this.setState({obrigatorio: false})
                        } else if (minSelection >= 1 && options.length !== 1) {
                            if (visibility === undefined || visibility.includes('DELIVERY'))
                                this.setState({obrigatorio: !(qtdeMax === 0)})
                        }
                    }
                })
            }
        } catch (error) {

        }
    }

    checkOptions = array => {
        let retorno = false
        try {
            for (let i = 0; array.length; i++) {
                if (array[i].options.length !== 1 || (array[i].minSelection !== 1 && array[i].maxSelection !== 1)) {
                    retorno = false
                    break
                }
                retorno = true
            }
        } catch (e) {
            console.log(e.message)
        }
        return retorno
    }

    clearInput = () => {
        try {
            document.getElementById('search').value = ''
            document.getElementById('search').focus()
        } catch (error) {

        }
    }

    clearText = text => {
        text = text.normalize("NFD").replace(/[^a-zA-Zs]/g, "")
        return text.toLowerCase()
    }

    favorite = () => {
        let favoritesSku = localStorage.getItem(`bitmenu-${identifier}:favoritesSku`)
        favoritesSku = favoritesSku !== null ? JSON.parse(favoritesSku) : []
        this.setState({favoritesSku: favoritesSku})
    }

    onClickFavorite = (object, add) => {
        let favorites = localStorage.getItem(`bitmenu-${identifier}:favorites`)
        favorites = favorites !== null ? JSON.parse(favorites) : []
        let favoritesSku = localStorage.getItem(`bitmenu-${identifier}:favoritesSku`)
        favoritesSku = favoritesSku !== null ? JSON.parse(favoritesSku) : []
        if (add) {
            favoritesSku.push(object.sku)
            favorites.push(object)
        } else {
            let index
            for (let i = 0; favorites.length; i++) {
                if (favorites[i].sku === object.sku) {
                    index = i
                    break
                }
            }
            if (index !== undefined) {
                favorites.splice(index, 1)
            }
            favoritesSku.splice(favoritesSku.indexOf(object.sku), 1)
        }
        localStorage.setItem(`bitmenu-${identifier}:favorites`, JSON.stringify(favorites))
        localStorage.setItem(`bitmenu-${identifier}:favoritesSku`, JSON.stringify(favoritesSku))
        this.setState({favoritesSku: favoritesSku})
    }

    onClickShare = whatsApp => {
        const {skuShare} = this.state
        let pathname = window.location.pathname
        pathname = pathname.replace('sacola', '')
        if (whatsApp) {
            window.location.href = `https://api.whatsapp.com/send?text=https://${window.location.hostname}${pathname}?produto=${skuShare}`
        } else {
            window.location.href = `https://www.facebook.com/sharer/sharer.php?u=https://${window.location.hostname}${pathname}?produto=${skuShare}`
        }
        this.setState({openShare: false})
    }

    personalize = () => {
        try {
            let colors = JSON.parse(localStorage.getItem(`bitmenu-${identifier}:colorStore`))
            const {
                title,
                description,
                price,
                toolbar,
                body,
                cardProduct,
                nextButton,
                fontButton,
                fontToolbar,
                footer
            } = colors
            this.setState({
                colorStore: {
                    color_title: `#${title}`,
                    color_description: `#${description}`,
                    color_price: `#${price}`,
                    color_toolbar: `#${toolbar}`,
                    color_body: `#${body}`,
                    color_cardProduct: `#${cardProduct}`,
                    color_nextButton: `#${nextButton}`,
                    color_fontButton: `#${fontButton}`,
                    color_fontToolbar: `#${fontToolbar}`,
                    color_footer: `#${footer}`
                }
            })
        } catch (e) {

        }
    }

    listOfSteps = variations => {
        let array = []
        variations.forEach(i => {
            if (!(i.minSelection === 1 && i.maxSelection === 1 && i.options.length === 1)) {
                let name = `${i.key}`
                array.push(name)
            }
        })
        array.push('observation-input')
        this.setState({listOfSteps: array})
    }

    checkStep = variations => {
        let array = []
        variations.forEach((i, index) => {
            if (i.minSelection === 1 && i.maxSelection === 1 && i.options.length === 1) {
                const {step, variation, key, ordem, options} = i
                let json = {
                    step: step,
                    variation: variation,
                    option: options[0].option,
                    sku: options[0].sku,
                    price: (i.price !== undefined) ? (options[0].price + i.price) : options[0].price,
                    amount: 1,
                    key: key,
                    ordem: ordem,
                    priceVariation: 0,
                    discountWithCoupon: true,
                    automaticallyAdded: true
                }
                array.push(json)
            }
        })
        let firstStepValue = 0
        array.forEach(i => firstStepValue += i.price)
        this.setState({
            firstStepValue: firstStepValue,
            variations: array,
            valorCombo: firstStepValue,
        })
    }


    componentDidMount() {
        identifier = IDENTIFIER_STORE
        const obj = this.props.location.state.dados
        let isFlavor = false
        let showSearchFlavor = false
        try {
            for (let i = 0; i < obj.variations.length; i++) {
                try {
                    isFlavor = obj.variations[i].options[0].sku.includes('S')
                    if (isFlavor) showSearchFlavor = true
                } catch (e) {
                    console.log(e)
                }
                let filterFlavor = []
                if (isFlavor) {
                    obj.variations[i].options.forEach(i => {
                        if (filterFlavor.indexOf(i.category) === -1) filterFlavor.push(i.category)
                    })
                }
                obj.variations[i].filterFlavor = filterFlavor
            }
        } catch (e) {

        }
        const description = this.props.location.state.description
        const position = this.props.location.state.position
        const itemCoupon = (this.props.location.state.itemCoupon !== undefined) ? this.props.location.state.itemCoupon : false
        this.listOfSteps(obj.variations)
        this.checkStep(obj.variations)
        try {
            localStorage.removeItem(`bitmenu-${identifier}:itensOld`)
            localStorage.removeItem(`bitmenu-${identifier}:lastWish`)
        } catch (e) {

        }
        let qtdeMax = sessionStorage.getItem(`bitmenu-${identifier}:qtdeMax-${obj.sku}`)
        sessionStorage.setItem(`bitmenu-${identifier}:qtdeMax-${obj.sku}`, (qtdeMax !== null) ? qtdeMax : obj.qtdeMax !== undefined ? obj.qtdeMax : 10)
        qtdeMax = JSON.parse(sessionStorage.getItem(`bitmenu-${identifier}:qtdeMax-${obj.sku}`))
        qt = obj.qtdeMax
        let allowFractioned = sessionStorage.getItem(`bitmenu-${identifier}:allowFractioned`)
        allowFractioned = (allowFractioned !== null) ? JSON.parse(allowFractioned) : false
        this.checkMandatory(obj, qtdeMax)
        this.favorite()
        this.personalize()
        this.setState({
            showSearchFlavor: showSearchFlavor,
            fracionado: obj.fractioned,
            total: obj.price,
            valor: obj.price,
            valorBase: obj.price,
            nome: `${obj.product} ${description !== undefined ? description : ''}`,
            sku: obj.sku,
            position: position,
            itemCoupon: itemCoupon,
            qtdeMax: qtdeMax,
            qtdeMin: (obj.qtdeMin !== undefined) ? obj.qtdeMin : 0,
            allowFractioned: allowFractioned,
            typeproduct: obj.typeproduct,
            visibleObs: obj.visibleObs !== undefined ? obj.visibleObs : true
        })
        window.scrollTo(0, 0)
        pageView()
    }

    componentDidUpdate() {
        try {
            const obj = this.props.location.state.dados
            options = []
            obj.variations.forEach(i => {
                i.options.forEach(e => {
                    options.push(`#${this.clearText(e.option)}`)
                })
            })
        } catch (error) {

        }
    }

    render() {
        const {
            openFlavorAssistant,
            flavors,
            showSearchFlavor,
            visibility,
            visibleObs,
            fracionado,
            peso,
            valor,
            firstStepValue,
            quantidade,
            favoritesSku,
            openImage,
            openShare,
            imageFlavor,
            allowFractioned,
            openAmount,
            sku_delete,
            typeproduct,
            valorCombo,
            qtdeMax,
            colorStore: {
                color_title,
                color_toolbar,
                color_body,
                color_cardProduct,
                color_description,
                color_price,
                color_nextButton,
                color_fontButton,
                color_fontToolbar,
                color_footer
            }
        } = this.state
        const obj = this.props.location.state.dados
        obrigatorios = []
        subItensObrigatorios = []
        const theme = createMuiTheme({
            palette: {
                primary: {
                    main: color_title ? color_title : '#000000'
                }
            },
        })
        const theme2 = createMuiTheme({
            palette: {
                primary: {
                    main: color_fontToolbar ? color_fontToolbar : '#000000'
                }
            }
        })
        let image = obj.image
        return (
            <div id="sacola" style={color_body ? {background: color_body} : {}}>
                <AppBar
                    id="appBar"
                    position="sticky"
                    color="default"
                    style={color_toolbar ? {
                        background: color_toolbar,
                        borderBottomRightRadius: openFlavorAssistant ? 8 : 0,
                        borderBottomLeftRadius: openFlavorAssistant ? 8 : 0
                    } : {
                        borderBottomRightRadius: openFlavorAssistant ? 8 : 0,
                        borderBottomLeftRadius: openFlavorAssistant ? 8 : 0
                    }}>
                    <Toolbar variant="dense">
                        <div id="toolbar">
                            <div id="toolbar-left" style={this.state.openSearch ? {width: '10%'} : {width: '40%'}}>
                                <ArrowBackIcon style={color_fontToolbar ? {color: color_fontToolbar} : {}}
                                               id="voltar-sacola"
                                               onClick={this.onclickVoltar}/>
                                {
                                    !(isMobile && this.state.openSearch) &&
                                    <Typography variant="h6" color="inherit" id={'label-title'}
                                                style={color_fontToolbar ? {color: color_fontToolbar} : {}}>
                                        Sacola
                                    </Typography>
                                }
                            </div>
                            <div id="toolbar-right" style={this.state.openSearch ? {width: '90%'} : {width: '60%'}}>
                                {
                                    this.state.openSearch &&
                                    <div id="div-search-sacola">
                                        <CloseIcon
                                            id="icon-search-sacola"
                                            onClick={this.onClickCloseSearch}/>
                                        <MuiThemeProvider theme={theme2}>
                                            <Input
                                                name="search"
                                                id="search"
                                                autoFocus={true}
                                                fullWidth={true}
                                                onChange={this.handleInput}
                                                placeholder="Buscar"/>
                                        </MuiThemeProvider>
                                        <div id="div-arrow-search">
                                            <ExpandLess
                                                id="expand-search-sacola"
                                                onClick={() => this.onClickExpand(true)}
                                            />
                                            <ExpandMore
                                                id="expand-search-sacola"
                                                onClick={() => this.onClickExpand(false)}
                                            />
                                        </div>
                                    </div>
                                }
                                <SearchIcon style={color_fontToolbar ? {color: color_fontToolbar} : {}}
                                            id="search-sacola"
                                            onClick={this.onClickSerach}/>
                                {
                                    (!this.state.openSearch && showSearchFlavor) &&
                                    <FormLabel
                                        id={'label-buscar'}
                                        onClick={this.onClickSerach}
                                        style={{color: color_fontToolbar}}>
                                        Buscar sabores
                                    </FormLabel>
                                }
                            </div>
                        </div>
                    </Toolbar>
                    {
                        openFlavorAssistant &&
                        <div id="flavor-assistant"
                             style={visibility ? {background: color_cardProduct ? color_cardProduct : '#FFFFFF'} : {
                                 background: color_cardProduct ? color_cardProduct : '#FFFFFF'
                             }}>
                            <div id="div-flavor-assistant">
                                {
                                    visibility ?
                                        <div id="div-flavor">
                                            {
                                                flavors.map((i, index) => (
                                                    <div style={{margin: 6}} key={index} id="div-flavor-item">
                                                        {
                                                            i.flavors.map((a, index) => (
                                                                <div key={index} id={'div-flavor-item-delete'} style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    alignItems: "center",
                                                                    margin: 3
                                                                }}>
                                                                    <div id="div-list-flavors">
                                                                        <Delete style={{
                                                                            color: color_title,
                                                                            width: 30,
                                                                            height: 30,
                                                                            marginRight: 10
                                                                        }} onClick={() => this.deleteFlavor(a)}/>
                                                                        <FormLabel id="label-description-flavor"
                                                                                   style={{
                                                                                       color: color_title,
                                                                                       fontSize: 16
                                                                                   }}>
                                                                            {`${a.amount}x ${a.item}`}
                                                                        </FormLabel>
                                                                        {
                                                                            a.price !== 0 &&
                                                                            <FormLabel
                                                                                id="label-price-flavor"
                                                                                style={color_price ? {
                                                                                    color: color_price,
                                                                                    fontSize: 16
                                                                                } : {}}>{a.price.toLocaleString('pt-BR', {
                                                                                style: 'currency',
                                                                                currency: 'BRL'
                                                                            })}</FormLabel>
                                                                        }
                                                                    </div>

                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        :
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: '100%'
                                        }}>
                                            <FormLabel style={color_description ? {
                                                color: color_description,
                                                fontSize: 14,
                                                marginTop: 10
                                            } : {fontSize: 14, margin: 2}}>
                                                Lista de sabores selecionados
                                            </FormLabel>
                                        </div>
                                }
                            </div>
                            {/*<div id="div-visibility">*/}
                            {/*    {*/}
                            {/*        !visibility*/}
                            {/*            ?*/}
                            {/*            <Visibility style={color_title ? {color: color_title} : {}}*/}
                            {/*                        id="icone-visibility" onClick={() => this.visibility(0)}/>*/}
                            {/*            :*/}
                            {/*            <VisibilityOff style={color_title ? {color: color_title} : {}}*/}
                            {/*                           id="icone-visibility" onClick={() => this.visibility(1)}/>*/}
                            {/*    }*/}
                            {/*</div>*/}
                        </div>
                    }
                </AppBar>

                <div id="container-sacola"
                     style={color_body ? {
                             background: color_body,
                             marginBottom: !image ? 700 : 150
                         } :
                         {
                             marginBottom: !image ? 700 : 150
                         }
                     }>
                    {
                        image &&
                        <div id="div-img-sacola">
                            <CardMedia image={image} id="img-sacola"/>
                        </div>
                    }
                    <Divider/>

                    <div id="descritivo-produto">
                        <div id="div-name-favorite">
                            <FormLabel id="produto-sacola"
                                       style={color_title ? {color: color_title} : {}}>{obj.product}</FormLabel>
                            {
                                favoritesSku.includes(obj.sku)
                                    ?
                                    <Favorite id="favorite" style={{color: '#f44336'}}
                                              onClick={() => this.onClickFavorite(obj, false)}/>
                                    :
                                    <FavoriteBorder id="favorite" style={{color: '#9e9e9e'}}
                                                    onClick={() => this.onClickFavorite(obj, true)}/>
                            }
                            <Share id="share" style={{color: '#9e9e9e'}} onClick={() => this.setState({
                                openShare: true,
                                skuShare: obj.sku
                            })}/>
                        </div>
                        {obj.description.split('\n').map((d) => (
                            <FormLabel
                                id="descricao-sacola" style={color_description ? {color: color_description} : {}}>
                                {d}
                            </FormLabel>
                        ))}
                        {
                            (obj.cashback !== 0 && obj.cashback !== undefined) &&
                            <FormLabel id="descricao-sacola"
                                       style={color_description ? {
                                           color: color_description,
                                           fontSize: 14
                                       } : {}}>Cashback: {obj.cashback.toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL'
                            })}</FormLabel>
                        }

                        {
                            obj.oldPrice !== 0 &&
                            <FormLabel id="preco-antigo">{obj.oldPrice.toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL'
                            })}</FormLabel>
                        }
                        {
                            obj.price !== 0 &&
                            <FormLabel id="preco"
                                       style={color_price ? {color: color_price} : {}}>{obj.price.toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL'
                            })}</FormLabel>
                        }
                    </div>

                    <div id="variacoes">
                        {(() => {
                            if (obj.variations !== undefined) {
                                return (
                                    // eslint-disable-next-line array-callback-return
                                    obj.variations.map((i, index) => {
                                        let {
                                            key,
                                            maxSelection,
                                            minSelection,
                                            options,
                                            visibility,
                                            step,
                                            filterFlavor
                                        } = i

                                        if (visibility !== undefined && !visibility.includes('DELIVERY')) {
                                            return (<div/>)
                                        }

                                        let obrigatorio
                                        if (minSelection === 1 && maxSelection === 1 && options.length === 1) {
                                            let s = options[0]
                                            s = {
                                                sku: s.sku,
                                                titulo: s.option,
                                                quantidade: 1,
                                                precoProduto: s.price,
                                                preco: (1 * s.price),
                                                etapa: step
                                            }
                                            subItensObrigatorios.push(s)
                                            options = []
                                            obrigatorio = false
                                        } else if (minSelection >= 1) {
                                            obrigatorios.push({
                                                obg: `${key}`,
                                                min: minSelection
                                            })
                                            obrigatorio = true
                                        } else {
                                            obrigatorio = false
                                        }

                                        if (options.length !== 0) {
                                            if (maxSelection === 1 && minSelection === 1) {
                                                return (<RadioButton key={index} options={options} variations={i}
                                                                     colors={this.state.colorStore}
                                                                     handleChange={this.handleClickRadio.bind(this)}/>)
                                            } else if (options[0].sku.includes('O')) {
                                                return (<CheckBox key={index} options={options} variations={i}
                                                                  colors={this.state.colorStore}
                                                                  handleChange={this.handleClick.bind(this)}/>)
                                            } else {
                                                return (<ButtonAdd
                                                    key={index}
                                                    options={options}
                                                    variations={i}
                                                    sku_delete={sku_delete}
                                                    filterFlavor={filterFlavor}
                                                    colors={this.state.colorStore}
                                                    obrigatorio={obrigatorio}
                                                    itens={this.state.variations}
                                                    handleChange={this.handleClick.bind(this)}/>)
                                            }
                                        }
                                    })
                                )
                            }
                        })()}
                    </div>

                    {
                        visibleObs &&
                        <Card className="card-carinho" id="observation"
                              style={color_cardProduct ? {background: color_cardProduct} : {}}>
                            <div id="observation-input"/>
                            <div id="titulo-carrinho" style={{paddingBottom: 1}}>
                                <FormLabel id="card-titulo-carrinho"
                                           style={color_title ? {color: color_title} : {}}>Observação</FormLabel>
                            </div>
                            <div id="div-pay-chose" style={{paddingTop: 1}}>
                                <MuiThemeProvider theme={theme}>
                                    <Input name="observationOrder" value={this.state.observationOrder}
                                           color="primary"
                                           style={color_title ? {
                                               background: color_cardProduct,
                                               color: color_title
                                           } : {}}
                                           fullWidth={true} placeholder="Ex: Sem Cebola"
                                           onChange={this.handleInput}/>
                                </MuiThemeProvider>
                            </div>
                        </Card>
                    }
                </div>

                <div id="footer" style={color_footer ? {background: color_footer} : {}} onClick={this.toggleChildMenu}>
                    {
                        (fracionado && allowFractioned) ?
                            <div id="botoes-footer">
                                <div onClick={this.buttonMenosKg}>
                                    <img src={peso === 0.1 ? MenosDisable : Menos}
                                         id="btn-footer-fracionado"
                                         alt=""/>
                                </div>
                                <div id="div-fracionado">
                                    <MuiThemeProvider theme={theme}>
                                        <Input name="peso" id="etKg" color="primary" fullWidth={true} value={peso}
                                               type="number" step=".01"
                                               onChange={this.handleInputKg} placeholder="Peso"/>
                                    </MuiThemeProvider>
                                </div>
                                <div onClick={this.buttonMaisKg} style={{cursor: "pointer"}}>
                                    <img src={Mais} id="btn-footer-fracionado" alt=""/>
                                </div>
                                <div style={{margin: 4}}>
                                    <FormLabel id="label-kg"
                                               style={color_title ? {color: color_title} : {color: '#1e1e1e'}}>Kg</FormLabel>
                                </div>
                            </div>
                            :
                            <div id="botoes-footer" style={{background: '#FFFFFF', cursor: "pointer"}}>
                                <div onClick={() => this.buttonMenos()}>
                                    <img src={this.state.quantidade === 1 ? MenosDisable : Menos} id="btn-menos-footer"
                                         alt=""/>
                                </div>
                                <FormLabel id="quantidade-footer"
                                           onClick={() => this.setState({openAmount: true})}>{(this.state.quantidade === '') ? 1 : this.state.quantidade}</FormLabel>
                                <div onClick={() => this.buttonMais()} style={{cursor: "pointer"}}>
                                    <img src={Mais} id="btn-mais-footer" alt=""/>
                                </div>
                            </div>
                    }
                    <div id="adicionar">
                        <Card
                            id="btn-adicionar"
                            onClick={() => this.buttonAdicionar()}
                            style={this.state.obrigatorio ? {backgroundColor: 'darkgray'} : {backgroundColor: color_nextButton ? color_nextButton : '#000000'}}>
                            <FormLabel id="adicionar-sacola"
                                       style={this.state.obrigatorio ? {color: '#ffffff'} : {color: color_fontButton}}>Adicionar</FormLabel>
                            {
                                (fracionado && allowFractioned) ?
                                    <FormLabel
                                        id="total-sacola"
                                        style={this.state.obrigatorio ? {color: '#ffffff'} : {color: color_fontButton}}>
                                        {(valor * (peso !== '' ? peso : 0)).toLocaleString('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL'
                                        })}
                                    </FormLabel>
                                    :
                                    <FormLabel
                                        id="total-sacola"
                                        style={this.state.obrigatorio ? {color: '#ffffff'} : {color: color_fontButton}}>
                                        {((((typeproduct === 'C') ? valorCombo : (valor + firstStepValue))) * ((quantidade === '') ? 1 : quantidade)).toLocaleString('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL'
                                        })}
                                    </FormLabel>
                            }
                        </Card>
                    </div>
                </div>
                <Dialog open={openImage} onClose={() => this.setState({openImage: false})}>
                    <CardMedia id="open-image-flavor" image={imageFlavor}/>
                </Dialog>
                <Dialog open={openShare} onClose={() => this.setState({openShare: false})}>
                    <DialogTitle>Compartilhar</DialogTitle>
                    <DialogContent id="dialog-content-share">
                        <WhatsApp id="share-whats" onClick={() => this.onClickShare(true)}/>
                        <Facebook id="share-face" onClick={() => this.onClickShare(false)}/>
                    </DialogContent>
                </Dialog>
                <MuiThemeProvider theme={themeDialog}>
                    <Dialog open={openAmount} onClose={() => this.onCloseAmount()}>
                        <DialogTitle>Quantidade</DialogTitle>
                        <DialogContent id={'dialog-content-select-amount'}>
                            <Box id={'box-dialog-amount'}>
                                <Button variant={'outlined'} onClick={() => this.onClickAddAmount(-1, false)}>
                                    <Remove/>
                                </Button>
                                <Box p={1}/>
                                <TextField
                                    id={'text-field-dialog-amount'}
                                    variant={'outlined'}
                                    placeholder={'1'}
                                    type={'number'}
                                    margin='dense'
                                    name='quantidade'
                                    value={this.state.quantidade}
                                    onChange={this.handleInputAmount}/>
                                <Box p={1}/>
                                <Button variant={'outlined'} onClick={() => this.onClickAddAmount(1, false)}>
                                    <Add/>
                                </Button>
                            </Box>
                            <Box p={1}/>
                            <Box id={'div-dialog-amount'}
                                 style={(qtdeMax >= 10) ? {justifyContent: 'space-between'} : {justifyContent: 'center'}}>
                                {
                                    (qtdeMax >= 5) &&
                                    <Button
                                        id={'button-dialog-amount'}
                                        variant={'outlined'}
                                        onClick={() => this.onClickAddAmount(5)}
                                        color="primary">
                                        + 5
                                    </Button>
                                }
                                {
                                    (qtdeMax >= 10) &&
                                    <Box p={1}/>
                                }
                                {
                                    (qtdeMax >= 10) &&
                                    <Button
                                        id={'button-dialog-amount'}
                                        variant={'outlined'}
                                        onClick={() => this.onClickAddAmount(10)}
                                        color="primary">
                                        + 10
                                    </Button>
                                }
                            </Box>
                            {
                                (qtdeMax >= 25) &&
                                <>
                                    <Box p={1}/>
                                    <div id={'div-dialog-amount'}
                                         style={(qtdeMax >= 50) ? {justifyContent: 'space-between'} : {justifyContent: 'center'}}>
                                        {
                                            (qtdeMax >= 25) &&
                                            <Button
                                                id={'button-dialog-amount'}
                                                variant={'outlined'}
                                                onClick={() => this.onClickAddAmount(25)}
                                                color="primary">
                                                + 25
                                            </Button>
                                        }
                                        {
                                            (qtdeMax >= 50) &&
                                            <Box p={1}/>
                                        }
                                        {
                                            (qtdeMax >= 50) &&
                                            <Button
                                                id={'button-dialog-amount'}
                                                variant={'outlined'}
                                                onClick={() => this.onClickAddAmount(50)}
                                                color="primary">
                                                + 50
                                            </Button>
                                        }
                                    </div>
                                </>
                            }
                        </DialogContent>
                        <DialogActions style={{paddingRight: 24, paddingBottom: 10}}>
                            <Button
                                id={'button-dialog'}
                                variant={'contained'}
                                onClick={() => this.onCloseAmount()}
                                color="primary">
                                Fechar
                            </Button>
                        </DialogActions>
                    </Dialog>
                </MuiThemeProvider>
                <Snackbar
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    open={this.state.snackbar.open}
                    autoHideDuration={1000}
                    onClose={this.handleClose}
                    message={this.state.snackbar.message}
                />
            </div>
        )
    }
}

export default withRouter(Sacola)
