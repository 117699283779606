import React from 'react'
import {withRouter} from 'react-router-dom'
import {Card, CardContent, CardMedia, FormLabel} from '@material-ui/core'
import {isMobile} from 'react-device-detect'
import './Produtos.css'
import {eventGA4, IDENTIFIER_STORE} from '../../util'

let identifier

class Product extends React.Component {

    state = {
        favoritesSku: []
    }

    onClickCard = () => {
        let positionPage = JSON.stringify(window.scrollY)
        sessionStorage.setItem(`bitmenu-${identifier}:positionPage`, positionPage)
        this.props.history.push({pathname: '/sacola', state: {dados: this.props.data}})
        try {
            let items = [{
                item_id: this.props.data.sku,
                item_name: this.props.data.product
            }]
            eventGA4('select_item', {items: items})
        } catch (e) {

        }
    }

    onClickShare = sku => {
        this.props.handleChange({type: 'share', object: sku})
    }

    clearText = text => {
        text = text.normalize("NFD").replace(/[^A-Z0-9]/ig, "")
        text = text.trim()
        return text.toLowerCase()
    }

    onClickFavorite = (object, add) => {
        let favorites = localStorage.getItem(`bitmenu-${identifier}:favorites`)
        favorites = favorites !== null ? JSON.parse(favorites) : []

        let favoritesSku = localStorage.getItem(`bitmenu-${identifier}:favoritesSku`)
        favoritesSku = favoritesSku !== null ? JSON.parse(favoritesSku) : []

        if (add) {
            favoritesSku.push(object.sku)
            object.favorites = true
            favorites.push(object)
        } else {
            let index
            for (let i = 0; favorites.length; i++) {
                if (favorites[i].sku === object.sku) {
                    index = i
                    break
                }
            }
            if (index !== undefined) {
                favorites.splice(index, 1)
            }
            favoritesSku.splice(favoritesSku.indexOf(object.sku), 1)
        }

        localStorage.setItem(`bitmenu-${identifier}:favorites`, JSON.stringify(favorites))
        localStorage.setItem(`bitmenu-${identifier}:favoritesSku`, JSON.stringify(favoritesSku))

        this.setState({favoritesSku: favoritesSku})
        this.props.handleChange({type: 'favorite', object: favorites})
    }

    favorites = () => {
        let favoritesSku = localStorage.getItem(`bitmenu-${identifier}:favoritesSku`)
        favoritesSku = favoritesSku !== null ? JSON.parse(favoritesSku) : []
        this.setState({favoritesSku: favoritesSku})
    }

    componentDidMount() {
        identifier = IDENTIFIER_STORE
        this.favorites()
    }

    render() {
        let {color_title, color_description, color_price, color_cardProduct} = this.props.colors
        let {
            product,
            description,
            price,
            prices,
            image,
            oldPrice,
            variations,
            visibleStepOne,
            cashback
        } = this.props.data
        //image = image.replace('http', 'https')
        image = `${image}?time=${sessionStorage.getItem('bitmenu:dataImage')}`
        visibleStepOne = visibleStepOne !== undefined ? visibleStepOne : true
        let variousPrices = false
        try {
            if (variations !== undefined && variations.length !== 0) {
                if ((variations[0].visibility !== undefined && variations[0].visibility.includes('DELIVERY')) || (variations[0].options[0]).sku.includes('S')) {
                    if (prices !== undefined && prices.length > 0) {
                        variousPrices = true
                    }
                }
            }
        } catch (e) {
            console.log(e)
        }

        try {
            if (description.includes('<div>')) {
                description = description.split('<div>')
                description = description[0]
            }
        } catch (e) {
            console.log(e)
        }

        return (
            <Card id={`_${this.clearText(product)}`} style={color_cardProduct ? {background: color_cardProduct} : {}}
                  className="card-product">
                <CardContent id="card-descriptions" style={!image ? {
                    width: '100%',
                    background: color_cardProduct ? color_cardProduct : '#FFFFFF'
                } : {background: color_cardProduct ? color_cardProduct : '#FFFFFF'}}>
                    <div id="div-description-image" onClick={this.onClickCard}>
                        <div id="div-description">
                            <FormLabel id="produto-home"
                                       style={color_title ? {color: color_title} : {}}>{product}</FormLabel>
                            {
                                isMobile
                                    ?
                                    <FormLabel id="descricao"
                                               style={color_description ? {
                                                   color: color_description,
                                                   width: image ? 207 : 250
                                               } : {
                                                   width: image ? 207 : 250
                                               }}>{description}</FormLabel>
                                    :

                                    <FormLabel id="descricao"
                                               style={color_description ? {color: color_description} : {}}>{description}</FormLabel>
                            }
                            {
                                (cashback !== 0 && cashback !== undefined) &&
                                <FormLabel id="price"
                                           style={color_description ? {
                                               color: color_description,
                                               fontSize: 14
                                           } : {}}>Cashback: {cashback.toLocaleString('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL'
                                })}
                                </FormLabel>
                            }
                            {
                                variousPrices && visibleStepOne
                                    ?
                                    prices.map((i, index) => {
                                        return (
                                            <FormLabel key={index}
                                                       id="price"
                                                       style={color_price ? {color: color_price} : {}}>{`${i.description} ${i.price.toLocaleString('pt-BR', {
                                                style: 'currency',
                                                currency: 'BRL'
                                            })}`}</FormLabel>
                                        )
                                    })
                                    :
                                    <div>
                                        {
                                            (price !== 0)
                                                ?
                                                <FormLabel
                                                    id="price"
                                                    style={color_price ? {color: color_price} : {}}>
                                                    {price.toLocaleString('pt-BR', {
                                                        style: 'currency',
                                                        currency: 'BRL'
                                                    })}
                                                </FormLabel>
                                                :
                                                <FormLabel
                                                    id="price"
                                                    style={color_price ? {color: color_price} : {}}>
                                                    R$ ***
                                                </FormLabel>
                                        }
                                    </div>
                            }
                            {
                                oldPrice !== 0 &&
                                <FormLabel id="old-price">{oldPrice.toLocaleString('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL'
                                })}</FormLabel>
                            }
                        </div>
                        <div id="div-image">
                            {
                                (image) &&
                                <CardContent id="card-image">
                                    {/*<div id="image-product">*/}
                                    {/*    <div id="image-product">*/}
                                    {/*        <LazyLoad offset={500}>*/}
                                                <CardMedia image={image} id="image-product"/>
                                            {/*</LazyLoad>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </CardContent>
                            }
                        </div>
                    </div>
                    {/*<div id="buttons-products">*/}
                    {/*    {*/}
                    {/*        favoritesSku.includes(sku)*/}
                    {/*            ?*/}
                    {/*            <Favorite id="favorite" style={{color: '#f44336'}}*/}
                    {/*                      onClick={() => this.onClickFavorite(this.props.data, false)}/>*/}
                    {/*            :*/}
                    {/*            <FavoriteBorder id="favorite" style={{color: '#9e9e9e'}}*/}
                    {/*                            onClick={() => this.onClickFavorite(this.props.data, true)}/>*/}
                    {/*    }*/}
                    {/*    <Share id="share" style={{color: '#9e9e9e'}} onClick={() => this.onClickShare(sku)}/>*/}
                    {/*</div>*/}
                </CardContent>
            </Card>
        )
    }
}

export default withRouter(Product)
