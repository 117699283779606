import React from "react"
import {withRouter} from 'react-router-dom'
import {
    AppBar,
    Button,
    Dialog,
    DialogActions, DialogContent,
    DialogTitle,
    Divider,
    FormLabel,
    Toolbar,
    Typography
} from '@material-ui/core'
import {ArrowBack} from '@material-ui/icons'
import './Historico.css'
import ListaPedidos from '../../components/listapedidos/ListaPedidos'
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles'
import {IDENTIFIER_STORE, pageView} from '../../util'

let identifier

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#000000',
        }
    },
})

class Historico extends React.Component {

    state = {
        orderHistory: [],
        openOrder: false,
        order: {
            dataHora: '',
            id_pedido: '',
            entrega: '',
            desconto: 0,
            gularis_desconto: 0,
            buscar: false,
            itens: [],
            total_produtos: 0,
            pagamentos: [{
                titulo: '',
                valor: '',
            }],
            cliente: {
                endereco_rua: '',
                endereco_numero: '',
                endereco_bairro: '',
                endereco_complemento: ''
            },
            retorno: {
                mensagem: '',
                status: ''
            }
        },
        colorStore: {
            color_title: '',
            color_description: '',
            color_price: '',
            color_toolbar: '',
            color_body: '',
            color_cardProduct: '',
            color_fontToolbar: ''
        }
    }

    handleClick = order => {
        const {retorno: {status}} = order
        this.setState({openOrder: (status !== 'ERRO' && status !== 'CANCELADO' && status !== 'PIX'), order: order})
    }

    onclickVoltar = () => this.props.history.goBack()

    checkOrder = () => {
        try {
            let orderHistory = localStorage.getItem(`bitmenu-${identifier}:orderHistory`)
            orderHistory = orderHistory !== null ? JSON.parse(orderHistory) : []
            let array = []
            orderHistory.forEach(i => {
                let achou = false
                array.forEach(a => achou = (i.id_pedido === a.id_pedido))
                if (!achou) array.push(i)
            })
            localStorage.setItem(`bitmenu-${identifier}:orderHistory`, JSON.stringify(array))
        } catch (e) {

        }
    }

    history = () => {
        try {
            let restaurant = localStorage.getItem(`bitmenu-${identifier}:restaurantAddress`)
            let orderHistory = localStorage.getItem(`bitmenu-${identifier}:orderHistory`)
            orderHistory = orderHistory !== null ? JSON.parse(orderHistory) : []
            this.idOrder(orderHistory.reverse())
            orderHistory.sort((a, b) => {
                const nameA = new Date(a.dataHora).getTime()
                const nameB = new Date(b.dataHora).getTime()
                if (nameA < nameB) {
                    return 1
                }
                if (nameA > nameB) {
                    return -1
                }
                return 0
            })
            this.setState({orderHistory: orderHistory, restaurant: restaurant})
        } catch (e) {

        }
    }

    formatDate = date => {
        if (date === '') return
        date = date.split(' ')
        let year = date[0]
        year = year.split('-')
        year = `${year[2]}/${year[1]}/${year[0]}`
        let hours = date[1]
        hours = hours.split(':')
        hours = `${hours[0]}:${hours[1]}`
        return `${hours} ${year}`
    }

    calculateSubTotal = () => {
        let {order: {itens}} = this.state
        let total = 0
        try {
            if (itens.length > 0) {
                itens.forEach(j => {
                    let valorSubitens = 0
                    let combo = false
                    let maxValue = 0
                    if (j.subItens !== undefined) {
                        j.subItens.forEach(s => {
                            if (s.soma === 'MAXVALUE') {
                                maxValue = s.precoProduto > maxValue ? s.precoProduto : maxValue
                            } else {
                                valorSubitens += (s.precoProduto * s.quantidade)
                            }
                            combo = s.sku.includes("C")
                        });
                    }
                    let precoProduto = combo ? 0 : j.precoProduto
                    total += ((precoProduto + valorSubitens + maxValue) * j.quantidade)
                })
            }
        } catch (e) {
            console.log(e)
        }
        return total.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        })
    }

    personalize = () => {
        try {
            let colors = JSON.parse(localStorage.getItem(`bitmenu-${identifier}:colorStore`))
            const {title, description, price, toolbar, body, cardProduct, nextButton, fontButton, fontToolbar} = colors
            this.setState({
                colorStore: {
                    color_title: `#${title}`,
                    color_description: `#${description}`,
                    color_price: `#${price}`,
                    color_toolbar: `#${toolbar}`,
                    color_body: `#${body}`,
                    color_cardProduct: `#${cardProduct}`,
                    color_nextButton: `#${nextButton}`,
                    color_fontButton: `#${fontButton}`,
                    color_fontToolbar: `#${fontToolbar}`
                }
            })
        } catch (e) {

        }
    }

    idOrder = orderHistory => {
        try {
            let idOrder = this.props.location.state.idOrder
            if (idOrder !== undefined) {
                orderHistory.forEach(i => {
                    if (i.id_pedido === idOrder) this.setState({openOrder: true, order: i})
                })
            }
        } catch (e) {

        }
    }

    restaurant = (id_loja, restaurant) => {
        try {
            let stores = localStorage.getItem(`bitmenu-${identifier}:storesLocations`)
            stores = (stores !== null) ? JSON.parse(stores) : false
            if (stores) {
                for (let i = 0; i < stores.length; i++)
                    if (stores[i].id_loja === id_loja)
                        return stores[i].address
            } else {
                return restaurant
            }
        } catch (e) {
            return 'Retira do no local'
        }
    }

    orderId = orderId => {
        try {
            return `#${orderId.substring(orderId.length - 4)}`
        } catch (e) {

        }
        return `Nº #${orderId}`
    }

    componentDidMount() {
        identifier = IDENTIFIER_STORE
        this.checkOrder()
        this.history()
        this.personalize()
        window.scrollTo(0, 0)
        pageView()
    }

    render() {
        const {
            orderHistory,
            openOrder,
            restaurant,
            order: {
                id_loja,
                id_pedido,
                desconto,
                buscar,
                gularis_desconto,
                entrega,
                dataHora,
                itens,
                pedidoPorRota,
                pagamentos,
                total_produtos,
                cliente: {
                    cliente_nome,
                    endereco_cidade,
                    endereco_rua,
                    endereco_numero,
                    endereco_bairro,
                    endereco_complemento
                },
                retorno: {
                    mensagem,
                    status
                }
            },
            colorStore: {
                color_toolbar,
                color_body,
                color_fontToolbar
            }
        } = this.state
        return (
            <div id="historico" style={color_body ? {background: color_body} : {}}>
                <AppBar
                    id="appBar"
                    position="sticky"
                    style={color_toolbar ? {background: color_toolbar} : {}}
                    color="default">
                    <Toolbar variant="dense">
                        <div id="toolbar-profile">
                            <div id="toolbar-left">
                                <ArrowBack style={color_fontToolbar ? {color: color_fontToolbar} : {}}
                                           id="voltar-sacola"
                                           onClick={this.onclickVoltar}/>
                                <Typography
                                    id={'label-title'}
                                    style={color_fontToolbar ? {color: color_fontToolbar} : {}}
                                    variant="h6" color="inherit">Pedidos</Typography>
                            </div>
                        </div>
                    </Toolbar>
                </AppBar>

                <div id="container-historico">
                    <div id="main-historico">
                        {
                            orderHistory.map((i, index) => {
                                return (
                                    <div id="div-order-history" key={index}>
                                        <ListaPedidos order={i} colors={this.state.colorStore}
                                                      handleChange={this.handleClick.bind(this)}/>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <MuiThemeProvider theme={theme}>
                    <Dialog open={openOrder} onClose={() => this.setState({openOrder: false})}
                            aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Detalhes Pedido</DialogTitle>
                        <DialogContent>
                            <div id="div-details-order">
                                <FormLabel id="details-title-order">Pedido</FormLabel>
                                {
                                    itens.map((i, index) => {
                                        const {quantidade, titulo, precoProduto, subItens} = i
                                        return (
                                            <div key={index}>
                                                <div id="details-order">
                                                    <div>
                                                        <FormLabel
                                                            id="details-text-item-order">{`${quantidade} x ${titulo}`}</FormLabel>
                                                    </div>
                                                    <div>
                                                        <FormLabel
                                                            id="details-text-item-order"> {precoProduto.toLocaleString('pt-BR', {
                                                            style: 'currency',
                                                            currency: 'BRL'
                                                        })} </FormLabel>
                                                    </div>
                                                </div>
                                                {
                                                    subItens !== undefined &&
                                                    subItens.map((i, index) => {
                                                        return (
                                                            <div id="details-order" key={index}>
                                                                <div>
                                                                    <FormLabel
                                                                        id="details-text-subitem-order">{i.sku.includes('O') ? `${i.titulo}` : `${i.quantidade + ' x'} ${i.titulo}`}</FormLabel>
                                                                </div>
                                                                <div>
                                                                    {
                                                                        i.precoProduto !== 0 &&
                                                                        <FormLabel
                                                                            id="details-text-subitem-order"> {i.precoProduto.toLocaleString('pt-BR', {
                                                                            style: 'currency',
                                                                            currency: 'BRL'
                                                                        })} </FormLabel>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <Divider/>
                            <div id="div-details-order">
                                <div id="details-order">
                                    <FormLabel id="details-text-item-order">{`SubTotal`}</FormLabel>
                                    <FormLabel id="details-text-item-order">{total_produtos.toLocaleString('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL'
                                    })}</FormLabel>
                                </div>

                                {
                                    (gularis_desconto !== 0 && gularis_desconto !== undefined) &&
                                    <div id="details-order">
                                        <FormLabel id="details-text-subitem-order">{`Cashback`}</FormLabel>
                                        <FormLabel
                                            id="details-text-subitem-order">{`- ${gularis_desconto.toLocaleString('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL'
                                        })}`}</FormLabel>
                                    </div>
                                }

                                {
                                    !buscar &&
                                    <div id="details-order">
                                        <FormLabel id="details-text-subitem-order">{`Taxa de entrega`}</FormLabel>
                                        <FormLabel id="details-text-subitem-order">{entrega.toLocaleString('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL'
                                        })}</FormLabel>
                                    </div>
                                }
                                {
                                    desconto !== 0 &&
                                    <div id="details-order">
                                        <FormLabel id="details-text-subitem-order">{`Desconto`}</FormLabel>
                                        <FormLabel
                                            id="details-text-subitem-order">{`- ${desconto.toLocaleString('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL'
                                        })}`}</FormLabel>
                                    </div>
                                }
                                {
                                    pagamentos !== undefined &&
                                    <div id="details-order">
                                        <FormLabel id="details-text-item-order">{`Total`}</FormLabel>
                                        <FormLabel
                                            id="details-text-item-order">{pagamentos[0].valor.toLocaleString('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL'
                                        })}</FormLabel>
                                    </div>
                                }
                            </div>
                            <Divider/>
                            <div id="div-details-order">
                                <FormLabel id="details-title-order">{buscar ? `Retirada` : `Entrega`}</FormLabel>
                                <div id="details-order">
                                    {
                                        buscar
                                            ? <FormLabel
                                                id="details-text-item-order">{this.restaurant(id_loja, restaurant)}</FormLabel>
                                            : <FormLabel
                                                id="details-text-item-order">{`${endereco_rua}, ${endereco_numero} - ${endereco_bairro}, ${endereco_complemento}`}</FormLabel>
                                    }
                                </div>
                            </div>
                            <Divider/>
                            {
                                (pedidoPorRota) &&
                                <div id="div-details-order">
                                    <FormLabel id="details-title-order">Pedido programado</FormLabel>
                                    <div id="details-order">
                                        <FormLabel id="details-text-item-order">
                                            {`Entrega do pedido ${this.formatDate(dataHora)}`}
                                        </FormLabel>
                                    </div>
                                </div>
                            }
                            {
                                (pedidoPorRota) &&
                                <Divider/>
                            }
                            <div id="div-details-order">
                                <FormLabel id="details-title-order">Mais informações</FormLabel>

                                <div id="details-order">
                                    <FormLabel id="details-text-subitem-order">{`Nome`}</FormLabel>
                                    <FormLabel id="details-text-subitem-order">{cliente_nome}</FormLabel>
                                </div>

                                <div id="details-order">
                                    <FormLabel id="details-text-subitem-order">{`Cidade`}</FormLabel>
                                    <FormLabel id="details-text-subitem-order">{endereco_cidade}</FormLabel>
                                </div>

                                <div id="details-order">
                                    <FormLabel id="details-text-subitem-order">{`Nº do pedido`}</FormLabel>
                                    <FormLabel id="details-text-subitem-order">{this.orderId(id_pedido)}</FormLabel>
                                </div>

                                <div id="details-order">
                                    <FormLabel id="details-text-subitem-order">{`Data`}</FormLabel>
                                    <FormLabel id="details-text-subitem-order">{this.formatDate(dataHora)}</FormLabel>
                                </div>
                                {
                                    pagamentos !== undefined &&
                                    <div id="details-order">
                                        <FormLabel id="details-text-subitem-order">{`Pagamento`}</FormLabel>
                                        <FormLabel id="details-text-subitem-order">{pagamentos[0].titulo}</FormLabel>
                                    </div>
                                }
                            </div>
                            <div id="div-details-order">
                                <FormLabel id="details-title-order">Status do pedido</FormLabel>
                                <div id="details-order">
                                    <FormLabel id="details-text-subitem-order">{`Status`}</FormLabel>
                                    <FormLabel id="details-text-subitem-order">{status}</FormLabel>
                                </div>
                                <div id="details-order">
                                    <FormLabel id="details-text-subitem-order">{mensagem}</FormLabel>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                id={'button-dialog'}
                                variant={'contained'}
                                onClick={() => this.setState({openOrder: false})} color="primary">Fechar</Button>
                        </DialogActions>
                    </Dialog>
                </MuiThemeProvider>
            </div>
        )
    }
}

export default withRouter(Historico)
