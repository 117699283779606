import React from 'react'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import {useSelector} from 'react-redux'

import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga4'
import ReactPixel from 'react-facebook-pixel'

import Menu from './pages/menu/Menu'
import Sacola from './pages/sacola/Sacola'
import Carrinho from './pages/carrinho/Carrinho'
import Login from './pages/login/Login'
import Cadastro from './pages/cadastro/Cadastro'
import Cartao from './pages/cartao/Cartao'
import Horarios from './pages/horarios/Horarios'
import Enderecos from './pages/enderecos/Enderecos'
import Perfil from './pages/perfil/Perfil'
import Historico from './pages/historico/Historico'
import Perguntas from './pages/perguntas/Perguntas'
import Area from './pages/area/Area'
import Redefinir from './pages/redefinir/Redefinir'
import NotFound from './pages/notfound/NotFound'

import {isDebug} from './util'

const App = () => {

    const isServiceWorkerUpdated = useSelector(
        state => state.serviceWorkerUpdated,
    )

    const serviceWorkerRegistration = useSelector(
        state => state.serviceWorkerRegistration,
    )

    const updateServiceWorker = () => {
        const registrationWaiting = serviceWorkerRegistration.waiting;

        if (registrationWaiting) {
            registrationWaiting.postMessage({type: 'SKIP_WAITING'})

            registrationWaiting.addEventListener('statechange', e => {
                if (e.target.state === 'activated') {
                    window.location.reload()
                }
            })
        }
    }

    try {
        if (!isDebug())
            TagManager.initialize({gtmId: 'GTM-KJ83VLC'})
    } catch (e) {

    }

    try {
        if (isDebug()) {
            ReactGA.initialize('G-68JNZWW827')
        } else {
            ReactGA.initialize([{trackingId: ''}, {trackingId: ''}])
        }
    } catch (e) {

    }

    try {
        if (!isDebug())
            ReactPixel.init('')
    } catch (e) {

    }

    return (
        <BrowserRouter basename="/">
            {isServiceWorkerUpdated && updateServiceWorker()}
            <Switch>
                <Route exact path="/" component={Menu}/>
                <Route exact path="/horarios" component={Horarios}/>
                <Route exact path="/sacola" component={Sacola}/>
                <Route exact path="/carrinho" component={Carrinho}/>
                <Route exact path="/enderecos" component={Enderecos}/>
                <Route exact path="/perfil" component={Perfil}/>
                <Route exact path="/login" component={Login}/>
                <Route exact path="/cadastro" component={Cadastro}/>
                <Route exact path="/cartao" component={Cartao}/>
                <Route exact path="/historico" component={Historico}/>
                <Route exact path="/perguntas" component={Perguntas}/>
                <Route exact path="/area" component={Area}/>
                <Route exact path="/redefinir" component={Redefinir}/>
                <Route component={NotFound}/>
            </Switch>
        </BrowserRouter>
    )
}

export default App
