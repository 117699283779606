import Alelo from '../src/images/bandeiras/alelo.png'
import Amex from '../src/images/bandeiras/amex.png'
import Aura from '../src/images/bandeiras/aura.png'
import Cabal from '../src/images/bandeiras/cabal.png'
import City from '../src/images/bandeiras/city.png'
import Dinners from '../src/images/bandeiras/dinners.png'
import Discover from '../src/images/bandeiras/discover.png'
import Elo from '../src/images/bandeiras/elo.png'
import Good from '../src/images/bandeiras/good.png'
import Hiper from '../src/images/bandeiras/hiper.png'
import HiperCard from '../src/images/bandeiras/hipercard.png'
import JCB from '../src/images/bandeiras/jcb.png'
import Maestro from '../src/images/bandeiras/maestro.png'
import Master from '../src/images/bandeiras/master.png'
import Nutricash from '../src/images/bandeiras/nutricash.png'
import Policard from '../src/images/bandeiras/policard.png'
import Rede from '../src/images/bandeiras/rede.png'
import Sodexo from '../src/images/bandeiras/sodexo.png'
import Ticket from '../src/images/bandeiras/ticket.png'
import Visa from '../src/images/bandeiras/visa.png'
import VR from '../src/images/bandeiras/vr.png'

export default [Alelo, Amex, Aura, Cabal, City, Dinners, Discover, Elo, Good, Hiper, HiperCard, JCB, Maestro, Master, Nutricash, Policard, Rede, Sodexo, Ticket, Visa, VR]

