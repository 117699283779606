import React from 'react'
import {withRouter} from 'react-router-dom'
import {
    AppBar,
    Box,
    Card,
    CardContent,
    CardMedia,
    TextField,
    FormLabel,
    Toolbar,
    Typography,
    Snackbar,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Dialog
} from '@material-ui/core'
import {ArrowBack as ArrowBackIcon} from '@material-ui/icons'
import './Cartao.css'
import Chip from '../../images/chip.png'
import Bandeiras from '../../Bandeiras'
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles'
import {IDENTIFIER_STORE, pageView, rest, showData, URL_BASE} from '../../util'

let flags = ['Alelo', 'Amex', 'Aura', 'Cabal', 'City', 'Dinners', 'Discover', 'Elo', 'Good', 'Hiper', 'HiperCard', 'JCB', 'Maestro', 'Master', 'Nutricash', 'Policard', 'Rede', 'Sodexo', 'Ticket', 'Visa', 'VR']
let identifier

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#000000'
        }
    },
})

class Cartao extends React.Component {

    state = {
        flag: '',
        openSnackBar: false,
        openData: false,
        openTerm: false,
        validate: false,
        card: true,
        salve: false,
        numberCard: '',
        expiry: '',
        cvv: '',
        type: '',
        snackBar: '',
        name: '',
        colorStore: {
            color_title: '',
            color_description: '',
            color_price: '',
            color_toolbar: '',
            color_body: '',
            color_cardProduct: '',
            color_nextButton: '#1e1e1e',
            color_fontButton: '',
            color_fontToolbar: ''
        }
    }

    handleInputs = e => {
        if (e.target.name === 'numberCard') {
            this.validateCard(e.target.value)
        } else if (e.target.name === 'expiry') {
            this.maskValidateDate(e.target.value)
        } else if (e.target.name === 'name') {
            this.setState({[e.target.name]: e.target.value.toUpperCase()})
        } else if (e.target.name === 'ddd') {
            if (e.target.value.length < 4) {
                this.setState({[e.target.name]: e.target.value})
            }
        } else {
            this.setState({[e.target.name]: e.target.value})
        }
    }

    onRadio = type => this.setState({type: type})

    onClickBack = () => {
        this.props.history.goBack()
        try {
            sessionStorage.removeItem(`bitmenu-${identifier}:change`)
        } catch (e) {

        }
    }

    onClickPhone = () => {
        const {ddd, phone} = this.state
        if (ddd.length < 2) {
            this.setState({openSnackBar: true, snackBar: 'DDD inválido'})
            return
        } else if (phone.length < 8) {
            this.setState({openSnackBar: true, snackBar: 'Telefone inválido'})
            return
        }
        let client = localStorage.getItem(`bitmenu-${identifier}:cliente`)
        client = client !== null ? JSON.parse(client) : ''
        client.ddd = ddd
        client.telefone = phone
        localStorage.setItem(`bitmenu-${identifier}:cliente`, JSON.stringify(client))
        this.setState({openData: false})
    }

    onClickDeleteCard = () => {
        sessionStorage.removeItem(`bitmenu-${identifier}:card`)
        this.setState({
            maskNumber: '',
            numberCard: '',
            expiry: '',
            cvv: '',
            type: '',
            name: '',
            cpf: '',
            flags: '',
            card: true
        })
    }

    onClickSalve = () => {
        let {numberCard, expiry, type, name, cpf, validate, cvv} = this.state

        if ((numberCard === '' || numberCard === undefined) || numberCard.length < 12) {
            this.setState({openSnackBar: true, snackBar: 'Número do cartão inválido'})
            return
        } else if (expiry === '' || expiry.length !== 5) {
            this.setState({openSnackBar: true, snackBar: 'Data validade cartão inválido'})
            return
        } else if (name === '') {
            this.setState({openSnackBar: true, snackBar: 'Nome cartão inválido'})
            return
        } else if (cvv === '' || cvv.length < 3) {
            this.setState({openSnackBar: true, snackBar: 'CVV inválido'})
            return
        }

        if (!this.typesPaymentsAccepted(validate)) return this.setState({
            openSnackBar: true,
            snackBar: 'O estabelecimento não aceita essa bandeira de cartão'
        })

        expiry = expiry.split('/')
        let card = {
            numberCard: this.clearText(numberCard),
            cvv: cvv,
            month: expiry[0],
            year: expiry[1],
            type: type,
            name: name,
            cpf: cpf,
            flag: validate
        }
        card = JSON.stringify(card)
        sessionStorage.setItem(`bitmenu-${identifier}:card`, btoa(card))
        // const publicKeyPagSeguro = sessionStorage.getItem(`bitmenu:publicKeyPagSeguro`)
        // if (publicKeyPagSeguro !== null) {
        //     this.checksCard()
        //     this.encryptedCard(name, expiry, numberCard, cvv)
        // } else {
        this.onClickBack()
        //}
    }

    typesPaymentsAccepted = flag => {
        try {
            let paymentTypes = JSON.parse(localStorage.getItem(`bitmenu-${identifier}:paymentTypes`))
            for (let i = 0; paymentTypes.length; i++) {
                if ((paymentTypes[i].online === 'True') && paymentTypes[i].description.toUpperCase().includes(flag.toUpperCase())) {
                    return paymentTypes[i].code
                }
            }
        } catch (e) {
            return false
        }
    }

    validateCard = numberCard => {
        if (numberCard.length <= 19 && !isNaN(this.clearText(numberCard))) {
            this.setState({numberCard: this.maskNumberCard(numberCard), maskNumber: this.maskNumberCard(numberCard)})

            var cartoes = {
                Elo: /^6362|6363|4514|5041|4389\d{12}/,
                Visa: /^4[0-9]{12}(?:[0-9]{3})/,
                Master: /^5[1-5][0-9]{14}/,
                Amex: /^3[47][0-9]{13}/,
                Dinners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
                Discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
                JCB: /^(?:2131|1800|35\d{3})\d{11}/,
                HiperCard: /^6062\d{12}/,
                Aura: /^50\d{14}/
            }

            function card(nr, cartoes) {
                for (var cartao in cartoes) if (nr.match(cartoes[cartao])) return cartao
                return false
            }

            let validate = card(this.clearText(numberCard), cartoes)
            if (validate) {
                this.setState({flag: Bandeiras[flags.indexOf(validate)], validate: validate})
            } else {
                this.setState({validate: validate, flag: ''})
            }
        }
    }

    clearText = text => {
        text = text.normalize("NFD").replace(/[^A-Z0-9]/ig, "")
        text = text.trim()
        return text.toLowerCase()
    }

    maskNumberCard = number => {
        if (number !== '') {
            number = number.substring(0, 19)
            number = number.replace(/\D/g, "")
            return number.match(/\d{1,4}/g).join(' ')
        }
    }

    maskValidateDate = number => {
        if (number === '') {
            this.setState({expiry: ''})
        } else if (number.length <= 5) {
            let s = number.substring(0, 5).replace(/\D/g, "").match(/\d{1,2}/g).join('/')
            this.setState({expiry: s})
        } else {
            number = number.split('/')
            let s = `${number[0]}/${number[1].substring(number[1].length - 2)}`
            this.setState({expiry: s})
        }
    }

    checksCard = () => {
        let card = sessionStorage.getItem(`bitmenu-${identifier}:card`)
        card = card !== null ? JSON.parse(atob(card)) : ''
        if (card !== '') {
            let maskNumber = card.numberCard
            maskNumber = `**** **** **** ${maskNumber.substring(maskNumber.length - 4)}`
            this.validateCard(card.numberCard)
            this.setState({
                maskNumber: maskNumber,
                expiry: `${card.month}/${card.year}`,
                type: card.type,
                cvv: card.cvv,
                name: card.name,
                cpf: card.cpf,
                card: false
            })
        }
    }

    checkClient = () => {
        let client = localStorage.getItem(`bitmenu-${identifier}:cliente`)
        client = client !== null ? JSON.parse(client) : ''

        if (client === '') {
            this.props.history.push({pathname: '/login', state: {goBack: '/cartao'}})
        } else if (client.ddd === undefined || client.ddd === '') {
            let ddd = client.telefone
            try {
                ddd = ddd.substring(0, ddd.length - 8)
                if (ddd.length > 2 && ddd.substring(0, 1) !== '0' && ddd.substring(ddd.length - 1) === '9') {
                    ddd = ddd.substring(0, ddd.length - 1)
                }

                if (ddd.length < 2) {
                    ddd = ''
                }
            } catch (e) {
                ddd = ''
            }

            let phone = client.telefone
            try {
                phone = phone.substring(phone.length - 8)
                if ((phone.substring(0, 1) === '9' || phone.substring(0, 1) === '8' || phone.substring(0, 1) === '7')) {
                    phone = `9${phone}`
                }
            } catch (e) {
                phone = client.telefone
            }

            if (ddd !== '') {
                client.ddd = ddd
                client.telefone = phone
                try {
                    let password = localStorage.getItem(`bitmenu-${identifier}:s`)
                    password = showData(password)
                    client.senha = password
                } catch (e) {

                }
                let conexao = {method: 'POST', body: JSON.stringify({json: client, key: client.key})}
                rest(`${URL_BASE}/cadastro`, conexao)
                localStorage.setItem(`bitmenu-${identifier}:cliente`, JSON.stringify(client))
            } else {
                this.setState({openData: true, ddd: ddd, phone: phone})
            }
        }
    }

    personalize = () => {
        try {
            let colors = JSON.parse(localStorage.getItem(`bitmenu-${identifier}:colorStore`))
            const {title, description, price, toolbar, body, cardProduct, nextButton, fontButton, fontToolbar} = colors
            this.setState({
                colorStore: {
                    color_title: `#${title}`,
                    color_description: `#${description}`,
                    color_price: `#${price}`,
                    color_toolbar: `#${toolbar}`,
                    color_body: `#${body}`,
                    color_cardProduct: `#${cardProduct}`,
                    color_nextButton: `#${nextButton}`,
                    color_fontButton: `#${fontButton}`,
                    color_fontToolbar: `#${fontToolbar}`
                }
            })
        } catch (e) {

        }
    }

    encryptedCard = (name, expiry, numberCard, cvv) => {
        try {
            sessionStorage.setItem('bitmenu-cartao', JSON.stringify({
                holder: name,
                number: numberCard,
                expMonth: expiry[0],
                expYear: '20' + expiry[1],
                securityCode: cvv
            }))
            const encryptedCard = sessionStorage.getItem(`bitmenu:encryptedCard`)
            if (encryptedCard === null) {
                window.location.href = 'pagseguro.html'
            }
        } catch (e) {

        }
    }

    componentDidMount() {
        identifier = IDENTIFIER_STORE
        this.checkClient()
        this.checksCard()
        this.personalize()
        window.scrollTo(0, 0)
        pageView()
    }

    render() {
        const {
            numberCard,
            maskNumber,
            expiry,
            cvv,
            card,
            name,
            flag,
            validate,
            openSnackBar,
            openData,
            snackBar,
            ddd,
            phone,
            colorStore: {
                color_toolbar,
                color_body,
                color_nextButton,
                color_fontToolbar
            }
        } = this.state

        const theme_2 = createMuiTheme({
            palette: {
                primary: {
                    main: color_nextButton
                }
            }
        })

        return (
            <div id="cartao" style={color_body ? {background: color_body} : {}}>

                <AppBar
                    id="appBar"
                    position="sticky"
                    color="default"
                    style={color_toolbar ? {background: color_toolbar} : {}}>
                    <Toolbar variant="dense">
                        <ArrowBackIcon
                            style={color_fontToolbar ? {color: color_fontToolbar} : {}}
                            id="voltar-carrinho" onClick={this.onClickBack}/>
                        <Typography style={color_fontToolbar ? {color: color_fontToolbar} : {}}
                                    variant="h6" color="inherit">Cartão</Typography>
                    </Toolbar>
                </AppBar>
                <div id="main-cartao">
                    <div id="div-card">
                        <div id="div-card-top">
                            <div id="div-card-top-right">
                                <CardMedia id="logo-chip" image={Chip}/>
                            </div>
                            <div id="div-card-top-left">
                                <FormLabel id="label-validate">{validate}</FormLabel>
                            </div>
                        </div>
                        <div id="div-card-bottom">
                            <div id="div-card-bottom-right">
                                {
                                    <div id="div-number-card">
                                        <FormLabel
                                            id="label-card">{maskNumber !== undefined ? maskNumber : ''}</FormLabel>
                                    </div>
                                }
                                {
                                    <div id="div-cvc-data">
                                        <FormLabel id="label-card-cvc">{expiry}</FormLabel>
                                    </div>
                                }
                                {
                                    <div id="div-name">
                                        <FormLabel id="label-card-cvc">{name}</FormLabel>
                                    </div>
                                }
                            </div>
                            <div id="div-card-bottom-left">
                                {
                                    flag !== '' &&
                                    <CardMedia image={flag} id="logo-flag"/>
                                }
                            </div>
                        </div>
                    </div>

                    {
                        !card &&
                        <div id="div-edit-card">
                            <MuiThemeProvider theme={theme_2}>
                                <Button
                                    id={'button-dialog'}
                                    style={{fontWeight: "bold"}}
                                    fullWidth={true}
                                    color={'primary'}
                                    onClick={() => this.setState({card: true})}
                                    variant={'outlined'}>
                                    Editar cartão
                                </Button>
                                <Box p={1}/>
                                <Button
                                    id={'button-dialog'}
                                    style={{fontWeight: "bold"}}
                                    fullWidth={true}
                                    color={'primary'}
                                    onClick={() => this.onClickDeleteCard()}
                                    variant={'outlined'}>
                                    Remover cartão
                                </Button>
                                <Box p={1}/>
                                <Button
                                    id={'button-dialog'}
                                    style={{fontWeight: "bold"}}
                                    fullWidth={true}
                                    color={'primary'}
                                    onClick={() => this.onClickBack()}
                                    variant={'contained'}>
                                    Confirmar cartão
                                </Button>
                            </MuiThemeProvider>
                        </div>
                    }
                    {
                        card &&
                        <Card id="div-data-card">
                            <MuiThemeProvider theme={theme}>
                                <CardContent>
                                    <div id="div-form-card">
                                        <TextField autoFocus margin="dense" name="numberCard"
                                                   label="Número do cartão"
                                                   value={numberCard}
                                                   fullWidth variant="outlined"
                                                   autoComplete="true"
                                                   onChange={this.handleInputs}/>
                                    </div>
                                    <div id="div-form-card">
                                        <TextField margin="dense" value={name} name="name"
                                                   label="Nome Titular"
                                                   type="text"
                                                   placeholder="Nome Titular" fullWidth variant="outlined"
                                                   autoComplete="true"
                                                   onChange={this.handleInputs}/>
                                    </div>
                                    <div id="div-form-card-row">
                                        <TextField margin="dense" value={expiry} name="expiry"
                                                   label="Validade"
                                                   placeholder="MM/AA" fullWidth variant="outlined"
                                                   autoComplete="true"
                                                   onChange={this.handleInputs}/>
                                        <Box p={1}/>
                                        <TextField margin="dense" value={cvv} name="cvv" label="CVV"
                                                   type="number"
                                                   fullWidth variant="outlined" autoComplete="true"
                                                   onChange={this.handleInputs}/>
                                    </div>
                                    <Box p={1}/>
                                    <MuiThemeProvider theme={theme_2}>
                                        <div style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}>
                                            <Button
                                                id={'button-dialog'}
                                                style={{fontWeight: "bold"}}
                                                color={'primary'}
                                                onClick={() => this.onClickSalve()}
                                                variant={'contained'}>
                                                Confirmar dados cartão
                                            </Button>
                                        </div>
                                    </MuiThemeProvider>
                                </CardContent>
                            </MuiThemeProvider>
                        </Card>
                    }
                </div>
                <MuiThemeProvider theme={theme}>
                    <Dialog open={openData}
                            aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Confirmar dados pessoais</DialogTitle>
                        <DialogContent>
                            <DialogContentText>Confirme seu telefone</DialogContentText>
                            <div id="form-cadastro">
                                <TextField margin="dense" name="ddd" label="DDD" fullWidth value={ddd} type="number"
                                           placeholder="DDD" variant="outlined" onChange={this.handleInputs}/>

                                <Box p={1}/>
                                <TextField margin="dense" name="phone" label="Telefone" fullWidth value={phone}
                                           type="number"
                                           placeholder="Telefone" variant="outlined" onChange={this.handleInputs}/>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.onClickBack} color="primary">Não</Button>
                            <Button onClick={this.onClickPhone} color="primary">Sim</Button>
                        </DialogActions>
                    </Dialog>

                    <Snackbar
                        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                        open={openSnackBar}
                        autoHideDuration={2000}
                        onClose={() => this.setState({openSnackBar: false})}
                        message={snackBar}
                    />
                </MuiThemeProvider>
            </div>
        )
    }
}

export default withRouter(Cartao)
