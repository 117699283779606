/* eslint-disable eqeqeq */
import React from 'react'
import {withRouter} from 'react-router-dom'
import {
    AppBar,
    Toolbar,
    Typography,
    FormLabel,
    Card,
    CircularProgress,
    Checkbox,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Snackbar,
    CardMedia,
    FormControlLabel,
    Radio,
    Input,
    TextField,
    Box, CardContent
} from '@material-ui/core'
import {Delete as DeleteIcon, ArrowBack as ArrowBackIcon, Edit, Description} from '@material-ui/icons'
import {createMuiTheme, MuiThemeProvider, withStyles} from '@material-ui/core/styles'
import {
    showData,
    rest,
    URL_BASE,
    pageView,
    IDENTIFIER_STORE,
    maskPhone,
    eventFacebook,
    eventGoogle,
    validaCPF,
    validarCNPJ,
    smoothScrolling,
    eventGA4
} from '../../util'
import ListaItens from '../../components/listaitens/ListaItens.jsx'
import './Carrinho.css'
import user from '../../usuario.json'
import firebase from '../../firebase'
import maps from '../../images/maps.png'
import {isSafari, isIOS, isMobile} from 'react-device-detect'
import Logo from '../../images/logo.png'
import Pix from '../../images/pix.png'
import Caixa from '../../images/caixa.png'
import CardCredit from '../../images/card_credit.png'
import Money from '../../images/money.png'
import PaymentOnline from '../../images/payment_on.png'
import {getDistance} from 'geolib'
import moment from "moment"

let authkey
let identifier
let id_loja
let turnedDay
let couponOld = ''

let tokens = ['', '30F038EC-1746-43A4-A616-B6F3B2DF7CFF', 'D9FD97B5-36AB-4C04-B4FF-EDEE764509ED', 'E55BA084-57F2-42D9-A62D-292BF73E1B59']

const RedRadio = withStyles({
    root: {
        color: '#000000',
        '&$checked': {
            color: '#000000',
        },
    },
    checked: {},
})(props => <Radio color="default" {...props} />)

const RedCheck = withStyles({
    root: {
        color: '#000000',
        '&$checked': {
            color: '#000000',
        },
    },
    checked: {},
})(props => <Checkbox color="default" {...props} />)

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#000000',
        }
    },
})

class Carrinho extends React.Component {

    state = {
        pix: false,
        cliente: '',
        observation: '',
        observationOrder: '',
        payment: '',
        coupon: '',
        snackBar: '',
        noticeToAddress: '',
        dateDelivery: '',
        house: '',
        change: 0,
        card: '',
        subtotal: 0,
        discount: 0,
        deliveryFee: 0,
        modules: 0,
        gularisMinimo: 0,
        balanceGularis: 0,
        valueRescue: 0,
        position: -1,
        adress: '',
        timeRoutes: [],
        scheduledDeliveryTime: '',
        receptionTimes: [],
        statusOrder: '',
        scheduleDelivery: 'Entrega imediata',
        storesLocation: 'Retira no local',
        responseQuestions: '',
        paymentOnline: '',
        cashPayments: '',
        confirmDDD: '',
        confirmPhone: '',
        nameTotem: '',
        optionsTakeWay: '',
        paymentTotem: '',
        buttonNext: 'Avançar',
        paymentTypes: [],
        totem: false,
        gularis: false,
        paymentNetworks: false,
        openSnackBar: false,
        openChange: false,
        openCleanCartItems: false,
        openOptions: false,
        openProcessOrder: false,
        openConfirmation: false,
        openNotification: false,
        openObservation: false,
        openReceptionTimes: false,
        cupomPrimeiraCompra: false,
        cupomCompraUnica: false,
        openConfirmDDD: false,
        openRescue: false,
        scheduledDay: false,
        openRescueNotice: false,
        openCouponNotice: false,
        openDateDelivery: false,
        openTotem: false,
        openDeliveryOrTakeaway: false,
        openDatechedule: false,
        scheduledOrderNotice: false,
        confirmationInformation: false,
        allowScheduledDelivery: false,
        withoutChange: false,
        openPayment: false,
        openRecord: false,
        openCoupon: false,
        openListRoutes: false,
        retire: false,
        warning: {
            tryAgain: false,
            open: false,
            title: '',
            text: '',
            id_pedido: '',
            cpf: ''
        },
        colorStore: {
            color_title: '',
            color_description: '',
            color_price: '',
            color_toolbar: '',
            color_body: '',
            color_cardProduct: '',
            color_nextButton: '',
            color_fontButton: '',
            color_fontToolbar: '',
            color_footer: ''
        },
        clientName: '',
        clientPhone: '',
        clientCPF: '',
        noRecord: false,
        openLogin: false,
        openCPF: false,
        recordCPF: false,
        itens: [],
        openRaio: false,
        messageTextRaio: '',
        messageTitleRaio: '',
        dateDeliveryRoute: '',
        scheduleDate: false,
        dateSchedule: '',
        visibleObs: true,
        limitTime: '',
        timeRoute: '',
    }

    handleOptions(index, open, obs, visibleObs) {
        this.setState({position: index, openOptions: open, observation: obs, visibleObs: visibleObs})
    }

    handleInputs = (e) => {
        let value = e.target.value
        if (e.target.name === 'change') {
            const {subtotal, deliveryFee, discount, valueRescue} = this.state
            if ((subtotal + deliveryFee - discount - valueRescue) !== 0) {
                this.setState({[e.target.name]: parseFloat(value = value === '' ? 0 : value)})
            } else {
                this.setState({snackBar: 'Não pode adicionar troco com o total zero', openSnackBar: true})
            }
        } else if (e.target.name === 'coupon') {
            this.setState({[e.target.name]: this.cleanAccents(value.toUpperCase())})
        } else if (e.target.name === 'observationOrder') {
            this.setState({[e.target.name]: value})
            sessionStorage.setItem(`bitmenu-${identifier}:observationOrder`, value)
        } else if (e.target.name === 'valueRescue') {
            try {
                const {subtotal, discount, deliveryFee} = this.state
                if (value !== '') {
                    let total = (subtotal + deliveryFee - discount)
                    value = parseFloat(value)
                    if (value > total) {
                        value = total
                        this.setState({snackBar: 'Valor a ser resgatado e maior do que o total', openSnackBar: true})
                    }
                }
            } catch (e) {

            }
            this.setState({[e.target.name]: value})
        } else if (e.target.name === 'confirmDDD') {
            if (value.length <= 2) {
                this.setState({[e.target.name]: value})
            }
        } else if (e.target.name === 'confirmPhone') {
            if (value.length <= 9) this.setState({[e.target.name]: value})
        } else if (e.target.name === 'nameTotem') {
            this.setState({[e.target.name]: value.toUpperCase()})
        } else if (e.target.name === 'clientPhone') {
            this.setState({[e.target.name]: maskPhone(value)})
        } else if (e.target.name === 'clientCPF') {
            this.setState({[e.target.name]: value.substring(0, 18)})
        } else if (e.target.name === 'dateSchedule') {
            let currentDate = new Date()
            currentDate.setHours(0, 0, 0, 0)
            let chosenDate = new Date(value)
            chosenDate.setHours(0, 0, 0, 0)
            chosenDate.setDate(chosenDate.getDate() + 1)
            if (chosenDate.getTime() < currentDate.getTime()) {
                this.setState({
                    snackBar: 'Não é possível programar para uma data anterior à atual.',
                    openSnackBar: true,
                    dateSchedule: value,
                    scheduledDeliveryTime: value
                })
            } else {
                this.setState({dateSchedule: value, scheduledDeliveryTime: value, openReceptionTimes: false})
                sessionStorage.setItem(`bitmenu-${identifier}:dateSchedule`, value)
            }
        } else {
            this.setState({[e.target.name]: value})
        }
    }

    onClickCheckSchedule = () => {
        try {
            const {scheduleDelivery, totem, scheduledDeliveryTime, modules} = this.state
            if (!totem && scheduleDelivery === 'Entrega programada' && scheduledDeliveryTime === '' && modules !== 3) {
                this.setState({
                    openSnackBar: true,
                    snackBar: 'Selecione a data de entrega ou mude para entrega imediata'
                })
            } else {
                let currentDate = new Date()
                currentDate.setHours(0, 0, 0, 0)
                let chosenDate = new Date(scheduledDeliveryTime)
                chosenDate.setHours(0, 0, 0, 0)
                chosenDate.setDate(chosenDate.getDate() + 1)
                if (chosenDate.getTime() < currentDate.getTime()) return this.setState({
                    snackBar: 'Não é possível programar para uma data anterior à atual.',
                    openSnackBar: true
                })
                this.setState({openReceptionTimes: false})
            }
        } catch (e) {

        }
    }

    closeDialogWarning = () => {
        let tid = sessionStorage.getItem(`bitmenu-${identifier}:transictionID`)
        let payment = JSON.parse(sessionStorage.getItem(`bitmenu-${identifier}:paymentNetworks`))
        if (tid !== null && payment.paymentNetworks === 'rede') {
            this.setState({openProcessOrder: true, statusOrder: 'Cancelando compra, aguarde por favor...'})
        } else if (tid !== null && payment.paymentNetworks === 'cielo') {
            this.setState({openProcessOrder: true, statusOrder: 'Cancelando compra, aguarde por favor...'})
        }
        this.setState({warning: {open: false, title: '', text: ''}})
    }

    closeSnackBar = () => this.setState({openSnackBar: false})

    closeCleanCartItems = () => this.setState({openCleanCartItems: false})

    closeChange = () => this.setState({openChange: false, withoutChange: false, change: 0})

    closeOptions = () => this.setState({openOptions: false})

    closeItemDetails = () => this.setState({openItemDetails: false})

    closeObservation = () => this.setState({openObservation: false})

    closeConfirmation = () => this.setState({openConfirmation: false, confirmationInformation: false})

    closeMethods = () => this.setState({openPayment: false})

    closeCoupon = () => {
        try {
            this.setState({openCoupon: false, coupon: ''})
            sessionStorage.removeItem(`bitmenu-${identifier}:discount`)
            sessionStorage.removeItem(`bitmenu-${identifier}:cupomPrimeiraCompra`)
            sessionStorage.removeItem(`bitmenu-${identifier}:cupomCompraUnica`)
        } catch (e) {

        }
    }

    closeNotification = () => {
        this.setState({openNotification: false})
        localStorage.setItem(`bitmenu-${identifier}:token`, 'bloqueou')
    }

    onClickcleanCartItems = () => this.setState({openCleanCartItems: true})

    cleanCartItems = () => {
        try {
            let value = 0
            let items = []
            let itens = localStorage.getItem(`bitmenu-${identifier}:itens`)
            itens = JSON.parse(itens)
            itens.forEach(i => {
                items.push({
                    item_id: i.sku,
                    item_name: i.titulo
                })
                value += i.total
            })
            eventGA4('remove_from_cart', {currency: 'BRL', value: value, items: items})
        } catch (e) {

        }
        this.cleaQtdeMax()
        localStorage.removeItem(`bitmenu-${identifier}:itens`)
        sessionStorage.removeItem(`bitmenu-${identifier}:discount`)
        sessionStorage.removeItem(`bitmenu-${identifier}:cupomPrimeiraCompra`)
        sessionStorage.removeItem(`bitmenu-${identifier}:cupomCompraUnica`)
        sessionStorage.removeItem(`bitmenu-${identifier}:change`)
        sessionStorage.removeItem(`bitmenu-${identifier}:scheduleDelivery`)
        sessionStorage.removeItem(`bitmenu-${identifier}:payment`)
        sessionStorage.removeItem(`bitmenu-${identifier}:responseQuestions`)
        sessionStorage.removeItem(`bitmenu-${identifier}:confirmedAnswers`)
        sessionStorage.removeItem(`bitmenu-${identifier}:cashback`)
        sessionStorage.removeItem(`bitmenu-${identifier}:dateDeliveryRoute`)
        sessionStorage.removeItem(`bitmenu-${identifier}:timeRoute`)
        this.calculateSubTotal([])
        this.setState({
            itens: [],
            payment: '',
            coupon: '',
            discount: 0,
            change: 0,
            valueRescue: 0,
            openCleanCartItems: false,
            scheduledDeliveryTime: '',
            dateDeliveryRoute: '',
            timeRoute: ''
        })
        this.statusButtonNext()
    }

    cleaQtdeMax = () => {
        try {
            const {itens} = this.state
            itens.forEach(i => {
                sessionStorage.removeItem(`bitmenu-${identifier}:qtdeMax-${i.sku}`)
            })
        } catch (e) {

        }
    }

    onClickConfirmationRemoveCoupon = () => {
        sessionStorage.setItem(`bitmenu-${identifier}:cashback`, '')
        this.onClickRemoveCoupon()
        this.onClickRescue()
        this.setState({openCouponNotice: false, openRescue: false})
    }

    onClickNotRescue = () => this.setState({openRescue: false, valueRescue: 0})

    onClickResgatar = () => {
        let {balanceGularis, subtotal, discount, deliveryFee} = this.state
        let total = (subtotal + deliveryFee - discount)
        this.setState({valueRescue: balanceGularis > total ? total : balanceGularis, openRescue: true})
    }

    onClickRescue = () => {
        let {valueRescue, balanceGularis, subtotal, discount, deliveryFee, gularisMinimo} = this.state
        valueRescue = valueRescue === '' ? 0 : valueRescue
        let total = (subtotal + deliveryFee - discount)
        valueRescue = parseFloat(valueRescue)
        gularisMinimo = parseFloat(gularisMinimo)

        if ((subtotal + deliveryFee - discount - valueRescue) === 0) {
            sessionStorage.setItem(`bitmenu-${identifier}:change`, 0)
            this.setState({change: 0})
        }

        if (valueRescue < gularisMinimo) {
            this.setState({
                snackBar: `O valor mínimo para resgate e de ${gularisMinimo.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                })}`, openSnackBar: true
            })
            return
        }

        if (sessionStorage.getItem(`bitmenu-${identifier}:discount`)) {
            this.setState({openCouponNotice: true, openRescue: false})
            return
        }

        if (valueRescue > total) {
            this.setState({snackBar: 'Valor a ser resgatado e maior do que o total', openSnackBar: true})
            return
        }

        if (valueRescue > balanceGularis) {
            this.setState({snackBar: 'Valor a ser resgatado e maior do que o saldo', openSnackBar: true})
            return
        }

        if (valueRescue != 0) {
            sessionStorage.setItem(`bitmenu-${identifier}:cashback`, JSON.stringify(valueRescue))
        } else {
            sessionStorage.removeItem(`bitmenu-${identifier}:cashback`)
            this.freeDelivery(subtotal)
        }

        this.setState({openRescue: false, valueRescue: valueRescue})
    }

    onClickConfirmationInformation = () => this.setState({confirmationInformation: !this.state.confirmationInformation})

    onClickSelectionAdress = () => {
        const {cliente, noRecord} = this.state
        if (cliente === '' && !noRecord) {
            this.setState({openLogin: true})
        } else {
            this.props.history.push('/enderecos')
        }
    }

    onClickNotification = () => {
        this.closeNotification()
        this.permissionNotification()
    }

    onClickConfirmationRemoveRescue = () => {
        sessionStorage.removeItem(`bitmenu-${identifier}:cashback`)
        this.setState({valueRescue: 0, openRescueNotice: false})
        this.onClickValidateCoupon()
    }

    onClickValidateCoupon = () => {
        let cashback = sessionStorage.getItem(`bitmenu-${identifier}:cashback`)
        if (cashback && cashback !== '0') {
            this.setState({openRescueNotice: true, openCoupon: false})
            return
        }
        this.setState({openCoupon: false})
        this.validateCoupon()
    }

    onClickPaymentMethod = obj => {
        const {noRecord} = this.state
        if (obj.description.toUpperCase() === "DINHEIRO") {
            this.setState({openChange: true, withoutChange: false, openPayment: false, payment: obj.description})
        } else if (obj.description.toUpperCase() === "PAGAMENTO ONLINE") {
            if (noRecord) return this.setState({
                snackBar: 'E obrigatório ser cadastrado para compras online',
                openSnackBar: true,
                openLogin: true,
                openPayment: false
            })
            this.props.history.push('/cartao')
        } else {
            this.setState({change: 0, openPayment: false, payment: obj.description})
            sessionStorage.removeItem(`bitmenu-${identifier}:change`)
        }
        sessionStorage.setItem(`bitmenu-${identifier}:payment`, obj.description)
        this.statusButtonNext()
    }

    onClickAddCoupon = () => this.setState({openCoupon: true})

    onClickPayment = () => {
        const {paymentOnline, paymentNetworks, paymentPhysical, cashPayments, retire} = this.state
        if (paymentOnline === 'ALL' && paymentPhysical === 'ALL' && cashPayments === 'ALL') {
            this.setState({openPayment: true})
        } else if (retire && paymentOnline === 'TAKEAWAY' && paymentPhysical !== 'TAKEAWAY' && paymentPhysical !== 'ALL' && cashPayments !== 'TAKEAWAY' && cashPayments !== 'ALL' && paymentNetworks) {
            sessionStorage.setItem(`bitmenu-${identifier}:payment`, 'PAGAMENTO ONLINE')
            this.setState({openPayment: true})
        } else if (!retire && paymentOnline === 'DELIVERY' && paymentPhysical !== 'DELIVERY' && paymentPhysical !== 'ALL' && cashPayments !== 'DELIVERY' && cashPayments !== 'ALL' && paymentNetworks) {
            sessionStorage.setItem(`bitmenu-${identifier}:payment`, 'PAGAMENTO ONLINE')
            this.setState({openPayment: true})
        } else if (retire && paymentOnline === 'ALL' && paymentPhysical !== 'TAKEAWAY' && cashPayments !== 'TAKEAWAY') {
            sessionStorage.setItem(`bitmenu-${identifier}:payment`, 'PAGAMENTO ONLINE')
            this.setState({openPayment: true})
        } else if (!retire && paymentOnline === 'ALL' && paymentPhysical !== 'DELIVERY' && cashPayments !== 'DELIVERY') {
            sessionStorage.setItem(`bitmenu-${identifier}:payment`, 'PAGAMENTO ONLINE')
            this.setState({openPayment: true})
        } else {
            this.setState({openPayment: true})
        }
    }

    onClickWithoutChange = e => {
        if (e.target.checked) {
            this.setState({
                withoutChange: true,
                change: 0
            })
        } else {
            this.setState({
                withoutChange: false,
            })
        }
    }

    onClickConfirmChange = () => {
        if (this.state.change === 0) {
            this.setState({snackBar: 'Você não adicionou troco !', openSnackBar: true})
        } else if (this.state.change < (this.state.subtotal + this.state.deliveryFee - this.state.discount - this.state.valueRescue)) {
            this.setState({snackBar: 'O total a pagar é menor que o valor total', openSnackBar: true})
        }
        this.setState({openChange: false})
        sessionStorage.setItem(`bitmenu-${identifier}:change`, this.state.change)
    }

    onClickBack = () => this.props.history.goBack()

    onClickDetail = () => {
        let itens = this.state.itens
        let position = this.state.position
        let detalhes = itens[position]
        this.setState({openItemDetails: true, openOptions: false, detalhes: detalhes})
    }

    onClickRemover = () => {
        let {itens, position, deliveryFee} = this.state
        const {sku, quantidade, titulo, total} = itens[position]
        try {
            let items = [{
                item_id: sku,
                item_name: titulo
            }]
            eventGA4('remove_from_cart', {currency: 'BRL', value: total, items: items})
        } catch (e) {

        }
        let qtdeMax = JSON.parse(sessionStorage.getItem(`bitmenu-${identifier}:qtdeMax-${sku}`))
        sessionStorage.setItem(`bitmenu-${identifier}:qtdeMax-${sku}`, (qtdeMax + quantidade))
        itens.splice(position, 1)
        this.setState({itens: itens, openOptions: false})
        itens = localStorage.getItem(`bitmenu-${identifier}:itens`)
        itens = itens !== null ? JSON.parse(itens) : []
        itens.splice(position, 1)
        localStorage.setItem(`bitmenu-${identifier}:itens`, JSON.stringify(itens))
        let subtotal = this.calculateSubTotal(itens)
        this.checkDiscount(deliveryFee, itens, subtotal)
    }

    onClickAddObs = () => this.setState({openObservation: true, openOptions: false})

    onClickObservation = () => {
        let position = this.state.position
        let itens = this.state.itens
        itens[position].obs = `${this.state.observation}`
        this.setState({itens: itens, openObservation: false})
        itens = localStorage.getItem(`bitmenu-${identifier}:itens`)
        itens = itens !== null ? JSON.parse(itens) : []
        itens[position].obs += ` ${this.state.observation}`
        localStorage.setItem(`bitmenu-${identifier}:itens`, JSON.stringify(itens))
    }

    onClickRemoveCoupon = () => {
        let {itens} = this.state
        for (let i = 0; i < itens.length; i++) {
            if (itens[i].titulo.includes(' - (Desconto)')) {
                itens.splice(i, 1)
            }
        }
        let subtotal = this.calculateSubTotal(itens)
        this.setState({valueRescue: 0, coupon: '', discount: 0, itens: itens, subtotal: subtotal})
        localStorage.setItem(`bitmenu-${identifier}:itens`, JSON.stringify(itens))
        sessionStorage.removeItem(`bitmenu-${identifier}:discount`)
        sessionStorage.removeItem(`bitmenu-${identifier}:cupomPrimeiraCompra`)
        sessionStorage.removeItem(`bitmenu-${identifier}:cupomCompraUnica`)
        this.freeDelivery(subtotal)
    }

    onClickListRoutes = () => {
        this.setState({
            openDateDelivery: false,
            openListRoutes: true
        })
    }

    onClickTimeRoute = ({time, limitTime}) => {
        this.setState({
            openListRoutes: false,
            openDateDelivery: true,
            limitTime: limitTime,
            timeRoute: time,
            dateDeliveryRoute: moment().add(limitTime, 'hours').format('YYYY-MM-DD'),
        })
    }

    onClickDateDelivery = () => {
        const {limitTime, timeRoute, dateDeliveryRoute, retire} = this.state
        const selectedDate = moment(dateDeliveryRoute, 'YYYY-MM-DD')
        const minimumDate = moment().add(limitTime, 'hours')
        if (minimumDate.isAfter(selectedDate, 'day')) return this.setState({
            snackBar: 'Data selecionada é inferior a data mínima para pedidos!',
            openSnackBar: true
        })
        this.setState({openDateDelivery: false})
        sessionStorage.setItem(`bitmenu-${identifier}:dateDeliveryRoute`, dateDeliveryRoute)
        sessionStorage.setItem(`bitmenu-${identifier}:timeRoute`, timeRoute)
        if (retire) {
            this.setState({dateDelivery: ''})
            localStorage.removeItem(`bitmenu-${identifier}:adress`)
        }
        this.statusButtonNext()
    }

    closeListRoutes = () => {
        this.setState({openListRoutes: false, openDateDelivery: false})
        sessionStorage.removeItem(`bitmenu-${identifier}:dateDeliveryRoute`)
        sessionStorage.removeItem(`bitmenu-${identifier}:timeRoute`)
        this.setState({dateDeliveryRoute: '', timeRoute: ''})
        this.statusButtonNext()
    }

    onClickScheduleDelivery = () => {
        try {
            const {scheduleDelivery, scheduledDay} = this.state
            if (scheduledDay) return this.setState({openReceptionTimes: true})
            if (!this.timeControl()) {
                this.deliveryTimes()
                this.setState({openReceptionTimes: true})
                return
            }
            if (scheduleDelivery !== 'Entrega imediata') {
                sessionStorage.removeItem(`bitmenu-${identifier}:scheduleDelivery`)
                sessionStorage.removeItem(`bitmenu-${identifier}:dateSchedule`)
                this.setState({
                    scheduleDelivery: `Entrega imediata`,
                    scheduledDeliveryTime: '',
                    openReceptionTimes: false,
                    dateSchedule: '',
                    openDatechedule: false
                })
            } else {
                this.deliveryTimes()
                this.setState({openReceptionTimes: true})
            }
        } catch (e) {

        }
    }

    onClickEditScheduleDelivery = () => {
        this.deliveryTimes()
        this.setState({openReceptionTimes: true})
    }

    onClickReceptionTimes = obj => {
        if (obj === 'Entrega imediata') {
            sessionStorage.removeItem(`bitmenu-${identifier}:scheduleDelivery`)
            this.setState({
                scheduleDelivery: `Entrega imediata`,
                scheduledDeliveryTime: '',
                openReceptionTimes: false,
                openDatechedule: false
            })
        } else {
            this.setState({
                scheduleDelivery: `Entrega programada`,
                scheduledDeliveryTime: obj,
                openReceptionTimes: false
            })
            sessionStorage.setItem(`bitmenu-${identifier}:scheduleDelivery`, obj)
        }
        this.statusButtonNext()
    }

    onClickConfirmDDD = async () => {
        const {confirmDDD, confirmPhone} = this.state
        let client = localStorage.getItem(`bitmenu-${identifier}:cliente`)
        client = client !== null ? JSON.parse(client) : ''
        if (client !== '' && confirmDDD.length === 2 && confirmPhone.length >= 8) {
            client.ddd = confirmDDD
            client.telefone = confirmPhone
            localStorage.setItem(`bitmenu-${identifier}:cliente`, JSON.stringify(client))
            this.setState({openConfirmDDD: false, cliente: client})
        } else {
            this.setState({snackBar: 'Preencha os dados corretamente', openSnackBar: true})
        }
    }

    onClickPaymentTotem = paymentTotem => this.setState({paymentTotem: paymentTotem})

    onClickSendTotem = () => {
        let {nameTotem, paymentTotem, pix} = this.state
        if (nameTotem === '') return this.setState({snackBar: 'Informe seu nome', openSnackBar: true})
        if (paymentTotem === '' && !pix) paymentTotem = 'caixa'
        if (paymentTotem === '' && pix) return this.setState({
            snackBar: 'Escolha uma forma de pagamento',
            openSnackBar: true
        })
        this.setState({openTotem: false})
        this.onClickConfirmation((paymentTotem === 'pix' && pix) ? 'Pix' : 'Dinheiro')
    }

    onCloseLogin = () => {
        let {payment} = this.state
        if (payment === 'Pix' || payment === 'PAGAMENTO ONLINE') {
            sessionStorage.removeItem(`bitmenu-${identifier}:payment`)
            payment = ''
        }
        this.setState({openLogin: false, payment: payment})
    }

    onClickLogin = () => {
        this.setState({openLogin: false})
        this.props.history.push({pathname: '/login', state: {goBack: '/carrinho'}})
    }

    onClickNoRecord = () => {
        try {
            let {payment} = this.state
            if (payment === 'Pix' || payment === 'PAGAMENTO ONLINE') {
                sessionStorage.removeItem(`bitmenu-${identifier}:payment`)
                payment = ''
            }
            this.setState({openLogin: false, noRecord: true, payment: payment})
            let cliente = localStorage.getItem(`bitmenu-${identifier}:cliente`)
            cliente = (cliente !== null) ? JSON.parse(cliente) : {}
            cliente.endereco = []
            localStorage.setItem(`bitmenu-${identifier}:cliente`, JSON.stringify(cliente))
            localStorage.setItem(`bitmenu-${identifier}:noRecord`, true)
            this.props.history.push({pathname: '/enderecos', state: {goBack: '/carrinho'}})
            this.statusButtonNext()
        } catch (e) {
            console.error(e)
        }
    }

    onClickRecord = () => this.props.history.push('/cadastro')

    onClickIdentification = () => {
        try {
            const {clientName, clientPhone, clientCPF} = this.state
            if (clientCPF === undefined || clientCPF.length < 11) return this.setState({
                snackBar: 'Coloque um cpf válido',
                openSnackBar: true
            })
            try {
                let cpf = this.clearText(clientCPF)
                if (cpf.length > 11) {
                    if (!validarCNPJ(cpf)) return this.setState({
                        snackBar: 'Coloque um CNPJ válido',
                        openSnackBar: true
                    })
                } else {
                    if (!validaCPF(cpf)) return this.setState({
                        snackBar: 'Coloque um CPF válido',
                        openSnackBar: true
                    })
                }
            } catch (e) {

            }
            if (clientName === '') return this.setState({snackBar: 'Coloque um nome válido', openSnackBar: true})
            let phone = this.clearText(clientPhone)
            if (phone.length !== 11) return this.setState({
                snackBar: 'Coloque um celular válido',
                openSnackBar: true
            })
            let cliente = localStorage.getItem(`bitmenu-${identifier}:cliente`)
            cliente = (cliente !== null) ? JSON.parse(cliente) : {}
            cliente.nome = clientName
            cliente.cpf = clientCPF
            cliente.ddd = clientPhone.substring(1, 3)
            cliente.telefone = clientPhone.substring(5)
            localStorage.setItem(`bitmenu-${identifier}:cliente`, JSON.stringify(cliente))
            this.setState({openCPF: false, openRecord: false, cliente: cliente})
            this.statusButtonNext()
            setTimeout(() => {
                this.onClickSend()
            }, 100)
        } catch (e) {
            console.error(e)
        }
    }

    onClickConfirmCPF = () => {
        try {
            const {clientCPF} = this.state
            if (clientCPF === undefined || clientCPF.length < 11) return this.setState({
                snackBar: 'Coloque um cpf válido',
                openSnackBar: true
            })
            try {
                let cpf = this.clearText(clientCPF)
                if (cpf.length > 11) {
                    if (!validarCNPJ(cpf)) return this.setState({
                        snackBar: 'Coloque um CNPJ válido',
                        openSnackBar: true,
                        openCPF: false
                    })
                } else {
                    if (!validaCPF(cpf)) return this.setState({
                        snackBar: 'Coloque um CPF válido',
                        openSnackBar: true,
                        openCPF: false
                    })
                }
            } catch (e) {

            }
            let cliente = localStorage.getItem(`bitmenu-${identifier}:cliente`)
            cliente = (cliente !== null) ? JSON.parse(cliente) : {}
            cliente.cpf = clientCPF
            localStorage.setItem(`bitmenu-${identifier}:cliente`, JSON.stringify(cliente))
            this.setState({openCPF: false, cliente: cliente})
            this.statusButtonNext()
            setTimeout(() => {
                this.onClickSend()
            }, 100)
        } catch (e) {
            console.error(e)
        }
    }

    onClickSend = () => {
        const {
            cliente,
            payment,
            purchaseMin,
            subtotal,
            dateDelivery,
            scheduleDelivery,
            itens,
            totem,
            scheduledDeliveryTime,
            noRecord,
            retire,
            modules,
            deliveryFee,
            discount,
            valueRescue,
            dateDeliveryRoute,
            scheduledDay
        } = this.state
        let questions = localStorage.getItem(`bitmenu-${identifier}:questions`)
        questions = (questions !== null && questions !== '') ? JSON.parse(questions) : []
        if (!scheduledDay) {
            this.checkScheduledTime()
        }
        if (!itens.length) {
            this.onClickBack()
        } else if (!this.timeControl() && scheduleDelivery !== 'Entrega programada') {
            this.setState({scheduledOrderNotice: true})
        } else if ((subtotal < purchaseMin) && !retire) {
            this.setState({
                openSnackBar: true,
                snackBar: `Esse estabelecimento tem uma compra mínima de ${purchaseMin.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                })}`
            })
        } else if (cliente === '' && !noRecord) {
            this.setState({openLogin: true})
        } else if (dateDelivery === '') {
            this.props.history.push({pathname: '/enderecos', state: {client: cliente}})
        } else if (!totem && scheduleDelivery === 'Entrega programada' && scheduledDeliveryTime === '' && modules !== 3) {
            this.setState({openReceptionTimes: true})
        } else if (modules === 3 && dateDeliveryRoute === '' && !retire) {
            this.setState({openListRoutes: true})
        } else if (this.checkToken()) {
            this.setState({openNotification: true})
        } else if (!totem && payment === '') {
            //window.scrollTo(0, 500)
            this.setState({openPayment: true})
        } else if (noRecord && (cliente.nome === undefined || cliente.telefone === undefined)) {
            this.setState({openRecord: true})
        } else if (questions.length > 0) {
            this.props.history.push('/perguntas')
        } else if (totem) {
            this.setState({openTotem: true})
            //this.setState({openDeliveryOrTakeaway: true})
        } else if (cliente.cpf === '' || cliente.cpf === undefined) {
            this.setState({openCPF: true})
        } else {
            this.setState({openConfirmation: true})
            eventFacebook('InitiateCheckout')
            eventGoogle('InitiateCheckout', {action: 'inicia_checkout', value: 0, content_name: null})
            try {
                let value = (subtotal + deliveryFee - discount - valueRescue)
                let items = []
                itens.forEach(i => {
                    items.push({
                        item_id: i.sku,
                        item_name: i.titulo,
                        price: i.preco,
                        quantity: i.quantidade
                    })
                })
                eventGA4('begin_checkout', {currency: 'BRL', value: value, items: items})
            } catch (e) {

            }
        }
        this.statusButtonNext()
    }

    onClickConfirmation = async (paymentTotem = '') => {
        try {
            let {
                cliente,
                observationOrder,
                dateDelivery,
                card: {
                    flag,
                    numberCard,
                    name,
                    month,
                    year,
                    cvv
                },
                subtotal,
                itens,
                authkey,
                deliveryFee,
                change,
                payment,
                retire,
                discount,
                coupon,
                valueRescue,
                scheduleDelivery,
                nameTotem,
                totem,
                cupomPrimeiraCompra,
                cupomCompraUnica,
                scheduledDay,
                scheduledDeliveryTime
            } = this.state
            let {cep, bairro, cidade, complemento, numero, uf, rua} = dateDelivery
            if (!itens.length) return this.setState({openSnackBar: true, snackBar: `Adicione itens ao carrinho`})
            let total = (subtotal + deliveryFee - discount - valueRescue)
            if (paymentTotem !== '') payment = paymentTotem
            payment = payment.toUpperCase()
            if (payment === 'DINHEIRO' && change < total) change = 0
            let valor = change < total ? total : change
            let idOrder = localStorage.getItem(`bitmenu:idOrder`)
            let token = localStorage.getItem(`bitmenu-${identifier}:token`)
            let scheduled = (scheduleDelivery === 'Entrega programada')
            let checkDiscount = false
            try {
                checkDiscount = sessionStorage.getItem(`bitmenu-${identifier}:discount`)
                checkDiscount = (checkDiscount !== null && checkDiscount !== '') ? JSON.parse(checkDiscount) : ''
                checkDiscount = (checkDiscount.type === 'DELIVERY_FEE')
            } catch (e) {
                console.log(e)
            }
            try {
                valueRescue = parseFloat((valueRescue !== null && valueRescue !== '' && valueRescue !== undefined) ? valueRescue.toFixed(2) : valueRescue)
            } catch (e) {

            }
            localStorage.setItem(`bitmenu-${identifier}:orderOld`, JSON.stringify(itens))
            localStorage.removeItem(`bitmenu-${identifier}:orderRoute`)
            const dateDeliveryRoute = sessionStorage.getItem(`bitmenu-${identifier}:dateDeliveryRoute`)
            const timeRoute = sessionStorage.getItem(`bitmenu-${identifier}:timeRoute`)
            if (dateDeliveryRoute !== null) localStorage.setItem(`bitmenu-${identifier}:orderRoute`, `pedido será entregue no dia ${moment(dateDeliveryRoute, 'YYYY-MM-DD').format('DD/MM/YYYY')} a partir das ${timeRoute.substring(0, 5)}hs`)
            try {
                let cpf = this.clearText(cliente.cpf)
                if (cpf !== undefined) {
                    if (cpf.length > 11) {
                        if (!validarCNPJ(cpf)) {
                            return this.setState({
                                openConfirmation: false,
                                openSnackBar: true,
                                confirmationInformation: false,
                                snackBar: `CNPJ inválido, revise as informações`,
                                openRecord: true,
                                clientName: (totem) ? this.cleanAccents(nameTotem) : this.cleanAccents(cliente.nome),
                                clientPhone: (totem) ? '' : maskPhone(`${cliente.ddd !== undefined ? cliente.ddd : ''}${cliente.telefone}`),
                                clientCPF: cpf
                            })
                        }
                    } else {
                        if (!validaCPF(cpf)) {
                            return this.setState({
                                openConfirmation: false,
                                openSnackBar: true,
                                confirmationInformation: false,
                                snackBar: `CPF inválido, revise as informações`,
                                openRecord: true,
                                clientName: (totem) ? this.cleanAccents(nameTotem) : this.cleanAccents(cliente.nome),
                                clientPhone: (totem) ? '' : maskPhone(`${cliente.ddd !== undefined ? cliente.ddd : ''}${cliente.telefone}`),
                                clientCPF: cpf
                            })
                        }
                    }
                }
            } catch (e) {

            }
            try {
                let CEPNeeded = JSON.parse(localStorage.getItem(`bitmenu-${identifier}:CEPNeeded`))
                if (!retire && CEPNeeded) {
                    let s = this.clearText(cep)
                    if (s.length < 8) {
                        this.deleteAddress(cep)
                        this.setState({
                            dateDelivery: '',
                            openConfirmation: false,
                            warning: {
                                open: true,
                                title: 'Aviso',
                                text: 'CEP inválido, selecione o endereço novamente'
                            }
                        })
                        return
                    }
                }
            } catch (e) {
                console.error(e.message)
            }

            let pedido = {
                id_loja: (dateDelivery.id_loja === undefined) ? id_loja : dateDelivery.id_loja,
                authkey: authkey,
                token: tokens[user.index],
                id_pedido: idOrder,
                tipo_pedido: 'delivery',
                dataHoraEnvio: scheduled ? this.dateTime(false) : 0,
                pedidoPorRota: (dateDeliveryRoute !== null),
                dataHora: (dateDeliveryRoute !== null) ? `${dateDeliveryRoute} ${timeRoute}` : this.dateTime(scheduled),
                scheduled: (dateDeliveryRoute !== null) ? true : scheduled,
                entrega: checkDiscount ? 0 : parseFloat(deliveryFee.toFixed(2)),
                taxaOriginalEntrega: checkDiscount ? parseFloat(deliveryFee.toFixed(2)) : 0,
                total_produtos: parseFloat(subtotal.toFixed(2)),
                desconto: checkDiscount ? 0 : parseFloat(discount.toFixed(2)),
                troco: parseFloat(change.toFixed(2)),
                obs: (localStorage.getItem(`bitmenu-${identifier}:optionsTakeWay`) !== null) ? localStorage.getItem(`bitmenu-${identifier}:optionsTakeWay`) + ' ' + observationOrder : observationOrder,
                motivo_desconto: coupon,
                gularis_desconto: valueRescue,
                total: parseFloat(total.toFixed(2)),
                status: 'PENDENTE',
                buscar: retire,
                messageToken: token,
                cupomPrimeiraCompra: cupomPrimeiraCompra,
                cupomCompraUnica: cupomCompraUnica,
                perguntas: this.questions(),
                cliente: {
                    cliente_cpf: (totem) ? '' : this.clearText(cliente.cpf),
                    cliente_email: cliente.email,
                    cliente_nome: (totem) ? this.cleanAccents(nameTotem) : this.cleanAccents(cliente.nome),
                    cliente_telefone: (totem) ? '' : this.clearText(`${cliente.ddd !== undefined ? cliente.ddd : ''}${cliente.telefone}`),
                    cliente_nascimento: (cliente.nascimento !== undefined && cliente.nascimento !== null) ? cliente.nascimento : '',
                    endereco_bairro: this.cleanAccents(bairro),
                    endereco_cep: this.clearText(cep),
                    endereco_cidade: this.cleanAccents(cidade),
                    endereco_complemento: this.cleanAccents(complemento !== undefined ? complemento : ''),
                    endereco_numero: numero,
                    endereco_rua: this.cleanAccents(rua),
                    endereco_uf: uf,
                },
                itens: this.cleanItem(itens),
                pagamentos: [
                    {
                        codigo: this.paymentCode(payment),
                        bandeira: payment === 'PAGAMENTO ONLINE' ? flag : '',
                        valor: parseFloat(valor.toFixed(2)),
                        titulo: payment,
                        ccc: this.ccc(payment, idOrder, name, month, year, cvv, numberCard)
                    }
                ],
                retorno: {
                    status: 'PENDENTE'
                },
                versao: localStorage.getItem(`bitmenu:version`)
            }
            if (scheduledDay && scheduleDelivery === 'Entrega programada' && dateDeliveryRoute === null) {
                let time = sessionStorage.getItem(`bitmenu-${identifier}:openTime`)
                pedido.dataHora = `${scheduledDeliveryTime} ${time}`
            }
            if (totem) pedido.totem = true
            if (!pedido.gularis_desconto) delete pedido['gularis_desconto']
            if (payment !== 'PAGAMENTO ONLINE') {
                delete pedido.pagamentos[0]['bandeira']
                delete pedido.pagamentos[0]['ccc']
            }
            if (sessionStorage.getItem('bitmenu:encryptedCard') !== null) {
                pedido.pagamentos[0]['cartaopagseguro'] = sessionStorage.getItem('bitmenu:encryptedCard')
                delete pedido.pagamentos[0]['ccc']
            }
            if (!scheduled) delete pedido['dataHoraEnvio']
            if (!checkDiscount) delete pedido['taxaOriginalEntrega']
            let conexao = {
                method: 'POST',
                body: JSON.stringify(pedido)
            }
            this.setState({
                openProcessOrder: true,
                statusOrder: 'Enviando pedido...',
                openConfirmation: false,
                confirmationInformation: false
            })
            try {
                if (localStorage.getItem(`bitmenu-${identifier}:teste`) !== null) {
                    pedido.teste = localStorage.getItem(`bitmenu-${identifier}:teste`)
                }
            } catch (e) {

            }
            localStorage.removeItem(`bitmenu-${identifier}:statusOrder`)
            localStorage.removeItem(`bitmenu-${identifier}:dataConfirmacaoPedido`)
            this.recordLog(pedido)
            const {returnCode, message} = await rest(`${URL_BASE}/pedido`, conexao)
            if (returnCode) {
                localStorage.setItem(`bitmenu-${identifier}:order_with_online_payment`, (pedido.pagamentos[0].ccc !== undefined))
                eventFacebook('Purchase', {currency: 'BRL', value: pedido.total})
                eventGoogle('Purchase', {
                    action: 'finaliza_compra',
                    content_name: null,
                    currency: 'BRL',
                    value: (pedido.total)
                })
                try {
                    let items = []
                    pedido.itens.forEach(i => {
                        items.push({
                            item_id: i.sku,
                            item_name: i.titulo,
                            price: i.preco,
                            quantity: i.quantidade
                        })
                    })
                    eventGA4('purchase', {
                        transaction_id: pedido.id_pedido,
                        currency: 'BRL',
                        value: pedido.total,
                        shipping: pedido.entrega,
                        items: items
                    })
                } catch (e) {

                }
                this.sucess(pedido)
            } else {
                this.error(message)
            }
            this.setState({openProcessOrder: false})
        } catch (e) {
            console.log(e)
        }
    }

    sucess = pedido => {
        try {
            this.deliveryForecasts(pedido.buscar)
            this.newIdOrder()
            if (couponOld !== pedido.id_pedido) {
                this.invalidCoupon()
                this.orderHistory(pedido)
            }
            couponOld = pedido.id_pedido
            this.clear(pedido.retire)
        } catch (e) {
            console.log(e.message)
        }
    }

    error = async (message, pedido) => {
        try {
            this.setState({
                openProcessOrder: false,
                warning: {
                    open: true,
                    tryAgain: true,
                    title: 'Falha ao enviar',
                    text: `Falha \n ${message}`
                }
            })
            let json = {
                pedido: {
                    authkey: authkey,
                    id_pedido: pedido.id_pedido,
                    retorno: {
                        status: 'CANCELADO',
                        mensagem: `O restaurante não confirmou o pedido Nº: #${pedido.id_pedido}`
                    }
                }
            }
            await rest(`${URL_BASE}/logs`, {method: 'PUT', body: JSON.stringify(json)})
        } catch (e) {
            console.log(e.message)
        }
    }

    recordLog = async pedido => {
        let object = {
            authkey_id: identifier,
            pedido: pedido,
            version: localStorage.getItem(`bitmenu:version`),
            key: pedido.id_pedido,
            data: this.dateTime(),
            dispositivo: {
                navegador: this.checkBrowser(),
                dispositivo: isMobile ? 'Mobile' : 'Pc',
                so: isMobile ? isIOS ? 'IOS' : 'Android' : 'Windons ou Mac Os'
            }
        }
        let pix = false

        try {
            pix = (pedido.pagamentos[0].codigo === 'PIX')
        } catch (e) {

        }

        let lastWish = JSON.stringify(pedido)
        localStorage.setItem(`bitmenu-${identifier}:lastWish`, lastWish)
        let status = JSON.stringify({
            pix: pix,
            id: pedido.id_pedido,
            hours: this.timeExpireOrder(),
            pixExpire: this.timeExpirePix()
        })
        localStorage.setItem(`bitmenu-${identifier}:statusOrder`, status)
        let interval = JSON.stringify({cont: 0, isCheck: true})
        localStorage.setItem(`bitmenu-${identifier}:timerInterval`, interval)
        let conexao = {method: 'POST', body: JSON.stringify(object)}
        await rest(`${URL_BASE}/logs`, conexao)
    }

    questions = () => {
        try {
            let responseQuestion = sessionStorage.getItem(`bitmenu-${identifier}:responseQuestions`)
            responseQuestion = responseQuestion !== null ? JSON.parse(responseQuestion) : []
            let array = []
            responseQuestion.forEach(a => {
                if (a.type === 3) {
                    if (array.indexOf(a.questao) === -1) {
                        array.push(a.questao)
                    }
                }
            })
            array.forEach(a => {
                let respostas = []
                let position
                responseQuestion.forEach((i, index) => {
                    if (a === i.questao) {
                        position = index
                        respostas.push(i.resposta)
                    }
                })
                responseQuestion[position].resposta = respostas
                responseQuestion[position].enviar = true
            })
            try {
                let cont = 0
                while (cont <= responseQuestion.length) {
                    if (responseQuestion[cont].type === 3 && responseQuestion[cont].enviar === undefined) {
                        let index = responseQuestion.indexOf(responseQuestion[cont])
                        responseQuestion.splice(index, 1)
                        cont--
                    } else {
                        cont++
                    }
                }
            } catch (e) {

            }
            responseQuestion.forEach(i => {
                delete i['enviar']
                delete i['type']
                delete i['questao']
            })
            return responseQuestion
        } catch (e) {
            console.log(e)
        }
    }

    deleteAddress = (cep) => {
        try {
            let cliente = localStorage.getItem(`bitmenu-${identifier}:cliente`)
            cliente = JSON.parse(cliente)
            let index = cliente.endereco.findIndex(e => (e.cep === cep))
            cliente.endereco.splice(index, 1)
            localStorage.setItem(`bitmenu-${identifier}:cliente`, JSON.stringify(cliente))
            localStorage.removeItem(`bitmenu-${identifier}:adress`)
        } catch (e) {

        }
    }

    ccc = (payment, idOrder, name, month, year, cvv, numberCard) => {
        let ccc = ''
        try {
            if (payment === 'PAGAMENTO ONLINE') {
                if ((!numberCard || !name || !month || !year || !cvv)) {
                    this.setState({
                        openSnackBar: true,
                        snackBar: 'Dados do cartão inválido',
                        openConfirmation: false,
                        confirmationInformation: false,
                    })
                } else {
                    ccc = btoa(JSON.stringify({
                        cartao: numberCard,
                        nome: name,
                        mes: month,
                        ano: year,
                        cvv: `${parseInt(cvv.split('').reverse().join('')) + parseInt(idOrder.substring(idOrder.length - 2))}`
                    }))
                }
            }
        } catch (e) {

        }
        return ccc
    }

    cleanItem = itens => {
        itens.forEach(i => {
            i.preco = i.total
            i.precoProduto = i.precoBase
            try {
                delete i['total']
            } catch (e) {

            }
            // try {
            //     delete i['precoBase']
            // } catch (e) {
            //
            // }
            i.subItens.forEach(a => {
                // try {
                //     if (a.priceVariation !== undefined && a.priceVariation !== 0) {
                //         a.preco = a.priceVariation
                //         a.precoProduto = a.priceVariation
                //     }
                // } catch (e) {
                //
                // }
                try {
                    delete a['key']
                } catch (e) {

                }
                try {
                    delete a['priceVariation']
                } catch (e) {

                }
                try {
                    delete a['variacao']
                } catch (e) {

                }
                try {
                    delete a['soma']
                } catch (e) {

                }
                try {
                    delete a['ordem']
                } catch (e) {

                }
            })
        })
        return itens
    }

    clear = retire => {
        this.cleaQtdeMax()
        let itens = localStorage.getItem(`bitmenu-${identifier}:itens`)
        localStorage.setItem(`bitmenu-${identifier}:itensOld`, itens)
        localStorage.removeItem(`bitmenu-${identifier}:itens`)
        sessionStorage.removeItem(`bitmenu-${identifier}:scheduleDelivery`)
        sessionStorage.removeItem(`bitmenu-${identifier}:card`)
        sessionStorage.removeItem(`bitmenu-${identifier}:discount`)
        sessionStorage.removeItem(`bitmenu-${identifier}:cupomPrimeiraCompra`)
        sessionStorage.removeItem(`bitmenu-${identifier}:cupomCompraUnica`)
        sessionStorage.removeItem(`bitmenu-${identifier}:change`)
        sessionStorage.removeItem(`bitmenu-${identifier}:payment`)
        sessionStorage.removeItem(`bitmenu-${identifier}:responseQuestions`)
        sessionStorage.removeItem(`bitmenu-${identifier}:confirmedAnswers`)
        sessionStorage.removeItem(`bitmenu-${identifier}:cashback`)
        sessionStorage.removeItem(`bitmenu-${identifier}:transictionID`)
        sessionStorage.removeItem('bitmenu:encryptedCard')
        sessionStorage.removeItem(`bitmenu-${identifier}:dateDeliveryRoute`)
        sessionStorage.removeItem(`bitmenu-${identifier}:timeRoute`)
        sessionStorage.removeItem(`bitmenu-${identifier}:freeDelivery`)
        sessionStorage.removeItem(`bitmenu-${identifier}:dateSchedule`)
        this.setState({openProcessOrder: false, valueRescue: 0, observationOrder: ''})
        this.props.history.replace({
            pathname: '/',
            response: {
                open: true,
                message: (isSafari || isIOS) ? 'Pedido enviado com sucesso' : 'Aguarde a confirmação ...',
                retire: retire
            }
        })
    }

    cleanAccents = text => {
        return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    }

    clearText = text => {
        try {
            text = text.replace(/[^\d]+/g, '')
            return text.trim()
        } catch (e) {
            console.log(e)
        }
        return '';
    }

    clearTextNeighborhood = text => {
        text = text.normalize("NFD").replace(/[^A-Z0-9]/ig, "")
        text = text.trim()
        return text.toLowerCase()
    }

    balanceGularis = (cpf, gularisToken) => {
        let url = `https://app.gularis.com.br/api/v1/consumidores/saldo?cpf=${cpf}`
        fetch(url, {
            headers: {
                'Token-Loja': `${gularisToken}`,
                'Content-type': 'application/json',
            },
            method: 'get'
        })
            .then((response) => response.json())
            .then((json) => {
                if (json.status !== 'error') {
                    const {data: {cpf, saldo}} = json
                    let maskCpf = cpf.substring(0, 3) + '.' + cpf.substring(3, 6) + '.' + cpf.substring(6, 9) + '-' + cpf.substring(9, 11)
                    this.setState({
                        gularis: true,
                        nameGularis: cpf.length === 11 ? maskCpf : cpf,
                        balanceGularis: saldo
                    })
                } else {
                    this.setState({gularis: false})
                }
            })
            .catch((error) => {
                console.error(error)
            })
    }

    deliveryTimes = (checkHours = false) => {
        try {
            let {timeDeliverMax, timeTakeAwayMax} = JSON.parse(localStorage.getItem(`bitmenu-${identifier}:data`))
            let adress = localStorage.getItem(`bitmenu-${identifier}:adress`)
            adress = adress !== null ? JSON.parse(adress) : ''
            let retire = adress.retire !== undefined ? adress.retire : false
            let hours
            if (retire) {
                hours = JSON.parse(localStorage.getItem(`bitmenu-${identifier}:weekShopStore`))
            } else {
                hours = JSON.parse(localStorage.getItem(`bitmenu-${identifier}:weekDeliveryStore`))
            }
            let array = []
            let timeMax = retire ? timeTakeAwayMax : timeDeliverMax
            let index
            for (let i = 0; i < hours.length; i++) {
                turnedDay = false
                index = 0
                let a = hours[i].openTime
                a = a.substring(a, a.length - 3)
                a = this.somaHora(a, '00:30', true)
                let b = hours[i].closeTime
                b = b.substring(b, b.length - 3)
                let open = this.convertDateTime(a, false)
                let close = this.convertDateTime(b, (b.substring(0, 1) === '0'))
                if (open > close) {
                    continue
                }
                array.push(a)
                while (this.timeCondition(a, b)) {
                    a = this.somaHora(a, '00:30')
                    if (index === 0) {
                        a = a.split(':')
                        a = a[1] < 30 ? `${a[0]}:00` : `${a[0]}:30`
                    }
                    if (this.convertDateTime(a, turnedDay) < this.convertDateTime(b, true)) {
                        array.push(a)
                    }
                    if (++index >= 48) {
                        break
                    }
                }
                array.push(b)
            }
            let current = this.somaHora(this.currentTime(), timeMax)
            hours = []
            let starting = false
            array.forEach(i => {
                let horarios = this.convertDateTime(i, false)
                let horarioMaisTempoTele = this.convertDateTime(current, false)
                if (horarios > horarioMaisTempoTele || starting) {
                    starting = true
                    hours.push(i)
                }
            })
            if (checkHours) {
                let scheduleDelivery = sessionStorage.getItem(`bitmenu-${identifier}:scheduleDelivery`)
                if (scheduleDelivery !== null) {
                    if (!hours.includes(scheduleDelivery)) {
                        sessionStorage.removeItem(`bitmenu-${identifier}:scheduleDelivery`)
                    }
                }
            }
            this.setState({receptionTimes: hours})
        } catch (e) {

        }
    }

    timeCondition = (a, b) => {
        if (this.convertDateTime(a, turnedDay) < this.convertDateTime(b, true)) {
            if (a === '23:30') {
                turnedDay = true
            }
            return true
        } else {
            return false
        }
    }

    convertDateTime = (hora, close) => {
        hora = hora.split(':')
        var d = new Date()
        let s = hora[0].substring(0, 1)
        let dia = d.getDate()
        if (close && s === '0') {
            dia = dia + 1
        }
        let data = new Date(d.getFullYear(), d.getMonth(), dia, hora[0], hora[1])
        return data.getTime()
    }

    somaHora = (hrA, hrB, addDay = true) => {
        if (hrA.length != 5 || hrB.length != 5) return '00:00'
        let temp = 0
        let nova_h = 0
        let novo_m = 0
        let hora1 = hrA.substr(0, 2) * 1
        let hora2 = hrB.substr(0, 2) * 1
        let minu1 = hrA.substr(3, 2) * 1
        let minu2 = hrB.substr(3, 2) * 1
        temp = minu1 + minu2
        while (temp > 59) {
            nova_h++
            temp = temp - 60
        }
        novo_m = temp.toString().length == 2 ? temp : ("0" + temp)
        temp = hora1 + hora2 + nova_h
        while (temp > 23 && addDay) {
            temp = temp - 24
        }
        nova_h = temp.toString().length == 2 ? temp : ("0" + temp)
        return nova_h + ':' + novo_m
    }

    day = () => {
        let d = new Date()
        return d.getDay() + 1
    }

    timeControl = () => {
        let closedHoliday = JSON.parse(localStorage.getItem(`bitmenu-${identifier}:closedHoliday`))
        let deliveryToday = JSON.parse(localStorage.getItem(`bitmenu-${identifier}:deliveryToday`))
        let takeAwayToday = JSON.parse(localStorage.getItem(`bitmenu-${identifier}:takeAwayToday`))
        let open = JSON.parse(localStorage.getItem(`bitmenu-${identifier}:open`))
        const {retire} = this.state
        return retire ? (takeAwayToday && closedHoliday && open) : (deliveryToday && closedHoliday && open)
    }

    currentTime = () => {
        let data = new Date()
        let hours = data.getHours().toString()
        let minutes = data.getMinutes().toString()
        hours = hours.length === 1 ? '0' + hours : hours
        minutes = minutes.length === 1 ? '0' + minutes : minutes
        return `${hours}:${minutes}`
    }

    timeExpireOrder = () => {
        let date = new Date()
        date.setHours(date.getHours() + 2)
        return date.getTime()
    }

    timeExpirePix = () => {
        let date = new Date()
        date.setMinutes(date.getMinutes() + 15)
        return date.getTime()
    }

    deliveryForecasts = retire => {
        try {
            let data = JSON.parse(localStorage.getItem(`bitmenu-${identifier}:data`))
            let array = []
            if (retire) {
                array.push(data.timeTakeAwayMin)
                array.push(data.timeTakeAwayMax)
            } else {
                array.push(data.timeDeliverMin)
                array.push(data.timeDeliverMax)
            }
            localStorage.setItem(`bitmenu-${identifier}:deliveryForecasts`, JSON.stringify(array))
        } catch (e) {

        }
    }

    newIdOrder = () => {
        try {
            localStorage.setItem(`bitmenu:idOrder`, this.key())
        } catch (e) {
            console.log(e)
        }
    }

    orderHistory = json => {
        try {
            let orderHistory = localStorage.getItem(`bitmenu-${identifier}:orderHistory`)
            try {
                orderHistory = orderHistory === 'null' ? null : orderHistory
            } catch (e) {

            }
            orderHistory = orderHistory !== null ? JSON.parse(orderHistory) : []
            let array = orderHistory.reverse()
            for (let i = 0; i < array.length; i++)
                if (json.id_pedido == array[i].id_pedido) return
            orderHistory.push(json)
            localStorage.setItem(`bitmenu-${identifier}:orderHistory`, JSON.stringify(orderHistory))
        } catch (e) {
            console.error(e)
        }
    }

    checkToken = () => {
        let token = localStorage.getItem(`bitmenu-${identifier}:token`)
        return (token === 'repeat' || Number.isInteger(parseInt(token)))
    }

    checkBrowser = () => {
        try {
            var isOpera = !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0
            var isFirefox = typeof InstallTrigger !== 'undefined'
            var isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0
            var isChrome = !!window.chrome && !isOpera
            var isIE = false || !!document.documentMode
            if (isOpera) {
                return 'Opera'
            } else if (isFirefox) {
                return 'Firefox'
            } else if (isChrome) {
                return 'Chrome'
            } else if (isSafari) {
                return 'Safari'
            } else if (isIE) {
                return 'IE'
            } else {
                return 'Outros'
            }
        } catch (e) {

        }
    }

    permissionNotification = async () => {
        try {
            const messaging = firebase.messaging()
            await messaging.requestPermission()
            const token = await messaging.getToken()
            //this.recordToken(token)
            return token
        } catch (error) {
            console.error(error)
        }
    }

    recordToken = async token => {
        let tokenOld = localStorage.getItem(`bitmenu-${identifier}:token`)
        if (token !== tokenOld) {
            let key = this.key()
            let json = {
                authkey_id: identifier,
                device: 'BITBAR_APP',
                key: key,
                token: token
            }
            localStorage.setItem(`bitmenu-${identifier}:token`, token)
            const {message} = await rest(`${URL_BASE}/tokenPush`, {method: 'POST', body: JSON.stringify(json)})
            console.log(message)
        }
    }

    validateCoupon = () => {
        const {authkey, id_loja, coupon, cliente, noRecord} = this.state
        if (cliente === '' && !noRecord) {
            this.setState({openLogin: true})
        } else if (coupon !== '') {
            fetch(`https://bitbar.online/util/api/v3/verificacupom.php?authkey=${authkey}&id=${id_loja}&cupom=${coupon}`)
                .then((response) => response.json())
                .then((json) => {
                    if (json.compra_unica) {
                        this.singlePurchase(json, coupon)
                    } else if (json.primeira_compra) {
                        this.firstCoupon(json, coupon)
                    } else {
                        this.checkCoupon(json, coupon)
                    }
                })
                .catch((error) => {
                    console.error(error)
                })
        } else {
            this.setState({
                openSnackBar: true,
                snackBar: `Cupom inválido`
            })
        }
    }

    invalidCoupon = () => {
        try {
            const {authkey, id_loja, coupon} = this.state
            if (coupon) {
                fetch(`https://bitbar.online/util/api/v3/queimacupom.php?authkey=${authkey}&id=${id_loja}&cupom=${coupon}`)
                    .then((response) => response.json())
                    .then((json) => {
                        console.log(json)
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            }
        } catch (e) {
            console.log(e)
        }
    }

    firstCoupon = (json = null, coupon = null) => {
        if (coupon) {
            this.checkCoupon(json, coupon)
            sessionStorage.setItem(`bitmenu-${identifier}:cupomPrimeiraCompra`, coupon)
            this.setState({cupomPrimeiraCompra: true})
        } else {
            let s = sessionStorage.getItem(`bitmenu-${identifier}:cupomPrimeiraCompra`)
            this.setState({cupomPrimeiraCompra: (s !== null)})
        }
    }

    singlePurchase = (json, coupon = null) => {
        if (coupon) {
            this.checkCoupon(json, coupon)
            sessionStorage.setItem(`bitmenu-${identifier}:cupomCompraUnica`, coupon)
            this.setState({cupomCompraUnica: true})
        } else {
            let s = sessionStorage.getItem(`bitmenu-${identifier}:cupomCompraUnica`)
            this.setState({cupomCompraUnica: (s !== null)})
        }
    }

    checkDiscountedItem = itens => {
        itens.forEach((i, index) => {
            if (i.titulo.includes(' - (Desconto)')) {
                itens.splice(index, 1)
            }
        })
    }

    checkCoupon = (json, coupon) => {
        let {subtotal, deliveryFee, itens} = this.state
        this.checkDiscountedItem(itens)
        let {sucesso, tipo_desconto, valor, compra_minima, mensagem} = json
        if (sucesso) {
            compra_minima = parseFloat(compra_minima)
            switch (tipo_desconto) {
                case 'DELIVERY_FEE':
                    if (subtotal >= compra_minima) {
                        if (deliveryFee !== 0) {
                            this.setState({discount: deliveryFee})
                        }
                        sessionStorage.setItem(`bitmenu-${identifier}:discount`, JSON.stringify({
                            type: 'DELIVERY_FEE',
                            coupon: coupon,
                            min: compra_minima
                        }))
                    } else {
                        this.setState({
                            warning: {
                                open: true,
                                title: 'Aviso',
                                text: `Esse cupom tem um valor mínimo de compra de ${compra_minima.toLocaleString('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL'
                                })}`
                            }, coupon: ''
                        })
                    }
                    break
                case 'VALUE':
                    if (subtotal >= compra_minima) {
                        this.setState({discount: parseFloat(valor)})
                        sessionStorage.setItem(`bitmenu-${identifier}:discount`, JSON.stringify({
                            type: 'VALUE',
                            coupon: coupon,
                            value: parseFloat(valor),
                            min: compra_minima
                        }))
                    } else {
                        this.setState({
                            warning: {
                                open: true,
                                title: 'Aviso',
                                text: `Esse cupom tem um valor mínimo de compra de ${compra_minima.toLocaleString('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL'
                                })}`
                            }, coupon: ''
                        })
                    }
                    break
                case 'PERCENTAGE':
                    if (subtotal >= compra_minima) {
                        let percentage = parseFloat(valor)
                        valor = (parseFloat(valor) / 100)
                        valor = (subtotal * valor)
                        //valor = parseFloat(valor.toFixed(1))
                        valor = parseFloat(valor)
                        let desconto_max = 0
                        try {
                            desconto_max = (json.desconto_max !== undefined) ? parseFloat(json.desconto_max) : 0
                            if (valor > desconto_max) valor = (desconto_max === 0) ? valor : desconto_max
                        } catch (e) {

                        }
                        this.setState({discount: valor})
                        sessionStorage.setItem(`bitmenu-${identifier}:discount`, JSON.stringify({
                            type: 'PERCENTAGE',
                            percentage: percentage,
                            coupon: coupon,
                            value: valor,
                            desconto_max: desconto_max,
                            min: compra_minima
                        }))
                    } else {
                        this.setState({
                            warning: {
                                open: true,
                                title: 'Aviso',
                                text: `Esse cupom tem um valor mínimo de compra de ${compra_minima.toLocaleString('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL'
                                })}`
                            }, coupon: ''
                        })
                    }
                    break
                default:
                    if (subtotal >= compra_minima) {
                        let menu = JSON.parse(sessionStorage.getItem(`bitmenu-${identifier}:menu`))
                        let item = ''
                        for (let i = 0; i < menu.length; i++) {
                            if (menu[i].sku === json.valor) {
                                item = menu[i]
                                break
                            }
                        }
                        if (item !== '') {
                            if (item.variations.length === 0) {
                                const {sku, product, price} = item
                                const i = {
                                    sku: sku,
                                    titulo: `${product} - (Desconto)`,
                                    quantidade: 1,
                                    precoProduto: price,
                                    precoBase: price,
                                    preco: price,
                                    obs: '',
                                    subItens: [],
                                    hora: this.orderTime(),
                                    itemCoupon: true
                                }
                                itens.push(i)
                                this.setState({itens: itens, discount: price, subtotal: subtotal + price})
                                localStorage.setItem(`bitmenu-${identifier}:itens`, JSON.stringify(itens))
                                sessionStorage.setItem(`bitmenu-${identifier}:discount`, JSON.stringify({
                                    type: 'PRODUCT',
                                    coupon: coupon,
                                    value: valor,
                                    min: compra_minima
                                }))
                            } else {
                                this.setState({itens: itens})
                                localStorage.setItem(`bitmenu-${identifier}:itens`, JSON.stringify(itens))
                                sessionStorage.setItem(`bitmenu-${identifier}:discount`, JSON.stringify({
                                    type: 'PRODUCT',
                                    coupon: coupon,
                                    value: valor,
                                    min: compra_minima
                                }))
                                this.props.history.push({
                                    pathname: '/sacola',
                                    state: {dados: item, description: '- (Desconto)', itemCoupon: true}
                                })
                            }
                        } else {
                            sessionStorage.removeItem(`bitmenu-${identifier}:discount`)
                            sessionStorage.removeItem(`bitmenu-${identifier}:cupomPrimeiraCompra`)
                            sessionStorage.removeItem(`bitmenu-${identifier}:cupomCompraUnica`)
                            this.setState({
                                warning: {
                                    open: true,
                                    title: 'Aviso',
                                    text: `O item desse cupom não está no cardápio.`
                                }, coupon: '', discount: 0
                            })
                        }
                    } else {
                        sessionStorage.removeItem(`bitmenu-${identifier}:discount`)
                        sessionStorage.removeItem(`bitmenu-${identifier}:cupomPrimeiraCompra`)
                        sessionStorage.removeItem(`bitmenu-${identifier}:cupomCompraUnica`)
                        this.setState({
                            warning: {
                                open: true,
                                title: 'Aviso',
                                text: `Esse cupom tem um valor mínimo de compra de ${compra_minima.toLocaleString('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL'
                                })}`
                            }, coupon: '', discount: 0
                        })
                    }
                    break
            }
        } else {
            mensagem = decodeURIComponent(escape(mensagem))
            this.setState({warning: {open: true, title: 'Aviso', text: mensagem}, coupon: '', discount: 0})
            sessionStorage.removeItem(`bitmenu-${identifier}:discount`)
            sessionStorage.removeItem(`bitmenu-${identifier}:cupomPrimeiraCompra`)
            sessionStorage.removeItem(`bitmenu-${identifier}:cupomCompraUnica`)
        }
    }

    orderTime = () => {
        let d = new Date()
        let hours = d.getHours()
        let day = d.getDate()
        if (hours === 0) {
            hours = 0
            day += 1
        } else if (hours === 21) {
            hours = 1
            day += 1
        } else if (hours === 22) {
            hours = 2
            day += 1
        } else if (hours === 23) {
            hours = 3
            day += 1
        } else {
            hours += 4
        }
        return new Date(d.getFullYear(), d.getMonth(), day, hours, d.getMinutes())
    }

    checkDiscount = (taxa, itens, subtotal) => {
        try {
            let discountApplied = this.state.discount
            let discount = sessionStorage.getItem(`bitmenu-${identifier}:discount`)
            discount = discount !== null ? JSON.parse(discount) : ''
            if (discount.type === 'VALUE' && (subtotal - discountApplied) >= discount.min) {
                this.setState({discount: discount.value, coupon: discount.coupon})
            } else if (discount.type === 'DELIVERY_FEE' && (subtotal - discountApplied) >= discount.min) {
                this.setState({discount: taxa, coupon: discount.coupon})
            } else if (discount.type === 'PERCENTAGE' && (subtotal - discountApplied) >= discount.min) {
                let valor = parseFloat((subtotal * (discount.percentage / 100)))
                this.setState({
                    discount: (valor > discount.desconto_max) ? (discount.desconto_max === 0) ? valor : discount.desconto_max : valor,
                    coupon: discount.coupon
                })
            } else if (discount.type === 'PRODUCT' && (subtotal - discountApplied) >= discount.min) {
                let desconto = false
                for (let i = 0; i < itens.length; i++) {
                    if (!itens[i].itemCoupon) continue
                    if (discount.value === itens[i].sku) {
                        desconto = true
                        let valueDiscount = itens[i].precoProduto
                        if (itens[i].subItens !== undefined && itens[i].subItens.length !== 0) {
                            itens[i].subItens.forEach(i => {
                                if (i.descontaComCupom)
                                    valueDiscount += (i.precoProduto * i.quantidade)
                            })
                        }
                        this.setState({discount: valueDiscount, coupon: discount.coupon})
                        break
                    }
                }
                if (!desconto) {
                    this.setState({discount: 0, coupon: ''})
                    sessionStorage.removeItem(`bitmenu-${identifier}:discount`)
                    sessionStorage.removeItem(`bitmenu-${identifier}:cupomPrimeiraCompra`)
                    sessionStorage.removeItem(`bitmenu-${identifier}:cupomCompraUnica`)
                }
            } else {
                try {
                    let position = -1
                    for (let i = 0; i < itens.length; i++) {
                        if (itens[i].titulo.includes('(Desconto)')) {
                            position = i
                            break
                        }
                    }
                    if (position !== -1) {
                        itens.splice(position, 1)
                        localStorage.setItem(`bitmenu-${identifier}:itens`, JSON.stringify(itens))
                        let subtotal = this.calculateSubTotal(itens)
                        this.setState({
                            itens: itens,
                            openSnackBar: true,
                            snackBar: 'Alguns produtos de desconto foram removidos',
                            subtotal: subtotal
                        })
                    }
                } catch (e) {

                }
                sessionStorage.removeItem(`bitmenu-${identifier}:discount`)
                sessionStorage.removeItem(`bitmenu-${identifier}:cupomPrimeiraCompra`)
                sessionStorage.removeItem(`bitmenu-${identifier}:cupomCompraUnica`)
                this.setState({discount: 0, coupon: ''})
            }
        } catch (error) {

        }
    }

    paymentCode = payment => {
        try {
            const {paymentTypes, card: {flag}} = this.state
            for (let i = 0; paymentTypes.length; i++) {
                if (payment === 'PAGAMENTO ONLINE') {
                    if ((paymentTypes[i].online === 'True') && paymentTypes[i].description.toUpperCase().includes(flag.toUpperCase())) {
                        return paymentTypes[i].code
                    }
                } else if (payment === paymentTypes[i].description.toUpperCase()) {
                    return paymentTypes[i].code
                }
            }
        } catch (e) {
            return ''
        }
    }

    key = () => {
        var hora = new Date()
        return hora.getTime().toString()
    }

    dateTime = scheduled => {
        let {scheduledDeliveryTime} = this.state
        let data = new Date()
        let year = data.getUTCFullYear()
        let mouth = (data.getUTCMonth() + 1).toString()
        let day = data.getUTCDate().toString()
        let hours = data.getHours().toString()
        let minutes = data.getMinutes().toString()
        let seconds = data.getSeconds().toString()
        mouth = mouth.length === 1 ? '0' + mouth : mouth
        day = day.length === 1 ? '0' + day : day
        hours = hours.length === 1 ? '0' + hours : hours
        minutes = minutes.length === 1 ? '0' + minutes : minutes
        seconds = seconds.length === 1 ? '0' + seconds : seconds
        if ((scheduledDeliveryTime !== undefined && scheduledDeliveryTime !== '') && scheduled) {
            return `${year}-${mouth}-${day} ${scheduledDeliveryTime}:00`
        } else {
            return `${year}-${mouth}-${day} ${hours}:${minutes}:${seconds}`
        }
    }

    calculateSubTotal = itens => {
        let total = 0
        itens.forEach(i => {
            let valorSubitens = 0
            let combo = false
            let maxValue = 0
            let priceVariation = this.priceVariation(i.subItens)
            i.subItens.forEach(s => {
                if (s.soma === 'MAXVALUE') {
                    maxValue = s.precoProduto > maxValue ? s.precoProduto : maxValue
                } else {
                    valorSubitens += (s.precoProduto * s.quantidade)
                }
                combo = s.sku.includes("C")
            })
            let precoBase = combo ? 0 : i.precoBase
            total += ((precoBase + valorSubitens + priceVariation + maxValue) * i.quantidade)
        })
        this.setState({subtotal: total})
        return total
    }

    priceVariation = variations => {
        let steps = []
        let total = 0
        try {
            variations.forEach(i => {
                if (i.priceVariation !== 0 && i.priceVariation !== undefined) {
                    let s = `${i.key}`
                    if (steps.indexOf(s) === -1) {
                        steps.push(s)
                        total += i.priceVariation
                    }
                }
            })
        } catch (e) {

        }
        return total
    }

    checkArea = (neighborhood, city, uf, cep) => {
        let area = localStorage.getItem(`bitmenu-${identifier}:area_atendimento`)
        area = showData(area)
        area = JSON.parse(area)
        let attendance
        let deliveryFee
        let id_loja = ''
        let message = ''

        try {
            if (cep !== '' && cep !== undefined) {
                let cepsBloqueados = showData(localStorage.getItem(`bitmenu-${identifier}:bloqueados`))
                if (cepsBloqueados.includes(cep)) return {attendance: false, message: ''}
            }
        } catch (e) {
            console.log(e.message)
        }

        for (let e = 0; e < area.length; e++) {
            attendance = true
            deliveryFee = 0
            if ((uf.toLowerCase() === area[e].UF.toLowerCase()) && (city.toLowerCase() === area[e].name.toLowerCase())) {
                let districts = area[e].districts
                districts = districts !== undefined ? districts : []
                for (let x = 0; x < districts.length; x++) {
                    if (this.clearTextNeighborhood(neighborhood) === this.clearTextNeighborhood(districts[x].name)) {
                        let restrictions = districts[x].restrictions
                        restrictions = restrictions !== undefined ? restrictions : []
                        deliveryFee = districts[x].deliveryFee
                        id_loja = districts[x].id_loja
                        if (restrictions.length === 0) {
                            attendance = true
                            break
                        }
                        let block = false
                        for (let i = 0; i < restrictions.length; i++) {
                            if (cep === restrictions[i].postalCode) {
                                try {
                                    message = restrictions[i].obs !== undefined ? restrictions[i].obs : ''
                                    try {
                                        if (restrictions[i].status === 'block') {
                                            block = true
                                        } else {
                                            id_loja = restrictions[i].id_loja
                                            block = false
                                        }
                                    } catch (e) {
                                        console.log(e)
                                    }
                                } catch (e) {
                                    block = true
                                }
                            }
                        }
                        if (block) {
                            attendance = false
                            deliveryFee = 0
                            break
                        } else {
                            attendance = true
                            break
                        }
                    } else {
                        attendance = false
                    }
                }
                break
            } else {
                attendance = false
            }
        }
        return {attendance: attendance, deliveryFee: deliveryFee, message: message, id_loja: id_loja}
    }

    checkScheduledDelivery = () => {
        if (!this.timeControl()) {
            this.setState({scheduleDelivery: 'Entrega programada'})
        }
        this.deliveryTimes(true)
        let scheduleDelivery = sessionStorage.getItem(`bitmenu-${identifier}:scheduleDelivery`)
        if (scheduleDelivery !== null) {
            this.setState({
                scheduleDelivery: `Entrega programada`,
                scheduledDeliveryTime: scheduleDelivery,
                openReceptionTimes: false
            })
        }
    }

    checkScheduledTime = () => {
        let scheduleDelivery = sessionStorage.getItem(`bitmenu-${identifier}:scheduleDelivery`)
        if (scheduleDelivery !== null) {
            let current = this.currentTime()
            let {timeDeliverMax, timeTakeAwayMax} = JSON.parse(localStorage.getItem(`bitmenu-${identifier}:data`))
            let adress = localStorage.getItem(`bitmenu-${identifier}:adress`)
            adress = adress !== null ? JSON.parse(adress) : ''
            let retire = adress.retire !== undefined ? adress.retire : false
            if (retire) {
                current = this.somaHora(current, timeTakeAwayMax)
            } else {
                current = this.somaHora(current, timeDeliverMax)
            }
            if (this.convertDateTime(current, false) > this.convertDateTime(scheduleDelivery, false)) {
                sessionStorage.removeItem(`bitmenu-${identifier}:scheduleDelivery`)
                this.setState({
                    scheduleDelivery: `Entrega imediata`,
                    scheduledDeliveryTime: ''
                })
            }
        } else {
            if (!this.timeControl()) {
                this.setState({scheduleDelivery: 'Entrega programada', scheduledDeliveryTime: ''})
            } else {
                this.setState({scheduleDelivery: `Entrega imediata`, scheduledDeliveryTime: ''})
            }
        }
    }

    checkModePayment = (paymentOnline, paymentPhysical, cashPayments) => {
        let adress = localStorage.getItem(`bitmenu-${identifier}:adress`)
        adress = adress !== null ? JSON.parse(adress) : ''
        let payment = sessionStorage.getItem(`bitmenu-${identifier}:payment`)
        payment = payment !== null ? payment : ''
        if (adress.retire && ((payment !== 'PAGAMENTO ONLINE') && (payment !== 'Pix') && (payment !== 'Dinheiro')) && (paymentPhysical === 'DELIVERY')) {
            sessionStorage.removeItem(`bitmenu-${identifier}:payment`)
            sessionStorage.removeItem(`bitmenu-${identifier}:change`)
            this.setState({change: 0, payment: ''})
        } else if (adress.retire && (payment === 'PAGAMENTO ONLINE') && paymentOnline === 'DELIVERY') {
            sessionStorage.removeItem(`bitmenu-${identifier}:payment`)
            sessionStorage.removeItem(`bitmenu-${identifier}:card`)
            this.setState({card: '', payment: ''})
        } else if (adress.retire && (payment === 'Dinheiro') && cashPayments === 'DELIVERY') {
            sessionStorage.removeItem(`bitmenu-${identifier}:payment`)
            sessionStorage.removeItem(`bitmenu-${identifier}:card`)
            this.setState({card: '', payment: ''})
        } else if (!adress.retire && ((payment !== 'PAGAMENTO ONLINE') && (payment !== 'Pix') && (payment !== 'Dinheiro')) && (paymentPhysical === 'TAKEAWAY')) {
            sessionStorage.removeItem(`bitmenu-${identifier}:payment`)
            sessionStorage.removeItem(`bitmenu-${identifier}:change`)
            this.setState({change: 0, payment: ''})
        } else if (!adress.retire && (payment === 'PAGAMENTO ONLINE') && paymentOnline === 'TAKEAWAY') {
            sessionStorage.removeItem(`bitmenu-${identifier}:payment`)
            sessionStorage.removeItem(`bitmenu-${identifier}:card`)
            this.setState({card: '', payment: ''})
        } else if (!adress.retire && (payment === 'Dinheiro') && cashPayments === 'TAKEAWAY') {
            sessionStorage.removeItem(`bitmenu-${identifier}:payment`)
            sessionStorage.removeItem(`bitmenu-${identifier}:card`)
            this.setState({card: '', payment: ''})
        }
    }

    checkRescue = (valueRescue, fee, subtotal) => {
        let discount = sessionStorage.getItem(`bitmenu-${identifier}:discount`)
        discount = discount !== null ? JSON.parse(discount) : 0
        let total = (subtotal + fee - discount)
        valueRescue = parseFloat(valueRescue)
        valueRescue = valueRescue > total ? total : valueRescue
        sessionStorage.setItem(`bitmenu-${identifier}:cashback`, valueRescue)
        return valueRescue
    }

    checkItemOld = () => {
        let itens = localStorage.getItem(`bitmenu-${identifier}:itensOld`)
        if (itens !== null) {
            localStorage.setItem(`bitmenu-${identifier}:itens`, itens)
        }
    }

    checkItem = item => {
        let itens = []
        item.forEach(i => {
            let date1 = new Date(i.hora)
            let date2 = new Date()
            date2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate(), date2.getHours(), date2.getMinutes())
            if (date1 >= date2) itens.push(i)
        })

        let menu = JSON.parse(sessionStorage.getItem(`bitmenu-${identifier}:menu`))

        let arrayVerificacao = []
        itens.forEach(i => {
            menu.forEach(m => {
                try {
                    if ((i.sku === m.sku) && (m.cat !== 'Destaques') && (i.precoBase === m.price)) {
                        // if (i.subItens.length > 0) {
                        //     let array = []
                        //     i.subItens.forEach(s => {
                        //         m.variations.forEach(v => {
                        //             v.options.forEach(o => {
                        //                 if (s.sku === o.sku) {
                        //                     if (!array.includes(s.sku + s.key)) {
                        //                         array.push(s.sku + s.key)
                        //                     }
                        //                 }
                        //             })
                        //         })
                        //     })
                        //     if (array.length === i.subItens.length) {
                        //         arrayVerificacao.push(i)
                        //     }
                        // } else {
                        arrayVerificacao.push(i)
                        //  }
                    }
                } catch (e) {

                }
            })
        })

        itens = arrayVerificacao

        if (itens.length) {
            localStorage.setItem(`bitmenu-${identifier}:itens`, JSON.stringify(itens))
        } else {
            localStorage.removeItem(`bitmenu-${identifier}:itens`)
        }
        if (item.length !== itens.length) {
            this.setState({
                snackBar: 'Alguns itens foram retirados do carrinho por valores desatualizados ou estarem indisponíveis no momento',
                openSnackBar: true
            })
        }
        return itens
    }

    checkModules = () => {
        try {
            let modules = localStorage.getItem(`bitmenu-${identifier}:modules`)
            modules = modules !== null ? parseInt(modules) : 0
            let cliente = localStorage.getItem(`bitmenu-${identifier}:cliente`)
            cliente = cliente !== null ? JSON.parse(cliente) : ''
            let adress = localStorage.getItem(`bitmenu-${identifier}:adress`)
            adress = adress !== null ? JSON.parse(adress) : ''
            if (modules === 2) {
                let obj = {
                    cep: cliente.endereco[0].cep,
                    bairro: cliente.endereco[0].bairro,
                    cidade: cliente.endereco[0].cidade,
                    complemento: cliente.endereco[0].complemento,
                    numero: cliente.endereco[0].numero,
                    rua: cliente.endereco[0].rua,
                    uf: cliente.endereco[0].uf,
                    retire: true,
                    deliveryFee: 0
                }
                localStorage.setItem(`bitmenu-${identifier}:adress`, JSON.stringify(obj))
            } else if (modules === 1 && adress.retire) {
                localStorage.removeItem(`bitmenu-${identifier}:adress`)
            }
        } catch (e) {

        }
    }

    checkFeeRadius = () => {
        try {
            let adress = localStorage.getItem(`bitmenu-${identifier}:adress`)
            adress = adress !== null ? JSON.parse(adress) : ''
            let radius = localStorage.getItem(`bitmenu-${identifier}:serviceRadius`)
            radius = showData(radius)
            if (adress !== '' && radius !== null && !adress.retire) {
                if (adress.latitude === undefined) {
                    return localStorage.removeItem(`bitmenu-${identifier}:adress`)
                } else {
                    const {answer, price} = this.calculateRadius({
                        latitude: adress.latitude,
                        longitude: adress.longitude
                    })
                    adress.deliveryFee = price
                    adress.radius = answer
                    localStorage.setItem(`bitmenu-${identifier}:adress`, JSON.stringify(adress))
                }
            }
        } catch (e) {

        }
    }

    calculateRadius = b => {
        let coordinates = localStorage.getItem(`bitmenu-${identifier}:restaurantCoordinates`)

        let radius = localStorage.getItem(`bitmenu-${identifier}:serviceRadius`)
        radius = showData(radius)
        radius = JSON.parse(radius)

        if (coordinates !== null) {
            coordinates = coordinates.split(',')
            let a = {latitude: parseFloat(coordinates[0]), longitude: parseFloat(coordinates[1])}
            let distance = getDistance(a, b)
            for (let i = 0; i < radius.length; i++) {
                if (distance <= radius[i].distance) {
                    return {answer: true, price: radius[i].price}
                }
            }
        }
        return {answer: false, price: 0}
    }

    adress = () => {
        let fee = 0
        let adress = localStorage.getItem(`bitmenu-${identifier}:adress`)
        adress = adress !== null ? JSON.parse(adress) : ''
        if (adress !== '') {
            let {retire, bairro, cidade, uf, cep, radius, deliveryFee} = adress
            if (!retire) {
                let {attendance, deliveryFee, message, id_loja} = this.checkArea(bairro, cidade, uf, cep)
                adress.id_loja = id_loja
                if (attendance) {
                    fee = deliveryFee
                } else {
                    localStorage.removeItem(`bitmenu-${identifier}:adress`)
                    this.setState({
                        dateDelivery: '',
                        warning: {
                            open: true,
                            title: 'Aviso',
                            text: message !== '' ? message : 'Infelizmente não atendemos a sua região'
                        }
                    })
                    return
                }
                this.setState({noticeToAddress: message})
            }
            this.setState({
                storesLocation: adress.storesLocation !== undefined ? adress.storesLocation : '',
                dateDelivery: adress,
                deliveryFee: radius ? deliveryFee : fee,
                retire: retire
            })
            fee = radius ? deliveryFee : fee
        }
        return fee
    }

    checkGularisClient = (gularisToken, cliente) => {
        if (cliente !== '' && gularisToken) {
            const {cpf} = cliente
            this.balanceGularis(cpf, gularisToken)
        }
    }

    personalize = () => {
        try {
            let colors = JSON.parse(localStorage.getItem(`bitmenu-${identifier}:colorStore`))
            const {
                title,
                description,
                price,
                toolbar,
                body,
                cardProduct,
                nextButton,
                fontButton,
                fontToolbar,
                footer
            } = colors
            this.setState({
                colorStore: {
                    color_title: `#${title}`,
                    color_description: `#${description}`,
                    color_price: `#${price}`,
                    color_toolbar: `#${toolbar}`,
                    color_body: `#${body}`,
                    color_cardProduct: `#${cardProduct}`,
                    color_nextButton: `#${nextButton}`,
                    color_fontButton: `#${fontButton}`,
                    color_fontToolbar: `#${fontToolbar}`,
                    color_footer: `#${footer}`
                }
            })
        } catch (e) {

        }
    }

    checkDDD = () => {
        try {
            let noRecord = localStorage.getItem(`bitmenu-${identifier}:noRecord`)
            noRecord = (noRecord !== null) ? JSON.parse(noRecord) : false
            let client = localStorage.getItem(`bitmenu-${identifier}:cliente`)
            client = client !== null ? JSON.parse(client) : ''
            if (client === '') {

            } else if (client.ddd === undefined || client.ddd === '') {
                let ddd = client.telefone
                try {
                    ddd = ddd.substring(0, ddd.length - 8)
                    if (ddd.length > 2 && ddd.substring(0, 1) === '0') {
                        ddd = ddd.substring(1, ddd.length)
                    }
                    if (ddd.length > 2 && ddd.substring(ddd.length - 1) === '9') {
                        ddd = ddd.substring(0, ddd.length - 1)
                    }
                    if (ddd.length < 2) {
                        ddd = ''
                    }
                } catch (e) {
                    ddd = ''
                }
                let phone = client.telefone
                try {
                    phone = phone.substring(phone.length - 8)
                    if ((phone.substring(0, 1) === '9' || phone.substring(0, 1) === '8' || phone.substring(0, 1) === '7')) {
                        phone = `9${phone}`
                    }
                } catch (e) {
                    phone = client.telefone
                }
                if (ddd !== '') {
                    client.ddd = ddd
                    client.telefone = phone
                    localStorage.setItem(`bitmenu-${identifier}:cliente`, JSON.stringify(client))
                } else {
                    if (!noRecord) this.setState({openConfirmDDD: true, confirmDDD: ddd, confirmPhone: phone})
                }
            }
        } catch (e) {

        }
    }

    adressTotem = (totem, cliente) => {
        try {
            if (totem) {
                if (cliente === '') return this.props.history.push({pathname: '/login', state: {goBack: '/'}})
                let adress = {
                    cep: cliente.endereco[0].cep,
                    bairro: cliente.endereco[0].bairro,
                    cidade: cliente.endereco[0].cidade,
                    complemento: cliente.endereco[0].complemento,
                    numero: cliente.endereco[0].numero,
                    rua: cliente.endereco[0].rua,
                    uf: cliente.endereco[0].uf,
                    retire: true,
                    deliveryFee: 0,
                    storesLocation: 'Retirar no local',
                    id_loja: id_loja
                }
                localStorage.setItem(`bitmenu-${identifier}:adress`, JSON.stringify(adress))
            }
        } catch (e) {

        }
    }

    statusButtonNext = () => {
        setTimeout(() => {
            try {
                let cliente = localStorage.getItem(`bitmenu-${identifier}:cliente`)
                cliente = cliente !== null ? JSON.parse(cliente) : ''
                const {
                    dateDelivery,
                    totem,
                    payment,
                    itens,
                    scheduleDelivery,
                    scheduledDeliveryTime,
                    noRecord,
                    modules,
                    dateDeliveryRoute,
                    retire,
                    scheduledDay
                } = this.state
                if (!itens.length) {
                    this.setState({buttonNext: 'Adicione itens ao carrinho'})
                } else if (cliente === '' && !noRecord) {
                    this.setState({buttonNext: 'Fazer login'})
                } else if (dateDelivery === '') {
                    this.setState({buttonNext: 'Escolher tipo de entrega'})
                } else if (!totem && scheduleDelivery === 'Entrega programada' && scheduledDeliveryTime === '' && modules !== 3) {
                    this.setState({buttonNext: scheduledDay ? 'Escolher data de entrega' : 'Escolher horário entrega'})
                } else if (modules === 3 && dateDeliveryRoute === '' && !retire) {
                    this.setState({buttonNext: 'Escolher horário de entrega'})
                } else if (!totem && payment === '') {
                    this.setState({buttonNext: 'Escolher tipo de pagamento'})
                } else {
                    this.setState({buttonNext: 'Enviar pedido'})
                }
            } catch (e) {

            }
        }, 10)
    }

    freeDelivery = subtotal => {
        if (sessionStorage.getItem(`bitmenu-${identifier}:cashback`) === '0' || sessionStorage.getItem(`bitmenu-${identifier}:cashback`) === null) {
            if (sessionStorage.getItem(`bitmenu-${identifier}:discount`) === null) {
                setTimeout(() => {
                    let freeDelivery = sessionStorage.getItem(`bitmenu-${identifier}:freeDelivery`)
                    if (freeDelivery === null) return
                    freeDelivery = JSON.parse(freeDelivery)
                    if (subtotal >= freeDelivery) {
                        let json = {
                            sucesso: true,
                            tipo_desconto: 'DELIVERY_FEE',
                            valor: 0,
                            compra_minima: freeDelivery,
                            mensagem: ''
                        }
                        this.setState({
                            coupon: 'freeDelivery2',
                            warning: {
                                open: true,
                                tryAgain: false,
                                title: 'Aviso',
                                text: `Você tem um cupom de frete grátis por ter atingido compras acima de ${freeDelivery.toLocaleString('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL'
                                })}`
                            }
                        })
                        this.checkCoupon(json, 'freeDelivery')
                    }
                }, 200)
            }
        }
    }

    checkAutomaticCoupon = () => {
        const coupon = sessionStorage.getItem(`bitmenu-${identifier}:automatic_coupon`)
        if (!!coupon) {
            this.setState({coupon: coupon}, () => {
                fetch(`https://bitbar.online/util/api/v3/verificacupom.php?authkey=${authkey}&id=${id_loja}&cupom=${coupon}`)
                    .then((response) => response.json())
                    .then((json) => {
                        if (json.compra_unica) {
                            this.singlePurchase(json, coupon)
                        } else if (json.primeira_compra) {
                            this.firstCoupon(json, coupon)
                        } else {
                            this.checkCoupon(json, coupon)
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                    })
                sessionStorage.removeItem(`bitmenu-${identifier}:automatic_coupon`)
            })
        }
    }

    componentDidMount() {
        identifier = IDENTIFIER_STORE
        authkey = localStorage.getItem(`bitmenu:authkey`)
        id_loja = localStorage.getItem(`bitmenu:id_loja`)

        let paymentTypes = JSON.parse(localStorage.getItem(`bitmenu-${identifier}:paymentTypes`))
        let purchaseMin = parseFloat(localStorage.getItem(`bitmenu-${identifier}:purchaseMin`))
        let timeDeliverMin = localStorage.getItem(`bitmenu-${identifier}:timeDeliverMin`)
        let timeDeliverMax = localStorage.getItem(`bitmenu-${identifier}:timeDeliverMax`)
        let timeTakeAwayMin = localStorage.getItem(`bitmenu-${identifier}:timeTakeAwayMin`)
        let timeTakeAwayMax = localStorage.getItem(`bitmenu-${identifier}:timeTakeAwayMax`)
        let paymentOnline = localStorage.getItem(`bitmenu-${identifier}:paymentOnline`)
        let paymentPhysical = localStorage.getItem(`bitmenu-${identifier}:paymentPhysical`)
        let cashPayments = localStorage.getItem(`bitmenu-${identifier}:cashPayments`)
        let dateDeliveryRoute = sessionStorage.getItem(`bitmenu-${identifier}:dateDeliveryRoute`)
        dateDeliveryRoute = (dateDeliveryRoute !== null) ? dateDeliveryRoute : ''
        let timeRoute = sessionStorage.getItem(`bitmenu-${identifier}:timeRoute`)
        timeRoute = (timeRoute !== null) ? timeRoute : ''

        const timeRoutes = []
        try {
            let weekDeliveryRoute = localStorage.getItem(`bitmenu-${identifier}:weekDeliveryRoute`)
            weekDeliveryRoute = (weekDeliveryRoute !== null) ? JSON.parse(weekDeliveryRoute) : []
            for (let i = 0; i < weekDeliveryRoute.length; i++) {
                const {time, limitTime} = weekDeliveryRoute[i]
                timeRoutes.push({
                    time: time,
                    limitTime: limitTime
                })
            }
        } catch (e) {
            console.error(e.message)
        }

        this.checkDDD()

        this.checkModePayment(paymentOnline, paymentPhysical, cashPayments)

        let gularisToken = localStorage.getItem(`bitmenu-${identifier}:gularisToken`)
        let gularisMinimo = localStorage.getItem(`bitmenu-${identifier}:gularisMinimo`)

        let modules = localStorage.getItem(`bitmenu-${identifier}:modules`)
        modules = modules !== null ? parseInt(modules) : 0

        let restaurantAddress = localStorage.getItem(`bitmenu-${identifier}:restaurantAddress`)
        restaurantAddress = restaurantAddress !== null ? restaurantAddress : ''

        let cliente = localStorage.getItem(`bitmenu-${identifier}:cliente`)
        cliente = cliente !== null ? JSON.parse(cliente) : ''

        let payment = sessionStorage.getItem(`bitmenu-${identifier}:payment`)
        payment = payment !== null ? payment : ''

        let card = sessionStorage.getItem(`bitmenu-${identifier}:card`)
        card = card !== null ? JSON.parse(atob(card)) : ''

        payment = !(payment === 'PAGAMENTO ONLINE' && card === '') ? payment : ''

        let change = sessionStorage.getItem(`bitmenu-${identifier}:change`)
        change = change !== null ? parseFloat(change) : 0

        let valueRescue = sessionStorage.getItem(`bitmenu-${identifier}:cashback`)
        valueRescue = (valueRescue !== null) ? valueRescue : 0

        let scheduledDay = localStorage.getItem(`bitmenu-${identifier}:scheduledDay`)
        scheduledDay = (scheduledDay !== null) ? JSON.parse(scheduledDay) : false

        this.checkItemOld()

        let itens = localStorage.getItem(`bitmenu-${identifier}:itens`)
        itens = (itens !== null) ? JSON.parse(itens) : []

        let observationOrder = sessionStorage.getItem(`bitmenu-${identifier}:observationOrder`)
        observationOrder = (observationOrder !== null) ? observationOrder : ''

        let allowScheduledDelivery = localStorage.getItem(`bitmenu-${identifier}:allowScheduledDelivery`)
        allowScheduledDelivery = (allowScheduledDelivery !== null) ? JSON.parse(allowScheduledDelivery) : false

        let totem = localStorage.getItem(`bitmenu-${identifier}:totem`)
        totem = (totem !== null) ? JSON.parse(totem) : false

        let pix = sessionStorage.getItem(`bitmenu-${identifier}:pix`)
        pix = (pix !== null) ? JSON.parse(pix) : false

        let noRecord = localStorage.getItem(`bitmenu-${identifier}:noRecord`)
        noRecord = (noRecord !== null) ? JSON.parse(noRecord) : false

        this.adressTotem(totem, cliente)

        couponOld = ''

        itens = this.checkItem(itens)
        this.checkModules()
        let subtotal = this.calculateSubTotal(itens)
        this.checkFeeRadius()
        let fee = this.adress()
        valueRescue = this.checkRescue(valueRescue, fee, subtotal)
        this.checkDiscount(fee, itens, subtotal)
        if (scheduledDay) {


            // const dateDeliveryRoute = sessionStorage.getItem(`bitmenu-${identifier}:dateDeliveryRoute`)
            // const timeRoute = sessionStorage.getItem(`bitmenu-${identifier}:timeRoute`)

            this.setState({scheduleDelivery: `Entrega programada`})
            let dateSchedule = sessionStorage.getItem(`bitmenu-${identifier}:dateSchedule`)
            if (dateSchedule !== null) this.setState({
                scheduledDeliveryTime: dateSchedule,
                dateSchedule: dateSchedule
            })
        } else {
            this.checkScheduledDelivery()
            this.checkScheduledTime()
        }
        this.checkGularisClient(gularisToken, cliente)
        this.personalize()
        this.statusButtonNext()
        this.firstCoupon()
        this.singlePurchase()
        this.freeDelivery(subtotal)
        this.checkAutomaticCoupon()

        this.setState({
            pix: pix,
            cliente: cliente,
            card: card,
            itens: itens,
            authkey: authkey,
            identifier: identifier,
            id_loja: id_loja,
            payment: payment,
            change: change,
            modules: modules,
            totem: totem,
            paymentTypes: paymentTypes,
            purchaseMin: purchaseMin,
            observationOrder: observationOrder,
            timeDeliverMin: timeDeliverMin,
            timeDeliverMax: timeDeliverMax,
            timeTakeAwayMin: timeTakeAwayMin,
            timeTakeAwayMax: timeTakeAwayMax,
            restaurantAddress: restaurantAddress,
            allowScheduledDelivery: allowScheduledDelivery,
            paymentOnline: paymentOnline,
            paymentPhysical: paymentPhysical,
            cashPayments: cashPayments,
            gularisToken: gularisToken,
            gularisMinimo: gularisMinimo,
            scheduledDay: scheduledDay,
            valueRescue: valueRescue,
            openConfirmation: (payment !== '' && sessionStorage.getItem(`bitmenu-${identifier}:confirmedAnswers`) !== null),
            openTotem: (totem && sessionStorage.getItem(`bitmenu-${identifier}:confirmedAnswers`) !== null),
            noRecord: noRecord,
            openLogin: (cliente === ''),
            optionsTakeWay: localStorage.getItem(`bitmenu-${identifier}:optionsTakeWay`) !== null ? localStorage.getItem(`bitmenu-${identifier}:optionsTakeWay`) : 'Retirar no local',
            dateDeliveryRoute: dateDeliveryRoute,
            timeRoute: timeRoute,
            timeRoutes: timeRoutes
        })
        window.scrollTo(0, 0)
        pageView()
    }

    render() {
        const {
            pix,
            paymentTotem,
            scheduledDeliveryTime,
            scheduleDelivery,
            receptionTimes,
            openReceptionTimes,
            scheduledOrderNotice,
            allowScheduledDelivery,
            payment,
            withoutChange,
            noticeToAddress,
            paymentPhysical,
            cashPayments,
            paymentOnline,
            optionsTakeWay,
            retire,
            modules,
            openDateDelivery,
            dateDelivery,
            gularis,
            openRescue,
            nameGularis,
            balanceGularis,
            valueRescue,
            gularisMinimo,
            openDeliveryOrTakeaway,
            totem,
            itens,
            observation,
            openCouponNotice,
            openDatechedule,
            openRescueNotice,
            openConfirmDDD,
            confirmDDD,
            confirmPhone,
            warning,
            storesLocation,
            restaurantAddress,
            openTotem,
            nameTotem,
            buttonNext,
            openRaio,
            messageTextRaio,
            messageTitleRaio,
            visibleObs,
            colorStore: {
                color_title,
                color_toolbar,
                color_body,
                color_cardProduct,
                color_description,
                color_price,
                color_nextButton,
                color_fontButton,
                color_fontToolbar
            },
            cliente,
            openRecord,
            openCPF,
            clientName,
            clientPhone,
            clientCPF,
            noRecord,
            openLogin,
            openListRoutes,
            timeRoutes,
            dateDeliveryRoute,
            timeRoute,
            scheduledDay,
            dateSchedule
        } = this.state

        const theme2 = createMuiTheme({
            palette: {
                primary: {
                    main: color_title ? color_title : '#000000'
                }
            }
        })

        return (
            <div id="carrinho" style={color_body ? {background: color_body} : {}}>
                <AppBar style={color_toolbar ? {background: color_toolbar} : {}}
                        id="appBar"
                        position="sticky"
                        color="default">
                    <Toolbar variant="dense">
                        <ArrowBackIcon id="voltar-carrinho" onClick={this.onClickBack}
                                       style={color_fontToolbar ? {color: color_fontToolbar} : {}}/>
                        <Typography variant="h6" color="inherit" id={'label-title'}
                                    style={color_fontToolbar ? {color: color_fontToolbar} : {}}>Carrinho</Typography>
                    </Toolbar>
                </AppBar>
                <div id="container-carrinho" style={totem ? {marginBottom: 600} : {marginBottom: 200}}>
                    {
                        (!totem) &&
                        <Card id="card-adress" style={color_cardProduct ? {background: color_cardProduct} : {}}>
                            <div id="card-location" onClick={this.onClickSelectionAdress}>
                                <div>
                                    <FormLabel id="card-title-adress"
                                               style={color_title ? {color: color_title} : {}}>
                                        Entregar em
                                    </FormLabel>
                                </div>
                                <div id="div-selection-adress">
                                    <div id="card-selection-adress">
                                        <div>
                                            <CardMedia image={maps} id="image-map"/>
                                        </div>
                                        <div id="div-info-delivery">
                                            {
                                                (dateDelivery === '') ?
                                                    <FormLabel id="description-info-delivery"
                                                               style={color_title ? {color: color_title} : {}}>Selecionar
                                                        endereço</FormLabel>
                                                    :
                                                    <div>
                                                        {
                                                            this.state.retire
                                                                ?
                                                                <FormLabel
                                                                    id="description-info-delivery"
                                                                    style={color_title ? {color: color_title} : {}}>
                                                                    {optionsTakeWay}
                                                                </FormLabel>
                                                                :
                                                                <FormLabel
                                                                    id="description-info-delivery"
                                                                    style={color_title ? {color: color_title} : {}}>
                                                                    {`${this.state.dateDelivery.rua}, ${this.state.dateDelivery.numero}`}
                                                                </FormLabel>
                                                        }
                                                    </div>
                                            }
                                            {
                                                (this.state.dateDelivery.complemento !== '' && !this.state.retire) &&
                                                <FormLabel
                                                    id="complement-info-delivery"
                                                    style={color_title ? {color: color_title} : {}}> {this.state.dateDelivery.complemento} </FormLabel>
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        <FormLabel
                                            id="alterar-info-delivery"
                                            style={color_title ? {
                                                color: color_title,
                                                textDecoration: 'underline'
                                            } : {textDecoration: 'underline'}}>Alterar</FormLabel>
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.dateDelivery !== '' &&
                                <div id="card-info-delivery">
                                    <div>
                                        {
                                            this.state.retire ?
                                                <div id="div-time-delivery">
                                                    <FormLabel
                                                        id="title-info-delivery"
                                                        style={color_title ? {color: color_title} : {}}>
                                                        {this.state.storesLocation}
                                                    </FormLabel>
                                                    <FormLabel
                                                        id={'label-description-delivery'}
                                                        style={color_title ? {
                                                            color: color_title, marginBottom: 4,
                                                            marginTop: 4
                                                        } : {
                                                            marginBottom: 4,
                                                            marginTop: 4
                                                        }}>{`${this.state.timeTakeAwayMin} - ${this.state.timeTakeAwayMax}`}</FormLabel>
                                                    {
                                                        noticeToAddress !== '' &&
                                                        <FormLabel
                                                            id={'label-description-delivery'}
                                                            style={{
                                                                marginBottom: 4,
                                                                marginTop: 4,
                                                                color: '#d32f2f'
                                                            }}>{noticeToAddress}</FormLabel>
                                                    }
                                                </div>
                                                :
                                                <div>
                                                    {
                                                        (scheduledDay && scheduleDelivery === 'Entrega programada') ?
                                                            <div/>
                                                            :
                                                            <div id="div-time-delivery">
                                                                <FormLabel
                                                                    id="title-info-delivery"
                                                                    style={color_title ? {color: color_title} : {}}>{`Entrega - ${this.state.deliveryFee.toLocaleString('pt-BR', {
                                                                    style: 'currency',
                                                                    currency: 'BRL'
                                                                })}`}</FormLabel>
                                                                <FormLabel
                                                                    id={'label-description-delivery'}
                                                                    style={color_title ? {
                                                                        color: color_title, marginBottom: 4,
                                                                        marginTop: 4
                                                                    } : {
                                                                        marginBottom: 4,
                                                                        marginTop: 4
                                                                    }}>{`${this.state.timeDeliverMin} - ${this.state.timeDeliverMax}`}</FormLabel>
                                                                {
                                                                    noticeToAddress !== '' &&
                                                                    <FormLabel style={{
                                                                        marginBottom: 4,
                                                                        marginTop: 4,
                                                                        color: '#d32f2f'
                                                                    }}>{noticeToAddress}</FormLabel>
                                                                }
                                                            </div>
                                                    }
                                                </div>
                                        }
                                    </div>
                                </div>
                            }
                        </Card>
                    }

                    <Card className="card-carinho" id={'pedido'}
                          style={color_cardProduct ? {background: color_cardProduct} : {}}>
                        <div id="div-titulo-carrinho">
                            <div id="div-divisor-titulo">

                            </div>
                            <FormLabel id="card-titulo-carrinho-meu-pedido"
                                       style={color_title ? {color: color_title} : {}}>Meu pedido</FormLabel>
                            <div id="div-divisor-titulo">
                                {
                                    this.state.itens !== 0 && this.state.itens !== undefined &&
                                    <DeleteIcon style={color_title ? {
                                        color: color_title,
                                        cursor: "pointer"
                                    } : {cursor: "pointer"}} onClick={this.onClickcleanCartItems}/>
                                }

                            </div>
                        </div>
                        {(this.state.itens !== 0 && this.state.itens !== undefined) && < Divider/>}
                        <div id="itens">
                            {(() => {
                                if (this.state.itens !== undefined && this.state.itens !== 0) {
                                    return (
                                        this.state.itens.map((i, index) => {
                                            return (
                                                <ListaItens
                                                    data={i}
                                                    key={index}
                                                    position={index}
                                                    colors={this.state.colorStore}
                                                    handleChange={this.handleOptions.bind(this)}/>
                                            )
                                        })
                                    )
                                }
                            })()}
                        </div>
                        <Divider/>
                        <div id="valores">
                            <div id="valores-subtotal">
                                <FormLabel id="texto-valor-geral"
                                           style={color_description ? {color: color_title} : {}}>Subtotal</FormLabel>
                                <FormLabel
                                    id="texto-valor-geral"
                                    style={color_description ? {color: color_title} : {}}>{this.state.subtotal.toLocaleString('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL'
                                })}</FormLabel>
                            </div>
                            {
                                this.state.deliveryFee !== 0 &&
                                <div id="valores-subtotal">
                                    <FormLabel id="texto-valor-geral"
                                               style={color_description ? {color: color_title} : {}}>Entrega</FormLabel>
                                    <FormLabel id="texto-valor-geral"
                                               style={color_description ? {color: color_title} : {}}>{this.state.deliveryFee.toLocaleString('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL'
                                    })}</FormLabel>
                                </div>
                            }
                            {
                                (valueRescue != 0) &&
                                <div id="valores-subtotal">
                                    <FormLabel id="texto-valor-geral"
                                               style={color_title ? {color: color_title} : {}}>Cashback</FormLabel>
                                    <FormLabel
                                        id="texto-valor-geral"
                                        style={color_title ? {color: color_title} : {}}>{`- ${parseFloat(valueRescue).toLocaleString('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL'
                                    })}`}</FormLabel>
                                </div>
                            }
                            {
                                this.state.discount !== 0 &&
                                <div id="valores-subtotal">
                                    <FormLabel id="texto-valor-geral"
                                               style={color_title ? {color: color_title} : {}}>Desconto</FormLabel>
                                    <FormLabel
                                        id="texto-valor-geral"
                                        style={color_title ? {color: color_title} : {}}>{`- ${this.state.discount.toLocaleString('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL'
                                    })}`}</FormLabel>
                                </div>
                            }
                            <div id="valores-subtotal">
                                <FormLabel id="texto-valor-total"
                                           style={color_title ? {color: color_title} : {}}>Total</FormLabel>
                                <FormLabel
                                    style={color_title ? {color: color_title} : {}}
                                    id="texto-valor-total">{(this.state.subtotal + this.state.deliveryFee - this.state.discount - valueRescue).toLocaleString('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL'
                                })}</FormLabel>
                            </div>
                            {
                                this.state.change >= (this.state.subtotal + this.state.deliveryFee - this.state.discount - valueRescue) &&
                                <div>
                                    <Divider/>
                                    <div>
                                        < div id="valores-subtotal">
                                            <FormLabel id="texto-valor-geral"
                                                       style={color_title ? {color: color_title} : {}}>Total
                                                a pagar</FormLabel>
                                            <FormLabel
                                                style={color_title ? {color: color_title} : {}}
                                                id="texto-valor-geral">{(this.state.change).toLocaleString('pt-BR', {
                                                style: 'currency',
                                                currency: 'BRL'
                                            })}</FormLabel>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                this.state.change >= (this.state.subtotal + this.state.deliveryFee - this.state.discount - valueRescue) &&
                                <div>
                                    < div id="valores-subtotal">
                                        <FormLabel id="texto-valor-geral"
                                                   style={color_title ? {color: color_title} : {}}>Troco</FormLabel>
                                        <FormLabel
                                            id="texto-valor-geral"
                                            style={color_title ? {color: color_title} : {}}>{(this.state.change - (this.state.subtotal + this.state.deliveryFee - this.state.discount - valueRescue)).toLocaleString('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL'
                                        })}</FormLabel>
                                    </div>
                                </div>
                            }
                        </div>
                    </Card>

                    {
                        ((scheduledDay || allowScheduledDelivery) && !totem && modules !== 3) &&
                        <Card className="card-carinho" id="pagamento"
                              style={color_cardProduct ? {background: color_cardProduct} : {}}>
                            <div id="titulo-carrinho" onClick={() => this.onClickScheduleDelivery()}>
                                <FormLabel id="card-titulo-carrinho"
                                           style={color_title ? {color: color_title} : {}}>{scheduleDelivery}</FormLabel>
                                <FormLabel id="text-chose-payment-method"
                                           style={color_title ? {
                                               color: color_title,
                                               textDecoration: 'underline'
                                           } : {textDecoration: 'underline'}}>{!this.timeControl() ? `Escolher` : `Alterar`}
                                </FormLabel>
                            </div>
                            <div>
                                {
                                    ((scheduleDelivery === 'Entrega programada') && (scheduledDeliveryTime !== '' || dateSchedule !== '')) &&
                                    <div>
                                        <Divider/>
                                        <div id="div-pay-chose" onClick={this.onClickEditScheduleDelivery}>
                                            <FormLabel
                                                id="text-pay-chose"
                                                style={color_title ? {color: color_title} : {}}>
                                                {
                                                    (!!dateSchedule)
                                                        ? `${moment(dateSchedule, 'YYYY-MM-DD').format('DD/MM/YYYY')}`
                                                        : `Pedido será entregue por volta  das ${scheduledDeliveryTime}hs`
                                                }
                                            </FormLabel>
                                            <Edit style={color_title ? {color: color_title, cursor: "pointer"} : {
                                                color: user.main_color,
                                                cursor: "pointer"
                                            }}/>
                                        </div>
                                    </div>
                                }
                            </div>
                        </Card>
                    }
                    {
                        (modules === 3) &&
                        <Card className="card-carinho" id="pagamento" style={{background: color_cardProduct}}>
                            <div id="titulo-carrinho" onClick={() => this.onClickListRoutes()}>
                                <FormLabel
                                    id="card-titulo-carrinho"
                                    style={color_title ? {color: color_title} : {}}>
                                    Entrega programada
                                </FormLabel>
                                <FormLabel
                                    id="text-chose-payment-method"
                                    style={{
                                        color: color_title,
                                        textDecoration: 'underline'
                                    }}>
                                    Escolher
                                </FormLabel>
                            </div>
                            <div>
                                {
                                    (dateDeliveryRoute !== '') &&
                                    <div>
                                        <Divider/>
                                        <div id="div-pay-chose" onClick={() => this.setState({openDateDelivery: true})}>
                                            <FormLabel
                                                id="text-pay-chose"
                                                style={{
                                                    color: color_title
                                                }}>
                                                {`Data de entrega ${moment(dateDeliveryRoute, 'YYYY-MM-DD').format('DD/MM/YYYY')} ${(timeRoute !== '') ? `saída da rota ${timeRoute}` : ''}`}
                                            </FormLabel>
                                            <Edit style={{
                                                color: color_title,
                                                cursor: 'pointer'
                                            }}/>
                                        </div>
                                    </div>
                                }
                            </div>
                        </Card>
                    }
                    <Card className="card-carinho" id="cupom"
                          style={color_cardProduct ? {background: color_cardProduct} : {}}>
                        <div id="titulo-carrinho" onClick={this.onClickAddCoupon}>
                            <FormLabel id="card-titulo-carrinho"
                                       style={color_title ? {color: color_title} : {}}>Cupom de desconto</FormLabel>
                            <FormLabel id="text-chose-payment-method"
                                       style={color_title ? {
                                           color: color_title,
                                           textDecoration: 'underline'
                                       } : {textDecoration: 'underline'}}>{this.state.coupon === '' ? `Inserir` : `Alterar`}</FormLabel>
                        </div>
                        {
                            (this.state.coupon !== '') &&
                            <div>
                                <Divider/>
                                <div id="div-pay-chose">
                                    <FormLabel id="text-pay-chose"
                                               style={color_title ? {color: color_title} : {}}>{this.state.coupon}</FormLabel>
                                    <DeleteIcon style={color_title ? {color: color_title, cursor: "pointer"} : {
                                        color: user.main_color,
                                        cursor: "pointer"
                                    }} onClick={this.onClickRemoveCoupon}/>
                                </div>
                            </div>
                        }
                    </Card>
                    {
                        (gularis && !totem) &&
                        <Card className="card-carinho" id="cupom"
                              style={color_cardProduct ? {background: color_cardProduct} : {}}>
                            <div id="titulo-carrinho">
                                <div style={{display: "flex", flexDirection: "column"}}>
                                    <FormLabel id="card-titulo-carrinho-cashback"
                                               style={color_title ? {color: color_title} : {}}>Cashback</FormLabel>
                                    <FormLabel id="label-name-gularis"
                                               style={color_title ? {color: color_title} : {}}>{`CPF: ${nameGularis}`}</FormLabel>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', marginTop: 4}}
                                     onClick={() => this.onClickResgatar()}>
                                    <FormLabel
                                        style={color_price ? {color: color_price} : {}}
                                        id="label-balance-gularis">{(balanceGularis > 0 ? (balanceGularis - valueRescue) : 0).toLocaleString('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL'
                                    })}</FormLabel>
                                    {
                                        (gularis && (balanceGularis >= parseFloat(gularisMinimo)) && (balanceGularis > 0)) && (itens !== 0) &&
                                        <FormLabel id="text-chose-payment-method"
                                                   style={color_title ? {
                                                       color: color_title,
                                                       textDecoration: 'underline'
                                                   } : {textDecoration: 'underline'}}>Resgatar</FormLabel>
                                    }
                                </div>
                            </div>
                        </Card>
                    }
                    {
                        (!totem) &&
                        <Card className="card-carinho" id="pagamento"
                              style={color_cardProduct ? {background: color_cardProduct} : {}}>
                            <div id="titulo-carrinho" onClick={this.onClickPayment}>
                                <FormLabel id="card-titulo-carrinho"
                                           style={color_title ? {color: color_title} : {}}>
                                    Formas de pagamento
                                </FormLabel>
                                <FormLabel id="text-chose-payment-method"
                                           style={color_title ? {
                                               color: color_title,
                                               textDecoration: 'underline'
                                           } : {textDecoration: 'underline'}}>{payment === '' ? `Escolher` : `Alterar`}</FormLabel>
                            </div>
                            {
                                payment !== '' &&
                                <div>
                                    <Divider/>
                                    <div id="div-pay-chose" onClick={this.onClickPayment}>
                                        <FormLabel
                                            style={color_title ? {color: color_title} : {}}
                                            id="text-pay-chose">{payment !== 'Dinheiro' && payment !== 'Pix' && payment !== 'PAGAMENTO ONLINE' ? `${payment} (máquina)` : payment}</FormLabel>
                                        <Edit style={color_title ? {color: color_title, cursor: "pointer"} : {
                                            color: user.main_color,
                                            cursor: "pointer"
                                        }}/>
                                    </div>
                                </div>
                            }
                        </Card>
                    }
                    {/*Observação no carrinho*/}
                    <Card className="card-carinho" id="observation"
                          style={color_cardProduct ? {background: color_cardProduct} : {}}>
                        <div id="titulo-carrinho" style={{paddingBottom: 1}}>
                            <FormLabel id="card-titulo-carrinho"
                                       style={color_title ? {color: color_title} : {}}>Observação</FormLabel>
                        </div>
                        <div id="div-pay-chose" style={{paddingTop: 1}}>
                            <MuiThemeProvider theme={theme2}>
                                <Input name="observationOrder" value={this.state.observationOrder}
                                       variant="outlined"
                                       style={color_title ? {
                                           background: color_cardProduct,
                                           color: color_title
                                       } : {}}
                                       fullWidth={true} placeholder="Observações destinadas ao motoboy!"
                                       onChange={this.handleInputs}/>
                            </MuiThemeProvider>
                        </div>
                    </Card>
                </div>
                {
                    (totem)
                        ?
                        <div
                            id="footer-carrinho"
                            style={{backgroundColor: color_nextButton}}
                            onClick={() => this.onClickSend()}>
                            <FormLabel
                                style={{color: color_fontButton, cursor: "pointer"}}
                                id="texto-finaliza">
                                Enviar Pedido
                            </FormLabel>
                        </div>
                        :
                        <div
                            id="footer-carrinho"
                            style={{backgroundColor: color_nextButton}}
                            onClick={() => this.onClickSend()}>
                            <FormLabel
                                style={{color: color_fontButton, cursor: 'pointer'}}
                                id="texto-finaliza">
                                {buttonNext}
                            </FormLabel>
                        </div>
                }
                <MuiThemeProvider theme={theme}>
                    {/*  ---- Dialogs ----- */}
                    {
                        (() => {
                            if (this.state.detalhes !== undefined) {
                                const {titulo, quantidade, precoProduto, subItens} = this.state.detalhes
                                return (
                                    <Dialog open={this.state.openItemDetails} onClose={this.closeItemDetails}
                                            aria-labelledby="form-dialog-title">
                                        <DialogTitle id="form-dialog-title">{titulo}</DialogTitle>
                                        <DialogContent>
                                            <div className="detalhes-item">
                                                <div>
                                                    <FormLabel className="detalhe-text-item">{quantidade}</FormLabel>
                                                    <FormLabel className="detalhe-text-item">x</FormLabel>
                                                    <FormLabel className="detalhe-text-item">{titulo}</FormLabel>
                                                </div>
                                                <div>
                                                    <FormLabel
                                                        className="detalhe-text-item">{precoProduto.toLocaleString('pt-BR', {
                                                        style: 'currency',
                                                        currency: 'BRL'
                                                    })}</FormLabel>
                                                </div>
                                            </div>
                                            <Divider/>
                                            {
                                                subItens.map((i, index) => {
                                                    return (
                                                        <div className="detalhes-item" key={index}>
                                                            <div>
                                                                <FormLabel
                                                                    className="detalhe-text-item">{i.quantidade}</FormLabel>
                                                                <FormLabel className="detalhe-text-item">x</FormLabel>
                                                                <FormLabel
                                                                    className="detalhe-text-item">{i.titulo}</FormLabel>
                                                            </div>
                                                            <div>
                                                                <FormLabel
                                                                    className="detalhe-text-item"> {i.precoProduto.toLocaleString('pt-BR', {
                                                                    style: 'currency',
                                                                    currency: 'BRL'
                                                                })} </FormLabel>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={() => this.setState({openItemDetails: false})}
                                                    color="primary">Fechar</Button>
                                        </DialogActions>
                                    </Dialog>
                                )
                            }
                        })()
                    }
                    <Dialog open={this.state.openNotification} onClose={this.closeNotification}>
                        <DialogTitle>Notificações</DialogTitle>
                        <DialogContentText
                            id={'label-dialog-content'}>
                            Permita as notificações para informarmos o andamento dos pedidos
                        </DialogContentText>
                        <DialogActions>
                            <Button
                                id={'button-dialog'}
                                onClick={this.closeNotification}
                                color="primary">
                                Não
                            </Button>
                            <Button
                                id={'button-dialog'}
                                variant={'contained'}
                                onClick={this.onClickNotification}
                                color="primary">
                                Sim
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={this.state.openPayment} onClose={this.closeMethods} fullScreen={isMobile}>
                        <DialogTitle>Formas de pagamento</DialogTitle>
                        <DialogContent>
                            {
                                (paymentOnline && noRecord) &&
                                <div id={'div-payment-online-no-record'}>
                                    <FormLabel id={'label-payment-online-no-record'}>
                                        Pagamento Online é habilitado somente para clientes cadastrados <p
                                        id={'a-no-record'}
                                        onClick={() => {
                                            this.setState({openPayment: false})
                                            this.props.history.push({pathname: '/login', state: {goBack: '/carrinho'}})
                                        }}>Fazer Login</p>
                                    </FormLabel>
                                </div>
                            }
                            <DialogContentText
                                id={'label-dialog-content'}
                                style={{color: '#1e1e1e'}}>
                                Escolha uma das opções aceitas por este estabelecimento
                            </DialogContentText>
                            {(() => {
                                if (paymentOnline === 'ALL' && !noRecord) {
                                    return (
                                        <div id="payment-method">
                                            <div>
                                                <div className="btn-payment"
                                                     onClick={() => this.onClickPaymentMethod({description: "PAGAMENTO ONLINE"})}>
                                                    <div id={'div-item-payment'}>
                                                        <CardMedia
                                                            style={{width: 30, height: 30}}
                                                            image={PaymentOnline}/>
                                                        <Box p={1}/>
                                                        <FormLabel
                                                            style={{cursor: "pointer"}}
                                                            id="text-description-payment">
                                                            Pagamento Online
                                                        </FormLabel>
                                                    </div>
                                                    <Divider/>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                } else if (retire && paymentOnline === 'TAKEAWAY' && !noRecord) {
                                    return (
                                        <div id="payment-method">
                                            <div>
                                                <div className="btn-payment"
                                                     onClick={() => this.onClickPaymentMethod({description: "PAGAMENTO ONLINE"})}>
                                                    <div id={'div-item-payment'}>
                                                        <CardMedia
                                                            style={{width: 30, height: 30}}
                                                            image={PaymentOnline}/>
                                                        <Box p={1}/>
                                                        <FormLabel
                                                            style={{cursor: "pointer"}}
                                                            id="text-description-payment">
                                                            Pagamento Online
                                                        </FormLabel>
                                                    </div>
                                                    <Divider/>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                } else if (!retire && paymentOnline === 'DELIVERY' && !noRecord) {
                                    return (
                                        <div id="payment-method">
                                            <div>
                                                <div className="btn-payment"
                                                     onClick={() => this.onClickPaymentMethod({description: "PAGAMENTO ONLINE"})}>
                                                    <div id={'div-item-payment'}>
                                                        <CardMedia
                                                            style={{width: 30, height: 30}}
                                                            image={PaymentOnline}/>
                                                        <Box p={1}/>
                                                        <FormLabel style={{cursor: "pointer"}}
                                                                   id="text-description-payment">
                                                            Pagamento Online
                                                        </FormLabel>
                                                    </div>
                                                    <Divider/>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                } else if (paymentOnline === 'OFF') {
                                    return (<div/>)
                                }
                            })()}
                            {(() => {
                                return (
                                    <div id="payment-method">
                                        <div>
                                            {
                                                this.state.paymentTypes !== null &&
                                                /*eslint-disable-next-line*/
                                                this.state.paymentTypes.map((i, index) => {
                                                    if (i.description === 'Pix') {
                                                        return (
                                                            <div key={index} className="btn-payment"
                                                                 onClick={() => this.onClickPaymentMethod(i)}>
                                                                <div id={'div-item-payment'}>
                                                                    <CardMedia
                                                                        style={{width: 30, height: 30}}
                                                                        image={Pix}/>
                                                                    <Box p={1}/>
                                                                    <FormLabel
                                                                        style={{cursor: "pointer"}}
                                                                        id="text-description-payment">{i.description}</FormLabel>
                                                                </div>
                                                                <Divider/>
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            })()}
                            {(() => {
                                if (cashPayments === 'ALL') {
                                    return (
                                        <div id="payment-method">
                                            <div>
                                                {
                                                    this.state.paymentTypes !== null &&
                                                    /*eslint-disable-next-line*/
                                                    this.state.paymentTypes.map((i, index) => {
                                                        if (!i.description.includes('Online') && i.description === 'Dinheiro' && i.description !== 'Pix') {
                                                            return (
                                                                <div key={index} className="btn-payment"
                                                                     onClick={() => this.onClickPaymentMethod(i)}>

                                                                    {(() => {
                                                                        if (i.description === 'Dinheiro') {
                                                                            return (
                                                                                <div id={'div-item-payment'}>
                                                                                    <CardMedia
                                                                                        style={{width: 30, height: 30}}
                                                                                        image={Money}/>
                                                                                    <Box p={1}/>
                                                                                    <FormLabel
                                                                                        style={{cursor: "pointer"}}
                                                                                        id="text-description-payment">
                                                                                        {i.description}
                                                                                    </FormLabel>
                                                                                </div>
                                                                            )
                                                                        } else {
                                                                            return (
                                                                                <div id={'div-item-payment'}>
                                                                                    <CardMedia
                                                                                        style={{width: 30, height: 30}}
                                                                                        image={CardCredit}/>
                                                                                    <Box p={1}/>
                                                                                    <FormLabel
                                                                                        style={{cursor: "pointer"}}
                                                                                        id="text-description-payment">{`${i.description} (máquina)`}</FormLabel>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })()}
                                                                    {(() => {
                                                                        if (i.description === 'Dinheiro') {
                                                                            return (<Divider/>)
                                                                        }
                                                                    })()}
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                } else if (retire && cashPayments === 'TAKEAWAY') {
                                    return (
                                        <div id="payment-method">
                                            <div>
                                                {
                                                    this.state.paymentTypes !== null &&
                                                    /*eslint-disable-next-line*/
                                                    this.state.paymentTypes.map((i, index) => {
                                                        if (!i.description.includes('Online') && i.description === 'Dinheiro' && i.description !== 'Pix') {
                                                            return (
                                                                <div key={index} className="btn-payment"
                                                                     onClick={() => this.onClickPaymentMethod(i)}>

                                                                    {(() => {
                                                                        if (i.description === 'Dinheiro') {
                                                                            return (
                                                                                <div id={'div-item-payment'}>
                                                                                    <CardMedia
                                                                                        style={{width: 30, height: 30}}
                                                                                        image={Money}/>
                                                                                    <Box p={1}/>
                                                                                    <FormLabel
                                                                                        style={{cursor: "pointer"}}
                                                                                        id="text-description-payment">{i.description}</FormLabel>
                                                                                </div>
                                                                            )
                                                                        } else {
                                                                            return (
                                                                                <div id={'div-item-payment'}>
                                                                                    <CardMedia
                                                                                        style={{width: 30, height: 30}}
                                                                                        image={CardCredit}/>
                                                                                    <Box p={1}/>
                                                                                    <FormLabel
                                                                                        style={{cursor: "pointer"}}
                                                                                        id="text-description-payment">{`${i.description} (máquina)`}</FormLabel>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })()}
                                                                    {(() => {
                                                                        if (i.description === 'Dinheiro') {
                                                                            return (<Divider/>)
                                                                        }
                                                                    })()}
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                } else if (!retire && cashPayments === 'DELIVERY') {
                                    return (
                                        <div id="payment-method">
                                            <div>
                                                {
                                                    this.state.paymentTypes !== null &&
                                                    /*eslint-disable-next-line*/
                                                    this.state.paymentTypes.map((i, index) => {
                                                        if (!i.description.includes('Online') && i.description === 'Dinheiro' && i.description !== 'Pix') {
                                                            return (
                                                                <div key={index} className="btn-payment"
                                                                     onClick={() => this.onClickPaymentMethod(i)}>
                                                                    {(() => {
                                                                        if (i.description === 'Dinheiro') {
                                                                            return (
                                                                                <div id={'div-item-payment'}>
                                                                                    <CardMedia
                                                                                        style={{width: 30, height: 30}}
                                                                                        image={Money}/>
                                                                                    <Box p={1}/>
                                                                                    <FormLabel
                                                                                        style={{cursor: "pointer"}}
                                                                                        id="text-description-payment">{i.description}</FormLabel>
                                                                                </div>
                                                                            )
                                                                        } else {
                                                                            return (
                                                                                <div id={'div-item-payment'}>
                                                                                    <CardMedia
                                                                                        style={{width: 30, height: 30}}
                                                                                        image={CardCredit}/>
                                                                                    <Box p={1}/>
                                                                                    <FormLabel
                                                                                        style={{cursor: "pointer"}}
                                                                                        id="text-description-payment">{`${i.description} (máquina)`}</FormLabel>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })()}
                                                                    {(() => {
                                                                        if (i.description === 'Dinheiro') {
                                                                            return (<Divider/>)
                                                                        }
                                                                    })()}
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                } else if (cashPayments === 'OFF') {
                                    return (<div/>)
                                }
                            })()}
                            {(() => {
                                if (paymentPhysical === 'ALL') {
                                    return (
                                        <div id="payment-method">
                                            <div>
                                                {
                                                    this.state.paymentTypes !== null &&
                                                    /*eslint-disable-next-line*/
                                                    this.state.paymentTypes.map((i, index) => {
                                                        if (!i.description.includes('Online') && i.description !== 'Dinheiro' && i.description !== 'Pix') {
                                                            return (
                                                                <div key={index} className="btn-payment"
                                                                     onClick={() => this.onClickPaymentMethod(i)}>
                                                                    {(() => {
                                                                        if (i.description === 'Dinheiro') {
                                                                            return (
                                                                                <div id={'div-item-payment'}>
                                                                                    <CardMedia
                                                                                        style={{width: 30, height: 30}}
                                                                                        image={Money}/>
                                                                                    <Box p={1}/>
                                                                                    <FormLabel
                                                                                        style={{cursor: "pointer"}}
                                                                                        id="text-description-payment">{i.description}</FormLabel>
                                                                                </div>
                                                                            )
                                                                        } else {
                                                                            return (
                                                                                <div id={'div-item-payment'}>
                                                                                    <CardMedia
                                                                                        style={{width: 30, height: 30}}
                                                                                        image={CardCredit}/>
                                                                                    <Box p={1}/>
                                                                                    <FormLabel
                                                                                        style={{cursor: "pointer"}}
                                                                                        id="text-description-payment">{`${i.description} (máquina)`}</FormLabel>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })()}
                                                                    {(() => {
                                                                        if (i.description === 'Dinheiro') {
                                                                            return (<Divider/>)
                                                                        }
                                                                    })()}
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                } else if (retire && paymentPhysical === 'TAKEAWAY') {
                                    return (
                                        <div id="payment-method">
                                            <div>
                                                {
                                                    this.state.paymentTypes !== null &&
                                                    /*eslint-disable-next-line*/
                                                    this.state.paymentTypes.map((i, index) => {
                                                        if (!i.description.includes('Online') && i.description !== 'Dinheiro' && i.description !== 'Pix') {
                                                            return (
                                                                <div key={index} className="btn-payment"
                                                                     onClick={() => this.onClickPaymentMethod(i)}>
                                                                    {(() => {
                                                                        if (i.description === 'Dinheiro') {
                                                                            return (
                                                                                <div id={'div-item-payment'}>
                                                                                    <CardMedia
                                                                                        style={{width: 30, height: 30}}
                                                                                        image={Money}/>
                                                                                    <Box p={1}/>
                                                                                    <FormLabel
                                                                                        style={{cursor: "pointer"}}
                                                                                        id="text-description-payment">{i.description}</FormLabel>
                                                                                </div>
                                                                            )
                                                                        } else {
                                                                            return (
                                                                                <div id={'div-item-payment'}>
                                                                                    <CardMedia
                                                                                        style={{width: 30, height: 30}}
                                                                                        image={CardCredit}/>
                                                                                    <Box p={1}/>
                                                                                    <FormLabel
                                                                                        style={{cursor: "pointer"}}
                                                                                        id="text-description-payment">{`${i.description} (máquina)`}</FormLabel>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })()}
                                                                    {(() => {
                                                                        if (i.description === 'Dinheiro') {
                                                                            return (<Divider/>)
                                                                        }
                                                                    })()}
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                } else if (!retire && paymentPhysical === 'DELIVERY') {
                                    return (
                                        <div id="payment-method">
                                            <div>
                                                {
                                                    this.state.paymentTypes !== null &&
                                                    /*eslint-disable-next-line*/
                                                    this.state.paymentTypes.map((i, index) => {
                                                        if (!i.description.includes('Online') && i.description !== 'Dinheiro' && i.description !== 'Pix') {
                                                            return (
                                                                <div key={index} className="btn-payment"
                                                                     onClick={() => this.onClickPaymentMethod(i)}>
                                                                    {(() => {
                                                                        if (i.description === 'Dinheiro') {
                                                                            return (
                                                                                <div id={'div-item-payment'}>
                                                                                    <CardMedia
                                                                                        style={{width: 30, height: 30}}
                                                                                        image={Money}/>
                                                                                    <Box p={1}/>
                                                                                    <FormLabel
                                                                                        style={{cursor: "pointer"}}
                                                                                        id="text-description-payment">{i.description}</FormLabel>
                                                                                </div>
                                                                            )
                                                                        } else {
                                                                            return (
                                                                                <div id={'div-item-payment'}>
                                                                                    <CardMedia
                                                                                        style={{width: 30, height: 30}}
                                                                                        image={CardCredit}/>
                                                                                    <Box p={1}/>
                                                                                    <FormLabel
                                                                                        style={{cursor: "pointer"}}
                                                                                        id="text-description-payment">{`${i.description} (máquina)`}</FormLabel>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })()}
                                                                    {(() => {
                                                                        if (i.description === 'Dinheiro') {
                                                                            return (<Divider/>)
                                                                        }
                                                                    })()}
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                } else if (paymentPhysical === 'OFF') {
                                    return (<div/>)
                                }
                            })()}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                id={'button-dialog'}
                                variant={'contained'}
                                onClick={() => this.setState({openPayment: false})}
                                color="primary">
                                Fechar
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={this.state.openProcessOrder} aria-labelledby="form-dialog-title">
                        <DialogContent>
                            <div id="processOrder">
                                <div id="processOrder-left">
                                    <CircularProgress/>
                                </div>
                                <div id="processOrder-right">
                                    <FormLabel id="processOrder-text">{this.state.statusOrder}</FormLabel>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
                    <Dialog open={openDateDelivery}>
                        <DialogTitle>Escolher data</DialogTitle>
                        <DialogContent>
                            <DialogContentText>Digite a data de entrega do pedido</DialogContentText>
                            <TextField
                                label={'Data entrega'}
                                variant={'outlined'}
                                focused={true}
                                value={dateDeliveryRoute}
                                fullWidth={true}
                                type={'date'}
                                name={'dateDeliveryRoute'}
                                onChange={this.handleInputs}/>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.closeListRoutes()}>
                                Cancelar
                            </Button>
                            <Button
                                id={'button-dialog'}
                                variant={'contained'}
                                color={'primary'}
                                onClick={() => this.onClickDateDelivery()}>
                                Confirmar
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={openListRoutes}>
                        <DialogTitle>Escolher horário</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {`Os pedidos serão entregues a partir do horário escolhido.`}
                            </DialogContentText>
                            {
                                (timeRoutes.length === 0) &&
                                <DialogContentText>
                                    Sem horários de entrega para essa data, selecione outra.
                                </DialogContentText>
                            }
                            {
                                timeRoutes.map((tr, index) => (
                                    <div
                                        className="btn-payment"
                                        key={index}>
                                        <FormControlLabel
                                            id="form-radio"
                                            control={<RedRadio/>}
                                            label={`${tr.time.substring(0, 5)}`}
                                            onChange={() => this.onClickTimeRoute(tr)}/>
                                    </div>
                                ))
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => this.closeListRoutes()}>
                                Cancelar
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={this.state.openConfirmation} onClose={this.closeConfirmation} fullScreen={isMobile}>
                        <DialogTitle id="form-dialog-title">Confirmar informações</DialogTitle>
                        <DialogContent style={{padding: 8}}>
                            <div id="confirm-information">
                                {
                                    noRecord &&
                                    <div>
                                        <div id="div-confirm-data-client">
                                            <div id="div-data-client">
                                                <div id="div-confirm-information">
                                                    <FormLabel id="title-confirm-information">Nome:</FormLabel>
                                                    <FormLabel id="title-confirm-information">{cliente.nome}</FormLabel>
                                                </div>
                                                <div id="div-confirm-information">
                                                    <FormLabel id="title-confirm-information">Telefone:</FormLabel>
                                                    <FormLabel
                                                        id="title-confirm-information">{`${cliente.ddd} ${cliente.telefone}`}</FormLabel>
                                                </div>
                                            </div>
                                            <div id="div-data-client-update"
                                                 onClick={() => this.setState({
                                                     openRecord: true,
                                                     openConfirmation: false,
                                                     clientName: cliente.nome,
                                                     clientPhone: `(${cliente.ddd}) ${cliente.telefone}`,
                                                     clientCPF: cliente.cpf,
                                                     confirmationInformation: false
                                                 })}>
                                                <Edit/>
                                                {/*<FormLabel id="label-update-client">Alterar</FormLabel>*/}
                                            </div>
                                        </div>
                                        <Box p={1}/>
                                        <Divider/>
                                    </div>
                                }
                                <div id="div-confirm-information">
                                    <FormLabel id="title-confirm-information">Total</FormLabel>
                                    <FormLabel
                                        id="title-confirm-information">{(this.state.subtotal + this.state.deliveryFee - this.state.discount - valueRescue).toLocaleString('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL'
                                    })}</FormLabel>
                                </div>
                                {
                                    this.state.change >= (this.state.subtotal + this.state.deliveryFee - this.state.discount - valueRescue) &&
                                    <div>

                                        <div id="div-confirm-information">
                                            <FormLabel id="descritivo-confirm-nformation">Total a Pagar</FormLabel>
                                            <FormLabel
                                                id="descritivo-confirm-nformation">{(this.state.change).toLocaleString('pt-BR', {
                                                style: 'currency',
                                                currency: 'BRL'
                                            })}</FormLabel>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.change >= (this.state.subtotal + this.state.deliveryFee - this.state.discount - valueRescue) &&
                                    <div>

                                        <div id="div-confirm-information">
                                            <FormLabel id="descritivo-confirm-nformation">Troco</FormLabel>
                                            <FormLabel
                                                id="descritivo-confirm-nformation">{(this.state.change - (this.state.subtotal + this.state.deliveryFee - this.state.discount - valueRescue)).toLocaleString('pt-BR', {
                                                style: 'currency',
                                                currency: 'BRL'
                                            })}</FormLabel>
                                        </div>
                                    </div>
                                }
                                {(() => {
                                    if (this.state.payment.toUpperCase() === 'DINHEIRO' && this.state.change < (this.state.subtotal + this.state.deliveryFee - this.state.discount - valueRescue)) {
                                        return (
                                            <div>
                                                <Divider/>
                                                <FormLabel id="descritivo-confirm-nformation">
                                                    Não precisa de troco
                                                </FormLabel>
                                            </div>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (this.state.retire) {
                                        return (
                                            <div id="div-retirar-confirm-information">
                                                <Divider/>
                                                <Box p={1}/>
                                                <FormLabel id="text-delivery-confirm-information">Endereço de
                                                    retirada</FormLabel>
                                                <FormLabel
                                                    id="descritivo-confirm-nformation">{storesLocation.toLowerCase() === 'retirar no local' ? restaurantAddress : storesLocation}</FormLabel>
                                                <Divider/>
                                                <Box p={1}/>
                                                {
                                                    scheduleDelivery === 'Entrega imediata'
                                                        ?
                                                        <FormLabel
                                                            id="time-confirm-nformation">{`Tempo aproximado para retirada entre ${this.state.timeTakeAwayMin} e ${this.state.timeTakeAwayMax}`}</FormLabel>
                                                        :
                                                        <FormLabel
                                                            id="time-confirm-nformation">{`Tempo de retirada por volta das ${scheduledDeliveryTime}`}</FormLabel>
                                                }
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div id="div-endereco-confirm-information">
                                                <Divider/>
                                                <Box p={1}/>
                                                <FormLabel id="text-delivery-confirm-information">
                                                    Endereço de entrega
                                                </FormLabel>
                                                <FormLabel id="descritivo-confirm-nformation">
                                                    {`${this.state.dateDelivery.rua}, ${this.state.dateDelivery.numero} - ${this.state.dateDelivery.bairro} ${(!!this.state.dateDelivery.complemento) ? `, ${this.state.dateDelivery.complemento}` : ''}`}
                                                </FormLabel>
                                                <Divider/>
                                                {(() => {
                                                    if (dateDeliveryRoute !== '') {
                                                        return (
                                                            <div>
                                                                <Box p={1}/>
                                                                <FormLabel id="time-confirm-nformation">
                                                                    {`Data de entrega ${moment(dateDeliveryRoute, 'YYYY-MM-DD').format('DD/MM/YYYY')} ${(timeRoute !== '') ? `saida da rota ${timeRoute}` : ''}`}
                                                                </FormLabel>
                                                            </div>
                                                        )
                                                    } else {
                                                        if (scheduleDelivery === 'Entrega imediata') {
                                                            return (
                                                                <div>
                                                                    <Box p={1}/>
                                                                    <FormLabel id="time-confirm-nformation">
                                                                        {`Tempo aproximado para entrega entre ${this.state.timeDeliverMin} e ${this.state.timeDeliverMax}`}
                                                                    </FormLabel>
                                                                </div>
                                                            )
                                                        } else {
                                                            return (
                                                                <div>
                                                                    {
                                                                        (scheduledDay && scheduleDelivery === 'Entrega programada') ?
                                                                            <div/>
                                                                            :
                                                                            <div>
                                                                                <Box p={1}/>
                                                                                <FormLabel id="time-confirm-nformation">
                                                                                    {`Tempo de entrega por volta das ${scheduledDeliveryTime}`}
                                                                                </FormLabel>
                                                                            </div>
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                    }
                                                })()}
                                            </div>
                                        )
                                    }
                                })()}
                                <div id={'div-view-order'} onClick={() => {
                                    this.closeConfirmation()
                                    smoothScrolling('pedido', 100)
                                }}>
                                    <Description/>
                                    <FormLabel id={'form-label-view-order'}>
                                        Ver pedido
                                    </FormLabel>
                                </div>
                                <div id="div-check-confirm">
                                    <FormControlLabel id="form-check" control={<RedCheck/>}
                                                      label="Confirmo as informações"
                                                      onChange={() => this.onClickConfirmationInformation()}/>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                id="btn-confirmar-info"
                                onClick={() => this.closeConfirmation()}
                                color="primary">
                                Cancelar
                            </Button>
                            {
                                this.state.confirmationInformation &&
                                <Button
                                    id="btn-confirmar-info"
                                    variant={'contained'}
                                    onClick={() => this.onClickConfirmation()}
                                    color="primary">
                                    Enviar
                                </Button>
                            }
                        </DialogActions>
                    </Dialog>
                    <Dialog open={this.state.openObservation} onClose={this.closeObservation}>
                        <DialogTitle id="form-dialog-title">
                            Adicione uma observação
                        </DialogTitle>
                        <DialogContent>
                            <div id="detalhes">
                                <div className="btn-options">
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        name="observation"
                                        id="name"
                                        label="Observação"
                                        type="text"
                                        fullWidth
                                        value={observation}
                                        variant="outlined"
                                        onChange={this.handleInputs}/>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.closeObservation} color="primary">Cancelar</Button>
                            <Button
                                id={'button-dialog'}
                                onClick={this.onClickObservation}
                                color={'primary'}
                                variant={'contained'}>
                                Confirmar
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={this.state.openOptions} onClose={this.closeOptions}>
                        <DialogTitle id="form-dialog-title">
                            Detalhes do produto
                        </DialogTitle>
                        <DialogContent>
                            <div id="detalhes">
                                <div className="btn-options">
                                    <FormLabel
                                        id={'label-options'}
                                        onClick={this.onClickDetail}>
                                        Detalhes do item
                                    </FormLabel>
                                </div>
                                <Divider/>
                                {
                                    (visibleObs) &&
                                    <>
                                        <div className="btn-options">
                                            <FormLabel
                                                id={'label-options'}
                                                onClick={this.onClickAddObs}>
                                                Adicionar Observação
                                            </FormLabel>
                                        </div>
                                        <Divider/>
                                    </>
                                }
                                <div className="btn-options">
                                    <FormLabel
                                        id={'label-options'}
                                        onClick={this.onClickRemover}>
                                        Remover item
                                    </FormLabel>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
                    <Dialog open={this.state.openCleanCartItems} onClose={this.closeCleanCartItems}>
                        <DialogTitle>Limpar carrinho</DialogTitle>
                        <DialogContent>
                            <DialogContentText
                                id={'label-dialog-content'}>
                                Deseja limpar todos os itens do carrinho ?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                id={'button-dialog'}
                                onClick={this.closeCleanCartItems}
                                color="primary">
                                Não
                            </Button>
                            <Button
                                id={'button-dialog'}
                                variant={'contained'}
                                onClick={this.cleanCartItems}
                                color="primary">
                                Sim
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={this.state.openChange}>
                        <DialogTitle>Precisa de troco ?</DialogTitle>
                        <DialogContent style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <DialogContentText id={'label-dialog-content'}>
                                Precisa de troco pra quanto ?
                            </DialogContentText>
                            <div id="div-total-troco">
                                <FormLabel id="title-confirm-information">Total</FormLabel>
                                <FormLabel
                                    id="title-confirm-information">{(this.state.subtotal + this.state.deliveryFee - this.state.discount - valueRescue).toLocaleString('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL'
                                })}</FormLabel>
                            </div>
                            {
                                !withoutChange &&
                                <TextField autoFocus margin="dense" name="change" type="number"
                                           value={this.state.change !== 0 ? this.state.change : ''} fullWidth
                                           variant="outlined" onChange={this.handleInputs}/>
                            }
                        </DialogContent>
                        <DialogContent style={{display: "flex", justifyContent: "center"}}>
                            <FormControlLabel id="form-check" control={<RedCheck/>} label="Não preciso de troco"
                                              onChange={this.onClickWithoutChange}/>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                id={'button-dialog'}
                                onClick={this.closeChange}
                                color="primary">
                                Cancelar
                            </Button>
                            {
                                ((this.state.change !== 0 && this.state.change !== '') || (withoutChange)) &&
                                <Button
                                    variant={'contained'}
                                    id={'button-dialog'}
                                    onClick={this.onClickConfirmChange}
                                    color="primary">
                                    Confirmar
                                </Button>
                            }
                        </DialogActions>
                    </Dialog>
                    <Dialog open={this.state.openCoupon} onClose={this.closeCoupon}>
                        <DialogTitle>Adicionando cupom</DialogTitle>
                        <DialogContent>
                            <DialogContentText
                                id={'label-dialog-content'}>
                                Insira o código para validarmos o cupom
                            </DialogContentText>
                            <TextField
                                variant={'outlined'}
                                placeholder={'Cupom'}
                                autoFocus
                                margin="dense"
                                name="coupon"
                                fullWidth
                                onChange={this.handleInputs}/>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                id={'button-dialog'}
                                onClick={this.closeCoupon}
                                color="primary">
                                Cancelar
                            </Button>
                            <Button
                                id={'button-dialog'}
                                variant={'contained'}
                                onClick={this.onClickValidateCoupon}
                                color="primary">
                                Confirmar
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={warning.open}>
                        <DialogTitle>{warning.title}</DialogTitle>
                        <DialogContent>
                            <DialogContentText
                                id={'label-dialog-content'}>
                                {warning.text}
                            </DialogContentText>
                            {
                                warning.id_pedido &&
                                <div>
                                    <DialogContentText
                                        id={'label-dialog-content'}
                                        style={{
                                            fontSize: "small",
                                            margin: 2
                                        }}>
                                        {warning.id_pedido}
                                    </DialogContentText>
                                    <DialogContentText
                                        id={'label-dialog-content'}
                                        style={{fontSize: "small", margin: 2}}>
                                        {warning.cpf}
                                    </DialogContentText>
                                </div>
                            }
                            <DialogContentText id={'label-dialog-content'}>
                                {`Versão: ${localStorage.getItem(`bitmenu:version`)}`}
                            </DialogContentText>
                        </DialogContent>
                        {
                            warning.tryAgain &&
                            <DialogActions>
                                <Button
                                    id={'button-dialog'}
                                    onClick={this.closeDialogWarning}
                                    color="primary">
                                    Cancelar
                                </Button>
                                <Button
                                    id={'button-dialog'}
                                    variant={'contained'}
                                    onClick={() => {
                                        this.onClickConfirmation()
                                        this.setState({warning: {open: false, title: '', text: ''}})
                                    }}
                                    color="primary">
                                    Tentar novamente
                                </Button>
                            </DialogActions>
                        }
                        {
                            !warning.tryAgain &&
                            <DialogActions>
                                <Button
                                    id={'button-dialog'}
                                    variant={'contained'}
                                    onClick={() => this.setState({warning: {open: false}})}
                                    color="primary">
                                    Ok
                                </Button>
                            </DialogActions>
                        }
                    </Dialog>
                    <Dialog
                        open={openReceptionTimes}
                        onClose={() => {
                            if (!openDatechedule) {
                                this.setState({openReceptionTimes: false})
                            } else {

                            }
                        }}
                        fullScreen={isMobile}>
                        <DialogTitle>Opções de entrega</DialogTitle>
                        <DialogContent>
                            {
                                (scheduledDay)
                                    ?
                                    <DialogContentText id={'label-dialog-content'}>
                                        Escolha a data de entrega do pedido
                                    </DialogContentText>
                                    :
                                    <DialogContentText id={'label-dialog-content'}>
                                        {receptionTimes.length === 0 ? `A Loja está fechada para pedidos programados` : `Escolha uma das opções de entrega`}
                                    </DialogContentText>
                            }
                            {
                                (this.timeControl() && !scheduledDay) &&
                                <div className="btn-payment">
                                    <FormControlLabel id="form-radio" control={<RedRadio/>} label="Entrega imediata"
                                                      onChange={() => this.onClickReceptionTimes("Entrega imediata")}/>
                                    <Divider/>
                                </div>
                            }
                            {
                                (scheduledDay) &&
                                <Box>
                                    <Box p={1}/>
                                    <TextField
                                        name={'dateSchedule'}
                                        label={'Data programada'}
                                        fullWidth={true}
                                        value={dateSchedule}
                                        placeholder={'Data'}
                                        type={'date'}
                                        InputLabelProps={{shrink: true}}
                                        variant={'outlined'}
                                        onChange={this.handleInputs}/>
                                </Box>
                            }
                            {
                                (!scheduledDay) &&
                                receptionTimes.map((i, index) => (
                                    <div className="btn-payment" key={index}>
                                        <FormControlLabel id="form-radio" control={<RedRadio/>} label={`${i}`}
                                                          onChange={() => this.onClickReceptionTimes(i)}/>
                                    </div>
                                ))
                            }
                        </DialogContent>
                        <DialogActions>
                            {
                                (!scheduledDay) &&
                                <Button
                                    id={'button-dialog'}
                                    variant={'contained'}
                                    onClick={() => {
                                        this.setState({openReceptionTimes: false})
                                    }}
                                    color="primary">
                                    Fechar
                                </Button>
                            }
                            {
                                (scheduledDay) &&
                                <Button
                                    id={'button-dialog'}
                                    variant={'contained'}
                                    onClick={() => this.onClickCheckSchedule()}
                                    color="primary">
                                    Confirmar
                                </Button>
                            }
                        </DialogActions>
                    </Dialog>
                    <Dialog open={scheduledOrderNotice} onClose={() => this.setState({scheduledOrderNotice: false})}>
                        <div style={{width: 250}}>
                            <div id="container-sucess">
                                <img id="logo-sucess" src={Logo} alt=""/>
                            </div>
                            <DialogContentText id={'label-dialog-content'}>
                                {this.state.message}
                            </DialogContentText>
                            <div>
                                <DialogContentText
                                    id={'label-dialog-content'}>
                                    Somente pedidos programados podem ser enviados
                                </DialogContentText>
                            </div>
                        </div>
                        <DialogActions>
                            <Button
                                id={'button-dialog'}
                                variant={'contained'}
                                onClick={() => this.setState({scheduledOrderNotice: false})}
                                color="primary">
                                Ok
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={openCouponNotice}
                            onClose={() => this.setState({openCouponNotice: false, valueRescue: 0})}>
                        <DialogTitle>Aviso</DialogTitle>
                        <DialogContent>
                            <DialogContentText id={'label-dialog-content'}>
                                Você tem um cupom de desconto ativo, ao resgatar o cashback o cupom será retirado
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                id={'button-dialog'}
                                onClick={() => this.setState({openCouponNotice: false, valueRescue: 0})}
                                color="primary">
                                Cancelar
                            </Button>
                            <Button
                                id={'button-dialog'}
                                variant={'contained'}
                                onClick={() => this.onClickConfirmationRemoveCoupon()}
                                color="primary">
                                Confirmar
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={openRescueNotice} onClose={() => this.setState({openRescueNotice: false})}>
                        <DialogTitle>Aviso</DialogTitle>
                        <DialogContent>
                            <DialogContentText id={'label-dialog-content'}>
                                Você resgatou um valor no cashback, ao utilizar o cupom o resgate será retirado
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                id={'button-dialog'}
                                onClick={() => this.setState({openRescueNotice: false})}
                                color="primary">
                                Cancelar
                            </Button>
                            <Button
                                id={'button-dialog'}
                                variant={'contained'}
                                onClick={() => this.onClickConfirmationRemoveRescue()}
                                color="primary">
                                Confirmar
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={openRescue} onClose={() => this.onClickNotRescue()}>
                        <DialogTitle>Resgatar valor</DialogTitle>
                        <DialogContent>
                            <DialogContentText id={'label-dialog-content'}>
                                Deseja resgatar algum valor do seu cashback ?
                            </DialogContentText>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                <DialogContentText id={'label-dialog-content'} style={{color: 'green'}}>
                                    {`Saldo inicial: ${(balanceGularis).toLocaleString('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL'
                                    })}`}
                                </DialogContentText>
                                <DialogContentText id={'label-dialog-content'}>
                                    {`Saldo final: ${(balanceGularis - valueRescue).toLocaleString('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL'
                                    })}`}
                                </DialogContentText>
                            </div>
                            <TextField
                                variant={'outlined'}
                                autoFocus
                                margin="dense"
                                name="valueRescue"
                                fullWidth
                                label="Valor a resgatar"
                                type="number"
                                value={parseFloat(valueRescue !== '' && valueRescue !== undefined ? valueRescue.toFixed(2) : valueRescue)}
                                onChange={this.handleInputs}/>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={this.onClickNotRescue}
                                color="primary">
                                Cancelar
                            </Button>
                            <Button
                                id={'button-dialog'}
                                variant={'contained'}
                                color="primary"
                                onClick={this.onClickRescue}>
                                Confirmar
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={openConfirmDDD}>
                        <DialogTitle id="form-dialog-title">Confirmar dados pessoais</DialogTitle>
                        <DialogContent>
                            <DialogContentText id={'label-dialog-content'}>Confirme seu telefone</DialogContentText>
                            <div id="form-cadastro">
                                <TextField margin="dense" name="confirmDDD" label="DDD" fullWidth value={confirmDDD}
                                           type="number"
                                           placeholder="DDD" variant="outlined" onChange={this.handleInputs}/>

                                <Box p={1}/>
                                <TextField margin="dense" name="confirmPhone" label="Telefone" fullWidth
                                           value={confirmPhone}
                                           type="number"
                                           placeholder="Telefone" variant="outlined" onChange={this.handleInputs}/>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({openConfirmDDD: false})}
                                    color="primary">Cancelar</Button>
                            <Button
                                id={'button-dialog'}
                                variant={'contained'}
                                onClick={this.onClickConfirmDDD}
                                color="primary">
                                Confirmar
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={openDeliveryOrTakeaway}>
                        <DialogTitle id="form-dialog-title">
                            Comer ou Levar
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id={'label-dialog-content'}>
                                Escolha uma das opções
                            </DialogContentText>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                alignItems={'center'}
                                p={1}>
                                <Button
                                    id={'button-dialog'}
                                    variant={'outlined'}
                                    fullWidth={true}
                                    style={{
                                        background: color_nextButton,
                                        color: color_fontButton,
                                        fontWeight: 'bold'
                                    }}
                                    onClick={() => {
                                        this.setState({
                                            openDeliveryOrTakeaway: false,
                                            openTotem: true
                                        })
                                        localStorage.setItem(`bitmenu-${identifier}:optionsTakeWay`, 'Comer local')
                                    }}>
                                    Comer aqui
                                </Button>
                                <Box p={1}/>
                                <Button
                                    id={'button-dialog'}
                                    variant={'outlined'}
                                    fullWidth={true}
                                    style={{
                                        background: color_nextButton,
                                        color: color_fontButton,
                                        fontWeight: 'bold'
                                    }}
                                    onClick={() => {
                                        this.setState({
                                            openDeliveryOrTakeaway: false,
                                            openTotem: true
                                        })
                                        localStorage.setItem(`bitmenu-${identifier}:optionsTakeWay`, 'Retirar na loja para levar')
                                    }}>
                                    Levar
                                </Button>
                            </Box>
                        </DialogContent>
                    </Dialog>

                    <Dialog open={openTotem} aria-labelledby="form-dialog-title"
                            onClose={() => this.setState({openTotem: false})}>
                        <DialogTitle
                            id="form-dialog-title">
                            {'Informações do pedido'}
                        </DialogTitle>
                        <DialogContent id={'dialog-content-totem-mesa'}>
                            <DialogContentText
                                id={'label-dialog-content'}>
                                {`${this.state.timeTakeAwayMin} - ${this.state.timeTakeAwayMax}`}
                            </DialogContentText>
                            <div id="form-totem">
                                <TextField margin="dense" name="nameTotem" label="Nome cliente" fullWidth
                                           value={nameTotem} autoComplete="false" placeholder="Nome"
                                           variant="outlined"
                                           autoFocus={true} onChange={this.handleInputs}/>
                                <div id="form-radio-totem">
                                    <Card id="card-radio-totem"
                                          onClick={() => this.onClickPaymentTotem('caixa')}>
                                        <CardMedia image={Caixa} id="card-image-totem"/>
                                        <FormControlLabel
                                            id="radio-totem" control={<RedRadio/>}
                                            checked={paymentTotem === 'caixa' || !pix} value='caixa'
                                            label="Pagar no caixa"/>
                                    </Card>
                                    {
                                        (pix) &&
                                        <Box p={1}/>
                                    }
                                    {
                                        (pix) &&
                                        <div id="div-card-radio-totem">
                                            <Card id="card-radio-totem" onClick={() => this.onClickPaymentTotem('pix')}>
                                                <CardMedia image={Pix} id="card-image-totem"/>
                                                <FormControlLabel id="radio-totem" control={<RedRadio/>}
                                                                  checked={paymentTotem === 'pix'} value='pix'
                                                                  label="Pagar com PIX"/>
                                            </Card>
                                        </div>
                                    }
                                </div>
                                <div
                                    id="btn-finaliza-totem"
                                    style={{backgroundColor: color_nextButton}} onClick={this.onClickSendTotem}>
                                    <FormLabel style={{color: color_fontButton, cursor: "pointer"}}
                                               id="texto-finaliza">Enviar pedido</FormLabel>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        open={openRecord}
                        onClose={() => this.setState({openRecord: false})}
                        fullScreen={isMobile}>
                        <DialogTitle id="form-dialog-title">Informe seus dados</DialogTitle>
                        <DialogContent>
                            <TextField
                                name="clientName"
                                label="Nome"
                                fullWidth={true}
                                placeholder="Nome"
                                value={clientName}
                                variant="outlined"
                                autoFocus={true}
                                autoComplete={true}
                                onChange={this.handleInputs}/>
                            <Box p={1}/>
                            <TextField
                                name="clientPhone"
                                label="Telefone"
                                fullWidth={true}
                                placeholder="Telefone"
                                autoComplete={true}
                                value={clientPhone}
                                variant="outlined"
                                onChange={this.handleInputs}/>
                            <Box p={1}/>
                            <TextField
                                name="clientCPF"
                                label="CPF/CNPJ"
                                fullWidth={true}
                                placeholder="00000000000"
                                autoComplete={true}
                                value={clientCPF}
                                variant="outlined"
                                onChange={this.handleInputs}/>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                id={'button-dialog'}
                                onClick={() => this.setState({openRecord: false})}
                                color="primary">
                                Cancelar
                            </Button>
                            <Button
                                id={'button-dialog'}
                                variant={'contained'}
                                onClick={() => this.onClickIdentification()}
                                color="primary">
                                Confirmar
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={openCPF} onClose={() => this.setState({openCPF: false})}>
                        <DialogTitle id="form-dialog-title">Informe seus dados</DialogTitle>
                        <DialogContent>
                            <TextField
                                name="clientCPF"
                                label="CPF/CNPJ"
                                fullWidth={true}
                                placeholder="00000000000"
                                autoComplete={true}
                                autoFocus={true}
                                value={clientCPF}
                                variant="outlined"
                                onChange={this.handleInputs}/>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                id={'button-dialog'}
                                onClick={() => this.setState({openCPF: false})}
                                color="primary">
                                Cancelar
                            </Button>
                            <Button
                                id={'button-dialog'}
                                variant={'contained'}
                                onClick={() => this.onClickConfirmCPF()}
                                color="primary">
                                Confirmar
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={openLogin} onClose={this.onCloseLogin}>
                        <DialogTitle>Login</DialogTitle>
                        <DialogContent id="dialog-content-identifier">
                            <DialogContentText id={'label-dialog-content'}>
                                Escolha a forma de comprar
                            </DialogContentText>
                            <Button id="button-dialog" variant="outlined" onClick={this.onClickLogin}
                                    style={{background: color_nextButton, color: color_fontButton, fontWeight: 'bold'}}>
                                Já tenho cadastro
                            </Button>
                            <Box p={1}/>
                            <Button id="button-dialog" variant="outlined" onClick={this.onClickRecord}
                                    style={{background: color_nextButton, color: color_fontButton, fontWeight: 'bold'}}>
                                Quero me cadastrar
                            </Button>
                            <Box p={1}/>
                            <Button id="button-dialog" variant="outlined" onClick={this.onClickNoRecord}
                                    style={{background: color_nextButton, color: color_fontButton, fontWeight: 'bold'}}>
                                Comprar sem cadastro
                            </Button>
                            <Box p={1}/>
                        </DialogContent>
                    </Dialog>
                    <Dialog open={openRaio}>
                        <DialogTitle>{messageTitleRaio}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id={'label-dialog-content'}>
                                {messageTextRaio}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                color="primary"
                                id="button-dialog"
                                variant="contained"
                                onClick={() => this.setState({openRaio: false})}>
                                Ok
                            </Button>
                        </DialogActions>
                    </Dialog>
                </MuiThemeProvider>
                <Snackbar
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    open={this.state.openSnackBar}
                    autoHideDuration={2000}
                    onClose={this.closeSnackBar}
                    message={this.state.snackBar}
                />
            </div>
        )
    }
}

export default withRouter(Carrinho)
