import React from 'react'
import {withRouter} from 'react-router-dom'
import {
    AppBar,
    Card,
    CardContent,
    FormLabel,
    Toolbar,
    Typography,
    Radio,
    FormControlLabel,
    RadioGroup,
    Snackbar,
    Checkbox
} from '@material-ui/core'
import {ArrowBack as ArrowBackIcon} from '@material-ui/icons'
import './Perguntas.css'
import MenosDisable from '../../images/menos_disable.png'
import Menos from '../../images/menos.png'
import Mais from '../../images/mais.png'
import {withStyles} from '@material-ui/core/styles'
import {pageView, IDENTIFIER_STORE} from '../../util'

let identifier

const RedRadio = withStyles({
    checked: {},
})(props => <Radio color="default" {...props} />)

const RedCheck = withStyles({})(props => <Checkbox color="default" {...props} />)

class Perguntas extends React.Component {

    state = {
        questions: [],
        responseQuestions: [],
        quantityForResponse: 0,
        minimumQuantityForResponse: 0,
        maximumQuantityForResponse: 0,
        openSnackBar: false,
        snackBar: '',
        colorStore: {
            color_title: '',
            color_description: '',
            color_price: '',
            color_toolbar: '',
            color_body: '',
            color_cardProduct: '',
            color_nextButton: '',
            color_fontButton: '',
            color_fontToolbar: '',
            color_footer: ''
        }
    }

    onClickBack = () => {
        this.props.history.goBack()
    }

    onClickConfirm = () => {
        const {questions} = this.state
        let responseQuestions = sessionStorage.getItem(`bitmenu-${identifier}:responseQuestions`)
        responseQuestions = (responseQuestions !== null && responseQuestions !== '') ? JSON.parse(responseQuestions) : []

        for (let i = 0; i < questions.length; i++) {
            for (let a = 0; a < responseQuestions.length; a++) {
                if (questions[i].internal === responseQuestions[a].interno && questions[i].type === 2) {
                    if (responseQuestions[a].resposta < questions[i].answer[0]) {
                        return this.setState({
                            openSnackBar: true,
                            snackBar: `${questions[i].question} tem uma resposta minina de ${questions[i].answer[0]}`
                        })
                    }
                }
            }
        }

        let contQuestions = 0
        questions.forEach(i => {
            contQuestions = i.type === 3 ? contQuestions : (contQuestions + 1)
        })

        let cont = 0
        let pergunta = ''
        responseQuestions.forEach(i => {
            if (pergunta !== i.questao) {
                cont++
            }
            pergunta = i.questao
        })

        if (cont < contQuestions) {
            this.setState({
                openSnackBar: true,
                snackBar: 'Responda as perguntas obrigatórias'
            })
            return
        }

        sessionStorage.setItem(`bitmenu-${identifier}:confirmedAnswers`, 'ok')
        sessionStorage.setItem(`bitmenu-${identifier}:responseQuestions`, JSON.stringify(responseQuestions))
        this.props.history.goBack()
    }

    onClickQuantityForAnswer = (index, obj, response, checked = false) => {
        let {question, internal, answer, type} = obj
        let questions = question
        question = this.clearText(question)
        //let {responseQuestions} = this.state
        let responseQuestions = sessionStorage.getItem(`bitmenu-${identifier}:responseQuestions`)
        responseQuestions = (responseQuestions !== null && responseQuestions !== '') ? JSON.parse(responseQuestions) : []

        let qtd
        if (index === 3) {
            if (checked) {
                this.setState({[`${question}${response}`]: response})
                responseQuestions.push({
                    questao: question,
                    pergunta: questions,
                    interno: '',
                    resposta: response,
                    type: type
                })
                sessionStorage.setItem(`bitmenu-${identifier}:responseQuestions`, JSON.stringify(responseQuestions))
                return
            } else {
                this.setState({[`${question}${response}`]: ''})
                for (let i = 0; i < responseQuestions.length; i++) {
                    if (responseQuestions[i].questao === question && responseQuestions[i].resposta === response) {
                        responseQuestions.splice(i, 1)
                        sessionStorage.setItem(`bitmenu-${identifier}:responseQuestions`, JSON.stringify(responseQuestions))
                        return
                    }
                }
            }
        } else if (index === 2) {
            if (response === 0) {
                qtd = this.state[question] !== undefined ? this.state[question] : 0
                if (--qtd >= 0) {
                    this.setState({[question]: qtd})
                }
                qtd = (qtd < 0 ? 0 : qtd)
            } else {
                let maximo = answer[1]
                qtd = this.state[question] !== undefined ? this.state[question] : 0
                if (++qtd <= maximo) {
                    this.setState({[question]: qtd})
                }
                qtd = (qtd > maximo ? maximo : qtd)
            }
        } else {
            this.setState({[question]: response})
        }

        if (responseQuestions.length === 0) {
            responseQuestions.push({
                questao: question,
                pergunta: questions,
                interno: internal,
                resposta: (type === 2 ? qtd : response),
                type: type
            })
        } else {
            let achou = true
            responseQuestions.forEach((i, index) => {
                if (type === 2) {
                    if (i.interno === internal) {
                        responseQuestions[index].resposta = qtd
                        achou = false
                    }
                } else {
                    if (i.interno === internal && i.resposta !== response) {
                        responseQuestions[index].resposta = response
                        achou = false
                    }
                }

            })
            if (achou) {
                responseQuestions.push({
                    questao: question,
                    pergunta: questions,
                    interno: internal,
                    resposta: (type === 2 ? qtd : response),
                    type: type
                })
            }
        }
        sessionStorage.setItem(`bitmenu-${identifier}:responseQuestions`, JSON.stringify(responseQuestions))
    }

    questions = () => {
        sessionStorage.removeItem(`bitmenu-${identifier}:confirmedAnswers`)
        let questions = localStorage.getItem(`bitmenu-${identifier}:questions`)
        questions = questions !== null ? JSON.parse(questions) : []
        this.setState({questions: questions})
        this.checkMandatory(questions)
        let responseQuestions = sessionStorage.getItem(`bitmenu-${identifier}:responseQuestions`)
        responseQuestions = (responseQuestions !== null && responseQuestions !== '') ? JSON.parse(responseQuestions) : []
        responseQuestions.forEach(i => {
            if (i.type === 3) {
                this.setState({[`${i.questao}${i.resposta}`]: i.resposta})
            } else {
                this.setState({[this.clearText(i.questao)]: i.resposta})
            }
        })
        if (responseQuestions.length > 0) {
            this.setState({responseQuestions: responseQuestions})
        }
    }

    checkMandatory = questions => {
        questions.forEach(i => {
                if (i.type === 2) {
                    this.setState({[this.clearText(i.question)]: 0})
                    let responseQuestions = sessionStorage.getItem(`bitmenu-${identifier}:responseQuestions`)
                    responseQuestions = (responseQuestions !== null && responseQuestions !== '') ? JSON.parse(responseQuestions) : []

                    let add = true
                    for (let a = 0; a < responseQuestions.length; a++) {
                        if (responseQuestions[a].interno === i.internal) {
                            add = false
                            break
                        }
                    }

                    if (add) {
                        responseQuestions.push({
                            questao: i.question,
                            pergunta: i.question,
                            interno: i.internal,
                            resposta: 0,
                            type: 2
                        })
                        sessionStorage.setItem(`bitmenu-${identifier}:responseQuestions`, JSON.stringify(responseQuestions))
                    }
                }
            }
        )
    }

    clearText = text => {
        try {
            text = text.normalize("NFD").replace(/[^A-Z0-9]/ig, "")
            text = text.trim()
            return text.toLowerCase()
        } catch (e) {

        }
    }

    personalize = () => {
        try {
            let colors = JSON.parse(localStorage.getItem(`bitmenu-${identifier}:colorStore`))
            const {
                title,
                description,
                price,
                toolbar,
                body,
                cardProduct,
                nextButton,
                fontButton,
                fontToolbar,
                footer
            } = colors
            this.setState({
                colorStore: {
                    color_title: `#${title}`,
                    color_description: `#${description}`,
                    color_price: `#${price}`,
                    color_toolbar: `#${toolbar}`,
                    color_body: `#${body}`,
                    color_cardProduct: `#${cardProduct}`,
                    color_nextButton: `#${nextButton}`,
                    color_fontButton: `#${fontButton}`,
                    color_fontToolbar: `#${fontToolbar}`,
                    color_footer: `#${footer}`
                }
            })
        } catch (e) {

        }
    }

    componentDidMount() {
        identifier = IDENTIFIER_STORE
        this.questions()
        this.personalize()
        window.scrollTo(0, 0)
        pageView()
    }

    render() {
        const {
            questions,
            openSnackBar,
            snackBar,
            colorStore: {
                color_title,
                color_toolbar,
                color_body,
                color_cardProduct,
                color_fontToolbar,
                color_fontButton,
                color_nextButton
            }
        } = this.state
        return (
            <div id="perguntas" style={color_body ? {background: color_body} : {}}>
                <AppBar
                    id="appBar"
                    position="sticky"
                    style={color_toolbar ? {background: color_toolbar} : {}}
                    color="default">
                    <Toolbar variant="dense">
                        <ArrowBackIcon style={color_fontToolbar ? {color: color_fontToolbar} : {}} id="voltar-carrinho"
                                       onClick={this.onClickBack}/>
                        <Typography id={'label-title'} style={color_fontToolbar ? {color: color_fontToolbar} : {}} variant="h6"
                                    color="inherit">Perguntas</Typography>
                    </Toolbar>
                </AppBar>
                <div id="container-perguntas" style={{marginBottom: 650}}>
                    {
                        questions.map((i, index) => (
                            <div key={index}>
                                <Card id="card-questions">
                                    <CardContent id="card-content-questions-title"
                                                 style={color_toolbar ? {background: color_toolbar} : {}}>
                                        <div>
                                            <FormLabel id="title-question"
                                                       style={color_fontToolbar ? {color: color_fontToolbar} : {}}>
                                                {i.question}
                                            </FormLabel>
                                        </div>
                                        {(() => {
                                            if ((i.type === 2 && i.answer[0] === 0) || (i.type === 3)) {
                                                return (
                                                    <div/>
                                                )
                                            } else {
                                                return (
                                                    <div>
                                                        {
                                                            !this.state[this.clearText(i.question)] &&
                                                            < div id="card-obrigatorio-button">
                                                                <FormLabel
                                                                    id="variacao-obrigatorio-button">OBRIGATÓRIO</FormLabel>
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            }
                                        })()}
                                    </CardContent>
                                    <CardContent id="card-content-questions"
                                                 style={color_cardProduct ? {background: color_cardProduct} : {}}>
                                        {(() => {
                                            let {question, type, answer} = i
                                            question = this.clearText(question)
                                            if (type === 0) {
                                                return (
                                                    <RadioGroup>
                                                        {
                                                            answer.map((a, index) => (
                                                                <div key={index}>
                                                                    <FormControlLabel id="form-radio"
                                                                                      style={color_title ? {color: color_title} : {}}
                                                                                      control={<RedRadio
                                                                                          style={{color: color_title}}/>}
                                                                                      label={a ? 'Sim' : 'Não'}
                                                                                      value={a ? 'Sim' : 'Não'}
                                                                                      checked={this.state[question] === (a ? 'Sim' : 'Não')}
                                                                                      onChange={() => this.onClickQuantityForAnswer(0, i, a ? 'Sim' : 'Não')}/>
                                                                </div>
                                                            ))
                                                        }
                                                    </RadioGroup>
                                                )
                                            } else if (type === 1) {
                                                return (
                                                    <RadioGroup>
                                                        {
                                                            answer.map((a, index) => (
                                                                <div key={index}>
                                                                    <FormControlLabel id="form-radio"
                                                                                      style={color_title ? {color: color_title} : {}}
                                                                                      control={<RedRadio
                                                                                          style={{color: color_title}}/>}
                                                                                      label={a}
                                                                                      value={a}
                                                                                      checked={this.state[question] === a}
                                                                                      onChange={() => this.onClickQuantityForAnswer(0, i, a)}/>
                                                                </div>
                                                            ))
                                                        }
                                                    </RadioGroup>
                                                )
                                            } else if (type === 3) {
                                                return (
                                                    answer.map((a, index) => (
                                                        <div key={index}>
                                                            <FormControlLabel id="form-check" control={<RedCheck
                                                                style={{color: color_title}}/>}
                                                                              style={color_title ? {color: color_title} : {}}
                                                                              label={a}
                                                                              checked={this.state[`${question}${a}`] === a}
                                                                              onChange={(event, checked) => this.onClickQuantityForAnswer(3, i, a, checked)}/>
                                                        </div>
                                                    ))
                                                )
                                            } else {
                                                return (
                                                    <div id="div-botoes-footer">
                                                        <div id="botoes-footer"
                                                             style={{
                                                                 display: "flex",
                                                                 justifyContent: "center",
                                                                 background: "white",
                                                                 width: 90
                                                             }}>
                                                            <div>
                                                                <img
                                                                    src={(this.state[question] === 0 || this.state[question] === undefined) ? MenosDisable : Menos}
                                                                    id="btn-menos-footer"
                                                                    onClick={() => this.onClickQuantityForAnswer(2, i, 0)}
                                                                    alt=""/>
                                                            </div>
                                                            <FormLabel
                                                                id="quantidade-footer">{this.state[question] === undefined ? 0 : this.state[question]}</FormLabel>
                                                            <div>
                                                                <img src={Mais} id="btn-mais-footer"
                                                                     onClick={() => this.onClickQuantityForAnswer(2, i, 1)}
                                                                     alt=""/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })()}
                                    </CardContent>
                                </Card>
                            </div>
                        ))
                    }
                </div>
                <div
                    id="footer-carrinho"
                    style={{
                        background: color_nextButton,
                        cursor: "pointer"
                    }}
                    onClick={this.onClickConfirm}>
                    <FormLabel
                        style={color_fontButton ? {color: color_fontButton} : {}}
                        id="texto-finaliza">
                        Confirmar
                    </FormLabel>
                </div>
                <Snackbar
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    open={openSnackBar}
                    autoHideDuration={2000}
                    onClose={() => this.setState({openSnackBar: false})}
                    message={snackBar}
                />
            </div>
        )
    }
}

export default withRouter(Perguntas)
