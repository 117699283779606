import React from 'react'
import {
    AppBar,
    Toolbar,
    Typography,
    FormLabel,
    Card,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Input,
    DialogContentText,
    CardMedia,
    Snackbar,
    CircularProgress,
    TextField
} from '@material-ui/core'
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles'
import {ArrowBack as ArrowBackIcon} from '@material-ui/icons'
import {withRouter} from 'react-router-dom'
import './Login.css'
import GoogleLogin from 'react-google-login'
import {isSafari} from 'react-device-detect'
import bitmenu from '../../images/bitmenu.png'
import {rest, URL_BASE, pageView, hideData, IDENTIFIER_STORE} from '../../util'
import {randomNumber} from "react-lf-tools";

let goBack = ''
let identifier

class Login extends React.Component {

    state = {
        cliente: {},
        openForgotIt: false,
        openInvalidEmail: false,
        openInvalidPassword: false,
        openMessage: false,
        openLogin: false,
        openEsqueci: false,
        openRedefinirSenha: false,
        textInvalid: '',
        email: '',
        password: '',
        name: '',
        openSnackBar: false,
        snackBar: '',
        colorStore: {
            color_title: '',
            color_description: '',
            color_price: '',
            color_toolbar: '',
            color_body: '',
            color_cardProduct: ''
        },
        redefinirSenha: '',
        redefinirSenhaRepetir: ''
    }

    handleInputs = e => {
        this.setState({[e.target.name]: e.target.value})
    }

    handleKeyPress = e => {
        if (e.key === 'Enter') this.entrar()
    }

    closeForgotIt = () => {
        this.setState({openForgotIt: false})
    }

    closeInvalidEmail = () => {
        this.setState({openInvalidEmail: false})
    }

    clearText = (text) => {
        return text.replace(/[^\d]+/g, '')
    }

    onClickInvalidEmail = () => {
        const {email, password, name} = this.state
        sessionStorage.setItem(`bitmenu-email:`, JSON.stringify({
            email: email,
            password: password,
            name: name,
            google: true
        }))
        this.props.history.replace('/cadastro')
    }

    entrar = async () => {
        try {
            this.setState({openLogin: true})
            let {email, password} = this.state
            if (!email.includes('@')) {
                return this.setState({
                    openMessage: true,
                    titleMessage: 'Dados incorretos',
                    textMessage: 'Esse e-mail não é válido, preencha o campo email corretamente.',
                    openLogin: false
                })
            }
            email = email.toLowerCase()
            const {returnCode, message, data} = await rest(`${URL_BASE}/login/v2`, {
                method: 'POST',
                body: JSON.stringify({email: email, password: password})
            })
            if (returnCode) {
                if (data === null) {
                    this.setState({openInvalidEmail: true, openLogin: false})
                    return
                }
                localStorage.setItem(`bitmenu-${identifier}:s`, hideData(password))
                localStorage.setItem(`bitmenu-${identifier}:cliente`, JSON.stringify(data))
                localStorage.removeItem(`bitmenu-${identifier}:noRecord`)
                this.onclickVoltar()
            } else {
                this.setState({
                    openMessage: true,
                    titleMessage: 'Erro ao efetuar login',
                    textMessage: message
                })
            }
            this.setState({openLogin: false})
        } catch (e) {
            console.log(e)
        }
    }

    google = async response => {
        try {
            const {email, googleId} = response.profileObj
            this.setState({openLogin: true})
            let conexao = {
                method: 'POST',
                body: JSON.stringify({email: email})
            }
            const {returnCode, message, data} = await rest(`${URL_BASE}/login`, conexao)
            if (returnCode) {
                if (data === null) {
                    this.setState({
                        openInvalidEmail: true,
                        openLogin: false,
                        email: email,
                        password: googleId,
                        name: response.profileObj.name
                    })
                    return
                }
                const cliente = Object.values(data)[0]
                const {senha} = cliente
                if (senha === googleId) {
                    localStorage.setItem(`bitmenu-${identifier}:cliente`, JSON.stringify(cliente))
                    localStorage.removeItem(`bitmenu-${identifier}:noRecord`)
                    this.onclickVoltar()
                } else {
                    this.setState({
                        openMessage: true,
                        titleMessage: 'Aviso',
                        textMessage: 'Esse e-mail está cadastrado, mas não foi feito com a conta Google, clique em esqueci minha senha.'
                    })
                }
            } else {
                this.setState({
                    openMessage: true,
                    titleMessage: 'Erro ao efetuar login',
                    textMessage: message
                })
            }
            this.setState({openLogin: false})
        } catch (e) {
            console.log(e)
        }
    }

    cancelaEsqueci = () => this.setState({openEsqueci: false})

    onClickEsqueci = () => this.setState({openEsqueci: true})

    esqueci = async () => {
        let {email} = this.state
        email = email.toLowerCase()
        if (!email.includes('@')) {
            return this.setState({
                openMessage: true,
                titleMessage: 'Dados incorretos',
                textMessage: 'Esse e-mail não é válido, preencha o campo email corretamente.',
                openLogin: false
            })
        }
        this.setState({openLogin: true, openEsqueci: false})
        const {returnCode, message, data} = await rest(`${URL_BASE}/login`, {
            method: 'POST',
            body: JSON.stringify({email: email})
        })
        this.setState({openLogin: false})
        if (returnCode) {
            localStorage.removeItem(`bitmenu-${identifier}:noRecord`)
            if (data === null) return this.setState({openInvalidEmail: true, openLogin: false})
            let codigo = randomNumber(6)
            let client = Object.values(data)[0]
            client.redefinir = codigo
            await rest(`${URL_BASE}/cadastro`, {
                method: 'POST',
                body: JSON.stringify({json: client, key: client.key})
            })
            let path = ''
            let pathname = window.location.pathname
            try {
                path = pathname.substring(0, pathname.lastIndexOf('/'))
            } catch (e) {

            }
            let url = `${window.location.origin + path}/redefinir/?email=${email}&codigo=${codigo}`
            this.setState({openLogin: true})
            await this.resendPassword({
                from: 'contato@bitbar.com.br',
                subject: `Recuperação se senha do ${window.location.hostname}`,
                destination: email,
                name: client.nome,
                body: this.body(url, codigo)
            })
            this.setState({email: email, openEsqueci: false, openLogin: false})
            this.props.history.replace({pathname: '/redefinir', search: `?email=${email}`})
        } else {
            this.setState({
                openMessage: true,
                titleMessage: 'Erro ao efetuar login',
                textMessage: message
            })
        }
        this.setState({openLogin: false})
    }

    // alterarSenha = async () => {
    //     let {cliente, redefinirSenha, redefinirSenhaRepetir} = this.state
    //     if (redefinirSenha !== redefinirSenhaRepetir) return alert('senha diferentes')
    //     cliente.senha = redefinirSenha
    //     let conexao = {method: 'POST', body: JSON.stringify({json: cliente, key: cliente.key})}
    //     const {returnCode} = await rest(`${URL_BASE}/cadastro`, conexao)
    //     if (returnCode) {
    //         localStorage.removeItem(`bitmenu-${identifier}:redefinir`)
    //         localStorage.setItem(`bitmenu-${identifier}:cliente`, JSON.stringify(cliente))
    //         this.onclickVoltar()
    //     }
    // }

    onClickCadastre = () => {
        let {email, password} = this.state
        sessionStorage.setItem(`bitmenu-email:`, JSON.stringify({email: email, password: password}))
        this.props.history.replace('/cadastro')
    }

    onClickNoRecord = () => {
        try {
            let cliente = localStorage.getItem(`bitmenu-${identifier}:cliente`)
            cliente = (cliente !== null) ? JSON.parse(cliente) : {}
            cliente.endereco = []
            localStorage.setItem(`bitmenu-${identifier}:cliente`, JSON.stringify(cliente))
            localStorage.setItem(`bitmenu-${identifier}:noRecord`, true)
            this.props.history.replace(goBack)
        } catch (e) {
            console.error(e)
        }
    }

    onclickVoltar = () => this.props.history.replace(goBack)

    personalize = () => {
        try {
            let colors = JSON.parse(localStorage.getItem(`bitmenu-${identifier}:colorStore`))
            const {
                title,
                description,
                price,
                toolbar,
                body,
                cardProduct,
                nextButton,
                fontButton,
                fontToolbar
            } = colors
            this.setState({
                colorStore: {
                    color_title: `#${title}`,
                    color_description: `#${description}`,
                    color_price: `#${price}`,
                    color_toolbar: `#${toolbar}`,
                    color_body: `#${body}`,
                    color_cardProduct: `#${cardProduct}`,
                    color_nextButton: `#${nextButton}`,
                    color_fontButton: `#${fontButton}`,
                    color_fontToolbar: `#${fontToolbar}`
                }
            })
        } catch (e) {

        }
    }

    body = (link, codigo) => {
        return `<html lang="pt-br">
                <head>
                    <meta charset="UTF-8">                
                    <style>
                        body {
                            margin: 0px;
                            width: 100%;
                            background-color: white;
                        }                
                        #toolbar {
                            width: 100%;
                            height: 120px;                            
                        }                
                        #img-logo {
                            width: 80px;
                            height: 80px;          
                            margin: 8px;                
                        }
                        a { 
                            color: #61b4da;                             
                            margin: 8px;
                        }                         
                        #div-main {
                            width: 100%;
                            padding: 8px;
                        }                                                                            
                    </style>
                </head>
                <body>
                    <div id="toolbar">
                        <img src="https://firebasestorage.googleapis.com/v0/b/bitmenu-57f8f.appspot.com/o/2.png?alt=media&token=d18af25e-0d6a-4e3d-9ec2-7bab695803a3" alt="" id="img-logo" />
                    </div>
                    <div id="div-main">
                        <h2>Recuperação de senha</h2>     
                        <h3>1º Copie o código de recuperação</h3>
                        <h3>2º Clique no link abaixo</h3>
                        <h3>Código de recuperação: ${codigo}</h3>                  
                        <a href="${link}">${link}</a>                                                                                                  
                    </div>                                
                </body>                            
                </html>`
    }

    resendPassword = async json => {
        await fetch(`https://bitmenu.com.br/util/email_senha.php`, {
            method: 'post',
            body: JSON.stringify(json)
        })
            .then((response) => response.text())
            .then((data) => {
                this.setState({openForgotIt: true})
            })
            .catch((error) => {
                this.setState({openForgotIt: true})
            })
    }

    // redefinirSenha = async () => {
    //     try {
    //         let redefinir = showData(localStorage.getItem(`bitmenu-${identifier}:redefinir`))
    //         if (redefinir !== null) {
    //             let dados = redefinir.split(':')
    //             const objeto = searchEmJSON(this.props.history.location.search)
    //             if (dados[0] === objeto.redefinir) {
    //                 let conexao = {method: 'POST', body: JSON.stringify({email: dados[1]})}
    //                 const {data} = await rest(`${URL_BASE}/login`, conexao)
    //                 if (data === null) return this.setState({openInvalidEmail: true, openLogin: false})
    //                 this.setState({openRedefinirSenha: true, cliente: Object.values(data)[0]})
    //             }
    //         }
    //     } catch (e) {
    //
    //     }
    // }

    checkGoBak = () => {
        try {
            goBack = this.props.location.state !== undefined ? this.props.location.state.goBack : '/'
        } catch (e) {
            console.error(e)
        }
    }

    componentDidMount() {
        identifier = IDENTIFIER_STORE
        this.personalize()
        this.checkGoBak()
        window.scrollTo(0, 0)
        pageView()
    }

    render() {
        const {
            email,
            openInvalidEmail,
            openMessage,
            openForgotIt,
            openSnackBar,
            snackBar,
            titleMessage,
            textMessage,
            colorStore: {
                color_title,
                color_toolbar,
                color_body,
                color_cardProduct,
                color_fontToolbar,
                color_nextButton,
                color_fontButton
            },
            openLogin,
            openEsqueci
        } = this.state

        const theme = createMuiTheme({
            palette: {
                primary: {
                    main: color_title ? color_title : '#000000'
                }
            },
        })

        const theme2 = createMuiTheme({
            palette: {
                primary: {
                    main: '#000000'
                }
            },
        })

        return (
            <div id="login" style={color_body ? {background: color_body} : {}}>
                <AppBar
                    id="appBar"
                    position="sticky"
                    style={color_toolbar ? {background: color_toolbar} : {}}
                    color="default">
                    <Toolbar variant="dense">
                        <ArrowBackIcon style={color_fontToolbar ? {color: color_fontToolbar} : {}}
                                       id="voltar-sacola"
                                       onClick={this.onclickVoltar}/>
                        <Typography style={color_fontToolbar ? {color: color_fontToolbar} : {}} variant="h6"
                                    color="inherit">Login</Typography>
                    </Toolbar>
                </AppBar>
                <div id="container-login">
                    <FormLabel id="title-login" style={color_title ? {color: color_title} : {}}>
                        Efetuar login
                    </FormLabel>
                    <Card id="card-login" style={color_cardProduct ? {background: color_cardProduct} : {}}>
                        <CardMedia image={bitmenu} id="img-logo"/>
                        <FormLabel id="label-login" style={color_title ? {color: color_title} : {}}>Seu login é
                            único em
                            todas as lojas do Bitmenu delivery</FormLabel>
                        <MuiThemeProvider theme={theme}>
                            <Input
                                margin="dense"
                                name="email"
                                value={this.state.email.toLowerCase()}
                                type="text"
                                fullWidth
                                autoComplete="false"
                                onKeyPress={(e) => this.handleKeyPress(e)}
                                placeholder="E-mail"
                                onChange={this.handleInputs}
                                style={color_title ? {color: color_title, margin: 4} : {margin: 4}}/>
                            <Input
                                margin="dense"
                                name="password"
                                type="password"
                                fullWidth
                                onKeyPress={(e) => this.handleKeyPress(e)}
                                placeholder="Senha"
                                autoComplete="false"
                                onChange={this.handleInputs}
                                style={color_title ? {color: color_title, margin: 4} : {margin: 4}}/>
                            <div className="button-register-forgot-password">
                                <Button
                                    id={'button-login'}
                                    style={{background: color_nextButton, color: color_fontButton}}
                                    fullWidth={true}
                                    variant="contained"
                                    color="primary"
                                    onClick={this.entrar}>
                                    Entrar
                                </Button>
                            </div>
                            <div className="button-register-forgot-password"
                                 style={color_title ? {
                                     color: color_title,
                                     textDecoration: 'underline'
                                 } : {textDecoration: 'underline'}}
                                 onClick={this.onClickEsqueci}>
                                Esqueci minha senha
                            </div>
                        </MuiThemeProvider>
                    </Card>
                    <div id="buttons-login">
                        {
                            !isSafari &&
                            <GoogleLogin
                                className="button-google"
                                clientId="787445357188-6shhl3i451o29km2drtfhtgsnldgisib.apps.googleusercontent.com"
                                buttonText="Google"
                                onSuccess={this.google}
                                onFailure={this.google}
                                cookiePolicy={'single_host_origin'}
                            />
                        }
                        <Button id="button-registre"
                                style={{background: color_cardProduct, color: color_title}}
                                variant="contained" color="primary" onClick={this.onClickCadastre}>
                            Cadastrar-se
                        </Button>
                        <Button id="btn-no-record"
                                style={{background: color_nextButton, color: color_fontButton}}
                                onClick={this.onClickNoRecord}>
                            Comprar sem cadastro
                        </Button>
                    </div>
                    <MuiThemeProvider theme={theme2}>
                        <Dialog open={openInvalidEmail} onClose={this.closeInvalidEmail}
                                aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">E-mail não encontrado</DialogTitle>
                            <DialogContent>
                                <FormLabel>
                                    E-mail não cadastrado, deseja se cadastrar ?
                                </FormLabel>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="outlined" onClick={this.closeInvalidEmail}
                                        color="primary">Não</Button>
                                <Button variant="outlined" onClick={this.onClickInvalidEmail}
                                        color="primary">Sim</Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog open={openMessage} onClose={() => this.setState({openMessage: false})}
                                aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">{titleMessage}</DialogTitle>
                            <DialogContent
                                style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <FormLabel style={{textAlign: 'center', margin: 8}}>{textMessage}</FormLabel>
                            </DialogContent>
                        </Dialog>
                        <Dialog open={openForgotIt} onClose={this.closeForgotIt}
                                aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">Recuperação de senha</DialogTitle>
                            <DialogContent>
                                <DialogContentText>Um link foi enviado para seu email</DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="outlined" onClick={this.closeForgotIt} color="primary">Ok</Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog open={openEsqueci} onClose={this.cancelaEsqueci}
                                aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">Recuperação de senha</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Digite seu e-mail para receber um link de redefinição de senha.
                                </DialogContentText>
                                <TextField variant="outlined" margin="dense" name="email" type="text" fullWidth
                                           placeholder="E-mail"
                                           onChange={this.handleInputs} value={email.toLowerCase()}
                                           style={{margin: 4}}/>
                            </DialogContent>
                            <DialogActions>
                                <Button variant="outlined" onClick={this.esqueci} color="primary">Confirmar</Button>
                            </DialogActions>
                        </Dialog>
                        {/*<Dialog open={openRedefinirSenha} onClose={this.cancelaEsqueci}*/}
                        {/*        aria-labelledby="form-dialog-title">*/}
                        {/*    <DialogTitle id="form-dialog-title">Redefinir a senha</DialogTitle>*/}
                        {/*    <DialogContent>*/}
                        {/*        <DialogContentText>Digite sua nova senha</DialogContentText>*/}
                        {/*        <TextField variant="outlined" margin="dense" name="redefinirSenha" type="text"*/}
                        {/*                   fullWidth*/}
                        {/*                   placeholder="Senha"*/}
                        {/*                   value={redefinirSenha} onChange={this.handleInputs}*/}
                        {/*                   style={{margin: 4}}/>*/}
                        {/*        <TextField variant="outlined" margin="dense" name="redefinirSenhaRepetir"*/}
                        {/*                   type="text"*/}
                        {/*                   fullWidth*/}
                        {/*                   placeholder="Repetir senha" value={redefinirSenhaRepetir}*/}
                        {/*                   onChange={this.handleInputs}*/}
                        {/*                   style={{margin: 4}}/>*/}
                        {/*    </DialogContent>*/}
                        {/*    <DialogActions>*/}
                        {/*        <Button onClick={this.alterarSenha} color="primary">Confirmar</Button>*/}
                        {/*    </DialogActions>*/}
                        {/*</Dialog>*/}
                        <Dialog open={openLogin} aria-labelledby="form-dialog-title">
                            <DialogContent id="dialog-content-login">
                                <CircularProgress id="progress-bar-login" style={{color: '#1e1e1e'}} size={30}/>
                                <DialogContentText id="dialog-text-login">Carregando ...</DialogContentText>
                            </DialogContent>
                        </Dialog>
                        <Snackbar
                            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                            open={openSnackBar}
                            autoHideDuration={2000}
                            onClose={() => this.setState({openSnackBar: false})}
                            message={snackBar}
                        />
                    </MuiThemeProvider>
                </div>
            </div>
        )
    }
}

export default withRouter(Login)
