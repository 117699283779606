import React from "react"
import {Card, CardContent, FormLabel} from "@material-ui/core"
import './ListaPedidos.css'

class ListaPedidos extends React.Component {

    onClick = order => {
        this.props.handleChange(order)
    }

    orderId = orderId => {
        try {
            return `Nº #${orderId.substring(orderId.length - 4)}`
        } catch (e) {

        }
        return `Nº #${orderId}`
    }

    render() {
        const {color_title, color_cardProduct} = this.props.colors
        const {order} = this.props
        let date = order.dataHora
        date = date.split(' ')
        let year = date[0]
        year = year.split('-')
        year = `${year[2]}/${year[1]}/${year[0]}`
        date = `${date[1]} ${year}`
        return (
            <Card id='card-order' onClick={() => this.onClick(order)}
                  style={color_cardProduct ? {background: color_cardProduct} : {}}>
                <CardContent id="card-order-history">
                    <FormLabel id="first-order"
                               style={color_title ? {color: color_title} : {}}>{`${order.itens[0].quantidade} x ${order.itens[0].titulo}`}</FormLabel>
                    <FormLabel style={color_title ? {color: color_title} : {}}
                               id="date-order">{this.orderId(order.id_pedido)}</FormLabel>
                    <FormLabel style={color_title ? {color: color_title} : {}}
                               id="date-order">{`Data: ${date}`}</FormLabel>
                    <FormLabel style={color_title ? {color: color_title} : {}}
                               id="date-order">{`Total: ${order.total.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                    })}`}</FormLabel>
                    {
                        order.pagamentos !== undefined &&
                        <FormLabel style={color_title ? {color: color_title} : {}}
                                   id="date-order">{`Tipo de pagamento: ${order.pagamentos[0].titulo}`}</FormLabel>
                    }
                    {
                        order.retorno.status !== undefined &&
                        <FormLabel style={color_title ? {color: color_title} : {}}
                                   id="date-order">{order.retorno.status === 'PIX' ? '(PIX) Aguardando pagamento' : `Status: (${order.retorno.status}) ${order.retorno.mensagem !== undefined ? order.retorno.mensagem : ''}`}  </FormLabel>
                    }
                </CardContent>
            </Card>
        )
    }
}

export default ListaPedidos
