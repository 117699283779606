import React from 'react'

class NotFound extends React.Component {

    componentDidMount() {
        this.props.history.push(`/`)
    }

    render() {
        return (
            <div/>
        )
    }
}

export default NotFound
