import React from 'react'
import './redefinir.css'
import {
    Button,
    Card,
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Snackbar,
    TextField,
    FormLabel, CircularProgress
} from '@material-ui/core'
import logo from '../../images/logo.png'
import {rest, URL_BASE, searchEmJSON, hideData, IDENTIFIER_STORE} from '../../util'
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles'

let identifier

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#000000'
        }
    },
})

class Redefinir extends React.Component {

    state = {
        email: '',
        code: '',
        openRedefinirSenha: false,
        openChangePassword: false,
        openReload: false,
        senha: '',
        senhaRepetir: '',
        openSnackBar: '',
        snackBar: ''
    }

    handleInput = e => this.setState({[e.target.name]: e.target.value})

    checkCode = async () => {
        const {code, email} = this.state
        this.setState({openReload: true})
        const {returnCode, data} = await rest(`${URL_BASE}/login/redefinir`, {
            method: 'POST',
            body: JSON.stringify({code: code, email: email})
        })
        this.setState({openReload: false})
        if (returnCode && data) {
            this.setState({openRedefinirSenha: true, client: data})
        } else {
            return this.setState({openSnackBar: true, snackBar: 'Código inválido'})
        }
    }

    alterarSenha = async () => {
        let {senha, senhaRepetir, client} = this.state
        senha = senha.trim()
        if (senha.length < 5)
            return this.setState({openSnackBar: true, snackBar: 'Senha deve ter 6 caracteres'})
        if (senha !== senhaRepetir)
            return this.setState({openSnackBar: true, snackBar: 'Senhas diferentes'})
        this.setState({openRedefinirSenha: false, openReload: true})
        const {returnCode, data} = await rest(`${URL_BASE}/cadastro`, {
            method: 'PUT',
            body: JSON.stringify({password: senha, client: client})
        })
        this.setState({openReload: false})
        if (returnCode) {
            localStorage.setItem(`bitmenu-${identifier}:s`, hideData(senha))
            localStorage.setItem(`bitmenu-${identifier}:cliente`, JSON.stringify(data))
            this.setState({openChangePassword: true})
        }
    }

    closeChangePassword = () => this.props.history.replace('/')

    reloadPage = () => {
        try {
            const {email, codigo} = searchEmJSON(window.location.search)
            this.setState({email: email, code: codigo !== undefined ? codigo : ''})
        } catch (e) {

        }
    }

    componentDidMount() {
        identifier = IDENTIFIER_STORE
        this.reloadPage()
    }

    render() {
        const {openRedefinirSenha, code, openChangePassword, openReload, openSnackBar, snackBar} = this.state
        return (
            <MuiThemeProvider theme={theme}>
                <div id="redefinir">
                    <Card id="container-redefinir">
                        <CardMedia id="img-logo" image={logo}/>
                        <FormLabel id="label-title-reset">
                            O digite o código de verificação enviado para seu e-mail, e clique em verificar código
                        </FormLabel>
                        <div id="div-redefinir-input">
                            <TextField fullWidth={true} placeholder="Código de verificação" name="code"
                                       variant="outlined" value={code}
                                       onChange={this.handleInput}/>
                        </div>
                        <div id="div-redefinir-button">
                            <Button fullWidth={true} variant="outlined" onClick={this.checkCode}>Verificar
                                Código</Button>
                        </div>
                    </Card>
                    <Dialog open={openRedefinirSenha} onClose={() => this.setState({openRedefinirSenha: false})}
                            aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Redefinir a senha</DialogTitle>
                        <DialogContent>
                            <DialogContentText>Digite sua nova senha</DialogContentText>
                            <TextField variant="outlined" margin="dense" name="senha" type="text" fullWidth
                                       placeholder="Senha"
                                       onChange={this.handleInput}
                                       style={{margin: 4}}/>
                            <TextField variant="outlined" margin="dense" name="senhaRepetir" type="text"
                                       fullWidth
                                       placeholder="Repetir senha"
                                       onChange={this.handleInput}
                                       style={{margin: 4}}/>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" onClick={() => this.setState({openRedefinirSenha: false})}
                                    color="primary">Cancelar</Button>
                            <Button variant="outlined" onClick={this.alterarSenha} color="primary">Confirmar</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={openChangePassword} onClose={this.closeChangePassword}
                            aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Recuperação de senha</DialogTitle>
                        <DialogContent>
                            <DialogContentText>Sua senha foi alterada com sucesso</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" onClick={this.closeChangePassword} color="primary">Ok</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={openReload} aria-labelledby="form-dialog-title">
                        <DialogContent id="dialog-content-login">
                            <CircularProgress id="progress-bar-login" style={{color: '#1e1e1e'}} size={30}/>
                            <DialogContentText id="dialog-text-login">Carregando ...</DialogContentText>
                        </DialogContent>
                    </Dialog>
                    <Snackbar
                        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                        open={openSnackBar}
                        autoHideDuration={2000}
                        onClose={() => this.setState({openSnackBar: false})}
                        message={snackBar}
                    />
                </div>
            </MuiThemeProvider>
        )
    }
}

export default Redefinir
