import React from 'react'
import {withRouter} from 'react-router-dom'
import {CardMedia, FormLabel} from '@material-ui/core'
import './Destaques.css'
import {eventGA4, IDENTIFIER_STORE} from '../../util'
import LazyLoad from 'react-lazy-load'

let identifier

class Destaques extends React.Component {

    onClickCard = () => {
        let {favorites} = this.props.data
        if (favorites === false) {
            this.props.handleChange('Este produto está indisponível no momento')
        } else {
            let positionPage = JSON.stringify(window.scrollY)
            sessionStorage.setItem(`bitmenu-${identifier}:positionPage`, positionPage)
            this.props.history.push({pathname: '/sacola', state: {dados: this.props.data}})
            try {
                let items = [{
                    item_id: this.props.data.sku,
                    item_name: this.props.data.product
                }]
                eventGA4('select_promotion', {creative_name: 'Destaques', items: items})
            } catch (e) {

            }
        }
    }

    clearText = text => {
        text = text.normalize("NFD").replace(/[^a-zA-Zs]/g, "")
        return text.toLowerCase()
    }

    componentDidMount() {
        identifier = IDENTIFIER_STORE
    }

    render() {
        let {
            product,
            description,
            image,
            oldPrice,
            price,
            prices,
            visibleStepOne,
            variations,
            cashback
        } = this.props.data
        //image = image.replace('http', 'https')
        image = `${image}?time=${sessionStorage.getItem('bitmenu:dataImage')}`
        visibleStepOne = (visibleStepOne !== undefined) ? visibleStepOne : true
        let variousPrices = false
        try {
            if (variations !== undefined && variations.length !== 0) {
                if ((variations[0].visibility !== undefined && variations[0].visibility.includes('DELIVERY')) || (variations[0].options[0]).sku.includes('S')) {
                    if (prices !== undefined && prices.length > 0) {
                        variousPrices = true
                    }
                }
            }
        } catch (e) {
            console.log(e)
        }
        const {color_title, color_description, color_price, color_cardProduct} = this.props.colors
        if (image === '' || description === '')
            return (<div/>)
        return (
            <div id="card-highlights" onClick={this.onClickCard}
                 style={color_cardProduct ? {background: color_cardProduct} : {}}>
                <div id="div-content-highlights"
                     style={color_cardProduct ? {background: color_cardProduct} : {}}>
                    <div id="div-image-highlights">
                        <LazyLoad>
                            <CardMedia id="card-media-highlights" image={image}/>
                        </LazyLoad>
                    </div>
                    <div id="div-descriptions-highlights"
                         style={color_cardProduct ? {background: color_cardProduct} : {}}>
                        <FormLabel id="label-product-highlights"
                                   style={color_title ? {color: color_title} : {}}>{product}</FormLabel>
                        {
                            description !== '' &&
                            <FormLabel id="label-description-highlights"
                                       style={color_description ? {color: color_description} : {}}>{description}</FormLabel>
                        }
                        {
                            (cashback !== 0 && cashback !== undefined) &&
                            <FormLabel id="price"
                                       style={color_description ? {
                                           color: color_description,
                                           fontSize: 14
                                       } : {}}>Cashback: {cashback.toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL'
                            })}</FormLabel>
                        }
                        {
                            (variousPrices && visibleStepOne)
                                ?
                                prices.map((i, index) => {
                                    return (
                                        <FormLabel key={index}
                                                   id="price-highlights"
                                                   style={color_price ? {color: color_price} : {}}>{`${i.description} ${i.price.toLocaleString('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL'
                                        })}`}</FormLabel>
                                    )
                                })
                                :
                                <div>
                                    {
                                        price !== 0 &&
                                        <FormLabel id="price"
                                                   style={color_price ? {color: color_price} : {}}>{price.toLocaleString('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL'
                                        })}</FormLabel>
                                    }
                                </div>
                        }
                        {
                            oldPrice !== 0 &&
                            <FormLabel
                                id="label-price-old-highlights">{oldPrice.toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL'
                            })}</FormLabel>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Destaques)
