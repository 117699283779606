import React from 'react'
import {
    Box,
    Button,
    Card,
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormLabel, Snackbar, TextField
} from '@material-ui/core'
import {Add, Add as AddIcon, Close, Remove, Remove as RemoveIcon} from '@material-ui/icons'
import './ButtonAdd.css'
import {IDENTIFIER_STORE} from '../../util'
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles'
import LazyLoad from "react-lazy-load";

let identifier

class ButtonAdd extends React.Component {

    componentWillReceiveProps(props) {
        try {
            const {sku_delete} = this.props
            if (sku_delete !== '' && sku_delete !== undefined) {
                let s = this.state
                delete s[sku_delete]
                this.setState(s)
            }
        } catch (e) {

        }
    }

    state = {
        showPicture: false,
        showDescription: false,
        showPhoto: false,
        filter: '',
        openAmount: false,
        sku: '',
        snackbar: {
            open: false
        }
    }

    handleClose = () => {
        this.setState({snackbar: {open: false, message: ''}})
    }

    handleInputAmount = e => {
        try {
            const {variation, maxSelection, amountType, step, key, ordem} = this.props.variations
            let priceVariation = this.props.variations.price !== undefined ? this.props.variations.price : 0
            const {sku, option, price} = this.state
            const qtdeMax = maxSelection
            if ((e.target.value !== 'e')) {
                let quantidade = parseInt(e.target.value)
                let quantidadeOutrosItens = 0
                this.props.itens.forEach(i => {
                    if ((i.key === key) && (i.sku !== sku)) {
                        quantidadeOutrosItens += i.amount
                    }
                })
                if ((quantidade + quantidadeOutrosItens) > qtdeMax) {
                    let options = {
                        step: step !== undefined ? step : '',
                        variation: variation,
                        option: option,
                        sku: sku,
                        price: price,
                        fixedPrice: price,
                        amount: (qtdeMax - quantidadeOutrosItens),
                        amountType: amountType,
                        priceVariation: priceVariation,
                        limit: maxSelection,
                        key: key,
                        ordem: ordem
                    }
                    this.setState({
                        [sku]: (qtdeMax - quantidadeOutrosItens),
                        snackbar: {
                            open: true,
                            message: 'Você atingiu o quantidade máxima!'
                        }
                    })
                    this.props.handleChange(options)
                } else {
                    if (!(e.target.value < 0)) {
                        let quantidade = parseInt(e.target.value)
                        let options = {
                            step: step !== undefined ? step : '',
                            variation: variation,
                            option: option,
                            sku: sku,
                            price: price,
                            fixedPrice: price,
                            amount: ((quantidade === 0 || isNaN(quantidade)) ? 1 : quantidade),
                            amountType: amountType,
                            priceVariation: priceVariation,
                            limit: maxSelection,
                            key: key,
                            ordem: ordem
                        }
                        this.setState({[sku]: quantidade})
                        this.props.handleChange(options)
                    }
                }
            }
        } catch (e) {
            this.setState({snackbar: {open: true, message: e.message}})
        }
    }

    onClickAddAmount = (amount, iniciaZero = true) => {
        try {
            const {variation, maxSelection, amountType, step, key, ordem} = this.props.variations
            let priceVariation = this.props.variations.price !== undefined ? this.props.variations.price : 0
            const {sku, option, price} = this.state
            let quantidade = this.state[sku]
            quantidade = ((quantidade === 1) && (iniciaZero)) ? 0 : quantidade
            quantidade += amount
            let quantidadeOutrosItens = 0
            this.props.itens.forEach(i => {
                if ((i.key === key) && (i.sku !== sku)) {
                    quantidadeOutrosItens += i.amount
                }
            })
            if ((quantidade + quantidadeOutrosItens) > maxSelection) {
                let options = {
                    step: step !== undefined ? step : '',
                    variation: variation,
                    option: option,
                    sku: sku,
                    price: price,
                    fixedPrice: price,
                    amount: (maxSelection - quantidadeOutrosItens),
                    amountType: amountType,
                    priceVariation: priceVariation,
                    limit: maxSelection,
                    key: key,
                    ordem: ordem
                }
                this.setState({
                    [sku]: (maxSelection - quantidadeOutrosItens),
                    snackbar: {
                        open: true,
                        message: 'Você atingiu o quantidade máxima!'
                    }
                })
                this.props.handleChange(options)
            } else {
                let options = {
                    step: step !== undefined ? step : '',
                    variation: variation,
                    option: option,
                    sku: sku,
                    price: price,
                    fixedPrice: price,
                    amount: ((quantidade === 0) ? 1 : quantidade),
                    amountType: amountType,
                    priceVariation: priceVariation,
                    limit: maxSelection,
                    key: key,
                    ordem: ordem
                }
                this.setState({[sku]: ((quantidade === 0) ? 1 : quantidade)})
                this.props.handleChange(options)
            }
        } catch (e) {
            this.setState({snackbar: {open: true, message: e.message}})
        }
    }

    onCloseAmount = () => {
        /* eslint-disable */
        const {sku} = this.state
        let quantidade = this.state[sku]
        this.setState({
            [sku]: ((quantidade === '' || quantidade == '0' || isNaN(quantidade)) ? 1 : quantidade),
            openAmount: false
        })
        /* eslint-disable */
    }

    clickAmount = (sku, option, price) => {
        this.setState({openAmount: true, sku: sku, option: option, price: price})
    }

    buttonMais = obj => {
        let {variation, maxSelection, minSelection, amountType, step, key, ordem} = this.props.variations
        let priceVariation = this.props.variations.price !== undefined ? this.props.variations.price : 0
        const {sku, option, price} = obj

        let amount = this.state[sku]
        amount = (amount !== undefined) ? ++amount : 1
        let options = {
            step: step !== undefined ? step : '',
            variation: variation,
            option: option,
            sku: sku,
            price: price,
            fixedPrice: price,
            amount: amount,
            amountType: amountType,
            priceVariation: priceVariation,
            limit: maxSelection,
            key: key,
            ordem: ordem
        }

        let quantidade = 0
        this.props.itens.forEach(i => {
            if (i.key === key) quantidade += i.amount
        })

        options.currentQuantity = (quantidade + 1)

        if (quantidade < maxSelection) {
            this.createOptions(options)
        } else {
            let snackbar = {open: true, message: 'Você atingiu o limite de seleções'}
            this.props.handleChange(snackbar)
        }

        if (++quantidade >= minSelection) {
            this.setState({[variation]: false})
        }
    }

    buttonMenos = obj => {
        let {variation, minSelection, amountType, step, key} = this.props.variations
        const {sku, option, price} = obj
        let amount = this.state[sku]
        if (amount === undefined) {
            amount = 1
        }

        if (--amount !== -1) {
            let options = {
                step: step !== undefined ? step : '',
                variation: variation,
                option: option,
                sku: sku,
                price: price,
                fixedPrice: price,
                amount: amount,
                amountType: amountType,
                key: key
            }
            this.createOptions(options)
        }

        let quantidade = 0
        this.props.itens.forEach(i => {
            if (i.key === key) quantidade += i.amount
        })

        if (quantidade < minSelection) {
            this.setState({[variation]: true})
        }
    }

    createOptions = (opt) => {
        const {sku, amount} = opt
        this.setState({[sku]: amount})
        this.props.handleChange(opt)
    }

    clearText = text => {
        text = text.normalize("NFD").replace(/[^a-zA-Zs]/g, "")
        return text.toLowerCase()
    }

    clickImage = image => {
        this.props.handleChange({image: image})
    }

    showPicture = options => {
        let showPicture = false
        try {
            for (let i = 0; i < options.length; i++) {
                if (options[i].image !== '') {
                    showPicture = true
                    break
                }
            }
        } catch (e) {

        }
        let showDescription = false
        let showPhoto = false
        try {
            showDescription = JSON.parse(localStorage.getItem(`bitmenu-${identifier}:showDescription`))
            showPhoto = JSON.parse(localStorage.getItem(`bitmenu-${identifier}:showPhoto`))
        } catch (e) {

        }
        this.setState({showPicture: showPicture, showDescription: showDescription, showPhoto: showPhoto})
    }

    checkVariations = () => {
        const {variations, options} = this.props
        this.setState({[variations.variation]: true})
        this.showPicture(options)
    }

    componentDidMount() {
        identifier = IDENTIFIER_STORE
        this.checkVariations()
    }

    render() {
        const {sku} = this.state
        const {
            obrigatorio,
            options,
            variations: {
                maxSelection,
                variation,
                price,
                key,
                amountType
            },
            filterFlavor
        } = this.props
        const {
            color_title,
            color_toolbar,
            color_cardProduct,
            color_description,
            color_price,
            color_fontToolbar
        } = this.props.colors
        const {showPicture, showDescription, showPhoto, filter} = this.state
        const buttons = createMuiTheme({
            palette: {
                primary: {
                    main: color_title ? color_title : '#ffffff'
                }
            },
        })
        const themeDialog = createMuiTheme({
            palette: {
                primary: {
                    main: '#000000',
                }
            },
        })
        return (
            <Card id="card-adicionais" style={color_cardProduct ? {background: color_cardProduct} : {}}>
                <div id={key}/>
                {
                    obrigatorio
                        ?
                        <div id="div-titulo-button">
                            <div id="card-titulo-button" style={color_toolbar ? {background: color_toolbar} : {}}>
                                <div id="card-texto-button">
                                    <FormLabel id="variation-button"
                                               style={color_fontToolbar ? {color: color_fontToolbar} : {}}>{`${variation} escolha até ${maxSelection}`}</FormLabel>
                                </div>
                                {
                                    this.state[variation] &&
                                    <div id="card-obrigatorio-button">
                                        <FormLabel id="variacao-obrigatorio-button">OBRIGATÓRIO</FormLabel>
                                    </div>
                                }
                            </div>
                            {
                                price &&
                                <div id="div-title-price">
                                    <FormLabel id="price-variation"
                                               style={color_price ? {color: color_price} : {}}>{price.toLocaleString('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL'
                                    })}</FormLabel>
                                </div>
                            }
                        </div>
                        :
                        <div id="div-titulo-button">
                            <div id="card-titulo-button" style={color_toolbar ? {background: color_toolbar} : {}}>
                                <div id="card-texto-button">
                                    <FormLabel
                                        id="variation-button"
                                        style={color_fontToolbar ? {color: color_fontToolbar} : {}}>{`${variation} escolha até ${maxSelection}`}</FormLabel>
                                </div>
                                {
                                    price &&
                                    <div id="div-title-price">
                                        <FormLabel id="price-variation"
                                                   style={color_price ? {color: color_price} : {}}>{price.toLocaleString('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL'
                                        })}</FormLabel>
                                    </div>
                                }
                            </div>
                        </div>
                }
                <Divider/>
                {
                    (filterFlavor !== undefined && filterFlavor.length > 1) &&
                    <div id={'div-filter-flavor'}>
                        <div id={'div-title-filter-flavor'}>
                            <FormLabel style={{color: color_title, fontSize: "medium"}}>
                                Filtro de sabores
                            </FormLabel>
                            {
                                (filter !== '') &&
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                    backgroundColor: '#a9a9a9',
                                    borderRadius: 15,
                                    padding: 4
                                }}
                                     onClick={() => this.setState({filter: ''})}>
                                    <FormLabel
                                        style={{cursor: "pointer", color: 'white', fontSize: "medium", margin: 4}}>
                                        Limpar filtro
                                    </FormLabel>
                                    <Box p={1}/>
                                    <Close style={{cursor: "pointer", color: 'white'}}/>
                                </div>
                            }
                        </div>
                        <MuiThemeProvider theme={buttons}>
                            <div id={'div-filter-flavor-button'}>
                                {
                                    filterFlavor.map(f => (
                                        <div key={f}>
                                            <Button
                                                color={'primary'}
                                                style={{margin: 8}}
                                                id={'button-filter-flavor'}
                                                variant={'outlined'}
                                                onClick={() => this.setState({filter: f})}>
                                                {f}
                                            </Button>

                                        </div>

                                    ))
                                }
                            </div>
                        </MuiThemeProvider>
                    </div>
                }
                {
                    (filterFlavor !== undefined && filterFlavor.length !== 0) &&
                    <Divider/>
                }
                {
                    (filter !== '') &&
                    <div style={{
                        width: '100%',
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: 10
                    }}>
                        <FormLabel style={{color: color_title, fontSize: "medium"}}>
                            {filter}
                        </FormLabel>
                        <Divider/>
                    </div>
                }
                <div>
                    {
                        // eslint-disable-next-line
                        options.map((i, index) => {
                            if (filter === '' || i.category === filter) {
                                return (
                                    <div id={this.clearText(i.option)} className="adicionais" key={index}>
                                        {
                                            (showPicture && (i.sku.includes('S') || showPhoto)) &&
                                            <div id="div-image-flavor" onClick={() => this.clickImage(i.image)}>
                                                <LazyLoad>
                                                    <CardMedia id="image-flavor"
                                                               image={i.image !== undefined ? i.image : ''}/>
                                                </LazyLoad>
                                            </div>
                                        }
                                        <div className="descritivos">
                                            <div id="div-descritivos-button">
                                                <FormLabel id="produto"
                                                           style={color_title ? {color: color_title} : {}}>{i.option}</FormLabel>
                                                {(showDescription || i.sku.includes('S')) && i.description !== '' &&
                                                    <FormLabel id="description-button"
                                                               style={color_description ? {color: color_description} : {}}>{i.description.replaceAll(',', ', ')}</FormLabel>}
                                                <div id="div-prices-button">
                                                    {
                                                        ((i.price !== 0) && (amountType !== 'AVERAGE')) &&
                                                        <FormLabel id="preco-button"
                                                                   style={color_price ? {color: color_price} : {}}>{i.price.toLocaleString('pt-BR', {
                                                            style: 'currency',
                                                            currency: 'BRL'
                                                        })}
                                                        </FormLabel>
                                                    }
                                                    {
                                                        i.oldPrice !== undefined && i.price !== 0 &&
                                                        <FormLabel
                                                            id="preco-button-old">{i.oldPrice.toLocaleString('pt-BR', {
                                                            style: 'currency',
                                                            currency: 'BRL'
                                                        })}
                                                        </FormLabel>
                                                    }
                                                    {
                                                        ((i.price !== 0) && i.sku.includes('S') && (amountType === 'AVERAGE')) &&
                                                        <FormLabel id="preco-button"
                                                                   style={color_price ? {color: color_price} : {}}>
                                                            {`+ ${(i.price / maxSelection).toLocaleString('pt-BR', {
                                                                style: 'currency',
                                                                currency: 'BRL'
                                                            })} por sabor`}
                                                        </FormLabel>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div id="botoes">
                                            {
                                                (!isNaN(this.state[i.sku]) && this.state[i.sku] !== undefined && this.state[i.sku] !== 0) &&
                                                <div>
                                                    <RemoveIcon
                                                        style={color_title ? {color: color_title} : {color: '#000000'}}
                                                        className="btn-menos"
                                                        onClick={() => this.buttonMenos(i)}/>
                                                </div>
                                            }
                                            {
                                                (!isNaN(this.state[i.sku]) && this.state[i.sku] !== undefined && this.state[i.sku] !== 0) &&
                                                <FormLabel id="amount" name={i.sku}
                                                           style={color_title ? {color: color_title} : {color: '#000000'}}
                                                           onChange={this.handleChange}
                                                           onClick={() => this.clickAmount(i.sku, i.option, i.price)}>{this.state[i.sku] === undefined ? 0 : this.state[i.sku]}</FormLabel>
                                            }
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <AddIcon style={color_title ? {color: color_title} : {color: '#000000'}}
                                                         className="btn-mais"
                                                         onClick={() => this.buttonMais(i)}/>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
                <MuiThemeProvider theme={themeDialog}>
                    <Dialog open={this.state.openAmount} onClose={() => this.onCloseAmount()}>
                        <DialogTitle>Quantidade</DialogTitle>
                        <DialogContent id={'dialog-content-select-amount'}>
                            <Box id={'box-dialog-amount'}>
                                <Button variant={'outlined'} onClick={() => this.onClickAddAmount(-1, false)}>
                                    <Remove/>
                                </Button>
                                <Box p={1}/>
                                <TextField
                                    id={'text-field-dialog-amount'}
                                    variant={'outlined'}
                                    placeholder={'10'}
                                    type={'number'}
                                    margin='dense'
                                    name='quantidade'
                                    value={this.state[sku]}
                                    onChange={this.handleInputAmount}/>
                                <Box p={1}/>
                                <Button variant={'outlined'} onClick={() => this.onClickAddAmount(1, false)}>
                                    <Add/>
                                </Button>
                            </Box>
                            <Box p={1}/>
                            <div id={'div-dialog-amount'}
                                 style={(maxSelection >= 10) ? {justifyContent: 'space-between'} : {justifyContent: 'center'}}>
                                {
                                    (maxSelection >= 5) &&
                                    <Button
                                        id={'button-dialog-amount'}
                                        variant={'outlined'}
                                        onClick={() => this.onClickAddAmount(5)}
                                        color="primary">
                                        + 5
                                    </Button>
                                }
                                {
                                    (maxSelection >= 10) &&
                                    <Box p={1}/>
                                }
                                {
                                    (maxSelection >= 10) &&
                                    <Button
                                        id={'button-dialog-amount'}
                                        variant={'outlined'}
                                        onClick={() => this.onClickAddAmount(10)}
                                        color="primary">
                                        + 10
                                    </Button>
                                }

                            </div>
                            <Box p={1}/>
                            <div id={'div-dialog-amount'}
                                 style={(maxSelection >= 50) ? {justifyContent: 'space-between'} : {justifyContent: 'center'}}>
                                {
                                    (maxSelection >= 25) &&
                                    <Button
                                        id={'button-dialog-amount'}
                                        variant={'outlined'}
                                        onClick={() => this.onClickAddAmount(25)}
                                        color="primary">
                                        + 25
                                    </Button>
                                }
                                {
                                    (maxSelection >= 50) &&
                                    <Box p={1}/>
                                }
                                {
                                    (maxSelection >= 50) &&
                                    <Button
                                        id={'button-dialog-amount'}
                                        variant={'outlined'}
                                        onClick={() => this.onClickAddAmount(50)}
                                        color="primary">
                                        + 50
                                    </Button>
                                }
                            </div>
                        </DialogContent>
                        <DialogActions style={{paddingRight: 24, paddingBottom: 10}}>
                            <Button
                                id={'button-dialog'}
                                variant={'contained'}
                                onClick={() => this.onCloseAmount()}
                                color="primary">
                                Fechar
                            </Button>
                        </DialogActions>
                    </Dialog>
                </MuiThemeProvider>
                <Snackbar
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    open={this.state.snackbar.open}
                    autoHideDuration={1000}
                    onClose={this.handleClose}
                    message={this.state.snackbar.message}
                />
            </Card>
        )
    }
}

export default ButtonAdd
