import React from 'react'

import {FormControlLabel, Checkbox, Card, FormLabel, Divider} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import './CheckBox.css'

const RedCheck = withStyles({
    checked: {},
})(props => <Checkbox color="default" {...props} />)

class CheckBox extends React.Component {

    onCheck = (obj, e) => {
        let options
        const {step, variation, ordem} = this.props.variations
        if (e.target.checked) {
            options = {
                step: step,
                variation: variation,
                option: obj.option,
                sku: obj.sku,
                price: 0,
                amount: 1,
                ordem: ordem
            }
        } else {
            options = {
                step: step,
                variation: variation,
                option: obj.option,
                sku: obj.sku,
                price: 0,
                amount: 0,
                ordem: ordem
            }
        }
        this.props.handleChange(options)
    }

    clearText = text => {
        text = text.normalize("NFD").replace(/[^a-zA-Zs]/g, "")
        return text.toLowerCase()
    }

    render() {
        const {variations: {variation, key}} = this.props
        const {color_title, color_toolbar, color_cardProduct, color_fontToolbar} = this.props.colors
        return (
            <Card id="card-check" style={color_cardProduct ? {background: color_cardProduct} : {}}>
                <div id={key}/>
                <div id="card-titulo-check" style={color_toolbar ? {background: color_toolbar} : {}}>
                    <div id="card-texto-check">
                        <FormLabel id="variation-check"
                                   style={color_fontToolbar ? {color: color_fontToolbar} : {}}>{variation}</FormLabel>
                    </div>
                </div>
                <Divider/>
                <div id="check">
                    {
                        this.props.options.map((i, index) => {
                            return (
                                <div id={this.clearText(i.option)} key={index}>
                                    <FormControlLabel id="form-check" control={<RedCheck
                                        style={color_title ? {color: color_title} : {color: '#000000'}}/>}
                                                      label={i.option}
                                                      style={color_title ? {color: color_title} : {}}
                                                      onChange={(e) => this.onCheck(i, e)}/>
                                </div>
                            )
                        })
                    }
                </div>
            </Card>
        )
    }
}

export default CheckBox
